import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

export const AddButton = React.memo(
  ({
    name,
    icon,
    onClick,
    type,
    sx,
    width,
    padding,
    background,
    color,
    disabled,
    borderColor,
  }) => {
    const [responsivePadding, setResponsivePadding] = useState("1.4rem 2.8rem");

    useEffect(() => {
      const updatePadding = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 1920) {
          setResponsivePadding("1.4rem 2.8rem");
        } else if (screenWidth >= 1680) {
          setResponsivePadding("1.2rem 2.6rem");
        } else if (screenWidth >= 1440) {
          setResponsivePadding("1rem 2.2rem");
        } else if (screenWidth >= 1366) {
          setResponsivePadding("0.875rem 1.95rem");
        } else if (screenWidth >= 1280) {
          setResponsivePadding("1.5rem 2.85rem");
        }
      };

      updatePadding();
      window.addEventListener("resize", updatePadding);

      return () => window.removeEventListener("resize", updatePadding);
    }, []);
    return (
      <Button
        className="add_Button"
        type={type ? type : null}
        style={{
          width: width ? width : "auto",
          backgroundColor: disabled
            ? "#ACAEC3"
            : background
              ? background
              : "var(--secondary)",
          padding: padding ? padding : responsivePadding,
          border: borderColor ? `1px solid ${borderColor}` : "",
          color: color ? color : "var(--grey-dark)",
        }}
        sx={sx}
        onClick={onClick ? onClick : null}
        disabled={disabled}
      >
        {icon ? icon : null}
        {name ? name : null}
      </Button>
    );
  },
);

export const AddButtonWhite = React.memo(
  ({
    name,
    icon,
    onClick,
    type,
    sx,
    width,
    padding,
    background,
    color,
    disabled,
    borderColor,
  }) => {
    const [responsivePadding, setResponsivePadding] = useState("1.4rem 2.8rem");

    useEffect(() => {
      const updatePadding = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 1920) {
          setResponsivePadding("1.4rem 2.8rem");
        } else if (screenWidth >= 1680) {
          setResponsivePadding("1.2rem 2.6rem");
        } else if (screenWidth >= 1440) {
          setResponsivePadding("1rem 2.2rem");
        } else if (screenWidth >= 1366) {
          setResponsivePadding("0.875rem 1.95rem");
        } else if (screenWidth >= 1280) {
          setResponsivePadding("1.5rem 2.85rem");
        }
      };

      updatePadding();
      window.addEventListener("resize", updatePadding);

      return () => window.removeEventListener("resize", updatePadding);
    }, []);
    return (
      <Button
        className="add_Button"
        type={type ? type : null}
        style={{
          width: width ? width : "auto",
          backgroundColor: "white",
          padding: padding ? padding : responsivePadding,
          border: `1px solid var(--secondary)`,
          color: "var(--secondary)",
        }}
        sx={sx}
        onClick={onClick ? onClick : null}
        disabled={disabled}
      >
        {icon ? icon : null}
        {name ? name : null}
      </Button>
    );
  },
);
