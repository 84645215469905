import { createSlice } from "@reduxjs/toolkit";

const purohitSettingsSlice = createSlice({
    name: "purohitSettings",
    initialState: {
        profileData: [],
        mediaData: [],
        documentData: [],
        experienceData: [],

        error: null,

        fetchLoading: false,
        editMediaDataLoading: false,
        editExperienceDataLoading: false,

        fetchProfileDataStatus: false,
        fetchMediaDataStatus: false,
        fetchDocumentDataStatus: false,
        fetchExperienceDataStatus: false,
        editMediaStatus: false,
        editExperienceStatus: false,
    },
    reducers: {
        fetchExperienceData: (state) => {
            state.experienceData = [];
            state.fetchLoading = true;
            state.error = null;
            state.fetchExperienceDataStatus = false;
        },
        fetchExperienceDataSuccess: (state, action) => {
            state.experienceData = action.payload;
            state.fetchLoading = false;
            state.error = null;
            state.fetchExperienceDataStatus = true;
        },
        fetchExperienceDataFailure: (state, action) => {
            state.experienceData = [];
            state.fetchLoading = false;
            state.error = action.payload;
            state.fetchExperienceDataStatus = false;
        },
        fetchDocumentData: (state) => {
            state.documentData = [];
            state.fetchLoading = true;
            state.error = null;
            state.fetchDocumentDataStatus = false;
        },
        fetchDocumentDataSuccess: (state, action) => {
            state.documentData = action.payload;
            state.fetchLoading = false;
            state.error = null;
            state.fetchDocumentDataStatus = true;
        },
        fetchDocumentDataFailure: (state, action) => {
            state.documentData = [];
            state.fetchLoading = false;
            state.error = action.payload;
            state.fetchDocumentDataStatus = false;
        },
        fetchProfileData: (state) => {
            state.profileData = [];
            state.fetchLoading = true;
            state.error = null;
            state.fetchProfileDataStatus = false;
        },
        fetchProfileDataSuccess: (state, action) => {
            state.profileData = action.payload;
            state.fetchLoading = false;
            state.error = null;
            state.fetchProfileDataStatus = true;
        },
        fetchProfileDataFailure: (state, action) => {
            state.profileData = [];
            state.fetchLoading = false;
            state.error = action.payload;
            state.fetchProfileDataStatus = false;
        },
        fetchMediaData: (state) => {
            state.mediaData = [];
            state.fetchLoading = true;
            state.error = null;
            state.fetchMediaDataStatus = false;
        },
        fetchMediaDataSuccess: (state, action) => {
            state.mediaData = action.payload;
            state.fetchLoading = false;
            state.error = null;
            state.fetchMediaDataStatus = true;
        },
        fetchMediaDataFailure: (state, action) => {
            state.mediaData = [];
            state.fetchLoading = false;
            state.error = action.payload;
            state.fetchMediaDataStatus = false;
        },
        updateExperience: (state) => {
            state.editExperienceDataLoading = true;
            state.error = null;
            state.editExperienceStatus = false;
        },
        updateExperienceSuccess: (state, action) => {
            state.editExperienceDataLoading = false;
            state.error = null;
            state.editExperienceStatus = true;
        },
        updateExperienceFailure: (state, action) => {
            state.editExperienceDataLoading = false;
            state.error = action.payload;
            state.editExperienceStatus = false;
        },
        toggleStatus: (state) => {
            state.fetchProfileDataStatus = false;
            state.fetchMediaDataStatus = false;
            state.fetchDocumentDataStatus = false;
            state.fetchExperienceDataStatus = false;
            state.editMediaStatus = false;
            state.editExperienceStatus = false;
        }
    }
});

export const {
    fetchExperienceData,
    fetchExperienceDataSuccess,
    fetchExperienceDataFailure,
    fetchDocumentData,
    fetchDocumentDataSuccess,
    fetchDocumentDataFailure,
    fetchProfileData,
    fetchProfileDataSuccess,
    fetchProfileDataFailure,
    fetchMediaData,
    fetchMediaDataSuccess,
    fetchMediaDataFailure,
    updateExperience,
    updateExperienceSuccess,
    updateExperienceFailure,
    toggleStatus
} = purohitSettingsSlice.actions;

export default purohitSettingsSlice.reducer;
