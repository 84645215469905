import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid2,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import "../../styles/purohit/UnavailableDatePage.css";
import { useDispatch } from "react-redux";
import { AddButton } from "../../components/Addbutton";
import AntSwitch from "../../components/AntSwitch";
const initialFormState = {
  startDate: "",
  endDate: "",
  startAt: dayjs(),
  endAt: dayjs(),
  days: [],
};

const initialErrorState = {
  startDate: "",
  endDate: "",
  startAt: "",
  endAt: "",
  days: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

const UnavailableDatePage = ({ openUnavailableDate, onClose }) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);

  const [selectedDays, setSelectedDays] = React.useState([]);
  const dispatch = useDispatch();
  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDays(typeof value === "string" ? value.split(",") : value);
  };

  const handleRemoveSelectedItem1 = (index) => {
    const updatedDays = selectedDays.filter((_, i) => i !== index);
    setSelectedDays(updatedDays);
  };

  const handleSubmit = () => {
    const newErrors = { ...initialErrorState };
    let isValid = true;

    if (!formData.startDate) {
      newErrors.startDate = "Start Date is required";
      isValid = false;
    }

    if (!formData.endDate) {
      newErrors.endDate = "End Date is required";
      isValid = false;
    }

    if (!formData.startAt) {
      newErrors.startAt = "Start Time is required";
      isValid = false;
    }
    if (!formData.endAt) {
      newErrors.endDate = "End Time is required";
      isValid = false;
    }

    if (formData.days.length === 0) {
      newErrors.startAt = "Days is required";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      let formArr = new FormData();

      formArr.append("days", JSON.stringify(formData?.days));
      formArr.append("startDate", formData?.startDate);
      formArr.append("endDate", formData?.endDate);
      formArr.append("startAt", formData?.startAt);
      formArr.append("endAt", formData?.endAt);

      //  dispatch(fetchDonationUploadRequest(formArr));
    }
  };

  const handleStatus = () => {};

  return (
    <Dialog
      open={openUnavailableDate}
      onClose={onClose}
      className="Booking-dialog-container"
    >
      <DialogContent className="Booking-dialog-content">
        <div className="Booking-dialog-title-container">
          <div className="onboarding-sub_heading">Set Unavailable Date</div>
          <CloseIcon
            className="Booking-dialog-cancel-icon "
            onClick={onClose}
          />
        </div>
        <div>
          <div>
            <>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <Typography
                    variant="subtitle1"
                    className="donation-form-title"
                  >
                    Start Date
                  </Typography>

                  <div className="muiNormal-date-picker">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          "& .MuiButtonBase-root": { color: "#67687c" },
                          width: "100%",
                        }}
                        value={dayjs(formData?.startDate)}
                        onChange={(newValue) => {
                          setFormData({
                            ...formData,
                            startDate: dayjs(newValue).format("YYYY-MM-DD"),
                          });
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <Typography
                    variant="subtitle1"
                    className="donation-form-title"
                  >
                    End Date
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        "& .MuiButtonBase-root": { color: "#67687c" },
                        width: "100%",
                      }}
                      value={dayjs(formData?.endDate)}
                      onChange={(newValue) => {
                        setFormData({
                          ...formData,
                          endDate: dayjs(newValue).format("YYYY-MM-DD"),
                        });
                      }}
                      defaultValue={dayjs("2022-04-17")}
                    />
                  </LocalizationProvider>
                </Grid2>
              </Grid2>
              <Grid2 container spacing={2} mt={1}>
                <Grid2 size={{ xs: 6, md: 6 }} spacing={1} container>
                  <Grid2 size={{ xs: 6, md: 6 }}>
                    <Typography className="onboarding-form_Heading">
                      Start Time*
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={dayjs(formData.startAt, "hh:mm A")}
                        onChange={(newValue) =>
                          setFormData({
                            ...formData,
                            startAt: newValue.format("hh:mm A"),
                          })
                        }
                      />
                    </LocalizationProvider>
                    {errors.startAt && (
                      <Typography color="error">{errors.startAt}</Typography>
                    )}
                  </Grid2>
                  <Grid2 size={{ xs: 6, md: 6 }}>
                    <Typography className="onboarding-form_Heading">
                      End Time*
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={dayjs(formData.endAt, "hh:mm A")}
                        onChange={(newValue) =>
                          setFormData({
                            ...formData,
                            endAt: newValue.format("hh:mm A"),
                          })
                        }
                      />
                    </LocalizationProvider>
                    {errors.endAt && (
                      <Typography color="error">{errors.endAt}</Typography>
                    )}
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 12 }}>
                {" "}
                <Typography className="OR-Typography">Or</Typography>
              </Grid2>
              <div className="onboarding-sub_heading" style={{marginBottom:'1rem'}}>
                Select Unavailable Days
              </div>
              <Grid2 container spacing={2} mt={1}>
                <Grid2 size={{ xs: 6, md: 6 }}>
                  <div>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <Typography className="onboarding-form_Heading">
                        Days*
                      </Typography>
                      <div></div>
                      <Select
                        labelId="days-label"
                        id="days-select"
                        multiple
                        value={selectedDays}
                        onChange={handleChange1}
                        input={<OutlinedInput />}
                        renderValue={(selected) =>
                          selected.length > 0
                            ? selected.join(", ")
                            : "Select Days"
                        }
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={selectedDays.includes(name)} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <AntSwitch
                          defaultChecked
                          inputProps={{ "aria-label": "ant design" }}
                          onChange={() => handleStatus()}
                        />
                        <p className="paddingZero">Every Week</p>
                      </span>
                    </FormControl>
                    <div>
                      {selectedDays.map((day, index) => (
                        <Chip
                          key={day}
                          label={day}
                          onDelete={() => handleRemoveSelectedItem1(index)}
                          sx={{
                            margin: 0.5,
                            "& .MuiSvgIcon-root": {
                              fill: "black !important",
                            },
                          }}
                        />
                      ))}
                    </div>
                    {errors.days && (
                      <Typography color="error">{errors.days}</Typography>
                    )}
                  </div>
                </Grid2>
              </Grid2>

              <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
                <AddButton
                  name={"Submit"}
                  onClick={handleSubmit}
                />
              </Box>
            </>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UnavailableDatePage;
