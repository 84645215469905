import React, { useState, useCallback } from "react";
import StatsCard from "../../components/cards/StatsCard";
import "../../styles/purohit/inventory.css";
import { Box } from "@mui/material";
import CommonTable from "../../components/CommonTable";
import { AddButton } from "../../components/Addbutton";
import SearchInput from "../../components/CommonSearchBar";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AntSwitch from "../../components/AntSwitch";
import { Link } from "react-router-dom";

const tableRows = [
  {
    name: "Campaign 1",
    orderId: "25821",
    transactionId: "25821",
    prasadamName: "Laddu",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Accepted",
    category: "Pooja Basic",
    sku: "BA1234",
    stock: 200
  },
  {
    name: "Campaign 2",
    orderId: "25821",
    transactionId: "25821",
    prasadamName: "MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Delivered",
    category: "Pooja Basic",
    sku: "BA1234",
    stock: 200
  },
  {
    name: "Campaign 3",
    orderId: "25821",
    transactionId: "25821",
    prasadamName: "Ghee",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Rejected",
    category: "Pooja Basic",
    sku: "BA1234",
    stock: 200
  },
  {
    name: "Campaign 4",
    orderId: "25821",
    transactionId: "25821",
    prasadamName: "MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Pending",
    category: "Pooja Basic",
    sku: "BA1234",
    stock: 200
  },
  {
    name: "Campaign 5",
    orderId: "25821",
    transactionId: "25821",
    prasadamName: "Doraemon",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Delivered",
    category: "Pooja Basic",
    sku: "BA1234",
    stock: 200
  },
];

const InventoryManagement = React.memo(() => {
  const columns  = [
    { field: "prasadamName", headerName: "Product Name" },
    { field: "category", headerName: "Category" },
    { field: "category", headerName: "Sub Category" },
    { field: "sku", headerName: "SKU" },
    { field: "amount", headerName: "Selling price" },
    { field: "stock", headerName: "Available Stock" },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => (
        <span className="action-row">
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
            // onChange={() => handleStatus(row.id, !row.active)}
            // checked={row?.active}
          />
          Active
        </span>
      ),
    },
    {
      field: "startDate", 
      headerName: "Details",
      renderCell: (params) => (
        <span className="action-row">
          <Link
            to="/view-product"
            className="link"
          >
            View
          </Link>
        </span>
      ), 
    },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  return (
    <>
      <Box
        className={"heading"}
        sx={{
          textWrap: "nowrap",
          paddingY: "10px",
        }}
      >
        Inventory Management
      </Box>

      <div className="payment-stats-container">
        <StatsCard
          label={"Total Products"}
          stat={"372"}
          image="/inventory-management-icons/products-icon.svg"
          info="Total Products"
        />
        <StatsCard
          label={"In-Stock Products"}
          stat={"256"}
          image="/inventory-management-icons/in-stock-icon.svg"
          info="In-Stock Products"
        />
        <StatsCard
          label={"Out of Stock Products"}
          stat={"785"}
          image="/inventory-management-icons/out-of-stock-icon.svg"
          info="Out of Stock Products"
        />
      </div>

        <Box>
            <div className="payment-header">
                <Box className="heading">
                    Product List
                </Box>

                <Box sx={{ display: "flex", alignItems: 'center', gap: "0.5rem" }}>
                    <SearchInput />

                    <AddButton
                        icon={<ArrowDropDownIcon />}
                        name="Filter By"
                        borderColor="var(--secondary)"
                        background="#F5F5F5"
                        color="var(--secondary)"
                        padding="1.15rem"
                        // onClick={handleBulkFileDownload}
                    />
                </Box>
            </div>

            <CommonTable
              columns={columns}
              data={tableRows}
              page={page}
              count={count}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
        </Box>
    </>
  );
});

export default InventoryManagement;
