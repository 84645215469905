import React from "react";
import ReactEcharts from "echarts-for-react";
import "../styles/dashboard.css";

const ProductDashboardChart = () => {
  const [filter, setFilter] = React.useState("1w");

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    // Update the chart data based on the filter if needed
  };
  const getOption = () => ({
    tooltip: {
      trigger: "axis",
      backgroundColor: "var(--black)",
      borderColor: "var(--black)",
      //   borderWidth: 1,
      textStyle: {
        color: "var(--off-white)",
      },
      formatter: (params) => {
        const { name, value } = params[0];
        return `
          <div style="text-align: left;">
            <strong>Eye Pledge</strong><br />
            <span> ${value}</span>
          </div>
        `;
      },
    },
    xAxis: {
      type: "category",
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C2C39", // Grid line color for the Y-axis
        },
      },
      axisLine: {
        lineStyle: {
          color: "#C2EBEC", // Axis line color
        },
      },
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
      data: [150, 230, 224, 218, 135, 147, 260],
      axisLine: {
        lineStyle: {
          color: "#C2EBEC", // Axis line color
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C2C39", // Grid line color for the Y-axis
        },
      },
    },
    series: [
      {
        name: "Eye Pledge",
        type: "line",
        smooth: true,
        data: [150, 230, 224, 218, 135, 147, 260],
        lineStyle: {
          width: 3,
        },
        areaStyle: {
          color: "#191F27",
        },
        itemStyle: {
          // Color of the point.
          color: "var(--primary)",
        },
        axisLine: {
          lineStyle: {
            color: "green", // Axis line color
          },
        },
      },
    ],
  });

  return (
    <div className="graph_container">
      <div className="graph_inner_container">
        <div className="graph_container_heading">Sales</div>
        <div className="duration_switch">
          <button
            onClick={() => handleFilterChange("1y")}
            className="switch_button"
            style={{
              backgroundColor: filter === "1y" ? "#141317" : "#2C2C39",
              color: filter === "1y" ? "var(--primary)" : "var(--off-white)",
            }}
          >
            1y
          </button>
          <button
            onClick={() => handleFilterChange("1m")}
            className="switch_button"
            style={{
              backgroundColor: filter === "1m" ? "#141317" : "#2C2C39",
              color: filter === "1m" ? "var(--primary)" : "var(--off-white)",
            }}
          >
            1m
          </button>
          <button
            onClick={() => handleFilterChange("1w")}
            className="switch_button"
            style={{
              backgroundColor: filter === "1w" ? "#141317" : "#2C2C39",
              color: filter === "1w" ? "var(--primary)" : "var(--off-white)",
            }}
          >
            1w
          </button>
        </div>
      </div>
      <ReactEcharts option={getOption()} className="dashboardChart" />
    </div>
  );
};

export default ProductDashboardChart;
