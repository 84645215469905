import { call, put, takeEvery } from "redux-saga/effects";

import {
    fetchAllCommunityMembers,
    fetchAllCommunityMembersSuccess,
    fetchAllCommunityMembersFailure,
    removeCommunityMembers,
    removeCommunityMembersSuccess,
    removeCommunityMembersFailure
} from "../slices/communityMemberSlice";

import {
    getRequest,
    deleteRequest
} from "../../api/apiActions";

import {
    getAllCommunityMemberUrl,
    removeCommunityMemberUrl
} from "../../api/apiUrls";

const getAllCommunityMembers = (data) => {
    return getRequest(`${getAllCommunityMemberUrl}/${data.templeId}?${data.queryParams}`);
}

const removeCommunityMember = (data) => {
    return deleteRequest(`${removeCommunityMemberUrl}?${data}`)
}

function* fetchCommunityMembers(action) {
    try {
        const result = yield call(getAllCommunityMembers, action.payload);

        if(result.status === 200) {
            yield put(fetchAllCommunityMembersSuccess(result?.data));
        } else {
            yield put(fetchAllCommunityMembersFailure());
        }
    } catch (error) {
        yield put(fetchAllCommunityMembersFailure(error));
    }
}

function* deleteCommunityMembers(action) {
    try {
        const result = yield call(removeCommunityMember, action.payload);

        if(result.status === 200) {
            yield put(removeCommunityMembersSuccess(result?.data));
        } else {
            yield put(removeCommunityMembersFailure());
        }
    } catch (error) {
        yield put(removeCommunityMembersFailure(error));
    }
}

export function* communityMemberSaga() {
    yield takeEvery(fetchAllCommunityMembers.type, fetchCommunityMembers);
    yield takeEvery(removeCommunityMembers.type, deleteCommunityMembers);
}

export default communityMemberSaga;
