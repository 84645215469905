import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Grid2,
  Autocomplete,
} from "@mui/material";

import "../../styles/onboardingForm.css";
import AddIcon from "@mui/icons-material/Add";
import "../../styles/ModalDatePicker.css";
import { AddButton } from "../../components/Addbutton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const initialFormState = {
  issueType: "",
  issueCategory: "",
  issueDescription: "",
};

const initialErrorState = {
  issueType: "",
  issueCategory: "",
  issueDescription: "",
};

const AddNewIssue = () => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const navigate = useNavigate();

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const SaveAndNext = () => {
    const newErrors = { ...initialErrorState };
    let isValid = true;

    if (!formData.issueCategory) {
      newErrors.issueCategory = "Issue Category is required";
      isValid = false;
    }
    if (!formData.issueDescription) {
      newErrors.issueDescription = "Issue Description is required";
      isValid = false;
    }
    if (!formData.issueType) {
      newErrors.issueType = "Issue Type is required";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
    }
  };

  return (
    <>
      <Box className="onboarding-main-container" >
        <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <ArrowBackIcon
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(`/issue-management`)}
          />
          <Box
            className={"heading"}
            sx={{
              textWrap: "nowrap",
            }}
          >
            Add Issue
          </Box>
        </span>
        <Box style={{  paddingRight:'1rem'}}>
          <Grid2 container spacing={2} mt={2}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Select Issue Type*
              </Typography>
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: "1rem" }}
                className="onboarding-custom-select"
              >
                <Autocomplete
                  options={["Order Issues", "Payment Issues", "Logistic Issues", "Other Issues"]}
                  value={formData.issueType}
                  disableClearable
                  renderTags={() => null}
                  onChange={handleChange("issueType")}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <span
                        {...props}
                        style={{
                          backgroundColor: selected
                            ? "var(--black)"
                            : "transparent",
                          color: selected ? "#FFD1A2" : "black",

                          pointerEvents: selected ? "none" : "auto",
                        }}
                      >
                        {option}
                      </span>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Issue Type"
                      variant="outlined"
                      style={{ marginBottom: "1rem" }}
                    />
                  )}
                />
                {errors.issueType && (
                  <Typography color="error">{errors.issueType}</Typography>
                )}
              </FormControl>
            </Grid2>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Select Category*
              </Typography>
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: "1rem" }}
                className="onboarding-custom-select"
              >
                <Autocomplete
                  options={["Red", "Blue", "Green", "Yellow", "Black"]}
                  value={formData.issueCategory}
                  disableClearable
                  renderTags={() => null}
                  onChange={handleChange("issueCategory")}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <span
                        {...props}
                        style={{
                          backgroundColor: selected
                            ? "var(--black)"
                            : "transparent",
                          color: selected ? "#FFD1A2" : "black",

                          pointerEvents: selected ? "none" : "auto",
                        }}
                      >
                        {option}
                      </span>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category"
                      variant="outlined"
                      style={{ marginBottom: "1rem" }}
                    />
                  )}
                />
                {errors.issueCategory && (
                  <Typography color="error">{errors.issueCategory}</Typography>
                )}
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Description About Issue*
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                value={formData.issueDescription}
                onChange={handleChange("issueDescription")}
                placeholder="Add Description  About The Issue"
                style={{ marginBottom: "1rem" }}
                className="onboarding-custom-textfield"
                InputProps={{
                  style: { color: "#8d8e9f" },
                }}
              />
              {errors.issueDescription && (
                <Typography color="error">{errors.issueDescription}</Typography>
              )}
            </Grid2>
          </Grid2>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <AddButton
            name="Add"
            icon={<AddIcon />}
            onClick={SaveAndNext}
            background="#A9392D"
            color="#FFFFFF"
            width="auto"
            sx={{
              marginRight: "1rem",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default AddNewIssue;
