import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import profileIcon from "../assets/images/profile_icon.svg";
import daiv_img from "../assets/images/DaivImage.svg";
import { logout } from "../features/slices/authSlice";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: "rgba(0, 0, 0, .03)",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiAccordionSummary-content": {
      marginRight: theme.spacing(2), // Adjust spacing to create space before the icon
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255, 255, 255, .05)",
    }),
  }),
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Header = React.memo(() => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logout(navigate));
  }, [dispatch, navigate]);

  return (
    <AppBar position="static" className="header_appBar_Main">
      <Typography sx={{ display: "flex", margin: "2rem 2rem 1rem 2rem" }}>
        <img
          className="header_account_img"
          src={daiv_img}
          alt="profile-rk-image"
        />
        <p className="daivFontstyle">Daiv</p>
      </Typography>

      <div className="header_profile_container">
        <img
          src={profileIcon}
          alt="profile-icon-image"
          style={{ marginRight: ".5rem", marginTop: ".2rem" }}
          className="header_profile_img"
        />

        <Typography className="user_Name">Admin</Typography>
      </div>

      {/* <Toolbar>
        <Accordion
          sx={{
            position: "absolute",
            width: {
              lg: "16rem",
            },
            top: {
              md: "-0.1rem",
              lg: "-.2rem",
              xl: "-.5rem",
            }, // Position it below the Toolbar
            right: {
              md: ".5rem",
              lg: "-6.5rem",
              xl: "-9rem",
            },
            backgroundColor: "white !important",
            borderRadius: "1rem !important",
            border:"0",
            zIndex: 10, // Ensure it appears above other content
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#8D8E9F", ml: 2 }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            onClick={handleMenuOpen}
          >
            <img
              src={profileIcon}
              alt="profile-icon-image"
              style={{ marginRight: ".5rem", marginTop: ".2rem" }}
            />
            
              <Typography className="user_Name">VectorStack</Typography>
            
          </AccordionSummary>
          <hr
            style={{ borderColor: "#46464F", borderWidth: "2px", width: "80%" }}
          />
          <AccordionDetails>
            <Box
              sx={{
                backgroundColor: "var(--black)",
                color: "var(--grey-subtext)",
                cursor: "pointer",
                display: "flex",
                gap: "1rem",
                paddingLeft: "2rem",
              }}
              onClick={handleLogout}
            >
              <img src="/logout.svg" alt="logout" />
              <Box sx={{ color: "#ACAEC3", fontWeight: 500 }}>Logout</Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Toolbar> */}
    </AppBar>
  );
});

export default Header;
