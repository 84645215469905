import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Grid2,
  Autocomplete,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import "../../styles/onboardingForm.css";

import "../../styles/ModalDatePicker.css";

import {
  fetchlineageRequest,
  uploadlineageRequest,
} from "../../features/slices/purohitOnboardingSlice";
import { useDispatch, useSelector } from "react-redux";
import { AddButton } from "../../components/Addbutton";

const initialFormState = {
  experience: "",
  lineAgeORTradition: "",
  religiousSpecialization: "",
  aboutYourself: "",
};

const initialErrorState = {
  experience: "",
  lineAgeORTradition: "",
  religiousSpecialization: "",
  aboutYourself: "",
};

const ExperienceAndLineage = forwardRef((props, ref) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);

  const { lineageData, lineageDataGetAllStatus } = useSelector(
    (state) => state.purohitOnboarding,
  );
  const dispatch = useDispatch();

  const handleChange = (field, event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    dispatch(fetchlineageRequest());
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;

      if (!formData.experience) {
        newErrors.experience = "experience is required";
        isValid = false;
      }

      if (!formData.lineAgeORTradition) {
        newErrors.lineAgeORTradition =
          "lineAgeORTradition or Tradition is required";
        isValid = false;
      }
      if (!formData.religiousSpecialization) {
        newErrors.religiousSpecialization =
          "Religious religiousSpecialization is required";
        isValid = false;
      }
      if (!formData.aboutYourself) {
        newErrors.aboutYourself = "Details is required";
        isValid = false;
      }
      setErrors(newErrors);
      if (isValid) {
        dispatch(uploadlineageRequest(formData));
      }
    },
  }));

  useEffect(() => {
    if (lineageData) {
      setFormData({
        ...formData,

        experience: lineageData?.experience,
        lineAgeORTradition: lineageData?.lineAgeORTradition,
        religiousSpecialization: lineageData?.religiousSpecialization,
        aboutYourself: lineageData?.aboutYourself,
      });
    }
  }, [lineageData]);

  return (
    <>
      <div className="heading">experience & Lineage</div>
      <Box style={{ marginTop: "1rem" }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              experience*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.experience}
              onChange={(e) => handleChange("experience", e)}
              placeholder="Enter Years of experience"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.experience && (
              <Typography color="error">{errors.experience}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Lineage or Tradition*
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              className="onboarding-custom-select"
            >
              <Autocomplete
                options={[
                  "Vedic",
                  "Shaiva",
                  "Vaishnavism",
                  "Smartha",
                  "Shakti",
                ]}
                value={formData.lineAgeORTradition}
                onChange={(e, newValue) =>
                  setFormData({
                    ...formData,
                    lineAgeORTradition: newValue,
                  })
                }
                renderOption={(props, option, { selected }) => {
                  return (
                    <span
                      {...props}
                      style={{
                        backgroundColor: selected
                          ? "var(--black)"
                          : "transparent",
                        color: selected ? "#FFD1A2" : "black",
                        pointerEvents: selected ? "none" : "auto",
                      }}
                    >
                      {option}
                    </span>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select lineage"
                    variant="outlined"
                  />
                )}
              />
              {errors.lineAgeORTradition && (
                <Typography color="error">
                  {errors.lineAgeORTradition}
                </Typography>
              )}
            </FormControl>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Religious religiousSpecializations*
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              className="onboarding-custom-select"
            >
              <Autocomplete
                options={["Rigveda", "Samaveda", "Yajurveda", "Atharvaveda"]}
                value={formData.religiousSpecialization}
                disableClearable
                renderTags={() => null}
                onChange={(e, newValue) =>
                  setFormData({
                    ...formData,
                    religiousSpecialization: newValue,
                  })
                }
                renderOption={(props, option, { selected }) => {
                  return (
                    <span
                      {...props}
                      style={{
                        backgroundColor: selected
                          ? "var(--black)"
                          : "transparent",
                        color: selected ? "#FFD1A2" : "black",
                        pointerEvents: selected ? "none" : "auto",
                      }}
                    >
                      {option}
                    </span>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Your religiousSpecialization"
                    variant="outlined"
                  />
                )}
              />
              {errors.religiousSpecialization && (
                <Typography color="error">
                  {errors.religiousSpecialization}
                </Typography>
              )}
            </FormControl>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              About Yourself*
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={formData.aboutYourself}
              onChange={(e, neValue) => handleChange("aboutYourself", e)}
              placeholder="Give description about yourself"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.aboutYourself && (
              <Typography color="error">{errors.aboutYourself}</Typography>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
});

export default ExperienceAndLineage;
