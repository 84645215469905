import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, FormControl, MenuItem, Popover, Select } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddButton } from "../components/Addbutton";
import AntSwitch from "../components/AntSwitch";
import SearchInput from "../components/CommonSearchBar";
import CommonTable from "../components/CommonTable";
import { getSeva, updateSevaStatus } from "../features/slices/sevaSlice";
import "../styles/dashboard.css";
import "../styles/filter.css";
import { CustomLoader } from "../components/CustomLoader";
import TableTooltip from "../components/TableTooltip";

const SevaManagement = React.memo(() => {
  const dispatch = useDispatch();
  const seva = useSelector((state) => state.seva);
  const columns = [
    {
      field: "name",
      headerName: "Seva Name",
      renderCell: (params) => <div>{params}</div>,
    },
    {
      field: "days",
      headerName: "Days",
      renderCell: (params, row) => {
        return (
          <span className="action-row">
            <TableTooltip title={row.days} days={true} />
          </span>
        );
      },
    },
    { field: "price", headerName: "Price" },
    {
      field: "time",
      headerName: "Time",
      renderCell: (params, row) => {
        return (row.startAt + "-" + row.endAt).replace(/\s/g, "");
      },
    },
    {
      field: "details",
      headerName: "Details",
      renderCell: (params, row) => {
        return (
          <div
            className="seva_preview"
            onClick={() => navigate(`/seva-management/view/${row.sevaId}`)}
          >
            Preview
          </div>
        );
      },
    },
    {
      field: "online",
      headerName: "Availability",
      renderCell: (params, row) => {
        return row.online ? "Online" : "Offline";
      },
    },
    {
      field: "active",
      headerName: "Action",
      renderCell: (params, row) => (
        <span className="action-row">
          <img
            src="/edit-icon.svg"
            alt="edit"
            className="edit-icon"
            onClick={() => onEdit(row.sevaId)}
          />
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
            onChange={() =>
              dispatch(
                updateSevaStatus({ sevaId: row.sevaId, status: !row.active }),
              )
            }
            checked={row?.active}
          />
          Active
        </span>
      ),
    },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [search, setSearch] = useState("");

  const HandleAdd = () => {
    setOpenAddNew(true);
    navigate(`/seva-management/Add`);
  };

  const onEdit = (id) => {
    navigate(`/seva-management/Edit/${id}`);
  };

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedValue, setSelectedValue] = useState("");
  const [booleanValue, setBooleanValue] = useState(null);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setBooleanValue(value === "true" ? true : value === "false" ? false : null);
  };

  const handleSubmit = (e) => {
    let query = `?pageNumber=0&pageSize=10&online=${selectedValue}`;
    dispatch(getSeva({ navigate, query }));
    handleClose();
  };

  useEffect(() => {
    let query;
    if (search) {
      query = `?pageNumber=0&pageSize=10&search=${search}&online=${selectedValue}`;
    } else {
      query = `?pageNumber=${page - 1}&pageSize=10&search=&online=${selectedValue}`;
    }
    dispatch(getSeva({ navigate, query }));
  }, [dispatch, navigate, search, seva.updateSevaStatus, page]);

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="filter_popover">
          <div className="filter_title_container">
            <img src="/filter.svg" alt="filter" />
            <div className="filter_title">Filter</div>
          </div>
          <div>
            <div className="filter_av_reset">
              <div className="filter_label">Availability*</div>
              <div
                className="filter_reset"
                onClick={() => setSelectedValue("")}
              >
                Reset
              </div>
            </div>

            <FormControl fullWidth>
              <Select value={selectedValue} onChange={handleSelectChange}>
                <MenuItem value="true">Online</MenuItem>
                <MenuItem value="false">Offline</MenuItem>
              </Select>
              <div style={{ marginTop: "1rem", textAlign: "center" }}>
                <AddButton
                  name="Cancel"
                  sx={{
                    backgroundColor: "white !important",
                    color: "var(--secondary) !important",
                    border: "1px solid var(--secondary) ",
                    marginRight: "1rem",
                  }}
                  onClick={handleClose}
                />
                <AddButton name="Apply" onClick={handleSubmit} />
              </div>
            </FormControl>
          </div>
        </div>
      </Popover>
      <CustomLoader
        loading={seva.loading}
        message="Please wait, data is getting loaded..."
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0rem 0px 1rem 0px",
        }}
      >
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            // paddingY: "10px",
          }}
        >
          Seva Management
        </Box>
        <AddButton name="Add" icon={<AddIcon />} onClick={HandleAdd} />
      </div>

      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          margin: "1rem 0",
          justifyContent: "flex-end",
        }}
      >
        <SearchInput setSearchTerm={setSearch} />
        <AddButton
          icon={<ArrowDropDownIcon />}
          name="Filter By"
          borderColor="var(--secondary)"
          background="#F5F5F5"
          color="var(--secondary)"
          padding="1.15rem"
          onClick={handleClick}
        />
      </Box>

      <CommonTable
        columns={columns}
        data={seva?.sevaData?.seva}
        page={page}
        count={seva?.sevaData?.totalPages}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </>
  );
});
export default SevaManagement;
