import { call, put, takeEvery, throttle } from "redux-saga/effects";
import {
  ForgotPassWordRequest,
  ForgotPassWordRequestFailure,
  ForgotPassWordRequestSuccess,
  ResetPassWordRequest,
  ResetPassWordRequestFailure,
  ResetPassWordRequestSuccess,
  TermsAndPolicyFailure,
  TermsAndPolicyRequest,
  TermsAndPolicySuccess,
  emailFailure,
  emailRequest,
  emailSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  sendOtpFailure,
  sendOtpRequest,
  sendOtpSuccess,
  signUpFailure,
  signUpRequest,
  signUpSuccess,
  templeDetailsFailure,
  templeDetailsRequest,
  templeDetailsSuccess,
  purohitDetailsRequest,
  purohitDetailsSuccess,
  purohitDetailsFailure,
  updateLogin,
  updateLoginFailure,
  updateLoginSuccess,
  verifyEmailFailure,
  verifyEmailRequest,
  verifyEmailSuccess,
  verifyOtpFailure,
  verifyOtpRequest,
  verifyOtpSuccess,
  documentStatusUpdateRequest,
  documentStatusUpdateSuccess,
  documentStatusUpdateFailure,
} from "../slices/authSlice";

import {
  getRequest,
  postRequest4,
  postRequestUsingPayload,
  putRequest,
} from "../../api/apiActions";
import {
  ForgotPasswordUrls,
  ResetPasswordUrls,
  documentStatus,
  emailVerificationUrls,
  loginUrls,
  purohitDetailsUrls,
  sendOtpUrls,
  signUpUrls,
  templeDetailsUrls,
  termsPolicyUrls,
  updateloginUrls,
  verifyEmailUrls,
  verifyOtpUrls,
} from "../../api/apiUrls";
import { openSnackbar } from "../slices/snackbarSlice";

const getLoginRequest = (payload) =>
  postRequestUsingPayload(loginUrls, payload.LoginObj);

const getSignUpRequest = (payload) =>
  postRequestUsingPayload(signUpUrls, payload.SingUpObj);

const getSendOtpRequest = (payload) =>
  postRequestUsingPayload(sendOtpUrls, payload.OtpObj);

const getVerifyOtpRequest = (payload) =>
  postRequestUsingPayload(verifyOtpUrls, payload.VerifyOtpObj);

const getTempleDetailsRequest = (payload) =>
  postRequestUsingPayload(templeDetailsUrls, payload.TempleObj);

const getPurohitDetailsRequest = (payload) =>
  postRequestUsingPayload(purohitDetailsUrls, payload.PurohitObj);

const getEmailRequest = (payload) => getRequest(verifyEmailUrls, payload);

const getVerifyEmailRequest = (payload) =>
  putRequest(emailVerificationUrls, { verifyCode: payload.verifyCode });

const updateRequest = (payload) =>
  postRequestUsingPayload(updateloginUrls, payload.payload);

const forgetPasswordCall = (payload) =>
  postRequestUsingPayload(ForgotPasswordUrls + payload);

const resetPasswordCall = (payload) => postRequest4(ResetPasswordUrls, payload);

const termsCall = () => putRequest(termsPolicyUrls);

const documentCall = () => putRequest(documentStatus);

function* handleLogin(action) {
  try {
    const { navigate } = action.payload;
    const response = yield call(getLoginRequest, action.payload);
    if (response.status === 200) {
      let data = response.data;

      yield put(loginSuccess({ data, navigate }));
    } else {
      yield put(loginFailure(response.message));
    }
  } catch (error) {
    yield put(
      openSnackbar({
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something Went Wrong",
        severity: "error",
      }),
    );
  }
}

function* handleSignUp(action) {
  try {
    const { navigate, email } = action.payload;
    const response = yield call(getSignUpRequest, action.payload);

    if (response.status === 200) {
      let data = response.data;

      yield put(signUpSuccess({ data, navigate, email }));
    } else {
      yield put(signUpFailure(response.data));
    }
  } catch (error) {
    yield put(signUpFailure(error.data));
    yield put(
      openSnackbar({
        message: error?.response?.data?.message,
        severity: "error",
      }),
    );
  }
}

function* handleEmail(action) {
  try {
    const { navigate } = action.payload;
    const response = yield call(getEmailRequest, action.payload);

    if (response.status === 200) {
      let data = response.data;

      yield put(emailSuccess({ data, navigate }));
    } else {
      yield put(emailFailure(response.data));
    }
  } catch (error) {
    yield put(emailFailure(error.data));
  }
}

function* handleVerifyEmail(action) {
  const { navigate } = action.payload;
  try {
    const response = yield call(getVerifyEmailRequest, action.payload);
    if (response.status === 200) {
      yield put(verifyEmailSuccess({ navigate }));
    } else {
      let message =
        "Email verification failed. Please login to verify your email.";
      yield put(verifyEmailFailure({ message, navigate }));
    }
  } catch (error) {
    let message =
      "Email verification failed. Please login to verify your email.";
    yield put(verifyEmailFailure({ message, navigate }));
  }
}

function* handleTempleDetails(action) {
  try {
    const { navigate } = action.payload;
    const response = yield call(getTempleDetailsRequest, action.payload);
    if (response.status === 200 || response.status === 201) {
      let data = response.data;
      yield put(templeDetailsSuccess({ data, navigate }));
    } else {
      yield put(templeDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(templeDetailsFailure(error.data));
  }
}

function* handlePurohitDetails(action) {
  try {
    const { navigate } = action.payload;
    const response = yield call(getPurohitDetailsRequest, action.payload);
    if (response.status === 200 || response.status === 201) {
      let data = response.data;
      yield put(purohitDetailsSuccess({ data, navigate }));
    } else {
      yield put(purohitDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(purohitDetailsFailure(error.data));
  }
}

function* sendOtp(action) {
  try {
    const { navigate } = action.payload;
    const response = yield call(getSendOtpRequest, action.payload);

    if (response.status === 200) {
      let data = response.data;

      yield put(sendOtpSuccess({ data, navigate }));
      yield put(
        openSnackbar({
          message: "Otp Sent Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(sendOtpFailure(response.data));
      yield put(
        openSnackbar({
          message: "Cannot send otp",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(sendOtpFailure(error.data));
    yield put(
      openSnackbar({
        message: error?.response?.data?.message,
        severity: "error",
      }),
    );
  }
}

function* verifyOtp(action) {
  try {
    const { navigate, url } = action.payload;
    const response = yield call(getVerifyOtpRequest, action.payload);
    if (response.status === 200) {
      let data = response.data;
      yield put(verifyOtpSuccess({ data, navigate, url }));
    } else {
      yield put(verifyOtpFailure(response.data));
      yield put(
        openSnackbar({
          message: "Wrong Otp",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(verifyOtpFailure(error.data));
    yield put(
      openSnackbar({
        message: error?.response?.data?.message,
        severity: "error",
      }),
    );
  }
}

function* UpdateLoginRequest(action) {
  try {
    const { navigate } = action.payload;
    const response = yield call(updateRequest, action.payload);

    if (response.status === 200) {
      let data = response.data;
      yield put(updateLoginSuccess({ data, navigate }));
    } else {
      yield put(updateLoginFailure(navigate));
    }
  } catch (error) {
    yield put(updateLoginFailure(action.payload));
  }
}
function* ForgetPassword(action) {
  try {
    const response = yield call(forgetPasswordCall, action.payload?.email);
    if (response?.status === 200) {
      let data = response?.data;

      yield put(ForgotPassWordRequestSuccess({ data }));
      yield put(
        openSnackbar({
          message: data,
          severity: "success",
        }),
      );
    } else {
      yield put(ForgotPassWordRequestFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(ForgotPassWordRequestFailure(error?.response?.data));
  }
}
function* ResetPassword(action) {
  try {
    const response = yield call(resetPasswordCall, action.payload);

    if (response.status === 200) {
      let data = response.data;
      yield put(ResetPassWordRequestSuccess({ data }));
    } else {
      yield put(ResetPassWordRequestFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(ResetPassWordRequestFailure(error?.response?.data));
  }
}

function* TermsAndPrivacy(action) {
  try {
    const response = yield call(termsCall, action.payload);
    const { navigate } = action.payload;
    if (response.status === 200) {
      let data = response.data;
      yield put(TermsAndPolicySuccess({ data, navigate }));
    } else {
      yield put(TermsAndPolicyFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(TermsAndPolicyFailure(error?.response?.data));
  }
}

function* documentUploadStatus(action) {
  try {
    const response = yield call(documentCall);
    const { navigate } = action.payload;
    if (response.status === 200) {
      let data = response.data;
      yield put(documentStatusUpdateSuccess({ data, navigate }));
    } else {
      yield put(documentStatusUpdateFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(documentStatusUpdateFailure(error?.response?.data));
  }
}

export function* watchAuthSaga() {
  yield takeEvery(loginRequest.type, handleLogin);
  yield takeEvery(signUpRequest.type, handleSignUp);
  yield throttle(3000, emailRequest.type, handleEmail);
  yield takeEvery(verifyEmailRequest.type, handleVerifyEmail);
  yield takeEvery(sendOtpRequest.type, sendOtp);
  yield takeEvery(verifyOtpRequest.type, verifyOtp);
  yield takeEvery(updateLogin.type, UpdateLoginRequest);
  yield takeEvery(ForgotPassWordRequest.type, ForgetPassword);
  yield takeEvery(ResetPassWordRequest.type, ResetPassword);
  yield takeEvery(TermsAndPolicyRequest.type, TermsAndPrivacy);
  yield takeEvery(templeDetailsRequest.type, handleTempleDetails);
  yield takeEvery(documentStatusUpdateRequest.type, documentUploadStatus);
  yield takeEvery(purohitDetailsRequest.type, handlePurohitDetails);
}

export default watchAuthSaga;
