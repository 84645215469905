import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Grid2,
  Autocomplete,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "../../styles/onboardingForm.css";

import "../../styles/ModalDatePicker.css";

import {
  fetchdocRequest,
  uploaddocRequest,
} from "../../features/slices/purohitOnboardingSlice";
import { useDispatch, useSelector } from "react-redux";
import { AddButton } from "../../components/Addbutton";

const initialFormState = {
  accountHolderName: "",
  bankName: "",
  accountNumber: "",
  ifscCode: "",
  certificationType: "",
  aadhaarCard: [],
  panCard: [],
  bankStatement: [],

  documentCertificate: [],
  affilationDocument: [],
};

const initialErrorState = {
  accountHolderName: "",
  bankName: "",
  accountNumber: "",
  ifscCode: "",
  certificationType: "",
  aadhaarCard: [],
  panCard: [],
  bankStatement: [],

  documentCertificate: [],
  affilationDocument: [],
};

const PurohitDocumentation = forwardRef((props, ref) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);

  const { docData, docGetAllStatus } = useSelector(
    (state) => state.purohitOnboarding,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchdocRequest());
  }, [dispatch]);

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;

      if (!formData.accountHolderName) {
        newErrors.accountHolderName = "Account holder's Name is required";
        isValid = false;
      }
      if (!formData.bankName) {
        newErrors.bankName = "Bank Name is required";
        isValid = false;
      }
      if (!formData.accountNumber) {
        newErrors.accountNumber = "Account Number is required";
        isValid = false;
      }
      if (!formData.ifscCode) {
        newErrors.ifscCode = "IFSC Code is required";
        isValid = false;
      }

      setErrors(newErrors);

      if (isValid) {
        let formArr = new FormData();

        formArr.append("aadhaarCard", formData?.aadhaarCard[0].file);
        formArr.append("panCard", formData?.panCard[0].file);
        formArr.append("bankStatement", formData?.bankStatement[0].file);
        formArr.append(
          "documentCertificate",
          formData?.documentCertificate[0].file,
        );
        formArr.append(
          "affilationDocument",
          formData?.affilationDocument[0].file,
        );

        formArr.append("accountHolderName", formData?.accountHolderName);
        formArr.append("bankName", formData?.bankName);
        formArr.append("accountNumber", formData?.accountNumber);
        formArr.append("ifscCode", formData?.ifscCode);
        formArr.append("certificationType", formData?.certificationType);

        dispatch(uploaddocRequest(formArr));
      }
    },
  }));

  const MAX_IMAGE_SIZE = 2 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 20 * 1024 * 1024;

  const removeUpload = (field, index) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  const handleUpload = (field, event, MAX_FILES) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length + (formData[field]?.length || 0) > MAX_FILES) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "2MB" : "20MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };

  const mapLocalImage = (images) => {
    if (images.length > 0) {
      let NewArr = images.map((item) => {
        return item?.file;
      });

      return NewArr;
    }
  };

  useEffect(() => {
    if (docData) {
      setFormData({
        ...formData,

        accountHolderName: docData?.accountHolderName,
        bankName: docData?.bankName,
        accountNumber: docData?.accountNumber,
        ifscCode: docData?.ifscCode,
        certificationType: docData?.certificationType,
        aadhaarCard: docData?.aadhaarCard
          ? mapImages([docData.aadhaarCard])
          : [],
        panCard: docData?.panCard ? mapImages([docData.panCard]) : [],
        bankStatement: docData?.bankStatement
          ? mapImages([docData.bankStatement])
          : [],
        documentCertificate: docData?.documentCertificate
          ? mapImages([docData.documentCertificate])
          : [],
        affilationDocument: docData?.affilationDocument
          ? mapImages([docData.affilationDocument])
          : [],
      });
    }
  }, [docData]);

  return (
    <>
      <div className="heading">Purohit Documentation</div>
      <Box style={{ marginTop: "1rem" }}>
        <div className="onboarding-sub_heading">Identity Verification</div>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }} spacing={2}>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Typography className="onboarding-form_Heading">
                AADHAAR Card*
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={""}
                onChange={handleChange("templeContactNumber")}
                placeholder="Browse From DigiLocker"
                className="onboarding-custom-textfield"
                InputProps={{
                  style: { color: "#8d8e9f" },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>
              {" "}
              <Typography className="OR-Typography">OR</Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Typography className="onboarding-form_Heading">
                AADHAAR Card
              </Typography>
              <Box className="upload-box_charity ">
                {formData?.aadhaarCard?.length > 0 ? (
                  <Box className="preview-image-container">
                    {formData?.aadhaarCard?.map((item, index) => (
                      <Box className="song-image-container">
                        <Close
                          className="song-image-close-icon"
                          onClick={() => removeUpload("aadhaarCard", index)}
                        />

                        <img src={item.url} className="song-image" alt="efew" />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                <Typography className="upload-instructions">
                  <img
                    style={{ marginBottom: "-0.25rem" }}
                    src="/upload-file-icon.svg"
                    alt="img"
                  />
                  Drag and Drop your file
                </Typography>
                <Typography className="onboarding-upload-instructions ">
                  OR
                </Typography>
                <input
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                  id="thumbnail-upload-Adhar"
                  onChange={(e) => handleUpload("aadhaarCard", e, 2)}
                  multiple={true}
                />
                <label htmlFor="thumbnail-upload-Adhar">
                  <Button
                    variant="outlined"
                    component="span"
                    className="browse-button"
                  >
                    Browse file
                  </Button>
                </label>
                <Typography className="upload-instructions ">
                  Max.2 Images, 2MB Each, JPEG/PNG
                </Typography>
              </Box>
              {errors.aadhaarCard && (
                <Typography color="error">{errors.aadhaarCard}</Typography>
              )}
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }} spacing={2}>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Typography className="onboarding-form_Heading">
                PAN Card
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={""}
                onChange={handleChange("templeContactNumber")}
                placeholder="Browse from Digi Locker"
                className="onboarding-custom-textfield"
                InputProps={{
                  style: { color: "#8d8e9f" },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>
              {" "}
              <Typography className="OR-Typography">OR</Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Typography className="onboarding-form_Heading">
                PAN Card
              </Typography>
              <Box className="upload-box_charity ">
                {formData?.panCard?.length > 0 ? (
                  <Box className="preview-image-container">
                    {formData?.panCard?.map((item, index) => (
                      <Box className="song-image-container">
                        <Close
                          className="song-image-close-icon"
                          onClick={() => removeUpload("panCard", index)}
                        />

                        <img
                          src={item.url}
                          className="song-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                <Typography className="upload-instructions">
                  <img
                    style={{ marginBottom: "-0.25rem" }}
                    src="/upload-file-icon.svg"
                    alt="img"
                  />
                  Drag and Drop your file
                </Typography>
                <Typography className="onboarding-upload-instructions ">
                  OR
                </Typography>
                <input
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                  id="thumbnail-upload-pan"
                  onChange={(e) => handleUpload("panCard", e, 5)}
                  multiple={true}
                />
                <label htmlFor="thumbnail-upload-pan">
                  <Button
                    variant="outlined"
                    component="span"
                    className="browse-button"
                  >
                    Browse file
                  </Button>
                </label>
                <Typography className="upload-instructions ">
                  Max.2 Images, 2MB Each, JPEG/PNG
                </Typography>
              </Box>
              {errors.panCard && (
                <Typography color="error">{errors.panCard}</Typography>
              )}
            </Grid2>
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">Financial Details</div>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Account Holder Name*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.accountHolderName}
              onChange={handleChange("accountHolderName")}
              placeholder="Enter accountHolderName"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.accountHolderName && (
              <Typography color="error">{errors.accountHolderName}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Bank Name*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.bankName}
              onChange={handleChange("bankName")}
              placeholder="Enter Bank Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.bankName && (
              <Typography color="error">{errors.bankName}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Account Number*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.accountNumber}
              onChange={handleChange("accountNumber")}
              placeholder="Enter Account Number"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.accountNumber && (
              <Typography color="error">{errors.accountNumber}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              IFSC Code*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.ifscCode}
              onChange={handleChange("ifscCode")}
              placeholder="Enter IFSC Code"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.ifscCode && (
              <Typography color="error">{errors.ifscCode}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Upload Last 6 Months Bank Statement or Cancelled cheque*
            </Typography>
            <Box className="upload-box_charity ">
              {formData?.bankStatement?.length > 0 ? (
                <Box className="preview-image-container">
                  {formData?.bankStatement?.map((item, index) => (
                    <Box className="song-image-container">
                      <Close
                        className="song-image-close-icon"
                        onClick={() => removeUpload("bankStatement", index)}
                      />

                      <img
                        src={item.url}
                        className="song-image"
                        alt="song_img"
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              <Typography className="upload-instructions">
                <img
                  style={{ marginBottom: "-0.25rem" }}
                  src="/upload-file-icon.svg"
                  alt="img"
                />
                Drag and Drop your file
              </Typography>
              <Typography className="onboarding-upload-instructions ">
                OR
              </Typography>
              <input
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                id="thumbnail-upload-pan"
                onChange={(e) => handleUpload("bankStatement", e, 5)}
                multiple={true}
              />
              <label htmlFor="thumbnail-upload-pan">
                <Button
                  variant="outlined"
                  component="span"
                  className="browse-button"
                >
                  Browse file
                </Button>
              </label>
              <Typography className="upload-instructions ">
                Max.2 Images, 2MB Each, JPEG/PNG
              </Typography>
            </Box>
            {errors.bankStatement && (
              <Typography color="error">{errors.bankStatement}</Typography>
            )}
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">Qualification</div>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Certification Type
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              className="onboarding-custom-select"
            >
              <Autocomplete
                options={[
                  "Vedic Education",
                  "Agama Certification",
                  "Temple Priest Certification ",
                  "Others",
                ]}
                value={formData.certificationType}
                disableClearable
                renderTags={() => null}
                onChange={handleChange("certificationType")}
                renderOption={(props, option, { selected }) => {
                  return (
                    <span
                      {...props}
                      style={{
                        backgroundColor: selected
                          ? "var(--black)"
                          : "transparent",
                        color: selected ? "#FFD1A2" : "black",

                        pointerEvents: selected ? "none" : "auto",
                      }}
                    >
                      {option}
                    </span>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Your Role"
                    variant="outlined"
                  />
                )}
              />
              {errors.certificationType && (
                <Typography color="error">
                  {errors.certificationType}
                </Typography>
              )}
            </FormControl>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Upload Document Certificates*
            </Typography>
            <Box className="upload-box_charity ">
              {formData?.documentCertificate?.length > 0 ? (
                <Box className="preview-image-container">
                  {formData?.documentCertificate?.map((item, index) => (
                    <Box className="song-image-container">
                      <Close
                        className="song-image-close-icon"
                        onClick={() =>
                          removeUpload("documentCertificate", index)
                        }
                      />

                      <img
                        src={item.url}
                        className="song-image"
                        alt="song_img"
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              <Typography className="upload-instructions">
                <img
                  style={{ marginBottom: "-0.25rem" }}
                  src="/upload-file-icon.svg"
                  alt="img"
                />
                Drag and Drop your file
              </Typography>
              <Typography className="onboarding-upload-instructions ">
                OR
              </Typography>
              <input
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                id="thumbnail-upload-pan"
                onChange={(e) => handleUpload("documentCertificate", e, 5)}
                multiple={true}
              />
              <label htmlFor="thumbnail-upload-pan">
                <Button
                  variant="outlined"
                  component="span"
                  className="browse-button"
                >
                  Browse file
                </Button>
              </label>
              <Typography className="upload-instructions ">
                Max.2 Images, 2MB Each, JPEG/PNG
              </Typography>
            </Box>
            {errors.documentCertificate && (
              <Typography color="error">
                {errors.documentCertificate}
              </Typography>
            )}
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">Affiliation Document</div>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Box className="upload-box_charity ">
              {formData?.affilationDocument?.length > 0 ? (
                <Box className="preview-image-container">
                  {formData?.affilationDocument?.map((item, index) => (
                    <Box className="song-image-container">
                      <Close
                        className="song-image-close-icon"
                        onClick={() =>
                          removeUpload("affilationDocument", index)
                        }
                      />

                      <img
                        src={item.url}
                        className="song-image"
                        alt="song_img"
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              <Typography className="upload-instructions">
                <img
                  style={{ marginBottom: "-0.25rem" }}
                  src="/upload-file-icon.svg"
                  alt="img"
                />
                Drag and Drop your file
              </Typography>
              <Typography className="onboarding-upload-instructions ">
                OR
              </Typography>
              <input
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                id="thumbnail-upload-pan-8"
                onChange={(e) => handleUpload("affilationDocument", e, 5)}
                multiple={true}
              />
              <label htmlFor="thumbnail-upload-pan-8">
                <Button
                  variant="outlined"
                  component="span"
                  className="browse-button"
                >
                  Browse file
                </Button>
              </label>
              <Typography className="upload-instructions ">
                Max.2 Images, 2MB Each, JPEG/PNG
              </Typography>
            </Box>
            {errors.affilationDocument && (
              <Typography color="error">{errors.affilationDocument}</Typography>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
});

export default PurohitDocumentation;
