import { createSlice } from "@reduxjs/toolkit";

const settingSlice = createSlice({
  name: "setting",
  initialState: {
    loading: false,
    userData: [],
    error: null,
    removeImageStatus: false,
    updateSettingStatus: false,
  },
  reducers: {
    fetchSettingRequest: (state) => {
      state.loading = true;
      state.userData = [];
    },
    fetchSettingSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    },
    fetchSettingFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.userData = [];
    },
    updateSetting: (state, action) => {
      state.loading = true;
      state.updateSettingStatus = false;
    },
    updateSettingSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.updateSettingStatus = true;
    },
    updateSettingFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.updateSettingStatus = false;
    },
    removeImage: (state) => {
      state.loading = true;
      state.removeImageStatus = false;
    },
    removeImageSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.removeImageStatus = true;
    },
    removeImageFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.removeImageStatus = false;
    },
  },
});

export const {
  fetchSettingRequest,
  fetchSettingSuccess,
  fetchSettingFailure,
  updateSetting,
  updateSettingFailure,
  updateSettingSuccess,
  removeImage,
  removeImageSuccess,
  removeImageFailure,
} = settingSlice.actions;

export default settingSlice.reducer;
