import React, { useCallback, useState, useEffect } from "react";
import "../../styles/AddUser.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box } from "@mui/material";
import * as Yup from "yup";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import { AddButton } from "../../components/Addbutton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const AddUser = React.memo((props) => {
  const { id } = useParams();

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    phone_number: Yup.number().required("Phone Number is Required"),
    email: Yup.string().required("Please Enter the Email ID"),
    animal: Yup.string().required("Please Select the Value"),
  });

  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div>
      <span style={{ display: "flex", marginBottom: "1rem" }}>
        <ArrowBackIcon
          className="arrow_icon"
          sx={{}}
          onClick={() => navigate(`/user-management/${id}`)}
        />
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          {props.type === "Add" ? "Add User" : "Edit User Details"}
        </Box>
      </span>
      <Formik
        initialValues={{
          name: "",
          phone_number: "",
          email: "",
          animal: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {}}
      >
        {({ setFieldValue, isSubmitting, errors, touched }) => (
          <Form>
            <div style={{ display: "flex", width: "100%", gap: "20px" }}>
              <MaterialUIFieldAdapter
                name="name"
                type="text"
                label="Name*"
                placeholder="Enter Name"
                style={{ width: "100%" }}
              />

              <MaterialUIFieldAdapter
                name="phone_number"
                type="text"
                label="Phone Number*"
                placeholder="Enter Phone Number"
                style={{ width: "100%" }}
              />
            </div>

            <div style={{ display: "flex", width: "50%", gap: "20px" }}>
              <MaterialUIFieldAdapter
                name="email"
                type="text"
                label="Email ID*"
                placeholder="Enter email"
                style={{ width: "100%" }}
              />
            </div>
            <div style={{ display: "flex", width: "100%", gap: "20px" }}>
              <MaterialUIFieldAdapter
                name="role"
                type="autocomplete"
                label="Role*"
                placeholder="Select Role"
                options={[
                  {
                    value: "admin",
                    label: "Admin",
                  },
                  {
                    value: "sub-admin",
                    label: "Sub-Admin",
                  },
                ]}
              />
              <MaterialUIFieldAdapter
                name="reportingTo"
                type="autocomplete"
                label="Reporting To*"
                placeholder="Select Reporting"
                options={[
                  {
                    value: "dog",
                    label: "Dog",
                  },
                  {
                    value: "cat",
                    label: "Cat",
                  },
                ]}
              />
            </div>

            <AddButton
              name={props.type === "Add" ? "Add" : "Save"}
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
});

export default AddUser;
