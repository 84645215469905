import React from "react";
import PurohitDetails from "./PurohitDetails";
import PurohitDocumentation from "./PurohitDocumentation";
import ExperienceAndLineage from "./ExperienceAndLineage";
import PujaOfferingsAndServices from "./PujaOfferingsAndServices";
import SelectedPujaOfferings from "./SelectedPujaOfferings";
import TimingAndAvailability from "./TimingAndAvailability";
import { AddButton } from "../../components/Addbutton";
import { Box } from "@mui/material";

const OnboardingPreview = React.memo(() => {
  const handleSubmit = () => {};

  const handleBack = () => {};
  return (
    <div>
      <PurohitDetails />
      <PurohitDocumentation />
      <ExperienceAndLineage />
      <ExperienceAndLineage />
      <PujaOfferingsAndServices />
      <SelectedPujaOfferings />
      <TimingAndAvailability />

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
        <AddButton
          name="Back"
          onClick={handleBack}
          background="#FFFFFF"
          color="##A9392D"
          width="auto"
          sx={{
            marginRight: "1rem",
            border: "1px solid #ACAEC3",
          }}
        />
        <AddButton
          name="Submit"
          onClick={handleSubmit}
          background="#A9392D"
          color="#FFFFFF"
          width="auto"
          sx={{
            marginRight: "1rem",
            // border: "1px solid #ACAEC3",
          }}
        />
      </Box>
    </div>
  );
});
export default OnboardingPreview;
