import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchOnBoardingRequest,
  fetchOnBoardingSuccess,
  fetchOnBoardingFailure,
  fetchOnBoardingStatusRequest,
  fetchOnBoardingStatusFailure,
  fetchOnBoardingStatusSuccess,
  uploadTempleRequest,
  uploadTempleSuccess,
  uploadTempleFailure,
  uploadDocumentRequest,
  uploadDocumentSuccess,
  uploadDocumentFailure,
  uploadDescriptionRequest,
  uploadDescriptionSuccess,
  uploadDescriptionFailure,
  uploadPhotosRequest,
  uploadPhotosSuccess,
  uploadPhotosFailure,
  uploadAdminRequest,
  uploadAdminSuccess,
  uploadAdminFailure,
  uploadSevaRequest,
  uploadSevaSuccess,
  uploadSevaFailure,
  uploadPrasadamRequest,
  uploadPrasadamSuccess,
  uploadPrasadamFailure,
  uploadDonationRequest,
  uploadDonationSuccess,
  uploadDonationFailure,
  uploadPaymentRequest,
  uploadPaymentSuccess,
  uploadPaymentFailure,
  fetchAdminRequest,
  fetchAdminSuccess,
  fetchAdminFailure,
  fetchSevaRequest,
  fetchSevaSuccess,
  fetchSevaFailure,
  fetchDonationRequest,
  fetchDonationSuccess,
  fetchDonationFailure,
  fetchPrasadamRequest,
  fetchPrasadamSuccess,
  fetchPrasadamFailure,
  fetchPaymentRequest,
  fetchPaymentSuccess,
  fetchPaymentFailure,
  uploadPreviewRequest,
  uploadPreviewSuccess,
  uploadPreviewFailure,
  uploadOnboardCompleteRequest,
  uploadOnboardCompleteSuccess,
  uploadOnboardCompleteFailure,
  updateSevaRequest,
  updateSevaSuccess,
  updateSevaFailure,
  updatePrasadamRequest,
  updatePrasadamSuccess,
  updatePrasadamFailure,
  updateDonationRequest,
  updateDonationSuccess,
  updateDonationFailure,
  updatePaymentRequest,
  updatePaymentSuccess,
  updatePaymentFailure,
  DeleteImageRequest,
  DeleteImageRequestFailure,
  DeleteImageRequestSuccess,
} from "../slices/onBordingSlice";
import {
  deleteRequest,
  getRequest,
  postRequest4,
  putRequestWithFormData,
  postRequestUsingFormData,
  putRequest,
} from "../../api/apiActions";

import {
  onBoardPreviewUrls,
  onBoardStatusUrls,
  onBoardUpdateUrls,
  onBoardDocumentUrls,
  onBoardDescriptionUrls,
  onBoardPhotoVideoUrls,
  onBoardAdminUrls,
  onBoardSevaUrls,
  onBoardPrasadamUrls,
  onBoardDonationUrls,
  onBoardPayementsUrls,
  onboardAdmingetUrls,
  onboardPaymentgetUrls,
  onboardSevagetUrls,
  onboardParsadamgetUrls,
  onboardDonationgetUrls,
  onBoardCompletedUrls,
  updateOnboardDonation,
  deleteImageUrls,
  updateOnboardSeva,
  updateOnboardPrasadam,
  updateOnboardPayment,
} from "../../api/apiUrls";

const getCall = () => getRequest(onBoardPreviewUrls);
const getCall2 = () => getRequest(onBoardStatusUrls);
const deleteCall = ({ etag, field }) =>
  postRequest4(`${deleteImageUrls}?eTag=${etag}&frameName=${field}`);

const updateFinalSubmitCall = () => putRequest(onBoardPreviewUrls);

const updateOnboardCall = () => putRequest(onBoardCompletedUrls);

const updateTempleCall = (payload) => putRequest(onBoardUpdateUrls, payload);
const updateDocCall = (payload) =>
  putRequestWithFormData(onBoardDocumentUrls, payload);
const updatePaymentCall = (payload) =>
  postRequestUsingFormData(onBoardPayementsUrls, payload);
const updateDonationCall = (payload) =>
  postRequestUsingFormData(onBoardDonationUrls, payload);
const updatePrasadCall = (payload) =>
  postRequestUsingFormData(onBoardPrasadamUrls, payload);
const updateSevaCall = (payload) =>
  postRequestUsingFormData(onBoardSevaUrls, payload);
const updateAdminCall = (payload) =>
  postRequestUsingFormData(onBoardAdminUrls, payload);
const updateDescribeCall = (payload) =>
  putRequest(onBoardDescriptionUrls, payload);
const updatePhotoCall = (payload) =>
  putRequestWithFormData(onBoardPhotoVideoUrls, payload);

const getAdminCall = () => getRequest(onboardAdmingetUrls);

const getDonationCall = () => getRequest(onboardDonationgetUrls);

const getPrasadamCall = () => getRequest(onboardParsadamgetUrls);

const getSevaCall = () => getRequest(onboardSevagetUrls);

const getPaymentCall = () => getRequest(onboardPaymentgetUrls);

const updatePaymentCall2 = (payload) =>
  putRequestWithFormData(updateOnboardPayment, payload);
const updateDonationCall2 = (payload) =>
  putRequestWithFormData(
    updateOnboardDonation + `${payload.id}`,
    payload.formArray,
  );
const updatePrasadCall2 = (payload) =>
  putRequestWithFormData(
    updateOnboardPrasadam + `${payload.id}`,
    payload.formData,
  );
const updateSevaCall2 = (payload) =>
  putRequestWithFormData(
    `${updateOnboardSeva}${payload.id}/update`,
    payload.formArr,
  );

function* fetchAdminAll(action) {
  try {
    const response = yield call(getAdminCall);

    if (response.status === 200) {
      yield put(fetchAdminSuccess(response?.data));
    } else {
      yield put(fetchAdminFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchAdminFailure(error?.response?.data));
  }
}
function* fetchSevaAll(action) {
  try {
    const response = yield call(getSevaCall);

    if (response.status === 200) {
      yield put(fetchSevaSuccess(response?.data?.seva?.[0]));
    } else {
      yield put(fetchSevaFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchSevaFailure(error?.response?.data));
  }
}
function* fetchDonationAll(action) {
  try {
    const response = yield call(getDonationCall);

    if (response.status === 200) {
      yield put(fetchDonationSuccess(response?.data?.content?.[0]));
    } else {
      yield put(fetchDonationFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchDonationFailure(error?.response?.data));
  }
}
function* fetchPrasadamAll(action) {
  try {
    const response = yield call(getPrasadamCall);

    if (response.status === 200) {
      yield put(fetchPrasadamSuccess(response?.data?.content?.[0]));
    } else {
      yield put(fetchPrasadamFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchPrasadamFailure(error?.response?.data));
  }
}
function* fetchPaymentAll(action) {
  try {
    const response = yield call(getPaymentCall);

    if (response.status === 200) {
      yield put(fetchPaymentSuccess(response?.data));
    } else {
      yield put(fetchPaymentFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchPaymentFailure(error?.response?.data));
  }
}
function* fetchOnBoardingsAll(action) {
  try {
    const response = yield call(getCall);

    if (response.status === 200) {
      yield put(fetchOnBoardingSuccess(response?.data));
    } else {
      yield put(fetchOnBoardingFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchOnBoardingFailure(error?.response?.data));
  }
}

function* fetchOnBoardingStatus(action) {
  try {
    const response = yield call(getCall2);

    if (response.status === 200) {
      yield put(fetchOnBoardingStatusSuccess(response?.data));
    } else {
      yield put(fetchOnBoardingStatusFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchOnBoardingStatusFailure(error?.response?.data));
  }
}

function* postOnBoardingTemple(action) {
  try {
    const response = yield call(updateTempleCall, action.payload);
    if (response.status === 200) {
      yield put(uploadTempleSuccess(response?.data));
    } else {
      yield put(uploadTempleFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadTempleFailure(error?.response?.data));
  }
}

function* postOnBoardingDocument(action) {
  try {
    const response = yield call(updateDocCall, action.payload);
    if (response.status === 200) {
      yield put(uploadDocumentSuccess(response?.data));
    } else {
      yield put(uploadDocumentFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadDocumentFailure(error?.response?.data));
  }
}
function* postOnBoardingDescription(action) {
  try {
    const response = yield call(updateDescribeCall, action.payload);
    if (response.status === 200) {
      yield put(uploadDescriptionSuccess(response?.data));
    } else {
      yield put(uploadDescriptionFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadDescriptionFailure(error?.response?.data));
  }
}
function* postOnBoardingPhotos(action) {
  try {
    const response = yield call(updatePhotoCall, action.payload);
    if (response.status === 200) {
      yield put(uploadPhotosSuccess(response?.data));
    } else {
      yield put(uploadPhotosFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadPhotosFailure(error?.response?.data));
  }
}
function* postOnBoardingAdmin(action) {
  try {
    const response = yield call(updateAdminCall, action.payload);
    if (response.status === 200 || response.status === 201) {
      yield put(uploadAdminSuccess(response?.data));
    } else {
      yield put(uploadAdminFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadAdminFailure(error?.response?.data));
  }
}
function* postOnBoardingSeva(action) {
  try {
    const response = yield call(updateSevaCall, action.payload);
    if (response.status === 200) {
      yield put(uploadSevaSuccess(response?.data));
    } else {
      yield put(uploadSevaFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadSevaFailure(error?.response?.data));
  }
}
function* postOnBoardingPrasadam(action) {
  try {
    const response = yield call(updatePrasadCall, action.payload);
    if (response.status === 200 || response.status === 201) {
      yield put(uploadPrasadamSuccess(response?.data));
    } else {
      yield put(uploadPrasadamFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadPrasadamFailure(error?.response?.data));
  }
}
function* postOnBoardingDonation(action) {
  try {
    const response = yield call(updateDonationCall, action.payload);
    if (response.status === 200) {
      yield put(uploadDonationSuccess(response?.data));
    } else {
      yield put(uploadDonationFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadDonationFailure(error?.response?.data));
  }
}
function* postOnBoardingPayment(action) {
  try {
    const response = yield call(updatePaymentCall, action.payload);
    if (response.status === 200) {
      yield put(uploadPaymentSuccess(response?.data));
    } else {
      yield put(uploadPaymentFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadPaymentFailure(error?.response?.data));
  }
}

function* postOnBoardingFinalSubmit(action) {
  try {
    const response = yield call(updateFinalSubmitCall);
    if (response.status === 200) {
      yield put(uploadPreviewSuccess(response?.data));
    } else {
      yield put(uploadPreviewFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadPreviewFailure(error?.response?.data));
  }
}

function* postOnBoardingCompleteSubmit(action) {
  try {
    const response = yield call(updateOnboardCall);
    if (response.status === 200) {
      yield put(uploadOnboardCompleteSuccess(response?.data));
    } else {
      yield put(uploadOnboardCompleteFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadOnboardCompleteFailure(error?.response?.data));
  }
}
function* updateOnBoardingSeva(action) {
  try {
    const response = yield call(updateSevaCall2, action.payload);
    if (response.status === 200) {
      yield put(updateSevaSuccess(response?.data));
    } else {
      yield put(updateSevaFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(updateSevaFailure(error?.response?.data));
  }
}
function* updateOnBoardingDonation(action) {
  try {
    const response = yield call(updateDonationCall2, action.payload);
    if (response.status === 200) {
      yield put(updateDonationSuccess(response?.data));
    } else {
      yield put(updateDonationFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(updateDonationFailure(error?.response?.data));
  }
}

function* updateOnBoardingPrasadm(action) {
  try {
    const response = yield call(updatePrasadCall2, action.payload);
    if (response.status === 200) {
      yield put(updatePrasadamSuccess(response?.data));
    } else {
      yield put(updatePrasadamFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(updatePrasadamFailure(error?.response?.data));
  }
}
function* updateOnBoardingPayment(action) {
  try {
    const response = yield call(updatePaymentCall2, action.payload);
    if (response.status === 200) {
      yield put(updatePaymentSuccess(response?.data));
    } else {
      yield put(updatePaymentFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(updatePaymentFailure(error?.response?.data));
  }
}
function* delteImageRequestCall(action) {
  try {
    const response = yield call(deleteCall, action.payload);
    if (response.status === 200) {
      yield put(DeleteImageRequestSuccess(response?.data));
    } else {
      yield put(DeleteImageRequestFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(DeleteImageRequestFailure(error?.response?.data));
  }
}

export function* onBoardingSaga() {
  yield takeLatest(fetchOnBoardingRequest.type, fetchOnBoardingsAll);
  yield takeLatest(DeleteImageRequest.type, delteImageRequestCall);
  yield takeLatest(uploadPreviewRequest.type, postOnBoardingFinalSubmit);
  yield takeLatest(updateSevaRequest.type, updateOnBoardingSeva);
  yield takeLatest(updateDonationRequest.type, updateOnBoardingDonation);
  yield takeLatest(updatePrasadamRequest.type, updateOnBoardingPrasadm);
  yield takeLatest(updatePaymentRequest.type, updateOnBoardingPayment);
  yield takeLatest(
    uploadOnboardCompleteRequest.type,
    postOnBoardingCompleteSubmit,
  );
  yield takeLatest(fetchAdminRequest.type, fetchAdminAll);
  yield takeLatest(fetchSevaRequest.type, fetchSevaAll);
  yield takeLatest(fetchDonationRequest.type, fetchDonationAll);
  yield takeLatest(fetchPrasadamRequest.type, fetchPrasadamAll);
  yield takeLatest(fetchPaymentRequest.type, fetchPaymentAll);
  yield takeLatest(fetchOnBoardingStatusRequest.type, fetchOnBoardingStatus);
  yield takeLatest(uploadTempleRequest.type, postOnBoardingTemple);
  yield takeLatest(uploadDocumentRequest.type, postOnBoardingDocument);
  yield takeLatest(uploadDescriptionRequest.type, postOnBoardingDescription);
  yield takeLatest(uploadPhotosRequest.type, postOnBoardingPhotos);
  yield takeLatest(uploadAdminRequest.type, postOnBoardingAdmin);
  yield takeLatest(uploadSevaRequest.type, postOnBoardingSeva);
  yield takeLatest(uploadPrasadamRequest.type, postOnBoardingPrasadam);
  yield takeLatest(uploadDonationRequest.type, postOnBoardingDonation);
  yield takeLatest(uploadPaymentRequest.type, postOnBoardingPayment);
}
