import React, { useCallback, useState } from "react";
import "../../styles/dashboard.css";
import { Box } from "@mui/material";
import CommonTable from "../../components/CommonTable";
import { useNavigate } from "react-router-dom";

const tableRows = [
  {
    userID: "25821",
    userName: "Santhosh",
    name: "Campaign 1",
    contacts: "+91 6345674567",
    category: "Education",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Accepted",
  },
  {
    userID: "25821",
    userName: "Santhosh",
    name: "Campaign 2",
    contacts: "+91 6345674567",
    category: "Education",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Delivered",
  },
  {
    userID: "25821",
    userName: "Santhosh",
    name: "Campaign 3",
    contacts: "+91 6345674567",
    category: "Education",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Rejected",
  },
  {
    userID: "25821",
    userName: "Santhosh",
    name: "Campaign 4",
    contacts: "+91 6345674567",
    category: "Education",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Pending",
  },
  {
    userID: "25821",
    userName: "Santhosh",
    name: "Campaign 5",
    contacts: "+91 6345674567",
    category: "Education",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Delivered",
  },
];

const DonationList = React.memo(() => {
  const navigate = useNavigate();
  const columns = [
    {
      field: "userID",
      headerName: "User ID",
      renderCell: (params, row) => {
        return (
          <span>
            <p style={{ marginLeft: "0.5rem" }}>{params}</p>
          </span>
        );
      },
    },
    { field: "userName", headerName: "User Name" },
    { field: "amount", headerName: "Phone No." },
    { field: "name", headerName: "Campaign Name" },
    { field: "category", headerName: "Campaign Category" },
    { field: "startDate", headerName: "Donation Date" },
    { field: "amount", headerName: "Amount" },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  return (
    <Box>
      <Box className="heading">Donation List</Box>

      <CommonTable
        columns={columns}
        data={tableRows}
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </Box>
  );
});

export default DonationList;
