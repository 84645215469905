// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-10d30g3-MuiPaper-root-MuiDialog-paper {
  max-width: 100rem !important;
}

.Booking-dialog-content {
  background-color: var(--grey-dark);
  border-radius: 1.5rem !important;
  padding: 2.8rem;
  width: 80rem !important;
}
.Booking-dialog-title-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.Booking-dialog-title {
  color: #c2ebec !important;
  font-size: 1.4rem !important;
}
.Booking-dialog-cancel-icon {
  color: var(--off-white);
  font-size: 1.8rem !important;
  cursor: pointer;
  border-radius: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/purohit/UnavailableDatePage.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,kCAAkC;EAClC,gCAAgC;EAChC,eAAe;EACf,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;AAC9B;AACA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,eAAe;EACf,qBAAqB;AACvB","sourcesContent":[".css-10d30g3-MuiPaper-root-MuiDialog-paper {\n  max-width: 100rem !important;\n}\n\n.Booking-dialog-content {\n  background-color: var(--grey-dark);\n  border-radius: 1.5rem !important;\n  padding: 2.8rem;\n  width: 80rem !important;\n}\n.Booking-dialog-title-container {\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: space-between;\n}\n\n.Booking-dialog-title {\n  color: #c2ebec !important;\n  font-size: 1.4rem !important;\n}\n.Booking-dialog-cancel-icon {\n  color: var(--off-white);\n  font-size: 1.8rem !important;\n  cursor: pointer;\n  border-radius: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
