import React, { useState, useEffect } from "react";
import Tabs from "../../components/common/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StatsCard from "../../components/cards/StatsCard";
import DonationCampaign from "./donation_campaign/DonationCampaign";
import DonationList from "./DonationList";
import "../../styles/donation.css";
import AddEditDonationCampaign from "./donation_campaign/AddEditDonationCampaign";
import { Box } from "@mui/material";

const tabItems = [
  { label: "Donation", value: "Donation" },
  { label: "Donation List", value: "Donation_List" },
];

const DonationManagement = React.memo(() => {
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const location = useLocation();

  const pathName = location.pathname;
  const { actionType, tabType } = useParams();

  const [selectedMenu, setSelectedMenu] = useState(() => {
    let pathName = location.pathname.split("/");
    return pathName[2] ? pathName[2] : "Donation";
  });

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
    navigate(`/donation-management/${menu}`);
  };

  useEffect(() => {
    let pathName = location.pathname.split("/");

    const filteredData = tabItems.filter((item) =>
      pathName.includes(item.value),
    );

    if (filteredData.length > 0 && !selectedMenu) {
      setSelectedMenu(filteredData[0].value);
      navigate(
        `/donation-management${filteredData[0].value ? `/${filteredData[0].value}` : ""}${tabType ? `/${tabType}` : ""}`,
      );
    }

    if (filteredData.length === 0 && !selectedMenu) {
      setSelectedMenu("Donation");
      navigate(`/donation-management/Donation`);
    }
  }, [pathName]);

  return (
    <>
      {actionType === "Add" || actionType === "Edit" ? (
        <AddEditDonationCampaign actionType={actionType} />
      ) : (
        <>
          <Box
            className={"heading"}
            sx={{
              textWrap: "nowrap",
              paddingY: "10px",
            }}
          >
            Donation Management
          </Box>
          <div className="donation-stats-container">
            <StatsCard
              label={"Total Donation Received"}
              stat={"0"}
              image="/donation-icons/donation-icon.svg"
              info="Total Donations received"
            />
            <StatsCard
              label={"General Donations"}
              stat={"0"}
              image="/donation-icons/general-donation-icon.svg"
            />
            <StatsCard
              label={"Recurring Donations"}
              stat={"0"}
              image="/donation-icons/recurring-donation-icon.svg"
            />
          </div>

          <div style={{ marginTop: "1rem" }}>
            <Tabs
              tabs={tabItems}
              tabStart={selectedMenu}
              onClick={handleMenuButtonClick}
            />
          </div>

          {selectedMenu === "Donation" ? (
            <DonationCampaign />
          ) : selectedMenu === "Donation_List" ? (
            <DonationList />
          ) : null}
        </>
      )}
    </>
  );
});

export default DonationManagement;
