import React, { useCallback, useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  useTheme,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import useCustomStyles from "./useCustomeStyles";
import { MenuImage } from "./MenuImage";
import { useDispatch } from "react-redux";
import { logout } from "../features/slices/authSlice";
import { use } from "echarts";

const styles = (theme) => ({
  drawer_logo_container: {
    height: 100,
    width: 100,
  },
  list_item_button: {
    "&.Mui-selected": {
      borderRadius: "0.5rem",
      backgroundColor: "#FFF1E6",
      color: "var(--green-text-color)",
    },
  },
});

const Sidebar = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const location = useLocation();

  const [activeMenu, setActiveMenu] = useState(() => {
    let activeMenu = location?.pathname.split("/")?.[1];
    let role = localStorage.getItem("role");

    return activeMenu
      ? `/${activeMenu}`
      : role === "ROLE_TEMPLE_OWNER"
        ? "/dashboard"
        : role === "ROLE_PUROHIT"
          ? "/purohit-dashboard"
          : role === "ROLE_SELLER_ECOMM"
            ? "/product-dashboard"
            : "/dashboard";
  });

  useEffect(() => {
    setActiveMenu(() => {
      let activeMenu = location?.pathname.split("/")?.[1];
      let role = localStorage.getItem("role");

      return activeMenu
        ? `/${activeMenu}`
        : role === "ROLE_TEMPLE_OWNER"
          ? "/dashboard"
          : role === "ROLE_PUROHIT"
            ? "/purohit-dashboard"
            : role === "ROLE_SELLER_ECOMM"
              ? "/product-dashboard"
              : "/dashboard";
    });
  }, [location]);

  const [subActiveMenu, setSubActiveMenu] = useState(() => {
    let pathname = location.pathname.split("/");

    let subActiveMenu = pathname?.[2];

    return location.pathname === "/contents/games/puzzle" ||
      location.pathname === "/contents/games/crossword" ||
      location.pathname === "/contents/games/dumbcharades"
      ? "/contents/games/puzzle"
      : subActiveMenu
        ? `/${pathname[1]}/${pathname[2]}`
        : "";
  });

  const [expandedMenu, setExpandedMenu] = useState(() => {
    let pathname = location.pathname.split("/");
    let subActiveMenu = pathname?.[2];
    return subActiveMenu ? `${pathname[1]}` : "";
  });

  const templeMenuItems = [
    {
      text: "Dashboard",
      path: "/dashboard",
      img: "/daiv-dashboard.svg",
    },
    {
      text: "Order Management",
      path: "/order-management",
      img: "/order.svg",
    },
    {
      text: "Seva Management",
      path: "/seva-management",
      img: "/seva.svg",
    },
    {
      text: "Prasadam Management",
      path: "/prasadam-management",
      img: "/laddu-icon.svg",
    },

    {
      text: "Donation Management",
      path: "/donation-management",
      img: "/donation.svg",
    },
    {
      text: "Payment Management",
      path: "/payment-management",
      img: "/payment.svg",
    },

    {
      text: "Community Management",
      path: "/community-management",
      img: "/community.svg",
    },
    {
      text: "Issue Management",
      path: "/issue-management",
      img: "/issue-management.svg",
    },
    {
      text: "Help and Support",
      path: "/help_and_support",
      img: "/help.svg",
    },
    {
      text: "Settings & Preferences",
      path: "/setting_and_prefrences",
      img: "/setting.svg",
    },
  ];

  const purohitMenuItems = [
    {
      text: "Dashboard",
      path: "/purohit-dashboard",
      img: "/daiv-dashboard.svg",
    },
    {
      text: "Booking Management",
      path: "/booking-management",
      img: "/booking-management.svg",
    },

    {
      text: "Puja Management",
      path: "/puja-management",
      img: "/puja-management.svg",
    },
    {
      text: "Schedule & Availability",
      path: "/schedule_availability",
      img: "/booking.svg",
    },
    {
      text: "Payment Management",
      path: "/payment-management",
      img: "/payment.svg",
    },
    {
      text: "Issue Management",
      path: "/issue-management",
      img: "/issue-management.svg",
    },
    {
      text: "Help and Support",
      path: "/help_and_support",
      img: "/help.svg",
    },
    {
      text:  "Settings & Preferences",
      path: "/setting_and_prefrences",
      img: "/setting.svg",
    },
  ];

  const productMenuItems = [
    {
      text: "Dashboard",
      path: "/product-dashboard",
      img: "/daiv-dashboard.svg",
    },
    {
      text: "Order Management",
      path: "/order-management",
      img: "/order.svg",
    },
    {
      text: "Product Management",
      path: "/product-management",
      img: "/product.svg",
    },
    {
      text: "Inventory Management",
      path: "/inventory-management",
      img: "/inventory-icon.svg",
    },
    {
      text: "Warehouse Management",
      path: "/warehouse-management",
      img: "/warehouse-icon.svg",
    },
    {
      text: "Payment Management",
      path: "/payment-management",
      img: "/warehouse.svg",
    },
    {
      text: "Issue Management",
      path: "/issue-management",
      img: "/issue-management.svg",
    },
    {
      text: "Help and Support",
      path: "/help_and_support",
      img: "/help.svg",
    },
    {
      text:  "Settings & Preferences",
      path: "/setting_and_prefrences",
      img: "/setting.svg",
    },
  ];

  useEffect(() => {
    let pathname = location?.pathname?.split("/");

    if (pathname[2]) {
      setExpandedMenu(`${pathname[1]}`);
    }
  }, [location.pathname]);

  useEffect(() => {
    setActiveMenu(activeMenu);
  }, [activeMenu]);

  const handleMenuClick = useCallback(
    (path) => {
      navigate(path);

      setActiveMenu(path);
      setSubActiveMenu("");
      setExpandedMenu("");

      localStorage.removeItem("openMenuBar");
      localStorage.removeItem("openProductView");
      localStorage.removeItem("openProductReviewModal");
      localStorage.removeItem("openProductReviewTable");
    },
    [navigate],
  );

  const toggleExpand = (type) => {
    setExpandedMenu((prev) => {
      return prev === type ? "" : type;
    });
  };

  const role = localStorage.getItem("role");

  const [menu, setMenu] = useState([]);

  const setMenuItems = () => {
    if (role === "ROLE_TEMPLE_OWNER") {
      setMenu(templeMenuItems);
    } else if (role === "ROLE_PUROHIT") {
      setMenu(purohitMenuItems);
    } else if (role === "ROLE_SELLER_ECOMM") {
      setMenu(productMenuItems);
    }
  };

  useEffect(() => {
    setMenuItems();
  }, [role]);

  const handleLogout = useCallback(() => {
    dispatch(logout(navigate));
  }, [dispatch, navigate]);

  return (
    <div className="sidebar_Style">
      <List style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            {menu.map((item) => (
              <React.Fragment key={item.text}>
                {["Products", "Contents", "Daily-Uploads"].includes(
                  item.text,
                ) ? (
                  <>
                    <ListItem
                      button
                      onClick={() => toggleExpand(item.text.toLowerCase())}
                      className={classes?.list_item_button}
                      style={{
                        backgroundColor: activeMenu.startsWith(
                          `/${item.text.toLowerCase()}`,
                        )
                          ? "#FFF1E6"
                          : "transparent",
                        borderRadius: "0.5rem",
                        cursor: "pointer",
                      }}
                    >
                      <ListItemIcon
                        className="menu_icon-size"
                        style={{
                          cursor: "pointer",
                          color: subActiveMenu.startsWith(
                            `/${item.text.toLowerCase()}`,
                          )
                            ? "var(--green-text-color)"
                            : "black",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        style={{
                          cursor: "pointer",
                          color: subActiveMenu.startsWith(
                            `/${item.text.toLowerCase()}`,
                          )
                            ? "var(--green-text-color)"
                            : "black",
                        }}
                      />
                      {expandedMenu === item.text.toLowerCase() ? (
                        <ExpandLess
                          style={{
                            color:
                              expandedMenu === item.text.toLowerCase()
                                ? "var(--green-text-color)"
                                : "black",
                          }}
                        />
                      ) : (
                        <ExpandMore
                          style={{
                            color:
                              expandedMenu === item.text.toLowerCase()
                                ? "var(--green-text-color)"
                                : "black",
                          }}
                        />
                      )}
                    </ListItem>
                    <Collapse
                      in={expandedMenu === item.text.toLowerCase()}
                      timeout="auto"
                      unmountOnExit
                    ></Collapse>
                  </>
                ) : (
                  <ListItem
                    button
                    onClick={() => handleMenuClick(item.path)}
                    className={classes?.list_item_button}
                    style={{
                      backgroundColor:
                        activeMenu === item.path ? "#FFF1E6" : "transparent",
                      borderRadius: "0.5rem",
                      cursor: "pointer",
                    }}
                  >
                    <ListItemIcon
                      className="menu_icon-size"
                      style={{
                        cursor: "pointer",
                        color:
                          activeMenu === item.path
                            ? "var(--green-text-color)"
                            : "black",
                      }}
                    >
                      <div
                        style={{
                          WebkitMaskImage: `url(${item.img})`,
                          WebkitMaskRepeat: "no-repeat",
                          WebkitMaskSize: "100% 100%",
                          backgroundColor:
                            activeMenu === item.path
                              ? "var(--green-text-color)"
                              : "#67687C",
                          width: 24,
                          height: 24,
                        }}
                      ></div>
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      style={{
                        cursor: "pointer",
                        color: activeMenu.includes(item.path)
                          ? "var(--green-text-color)"
                          : "black",
                      }}
                    />
                  </ListItem>
                )}
              </React.Fragment>
            ))}
          </div>
          <div>
            <ListItem
              button
              onClick={handleLogout}
              className={classes?.list_item_button}
              style={{
                borderRadius: "0.5rem",
                cursor: "pointer",
              }}
            >
              <ListItemIcon
                className="menu_icon-size"
                style={{
                  cursor: "pointer",
                }}
              >
                <img
                  style={{
                    width: 24,
                    height: 24,
                  }}
                  src="/logout-icon.svg"
                  alt="logout"
                />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                style={{
                  cursor: "pointer",
                }}
              />
            </ListItem>
          </div>
        </div>
      </List>
    </div>
  );
});

export default Sidebar;
