import { createSlice } from "@reduxjs/toolkit";

const communityPostSlice = createSlice({
    name: "communityPost",
    initialState: {
        templeId: 0,
        communityPostData: [],
        specificCommunityPostData: [],
        error: null,
        loading: false,
        fetchTempleIdStatus: false,
        fetchAllCommunityPostsStatus: false,
        fetchCommunityPostByIdStatus: false,
        createCommunityPostStatus: false,
        updateCommunityPostStatus: false,
        deleteCommunityPostStatus: false
    },
    reducers: {
        communityPostCreation: (state) => {
            state.loading = true;
            state.error = null;
            state.createCommunityPostStatus = false;
        },
        communityPostCreationSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.createCommunityPostStatus = true;
        },
        communityPostCreationFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.createCommunityPostStatus = false;
        },
        fetchAllCommunityPost: (state) => {
            state.communityPostData = [];
            state.loading = true;
            state.error = null;
            state.fetchAllCommunityPostsStatus = false;
        },
        fetchAllCommunityPostSuccess: (state, action) => {
            state.communityPostData = action.payload;
            state.loading = false;
            state.error = null;
            state.fetchAllCommunityPostsStatus = true;
        },
        fetchAllCommunityPostFailure: (state, action) => {
            state.communityPostData = [];
            state.loading = false;
            state.error = action.payload;
            state.fetchAllCommunityPostsStatus = false;
        },
        communityPostDeletion: (state) => {
            state.loading = true;
            state.error = null;
            state.deleteCommunityPostStatus = false;
        },
        communityPostDeletionSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.deleteCommunityPostStatus = true;
        },
        communityPostDeletionFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.deleteCommunityPostStatus = false;
        },
        fetchCommunityPostById: (state) => {
            state.specificCommunityPostData = [];
            state.loading = true;
            state.error = null;
            state.fetchCommunityPostByIdStatus = false;
        },
        fetchCommunityPostByIdSuccess: (state, action) => {
            state.specificCommunityPostData = action.payload;
            state.loading = false;
            state.error = null;
            state.fetchCommunityPostByIdStatus = true;
        },
        fetchCommunityPostByIdFailure: (state, action) => {
            state.specificCommunityPostData = [];
            state.loading = false;
            state.error = action.payload;
            state.fetchCommunityPostByIdStatus = false;
        },
        fetchTempleId: (state) => {
            state.templeId = 0;
            state.loading = true;
            state.error = null;
            state.fetchTempleIdStatus = false;
        },
        fetchTempleIdSuccess: (state, action) => {
            state.templeId = action.payload;
            state.loading = false;
            state.error = null;
            state.fetchTempleIdStatus = true;
        },
        fetchTempleIdFailure: (state, action) => {
            state.templeId = 0;
            state.loading = false;
            state.error = action.payload;
            state.fetchTempleIdStatus = false;
        },
        communityPostUpdation: (state) => {
            state.loading = true;
            state.error = null;
            state.updateCommunityPostStatus = false;
        },
        communityPostUpdationSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.updateCommunityPostStatus = true;
        },
        communityPostUpdationFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.updateCommunityPostStatus = false;
        },
        toggleStatus: (state) => {
            state.fetchAllCommunityPostsStatus = false;
            state.fetchCommunityPostByIdStatus = false;
            state.createCommunityPostStatus = false;
            state.updateCommunityPostStatus = false;
            state.deleteCommunityPostStatus = false;
        },
    }
});

export const {
    communityPostCreation,
    communityPostCreationSuccess,
    communityPostCreationFailure,
    fetchAllCommunityPost,
    fetchAllCommunityPostSuccess,
    fetchAllCommunityPostFailure,
    fetchTempleId,
    fetchTempleIdSuccess,
    fetchTempleIdFailure,
    communityPostDeletion,
    communityPostDeletionSuccess,
    communityPostDeletionFailure,
    fetchCommunityPostById,
    fetchCommunityPostByIdSuccess,
    fetchCommunityPostByIdFailure,
    communityPostUpdation,
    communityPostUpdationSuccess,
    communityPostUpdationFailure,
    toggleStatus
} = communityPostSlice.actions;

export default communityPostSlice.reducer;
