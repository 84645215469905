import { call, put, takeEvery } from "redux-saga/effects";

import {
  communityPostCreation,
  communityPostCreationSuccess,
  communityPostCreationFailure,
  fetchAllCommunityPost,
  fetchAllCommunityPostSuccess,
  fetchAllCommunityPostFailure,
  communityPostDeletion,
  communityPostDeletionSuccess,
  communityPostDeletionFailure,
  fetchCommunityPostById,
  fetchCommunityPostByIdSuccess,
  fetchCommunityPostByIdFailure,
  fetchTempleId,
  fetchTempleIdSuccess,
  fetchTempleIdFailure,
  communityPostUpdation,
  communityPostUpdationSuccess,
  communityPostUpdationFailure,
} from "../slices/communityPostSlice";

import {
  getRequest,
  postRequestUsingFormData,
  deleteRequest,
  putRequest,
} from "../../api/apiActions";

import {
  createCommunityPostUrl,
  getAllCommunityPostsUrl,
  deleteCommunityPostUrl,
  getCommunityPostByIdUrl,
  updateCommunityPostUrl,
  getTempleIdUrl,
} from "../../api/apiUrls";
import { openSnackbar } from "../slices/snackbarSlice";

const createCommunityPost = (payload) => {
  return postRequestUsingFormData(createCommunityPostUrl, payload);
};

const fetchCommunityPosts = (data) => {
  return getRequest(
    `${getAllCommunityPostsUrl}/${data.templeId}/all?${data.queryParamString}`,
  );
};

const removeCommunityPost = (id) => {
  return deleteRequest(`${deleteCommunityPostUrl}/${id}/delete`);
};

const fetchASpecificCommunityPost = (id) => {
  return getRequest(`${getCommunityPostByIdUrl}/${id}`);
};

const editCommunityPost = (data) => {
  return putRequest(
    `${updateCommunityPostUrl}/${data.communityPostId}/update`,
    data.formData,
  );
};

const fetchTempleIdDetails = () => {
  return getRequest(`${getTempleIdUrl}`);
};

function* addCommunityPost(action) {
  try {
    const result = yield call(createCommunityPost, action.payload);

    if (result.status === 200) {
      yield put(communityPostCreationSuccess(result?.data));
      yield put(
        openSnackbar({
          message: "Community Post Added Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(communityPostCreationFailure());
    }
  } catch (error) {
    yield put(communityPostCreationFailure(error));
  }
}

function* getCommunityPosts(action) {
  try {
    const result = yield call(fetchCommunityPosts, action.payload);

    if (result.status === 200) {
      yield put(fetchAllCommunityPostSuccess(result?.data));
    } else {
      yield put(fetchAllCommunityPostFailure());
    }
  } catch (error) {
    yield put(fetchAllCommunityPostFailure(error));
  }
}

function* deleteCommunityPost(action) {
  try {
    const result = yield call(removeCommunityPost, action.payload);

    if (result.status === 200) {
      yield put(communityPostDeletionSuccess(result));
      yield put(
        openSnackbar({
          message: "Community Post Deleted Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(communityPostDeletionFailure());
    }
  } catch (error) {
    yield put(communityPostDeletionFailure(error));
  }
}

function* getCommunityPostById(action) {
  try {
    const result = yield call(fetchASpecificCommunityPost, action.payload);

    if (result.status === 200) {
      yield put(fetchCommunityPostByIdSuccess(result?.data));
    } else {
      yield put(fetchCommunityPostByIdFailure());
    }
  } catch (error) {
    yield put(fetchCommunityPostByIdFailure(error));
  }
}

function* updateCommunityPost(action) {
  try {
    const result = yield call(editCommunityPost, action.payload);

    if (result.status === 200) {
      yield put(communityPostUpdationSuccess(result));
      yield put(
        openSnackbar({
          message: "Community Post Updated Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(communityPostUpdationFailure());
    }
  } catch (error) {
    yield put(communityPostUpdationFailure(error));
  }
}

function* getTempleIdDetails(action) {
  try {
    const result = yield call(fetchTempleIdDetails);

    if (result.status === 200) {
      yield put(fetchTempleIdSuccess(result?.data));
    } else {
      yield put(fetchTempleIdFailure());
    }
  } catch (error) {
    yield put(fetchTempleIdSuccess(error));
  }
}

export function* communityPostSaga() {
  yield takeEvery(communityPostCreation.type, addCommunityPost);
  yield takeEvery(fetchAllCommunityPost.type, getCommunityPosts);
  yield takeEvery(communityPostDeletion.type, deleteCommunityPost);
  yield takeEvery(fetchCommunityPostById.type, getCommunityPostById);
  yield takeEvery(communityPostUpdation.type, updateCommunityPost);
  yield takeEvery(fetchTempleId.type, getTempleIdDetails);
}

export default communityPostSaga;
