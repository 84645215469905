import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Flag from "react-world-flags";
import * as Yup from "yup";
import { AddButton } from "../../components/Addbutton";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import {
  sendOtpRequest,
  verifyOtpRequest,
} from "../../features/slices/authSlice";
import "../../styles/login.css";

const countryData = [
  { code: "+91", country: "India", flag: "IN" },
  { code: "+1", country: "US", flag: "US" },
  { code: "+44", country: "United Kingdom", flag: "GB" },
  { code: "+81", country: "Japan", flag: "JP" },
  { code: "+61", country: "Australia", flag: "AU" },
  { code: "+49", country: "Germany", flag: "DE" },
  { code: "+33", country: "France", flag: "FR" },
  { code: "+39", country: "Italy", flag: "IT" },
  { code: "+34", country: "Spain", flag: "ES" },
  { code: "+55", country: "Brazil", flag: "BR" },
  { code: "+7", country: "Russia", flag: "RU" },
  { code: "+52", country: "Mexico", flag: "MX" },
  { code: "+34", country: "Spain", flag: "ES" },
  { code: "+20", country: "Egypt", flag: "EG" },
  { code: "+31", country: "Netherlands", flag: "NL" },
  { code: "+46", country: "Sweden", flag: "SE" },
  { code: "+48", country: "Poland", flag: "PL" },
  { code: "+66", country: "Thailand", flag: "TH" },
  { code: "+27", country: "South Africa", flag: "ZA" },
  { code: "+41", country: "Switzerland", flag: "CH" },
  { code: "+86", country: "China", flag: "CN" },
  { code: "+90", country: "Turkey", flag: "TR" },
  { code: "+82", country: "South Korea", flag: "KR" },
  { code: "+64", country: "New Zealand", flag: "NZ" },
  { code: "+32", country: "Belgium", flag: "BE" },
  { code: "+48", country: "Poland", flag: "PL" },
  { code: "+55", country: "Brazil", flag: "BR" },
  { code: "+254", country: "Kenya", flag: "KE" },
  { code: "+971", country: "United Arab Emirates", flag: "AE" },
  { code: "+966", country: "Saudi Arabia", flag: "SA" },
  { code: "+59", country: "Puerto Rico", flag: "PR" },
  { code: "+56", country: "Chile", flag: "CL" },
  { code: "+62", country: "Indonesia", flag: "ID" },
  { code: "+53", country: "Cuba", flag: "CU" },
  { code: "+40", country: "Romania", flag: "RO" },
  { code: "+43", country: "Austria", flag: "AT" },
  { code: "+20", country: "Egypt", flag: "EG" },
  { code: "+60", country: "Malaysia", flag: "MY" },
  { code: "+370", country: "Lithuania", flag: "LT" },
  { code: "+234", country: "Nigeria", flag: "NG" },
  { code: "+380", country: "Ukraine", flag: "UA" },
  { code: "+30", country: "Greece", flag: "GR" },
  { code: "+356", country: "Malta", flag: "MT" },
  { code: "+20", country: "Egypt", flag: "EG" },
  { code: "+93", country: "Afghanistan", flag: "AF" },
  { code: "+359", country: "Bulgaria", flag: "BG" },
  { code: "+222", country: "Mauritania", flag: "MR" },
  { code: "+260", country: "Zambia", flag: "ZM" },
  { code: "+254", country: "Kenya", flag: "KE" },
  { code: "+263", country: "Zimbabwe", flag: "ZW" },
  { code: "+977", country: "Nepal", flag: "NP" },
  { code: "+355", country: "Albania", flag: "AL" },
  { code: "+855", country: "Cambodia", flag: "KH" },
  { code: "+62", country: "Indonesia", flag: "ID" },
  { code: "+263", country: "Zimbabwe", flag: "ZW" },
  { code: "+974", country: "Qatar", flag: "QA" },
  { code: "+49", country: "Germany", flag: "DE" },
  { code: "+358", country: "Finland", flag: "FI" },
  { code: "+31", country: "Netherlands", flag: "NL" },
  { code: "+966", country: "Saudi Arabia", flag: "SA" },
  { code: "+86", country: "China", flag: "CN" },
];

const UserContact = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [phoneError, setPhoneError] = useState("");
  const [name, setName] = useState("");
  const [value, setValue] = useState();
  const [index, setIndex] = useState(0);
  const [otp, setOtp] = React.useState("");

  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleCountryChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handlePhoneChange = (event) => {
    if (event.target.value.length === 16) {
      return;
    }
    setPhoneError("");
    setPhoneNumber(event.target.value);
  };

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
  });

  const handleSubmit = () => {
    let OtpObj = {
      countryCode: countryCode,
      phoneNumber: phoneNumber,
    };

    dispatch(sendOtpRequest({ OtpObj, navigate }));
  };

  const handleVerifyOtp = () => {
    let VerifyOtpObj = {
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      userName: name,
      otp,
    };

    const role = localStorage.getItem("role");
    let url = "/signup/view-policy";
    dispatch(verifyOtpRequest({ VerifyOtpObj, navigate, url }));
  };

  useEffect(() => {
    if (auth.otpSentStatus) {
      setIndex(1);
    }
  }, [auth.otpSentStatus]);

  return (
    <>
      <img src="/daiv-logo.svg" alt="logo" className="daiv_logo" />
      <div container className="login_container">
        <div className="login_background_image"></div>
        <div className="login_content">
          <div className="greeting_text_container">
            <div className="login_form">
              {index === 0 && (
                <>
                  <div className="welcome_title">User Contact Details</div>
                  <div className="welcome_login_btn">
                    <Formik
                      initialValues={{
                        fullName: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        setName(values.fullName);
                        if (!phoneNumber) {
                          setPhoneError("Phone number is required");
                          return;
                        }
                        handleSubmit(value);
                      }}
                    >
                      {({ setFieldValue, isSubmitting, errors, touched }) => (
                        <Form>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              marginTop: "25px",
                            }}
                          >
                            <MaterialUIFieldAdapter
                              name="fullName"
                              type="text"
                              label="Full Name*"
                              placeholder="Enter Name"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <Typography
                              textAlign={"left"}
                              color={"var(--off-white)"}
                              sx={{ marginBottom: ".3rem" }}
                            >
                              Phone No
                            </Typography>
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: 500,
                                margin: "0 auto",
                                display: "flex",
                              }}
                            >
                              <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{
                                  width: "45%",
                                }}
                              >
                                <Select
                                  value={countryCode}
                                  onChange={handleCountryChange}
                                  displayEmpty
                                  renderValue={(selected) => {
                                    const selectedCountry = countryData.find(
                                      (country) => country.code === selected,
                                    );
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: ".3rem",
                                        }}
                                      >
                                        <div>
                                          <Flag
                                            code={selectedCountry?.flag}
                                            style={{
                                              height: 17,
                                              margin: "auto",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          />
                                        </div>
                                        <div>{selectedCountry?.code}</div>
                                      </div>
                                    );
                                  }}
                                  sx={{
                                    borderRadius: 0,
                                    borderTopLeftRadius: ".2rem",
                                    borderBottomLeftRadius: ".2rem",
                                    border: "none", // Remove the border
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none", // Remove the outline border
                                      borderLeft: "1px solid black",
                                      borderTop: "1px solid black",
                                      borderBottom: "1px solid black",
                                    },

                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: 0, // Remove border radius inside the input root
                                    },
                                    "& .MuiSelect-icon": {
                                      right: 8, // Adjust dropdown icon position if needed
                                    },
                                  }}
                                >
                                  {countryData.map((country, index) => (
                                    <MenuItem key={index} value={country.code}>
                                      <Grid container>
                                        <Grid item sx={{ marginRight: 1 }}>
                                          <Flag
                                            code={country.flag}
                                            style={{ width: 24, height: 23 }}
                                          />
                                        </Grid>
                                        <Grid item>
                                          {country.code} - {country.country}
                                        </Grid>
                                      </Grid>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <TextField
                                sx={{ width: "100%" }}
                                variant="outlined"
                                fullWidth
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                placeholder="Enter Phone Number"
                                type="number"
                                maxLength={15}
                                inputProps={{
                                  pattern: "[0-9]{10}",
                                  maxLength: 12,
                                }}
                                InputProps={{
                                  sx: { borderRadius: 0 },
                                }}
                              />
                            </Box>
                            {phoneError && (
                              <div
                                style={{
                                  border: "1px solid white",
                                  marginTop: "-2px",
                                  borderTop: "none",
                                  color: "#d32f2f",
                                  fontSize: ".75rem",
                                  paddingTop: "6px",
                                  paddingLeft: "15px",
                                  borderBottomLeftRadius: "5px",
                                  borderBottomRightRadius: "5px",
                                }}
                              >
                                {phoneError}
                              </div>
                            )}
                          </div>

                          <AddButton
                            name="Send OTP to verify"
                            type="submit"
                            width="100%"
                            padding="18px"
                            background="var(--secondary)"
                            sx={{
                              fontFamily: "var(--font-secondary) !important",
                              fontWeight: 600,
                              fontSize: ".9rem",
                              marginTop: "3rem",
                            }}
                          />
                        </Form>
                      )}
                    </Formik>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    <div className="user_contact_box_otp">
                      <div className="welcome_title user_contact_title">
                        Enter the OTP that you have received on{" "}
                        {countryCode + " " + phoneNumber}
                      </div>
                      <div
                        className="user_contact_edit"
                        onClick={() => setIndex(0)}
                      >
                        <EditOutlinedIcon sx={{ fontSize: "1rem" }} /> Edit
                      </div>
                    </div>
                    <MuiOtpInput value={otp} onChange={handleChange} />
                    <AddButton
                      name="Verify"
                      type="submit"
                      width="100%"
                      padding="1.3rem"
                      background="var(--secondary)"
                      sx={{
                        fontFamily: "var(--font-secondary) !important",
                        fontWeight: 600,
                        fontSize: ".9rem",
                      }}
                      onClick={handleVerifyOtp}
                      // onClick={() => navigate("/signup/temple-details")}
                    />
                    <div className="welcome_footer">
                      <div className="welcome_footer_1">
                        Didn’t Receive the Code?
                      </div>
                      <div
                        className="welcome_footer_2"
                        onClick={() => {
                          let OtpObj = {
                            countryCode: countryCode,
                            phoneNumber: phoneNumber,
                          };
                          dispatch(sendOtpRequest({ OtpObj, navigate }));
                        }}
                      >
                        Resend Otp
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default UserContact;
