import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Tabs from "../components/common/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OrderTickets from "./Issue/OrderTickets";
import OtherTickets from "./Issue/OtherTickets";
import PaymentTickets from "./Issue/PaymentTickets";

const tabItems = [
  { label: "Order Tickets", value: "Order-Tickets" },
  { label: "Other Tickets", value: "Other-Tickets" },
  { label: "Payment Tickets", value: "Payment-Tickets" },
];

const HelpRequest = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { subTabType, actionType, tabType } = useParams();
  const [selectedMenu, setSelectedMenu] = useState("");

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
    navigate(`/help-request/${menu}`);
  };

  useEffect(() => {
    let pathName = location.pathname.split("/");
    const filteredData = tabItems.filter((item) =>
      pathName.includes(item.value),
    );

    if (filteredData.length > 0 && !selectedMenu) {
      setSelectedMenu(filteredData[0].value);
      navigate(
        `/help-request/${filteredData[0].value}${subTabType ? `/${subTabType}` : ""}${actionType ? `/${actionType}` : ""}`,
      );
    }
    if (filteredData.length === 0 && !selectedMenu) {
      setSelectedMenu("Order-Tickets");
      navigate(`/help-request/Order-Tickets`);
    }
  }, [pathName]);

  return (
    <div>
      <Box
        className={"heading"}
        sx={{
          textWrap: "nowrap",
          paddingY: "0.5rem",
        }}
      >
        Help Request
      </Box>
      <div style={{ marginTop: "1rem" }}>
        <Tabs
          tabs={tabItems}
          tabStart={selectedMenu}
          onClick={handleMenuButtonClick}
        />
      </div>

      {selectedMenu === "Order-Tickets" ? (
        <OrderTickets />
      ) : selectedMenu === "Other-Tickets" ? (
        <OtherTickets />
      ) : selectedMenu === "Payment-Tickets" ? (
        <PaymentTickets />
      ) : null}
    </div>
  );
});

export default HelpRequest;
