import React, { useCallback, useState, useEffect } from "react";
import { Box } from "@mui/material";
import CommonTable from "../../../components/CommonTable";
import { useNavigate, useParams } from "react-router-dom";
import AntSwitch from "../../../components/AntSwitch";
import { AddButton } from "../../../components/Addbutton";
import AddIcon from "@mui/icons-material/Add";
import "../../../styles/donation.css";
import AddEditDonationCampaign from "./AddEditDonationCampaign";
import {
  fetchDonationRequest,
  fetchDonationUpdateByStatusRequest,
} from "../../../features/slices/donationSlice";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoader } from "../../../components/CustomLoader";

const tableRows = [
  {
    name: "Campaign 1",
    contacts: "+91 6345674567",
    prasadamName: "Laddu, Ghee, MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Accepted",
  },
  {
    name: "Campaign 2",
    contacts: "+91 6345674567",
    prasadamName: "Laddu, Ghee, MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Delivered",
  },
  {
    name: "Campaign 3",
    contacts: "+91 6345674567",
    prasadamName: "Laddu, Ghee, MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Rejected",
  },
  {
    name: "Campaign 4",
    contacts: "+91 6345674567",
    prasadamName: "Laddu, Ghee, MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Pending",
  },
  {
    name: "Campaign 5",
    contacts: "+91 6345674567",
    prasadamName: "Laddu, Ghee, MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Delivered",
  },
];

const DonationCampaign = React.memo(() => {
  const navigate = useNavigate();
  const { actionType, tabType } = useParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const {
    donationData,
    donationActiveStatus,
    loading,
    Totalpage,
    donationUpdateStatus,
  } = useSelector((state) => state.donation);

  const columns = [
    {
      field: "name",
      headerName: "Campaign Name",
      renderCell: (params, row) => {
        return (
          <span>
            <p style={{ marginLeft: "0.5rem" }}>{params}</p>
          </span>
        );
      },
    },
    { field: "category", headerName: "Campaign Category" },
    { field: "minimumAmount", headerName: "Minimum Amount" },
    { field: "goalAmount", headerName: "Goal Amount" },
    { field: "startDate", headerName: "Start Date" },
    { field: "endDate", headerName: "End Date" },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params, row) => (
        <span className="action-row">
          <img
            src="/edit-icon.svg"
            alt="edit"
            className="edit-icon"
            onClick={() => onEdit(row)}
          />
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
            onChange={() => handleStatus(row.id, !row.active)}
            checked={row?.active}
          />
          Active
        </span>
      ),
    },
  ];

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  const onAdd = () => {
    navigate(`/donation-management/Donation/Add`);
  };

  const onEdit = (row) => {
    navigate(`/donation-management/Donation/Edit?donationId=${row.id}`);
  };

  const handleStatus = (id, status) => {
    dispatch(fetchDonationUpdateByStatusRequest(id));
  };

  useEffect(() => {
    let queryParams;
    if (searchText) {
      queryParams = `?page=0&size=10&searchTerm=${searchText}`;
    } else {
      queryParams = `?page=${page - 1}&size=10&searchTerm=${searchText}`;
    }

    dispatch(fetchDonationRequest(queryParams));
  }, [dispatch, page, searchText, donationActiveStatus, donationUpdateStatus]);

  return (
    <>
      {actionType === "Add" || actionType === "Edit" ? (
        <AddEditDonationCampaign actionType={actionType} />
      ) : (
        <Box>
          <div className="donation-header">
            <Box className="heading">Donation</Box>

            <AddButton name="Add" onClick={() => onAdd()} icon={<AddIcon />} />
          </div>
          <CustomLoader
            loading={loading}
            message="Please wait, Uploading Donations..."
          />

          <CommonTable
            columns={columns}
            data={donationData}
            page={page}
            count={Totalpage}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Box>
      )}
    </>
  );
});

export default DonationCampaign;
