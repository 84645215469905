import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Box, Typography, TextField, Grid2 } from "@mui/material";

import "../../styles/onboardingForm.css";

import "../../styles/ModalDatePicker.css";
import { AddButton } from "../../components/Addbutton";
import {
  uploadDescriptionRequest,
  toggleStatus,
} from "../../features/slices/onBordingSlice";
import { useDispatch, useSelector } from "react-redux";

const initialFormState = {
  templeHistory: "",
  mythology: "",
  deity: "",
  miracles: "",
};

const initialErrorState = {
  templeHistory: "",
  mythology: "",
  deity: "",
  miracles: "",
};

const BusinessDescription = forwardRef((props, ref) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const { onBoardingUploadStatus } = useSelector((state) => state.onbording);

  const dispatch = useDispatch();

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      dispatch(uploadDescriptionRequest(formData));
    },
  }));

  useEffect(() => {
    setFormData({
      ...formData,

      templeHistory: props?.OnBoardData?.templeHistory
        ? props?.OnBoardData?.templeHistory
        : "",
      mythology: props?.OnBoardData?.mythology
        ? props?.OnBoardData?.mythology
        : "",
      templeType: props?.OnBoardData?.templeType
        ? props?.OnBoardData?.templeType
        : "",
      deity: props?.OnBoardData?.deity ? props?.OnBoardData?.deity : "",
      miracles: props?.OnBoardData?.miracles
        ? props?.OnBoardData?.miracles
        : "",
    });
  }, [props?.OnBoardData]);

  return (
    <>
      <Box>
        <div className="heading">Temple Description</div>
        <Box style={{ marginTop: "1rem" }}>
          <Grid2 container spacing={0.5}>
            <Typography className="onboarding-form_Heading">
              History of the Temple
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              disabled={props?.previewStatus ? true : false}
              multiline
              rows={3}
              value={formData.templeHistory}
              onChange={handleChange("templeHistory")}
              placeholder="Please provide a detailed description outlining the history and origin of the temple. "
              style={{ marginBottom: "1rem", height: "50px !important" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.templeHistory && (
              <Typography color="error">{errors.templeHistory}</Typography>
            )}
          </Grid2>
          <Grid2 container spacing={0.5} mt={1}>
            <Typography className="onboarding-form_Heading">
              Mythology
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              disabled={props?.previewStatus ? true : false}
              multiline
              rows={3}
              value={formData.mythology}
              onChange={handleChange("mythology")}
              placeholder="Please provide any mythological stories or legends associated with the temple. "
              style={{ marginBottom: "1rem", height: "50px !important" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.mythology && (
              <Typography color="error">{errors.mythology}</Typography>
            )}
          </Grid2>
          <Grid2 container spacing={0.5} mt={1}>
            <Typography className="onboarding-form_Heading">
              Significance of the Deity
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              disabled={props?.previewStatus ? true : false}
              multiline
              rows={3}
              value={formData.deity}
              onChange={handleChange("deity")}
              placeholder="Please provide a detailed description of the significance of the temple's main deity. "
              style={{ marginBottom: "1rem", height: "50px !important" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.deity && (
              <Typography color="error">{errors.deity}</Typography>
            )}
          </Grid2>
          <Grid2 container spacing={0.5} mt={1}>
            <Typography className="onboarding-form_Heading">
              Miracles
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              disabled={props?.previewStatus ? true : false}
              multiline
              rows={3}
              value={formData.miracles}
              onChange={handleChange("miracles")}
              placeholder="Please elaborate on the narratives, folklore and tales highlighting any miracles that have occurred at the temple."
              style={{ marginBottom: "1rem", height: "50px !important" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.miracles && (
              <Typography color="error">{errors.miracles}</Typography>
            )}
          </Grid2>
        </Box>
      </Box>
    </>
  );
});

export default BusinessDescription;
