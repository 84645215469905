import React, { useState, memo, useEffect, useCallback } from "react";
import CommonTable from "../../components/CommonTable";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import AddUser from "./AddUser";
import ConfirmationModal from "../../components/Active-Inactive";
import DeleteModal from "../../components/DeleteModal";
import SearchInput from "../../components/CommonSearchBar";
import AntSwitch from "../../components/AntSwitch";
const AllUser = memo(() => {
  const navigate = useNavigate();
  const { id, actionType } = useParams();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  const columns = [   
    { field: "name", headerName: "Name" },
    { field: "contacts", headerName: "Contact" },
    { field: "emailID", headerName: "Email ID" },
    { field: "role", headerName: "Role" },
    { field: "reportingTo", headerName: "Reporting To" },
    {
      field: "status",
      headerName: "Access",
      renderCell: (params) => (
        <AntSwitch
          defaultChecked
          inputProps={{ "aria-label": "ant design" }}
          onChange={() => handleStatus()}
          // checked={row?.active}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => (
        <span style={{ display: "flex" }}>
          <img
            src="/edit-icon.svg"
            alt="edit"
            style={{ cursor: "pointer" }}
            onClick={() => onEdit()}
          />

          {/* <img
            src="/delete-icon.svg"
            alt="edit"
            style={{ cursor: "pointer" }}
            onClick={() => onDelete()}
          /> */}
        </span>
      ),
    },
  ];
  const tableRows = [
    {
      name: "Ritika K.",
      contacts: "+91-634 567 4567",
      emailID: "Ritika.K@Vector.com",
      role: "Admin",
      reportingTo: "Sunil Kumar",
      action: "Active",
      value: "Active",
    },
    {
      name: "Ritika K.",
      contacts: "+91-634 567 4567",
      emailID: "Ritika.K@Vector.com",
      role: "Sub Admin",      
      reportingTo: "Ritika",
      action: "Active",
      value: "Active",
    },
    {
      name: "Ritika K.",
      contacts: "+91-634 567 4567",
      emailID: "Ritika.K@Vector.com",
      role: "Sub Admin",   
      reportingTo: "Ritika",
      action: "DeActive",
      //   value: "Active",
    },
    {
      name: "Ritika K.",
      contacts: "+91-634 567 4567",
      emailID: "Ritika.K@Vector.com",
      role: "Sub Admin",      
      reportingTo: "Ritika",
      action: "DeActive",
      //   value: "Active",
    },
    {
      name: "Ritika K.",
      contacts: "+91-634 567 4567",
      emailID: "Ritika.K@Vector.com",
      role: "Sub Admin",    
      reportingTo: "Ritika",
      action: "DeActive",
      //   value: "Active",
    },
    {
      name: "Ritika K.",
      contacts: "+91-634 567 4567",
      emailID: "Ritika.K@Vector.com",
      role: "Sub Admin",    
      reportingTo: "Ritika",
      action: "DeActive",
      //   value: "Active",
    },
    {
      name: "Ritika K.",
      contacts: "+91-634 567 4567",
      emailID: "Ritika.K@Vector.com",
      role: "Sub Admin",     
      reportingTo: "Ritika",
      action: "DeActive",
      //   value: "Active",
    },
    {
      name: "Ritika K.",
      contacts: "+91-634 567 4567",
      emailID: "Ritika.K@Vector.com",
      role: "Sub Admin",     
      reportingTo: "Ritika",
      action: "DeActive",
      //   value: "Active",
    },
    {
      name: "Ritika K.",
      contacts: "+91-634 567 4567",
      emailID: "Ritika.K@Vector.com",
      role: "Sub Admin",      
      reportingTo: "Ritika",
      action: "DeActive",
      //   value: "Active",
    },
    {
      name: "Ritika K.",
      contacts: "+91-634 567 4567",
      emailID: "Ritika.K@Vector.com",
      role: "Sub Admin",      
      reportingTo: "Ritika",
      action: "DeActive",
      //   value: "Active",
    },
  ];

  const onEdit = (row) => {
    navigate(`/user-management/All/Edit`);
  };
  const [oepnDeleteModal, setOpenDeleteModal] = useState(false);
  const onDelete = () => {
    setOpenDeleteModal(true);
  };
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const handleStatus = (actionType) => {
    setOpenStatusModal(true);
    // setModalOpen(true);
  };

  const handleCloseModal = () => {
    setOpenStatusModal(false);
  };

  const handleConfirmAction = () => {
    setOpenStatusModal(false);
  };
  const handleDeleteConfirm = () => {
    setOpenDeleteModal(false);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      {actionType === "Edit" ? (
        <AddUser type="Edit" />
      ) : (
        <>
          <div style={{ margin: "2rem 0px 2rem 0px" }}>
            <SearchInput />
          </div>{" "}
          <CommonTable
            columns={columns}
            data={tableRows}
            page={page}
            count={count}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
          <ConfirmationModal
            open={openStatusModal}
            onClose={handleCloseModal}
            onConfirm={handleConfirmAction}
            actionType={"inactivate"}
          />
          <DeleteModal
            open={oepnDeleteModal}
            onClose={setOpenDeleteModal}
            onConfirm={handleDeleteConfirm}
            content="Are you sure you want to Delete this Item ?"
          />
        </>
      )}
    </div>
  );
});

export default AllUser;
