import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid2,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import "../../styles/onboardingForm.css";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminRequest,
  uploadAdminRequest,
} from "../../features/slices/onBordingSlice";
import "../../styles/ModalDatePicker.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialFormState = {
  fullName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  pinCode: "",
  phoneNumber: "",
  email: "",
  preferredContactMethod: [],
  aadhaarCard: [],
  panCard: [],
};

const initialErrorState = {
  templeName: "",
  fullName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  pinCode: "",
  phoneNumber: "",
  email: "",
  preferredContactMethod: [],
  aadhaarCard: "",
  panCard: "",
};

const AdminInfo = forwardRef((props, ref) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const { onBoardingUploadStatus, AdminData, imageDeleteStatus } = useSelector(
    (state) => state.onbording,
  );
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;

      if (!formData.fullName) {
        newErrors.fullName = "Admin Name is required";
        isValid = false;
      }
      if (!formData.address) {
        newErrors.address = "Address is required";
        isValid = false;
      }
      if (!formData.country) {
        newErrors.country = "Country Name is required";
        isValid = false;
      }
      if (!formData.city) {
        newErrors.city = "City is required";
        isValid = false;
      }
      if (!formData.state) {
        newErrors.state = "State is required";
        isValid = false;
      }
      if (!formData.pinCode) {
        newErrors.pinCode = "Pincode is required";
        isValid = false;
      }
      if (!formData.phoneNumber) {
        newErrors.phoneNumber = "Phone Number is required";
        isValid = false;
      }
      if (!formData.email) {
        newErrors.email = "Email is required";
        isValid = false;
      }
      if (formData.aadhaarCard.length === 0) {
        newErrors.selectedMethod = "Aadhaar Card is required";
        isValid = false;
      }
      if (formData.panCard.length === 0) {
        newErrors.selectedMethod = "Pan Card is required";
        isValid = false;
      }
      if (!selectedMethod.length === 0) {
        newErrors.selectedMethod = "Preferred Number is required";
        isValid = false;
      }

      setErrors(newErrors);

      if (isValid) {
        let formArr = new FormData();

        if (!formData?.panCard[0]?.etag && formData?.panCard.length > 0) {
          formArr.append("panCard", formData?.panCard[0]?.file);
        }
        if (
          !formData?.aadhaarCard[0]?.etag &&
          formData?.aadhaarCard.length > 0
        ) {
          formArr.append("aadhaarCard", formData?.aadhaarCard[0]?.file);
        }

        formArr.append("address", formData?.address);
        formArr.append("fullName", formData?.fullName);
        formArr.append("email", formData?.email);
        formArr.append("phoneNumber", formData?.phoneNumber);
        formArr.append("pinCode", formData?.pinCode);
        formArr.append("preferredContactMethod", selectedMethod.join(","));

        formArr.append("state", formData?.state);
        formArr.append("templeName", formData?.templeName);
        formArr.append("city", formData?.city);
        formArr.append("country", formData?.country);
        dispatch(uploadAdminRequest(formArr));
      }
    },
  }));

  const mapLocalImage = (images) => {
    if (images.length > 0) {
      let NewArr = images.map((item) => {
        return item?.file;
      });

      return NewArr;
    }
  };

  useEffect(() => {
    dispatch(fetchAdminRequest());
  }, [dispatch, imageDeleteStatus]);

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };
  useEffect(() => {
    setFormData({
      ...formData,
      fullName: AdminData?.fullName ? AdminData?.fullName : "",
      email: AdminData?.email ? AdminData?.email : "",
      phoneNumber: AdminData?.phoneNumber ? AdminData?.phoneNumber : "",

      city: AdminData?.city ? AdminData?.city : "",
      country: AdminData?.country ? AdminData?.country : "",
      address: AdminData?.address ? AdminData?.address : "",
      state: AdminData?.state ? AdminData?.state : "",
      templeName: AdminData?.templeName ? AdminData?.templeName : "",

      pinCode: AdminData?.pinCode ? AdminData?.pinCode : "",

      aadhaarCard: AdminData?.aadhaarCard
        ? mapImages([AdminData?.aadhaarCard])
        : [],
      panCard: AdminData?.panCard ? mapImages([AdminData?.panCard]) : [],
    });
    setSelectedMethod(
      AdminData?.preferredContactMethod
        ? AdminData?.preferredContactMethod
        : [],
    );
  }, [AdminData]);

  const MAX_IMAGE_SIZE = 20 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

  const handleUpload = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;
    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (selectedFiles?.length + (formData[field]?.length || 0) > MAX_FILES) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type === "video/mp4";
      const isValidImageFormat = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ].includes(file.type);
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (
        (uploadType === "image/*" && (!isImage || !isValidImageFormat)) ||
        (uploadType === "video/mp4" && !isVideo)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Invalid file type. Please upload ${
            uploadType === "image/*" ? "JPEG, JPG, or PNG images" : "MP4 videos"
          }.`,
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "20MB" : "50MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeUpload = (field, index, item) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (field, e, maxFiles, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    handleUpload(field, e, maxFiles, uploadType);
  };

  const [selectedMethod, setSelectedMethod] = useState([]);
  const methodList = [
    { label: "Phone Number", value: "PHONE_NUMBER" },
    { label: "Email", value: "EMAIL" },
  ];

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMethod(typeof value === "string" ? value.split(",") : value);
  };

  const handleRemoveSelectedItem1 = (index) => {
    const updatedDays = selectedMethod.filter((_, i) => i !== index);
    setSelectedMethod(updatedDays);
  };
  return (
    <>
      <div className="heading">Admin Information</div>
      <Box>
        <div className="onboarding-sub_heading">Basic Admin Information</div>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Full Name*
            </Typography>
            <TextField
              fullWidth
              disabled={props.previewStatus}
              variant="outlined"
              value={formData.fullName}
              onChange={handleChange("fullName")}
              placeholder="Enter fullName"
              sx={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.fullName && (
              <Typography color="error">{errors.fullName}</Typography>
            )}
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">Location Details</div>
        <Grid2 size={{ xs: 12, md: 12 }}>
          <Typography className="onboarding-form_Heading">Address*</Typography>
          <TextField
            fullWidth
            disabled={props.previewStatus}
            variant="outlined"
            value={formData.address}
            onChange={handleChange("address")}
            placeholder="Enter address"
            className="onboarding-custom-textfield"
            InputProps={{
              style: { color: "#8d8e9f" },
            }}
          />
          {errors.address && (
            <Typography color="error">{errors.address}</Typography>
          )}
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Enter you Country*
            </Typography>
            <TextField
              fullWidth
              disabled={props.previewStatus}
              variant="outlined"
              value={formData.country}
              onChange={handleChange("country")}
              placeholder="Enter your country"
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.country && (
              <Typography color="error">{errors.country}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Enter you State*
            </Typography>
            <TextField
              fullWidth
              disabled={props.previewStatus}
              variant="outlined"
              value={formData.state}
              onChange={handleChange("state")}
              placeholder="Enter your state"
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.state && (
              <Typography color="error">{errors.state}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1} mb={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Enter you City*
            </Typography>
            <TextField
              fullWidth
              disabled={props.previewStatus}
              variant="outlined"
              value={formData.city}
              onChange={handleChange("city")}
              placeholder="Enter your city"
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.city && (
              <Typography color="error">{errors.city}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Enter your Pincode*
            </Typography>
            <TextField
              fullWidth
              disabled={props.previewStatus}
              type="number"
              variant="outlined"
              value={formData.pinCode}
              onChange={handleChange("pinCode")}
              placeholder="Enter Pincode"
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.pinCode && (
              <Typography color="error">{errors.pinCode}</Typography>
            )}
          </Grid2>
        </Grid2>
        <div className="onboarding-sub_heading">Contact Information</div>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Phone number*
            </Typography>
            <TextField
              fullWidth
              disabled={props.previewStatus}
              type="number"
              variant="outlined"
              value={formData.phoneNumber}
              onChange={handleChange("phoneNumber")}
              placeholder="Enter Phone Number"
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.phoneNumber && (
              <Typography color="error">{errors.phoneNumber}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Email ID*
            </Typography>
            <TextField
              fullWidth
              disabled={props.previewStatus}
              variant="outlined"
              value={formData.email}
              onChange={handleChange("email")}
              placeholder="Enter Email ID"
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.email && (
              <Typography color="error">{errors.email}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1} mb={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <div>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Typography className="onboarding-form_Heading">
                  Preferred contact method*
                </Typography>
                <Select
                  labelId="days-label"
                  id="days-select"
                  multiple
                  disabled={props.previewStatus}
                  value={selectedMethod}
                  onChange={handleChange1}
                  input={<OutlinedInput />}
                  renderValue={(selected) =>
                    selected.length > 0 ? selected.join(", ") : "Select Method"
                  }
                  MenuProps={MenuProps}
                >
                  {methodList.map((name) => (
                    <MenuItem key={name.value} value={name.value}>
                      <Checkbox checked={selectedMethod.includes(name.value)} />
                      <ListItemText primary={name.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!props.previewStatus && (
                <div>
                  {selectedMethod.map((day, index) => (
                    <Chip
                      key={day}
                      label={day}
                      onDelete={() => handleRemoveSelectedItem1(index)}
                      sx={{
                        margin: 0.5,
                        "& .MuiSvgIcon-root": {
                          fill: "black !important",
                        },
                      }}
                    />
                  ))}
                </div>
              )}
              {errors.preferredContactMethod && (
                <Typography color="error">
                  {errors.preferredContactMethod}
                </Typography>
              )}
            </div>
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">Identity Verification</div>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }} spacing={2}>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Typography className="onboarding-form_Heading">
                AADHAR Card
              </Typography>
              <TextField
                fullWidth
                disabled
                variant="outlined"
                value={""}
                onChange={handleChange("templeContactNumber")}
                placeholder="Browse From DigiLocker"
                className="onboarding-custom-textfield"
                InputProps={{
                  style: { color: "#8d8e9f" },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>
              {" "}
              <Typography className="OR-Typography">OR</Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Typography className="onboarding-form_Heading">
                AADHAR Card*
              </Typography>
              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("aadhaarCard", e, 1, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.aadhaarCard?.length > 0 ? (
                  <Box className="preview-image-container">
                    {formData?.aadhaarCard?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("aadhaarCard", index, item)
                            }
                          />
                        )}

                        <img src={item.url} className="song-image" alt="efew" />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props.previewStatus && (
                  <div>
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-Adhar"
                      onChange={(e) =>
                        handleUpload("aadhaarCard", e, 1, "image/*")
                      }
                      multiple={true}
                    />
                    <label htmlFor="thumbnail-upload-Adhar">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>
                    <Typography className="upload-instructions ">
                      Max.1 Images, 20MB Each, JPEG/PNG
                    </Typography>
                  </div>
                )}
              </Box>
              {errors.aadhaarCard && (
                <Typography color="error">{errors.aadhaarCard}</Typography>
              )}
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }} spacing={2}>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Typography className="onboarding-form_Heading">
                PAN Card
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={""}
                disabled
                onChange={handleChange("templeContactNumber")}
                placeholder="Browse from Digi Locker"
                className="onboarding-custom-textfield"
                InputProps={{
                  style: { color: "#8d8e9f" },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>
              {" "}
              <Typography className="OR-Typography">OR</Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <Typography className="onboarding-form_Heading">
                PAN Card*
              </Typography>
              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("panCard", e, 1, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.panCard?.length > 0 ? (
                  <Box className="preview-image-container">
                    {formData?.panCard?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() => removeUpload("panCard", index, item)}
                          />
                        )}

                        <img
                          src={item.url}
                          className="song-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props.previewStatus && (
                  <div>
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-pan"
                      onChange={(e) => handleUpload("panCard", e, 1, "image/*")}
                      multiple={true}
                    />
                    <label htmlFor="thumbnail-upload-pan">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>
                    <Typography className="upload-instructions ">
                      Max.1 Images, 20MB Each, JPEG/PNG
                    </Typography>
                  </div>
                )}
              </Box>
              {errors.panCard && (
                <Typography color="error">{errors.panCard}</Typography>
              )}
            </Grid2>
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
});

export default AdminInfo;
