// function that will take number of bytes as input and will convert it into KB/MB/GB
export function formatFileSize(bytes, decimalPoint) {
    if(bytes === 0) return '0 Bytes';

    const decimalValue = decimalPoint || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const value = Math.floor(Math.log(bytes) / Math.log(1000));

    return parseFloat((bytes / Math.pow(1000, value)).toFixed(decimalValue)) + ' ' + sizes[value];
}
