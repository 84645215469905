import React, { useCallback, useState } from "react";
import "../../styles/dashboard.css";
import { Box, Grid2, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PledgeOthersCards from "../../components/cards/PledgeOthersCards";
import AlternativeLabelStepper from "../../components/LabelStepper";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/PledgeCards.css"; 
import { AddButton } from "../../components/Addbutton";

const NewBookingTracking = React.memo(() => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const orderId = query.get("OrderId");

  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "Booking Received",
    "Booking Accepted",
    "Out for Puja",
    "Completed",
  ];

  const navigate = useNavigate();

  return (
    <>     
      <div className="header-title">
        <ArrowBackIcon
          sx={{
            color: "var(--off-white)",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/order-management/Prasadam_Orders`)}
        />
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          Order Id: #25821
        </Box>
      </div>
      <div style={{height:'80vh' ,overflow:"auto", scrollbarWidth:'thin'}}>
        
        <Box style={{width:"98%"}}>
          <PledgeOthersCards
            content={
              <div className="details_container">
                <div className="status_text">
                  Rohan
                </div>
                <div className="details_content">Customer Details:</div>

                {[
                  { label: "User ID", value: "PR0001" },
                  { label: "User Name", value: "Rohan" },
                  { label: "Amount Paid", value: "684" },
                  { label: "Phone Number", value: "+91 9787868765" },
                  { label: "Mail ID", value: "rohan@gmail.com" },
                ].map((detail, index) => (
                  <div key={index} className="details_text">
                    {detail.label} :{" "}
                    <span className="details_text">{detail.value}</span>
                  </div>
                ))}

                <div className="details_content">Delivery Address:</div>

                {[
                  { label: "Neha", value: "9392463986" },
                  {
                    label: "",
                    value:
                      "A/9, Ashok Nagar, RC Marg, Vasi Naka, Chembur, Mumbai, Maharashtra, 400074",
                  },
                ].map((address, index) => (
                  <div key={index} className="details_text">
                    {address.label && `${address.label} | `}
                    <span className="details_text">{address.value}</span>
                  </div>
                ))}
              </div>
            }
            editButton={false}
            showView={false}
            borderColor="var(--primary-2)"
          />
        </Box>
        
        <Box sx={{mt:2, width:'98%'}}>
          <PledgeOthersCards
              content={
              <div className="details_container">
                  <div className="onboarding-sub_heading">Booking Details</div>
                  <Grid2 container spacing={2}>
                      <Grid2 size={{ xs: 6, md: 6 }}>   
                          <Box>
                              <div className="details_content" style={{color:'#000000'}}>Wedding Puja</div>
                              <Box>
                                  <div className="booking_span">
                                      Ceremony Name:<span>Wedding Ceremony</span>
                                  </div>
                                  <div className="booking_span">
                                      Date:<span> Oct 25, 2024</span>
                                  </div>
                                  <div className="booking_span">
                                      Time:<span>10:00 AM</span>
                                  </div>
                                  <div className="booking_span">
                                      Contact:<span>91-XXXXXXXXX</span>
                                  </div>
                                  <div className="booking_span">
                                      Location:<span>123 Temple Street, City Name</span>
                                  </div>
                              </Box>                               
                          </Box>                                               
                      </Grid2>
                      <Grid2 size={{ xs: 6, md: 6 }}>  
                          <Box>
                              <div className="details_content" style={{color:'#000000'}}>Puja Description:</div>
                              <Box>
                                  <div className="booking_span">
                                      Special Description:<span>The wedding ceremony will include traditional rituals.</span>
                                  </div>                                    
                              </Box> 
                          </Box> 
                          <Box>
                              <div className="details_content" style={{color:'#000000'}}>Special Instructions:</div>
                              <Box>
                                  <div className="booking_span">
                                      <li>Please arrive at least 30 minutes before the ceremony. </li>
                                      <li>Bring the necessary pooja items as discussed.</li>
                                  </div>                                    
                              </Box>  
                          </Box>    
                      </Grid2>
                  </Grid2>
                  <Grid2 container spacing={2}>
                      <Grid2 size={{ xs: 6, md: 6 }}>   
                          <Box>
                              <div className="details_content" style={{color:'#000000'}}>Samagri:</div>
                              <Box>
                                  <div className="booking_span">
                                      Kumkum- 100g
                                  </div>
                                  <div className="booking_span">
                                      Agarbathi-1
                                  </div>
                                  <div className="booking_span">
                                      Coconut-4
                                  </div>
                                  <div className="booking_span">
                                      Flower- 1Kg
                                  </div>
                                  <div className="booking_span">
                                      Panner-50ml
                                  </div>
                                  <div className="booking_span">
                                      Milk-1L
                                  </div>
                              </Box>                               
                          </Box>                                               
                      </Grid2>
                  </Grid2>
              </div>
              }
              editButton={false}
              showView={false}
              borderColor="var(--primary-2)"
          />
        </Box>    

        <Box sx={{margin:"1rem 0 !important"}}>
          <Typography className="form_Heading">Booking Status</Typography>
          <AlternativeLabelStepper activeStep={activeStep} steps={steps} />
          <Box className="cancel_and_submit">
            <AddButton name="Reject" background="#F5F5F5" color="var(--secondary)" borderColor="var(--secondary)" onClick={()=>{}} /> 
            <AddButton name="Accept"  onClick={()=>{}} /> 
          </Box>
        </Box>
      </div>
      
    </>
  );
});

export default NewBookingTracking;
