import React from "react";
export const MenuImage = (subActiveMenu, url, path) => {
  return (
    <div
      style={{
        WebkitMaskImage: `url(${url})`,
        WebkitMaskRepeat: "no-repeat",
        WebkitMaskSize: "100% 100%",

        width: 24,
        height: 24,
      }}
    ></div>
  );
};
