import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Popover } from "@mui/material";
import { useState } from "react";
import "../../styles/PledgeCards.css";
import TimerIcon from "@mui/icons-material/Timer";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import AntSwitch from "../AntSwitch";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#67687C",
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#0BFDC6",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));
const PledgeOthersCards = ({
  image,
  content,
  label,
  editButton,
  showView,
  onViewClick,
  showBill,
  billAmount,
  onEditClick,
  timerdetailes,
  showTimer,
  borderColor
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { actionType, tabType } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleStatus = () => {};

  return (
    <div className="Pleadge-card-container">
      {actionType === "View" && tabType !== "Past" ? (
        <div className="language-container">
          <span style={{ display: "flex", alignItems: "center" }}>
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
              onChange={() => handleStatus()}
            />
            <p className="paddingZero">English</p>
          </span>
        </div>
      ) : null}

      <Box className="stats-container-2">
        <Box className="image-content-container">
          {tabType !== "Past" ? (
            <Box className="content_container">
              <Box className="stats-label">{label ? label : ""}</Box>
              <Box className="stats-label-info-container">
                {content ? content : ""}
              </Box>
            </Box>
          ) : (
            <Box className="content_container">
              {content ? content : ""}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default PledgeOthersCards;
