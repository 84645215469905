import React, { useState } from "react";
import Tabs from "../../components/common/Tabs";
import { useLocation, useNavigate } from "react-router-dom";

const tabItems = [
  { label: "Recent Orders", value: "recent_orders" },
  { label: "Shipped", value: "shipped" },
  { label: "Delivered", value: "delivered" },
];

const DashboardTable = () => {
  const [selectedMenu, setSelectedMenu] = useState("recent_orders");

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
  };

  return (
    <div>
      <Tabs
        tabs={tabItems}
        tabStart={selectedMenu}
        onClick={handleMenuButtonClick}
        style={{ width: "50px !important" }}
      />
    </div>
  );
};

export default DashboardTable;
