import React from "react";
import { useNavigate } from "react-router-dom";
import { AddButton } from "../components/Addbutton";
import "../styles/login.css";

const Welcome = React.memo(() => {
  const navigate = useNavigate();

  return (
    <>
      <img src="/daiv-logo.svg" alt="logo" className="daiv_logo" />

      <div container className="login_container">
        <div className="login_background_image"></div>
        <div className="login_content">
          <div className="greeting_text_container">
            <div className="login_form">
              <div className="welcome_title" style={{ textAlign: "center" }}>
                Welcome to DAIV
              </div>
              <div className="welcome_desc">
                Namaste and Swagatam to DAIV. With deep gratitude, we welcome
                your presence on this sacred journey with us.
              </div>
              <div className="welcome_login_btn">
                <AddButton
                  name="Log In"
                  type="submit"
                  width="100%"
                  padding="1.3rem"
                  onClick={() => navigate("/login")}
                  background="var(--secondary)"
                  sx={{
                    fontFamily: "var(--font-secondary) !important",
                    fontWeight: 600,
                    fontSize: ".9rem",
                  }}
                />
              </div>
              <div className="welcome_footer">
                <div className="welcome_footer_1">Don’t have an account?</div>
                <div
                  className="welcome_footer_2"
                  onClick={() => navigate("/signup")}
                >
                  Sign Up
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Welcome;
