import React, { useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Divider,
  InputAdornment,
  Avatar,
  Chip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import "../../styles/Issue.css";
import UserCard from "./UserCard";
import CancelIcon from "@mui/icons-material/Cancel";
import StatusModal from "./StatusModal";
import PreviewImageModal from "./PreviewImageModal";


const IssueResolved = () => {
  const { tabType, actionType } = useParams();
  const navigate = useNavigate();

  const [fileOption, setFileOption] = React.useState("");
  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (message.trim()) {
      // onSend(message);
      setMessage("");
    }
  };
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove),
    );
  };
  const handleAttachFile = () => {
    document.getElementById("fileInput").click();
  };
  const handleChangeStatus = (event) => {
    setFileOption(event.target.value);
    setOpenStatusModal(true);
  };
  const currentDate = new Date();
  const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const closeStatusModal = () => {
    setOpenStatusModal(false);
  };
  const handleSubmitStatus = () => {
    setOpenStatusModal(false);
  };
  const [openImgeModal, setOpenImageModal] = useState(false);
  const closeImageModal = () => {
    setOpenImageModal(false);
  };

  const HandleOpenImageModal = () => {
    setOpenImageModal(true);
  };

  const Images = [
    { name: "First", url: "/Issue-chat-icon.svg" },
    { name: "First", url: "/Issue-chat-icon.svg" },
    { name: "First", url: "/Issue-chat-icon.svg" },
    { name: "First", url: "/school.jfif" },
  ];

  return (
    <div className="issue-container">
      <div className="Issue-header-container">
        <span className="header-title">
          <ArrowBackIcon
            sx={{
              color: "var(--off-white)",
              cursor: "pointer",
              mr: 1,
            }}
            onClick={() => navigate(`/issue-management/${tabType}`)}
          />
          <Box
            className={"heading"}
            sx={{
              textWrap: "nowrap",
              paddingY: "10px",
            }}
          >
            Issue No. 443344
          </Box>
        </span>
      </div>
      <Box className="title-container">
        <div className="chat-title">Issue Type: Order Issue</div>
        <div className="chat-created-date">Date: 24/10/2024</div>
      </Box>
      <UserCard
        profilePic="/school.jfif"
        date="24/10/2024, 08:50 AM"
        content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
      />
      <UserCard
        profilePic="/school.jfif"
        date="24/10/2024, 08:50 AM"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus..."
      />
      <UserCard
        profilePic="/school.jfif"
        date="24/10/2024, 08:50 AM"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus..."
      />
      <Box className="chat_input_box">
        <Avatar
          src="https://example.com/avatar.jpg"
          alt="User Avatar"
          sx={{ marginRight: 2 }}
        />

        <TextField
          variant="outlined"
          placeholder="Start writing here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          minRows={2.5}
          className="customTextField"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*,application/pdf,audio/*,video/*"
                  style={{ display: "none" }}
                  multiple
                  onChange={handleFileChange}
                />
                <IconButton
                  onClick={handleAttachFile}
                  className="icon_button_inputbox"
                  size="small"
                >
                  <AttachFileIcon />
                </IconButton>
                <IconButton
                  onClick={handleSend}
                  className="icon_button_inputbox"
                  size="small"
                >
                  <SendIcon />
                </IconButton>
                <Box className="inside_chat_box">
                  {selectedFiles.map((file, index) => (
                    <Chip
                      className="upload_file_chip"
                      key={index}
                      label={file.name}
                      onDelete={() => handleRemoveFile(file)} // Remove file on delete icon click
                      deleteIcon={<CancelIcon />}
                    />
                  ))}
                </Box>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </div>
  );
};

export default IssueResolved;
