import { call, put, takeLatest } from "redux-saga/effects";
import { saveAs } from "file-saver";
import {
  getPrasadOrder,
  getPrasadOrderSuccess,
  getPrasadOrderFailure,
  getPrasadOrderById,
  getPrasadOrderByIdSuccess,
  getPrasadOrderByIdFailure,
  updatePrasadOrderById,
  updatePrasadOrderByIdSuccess,
  updatePrasadOrderByIdFailure,
  downloadPrasadOrder,
  downloadPrasadOrderSuccess,
  downloadPrasadOrderFailure,
  getSevaOrder,
  getSevaOrderSuccess,
  getSevaOrderFailure,
  getSevaOrderById,
  getSevaOrderByIdSuccess,
  getSevaOrderByIdFailure,
  updateSevaOrderById,
  updateSevaOrderByIdSuccess,
  updateSevaOrderByIdFailure,
  downloadSevaOrder,
  downloadSevaOrderSuccess,
  downloadSevaOrderFailure,
} from "../slices/orderSlice";
import { getRequest, putRequest, downLoadRequest } from "../../api/apiActions";

import {
  getAllPrasadOrderUls,
  getPrasadOrderByIdUrls,
  updatePrasadOrderUrls,
  dwonloadPrasadOrderUrls,
  getAllSevaOrderUls,
  getSevaOrderByIdUrls,
  updateSevaOrderUrls,
  dwonloadSevaOrderUrls,
} from "../../api/apiUrls";

const getCall = (payload) => getRequest(getAllPrasadOrderUls + payload);

const updateCall = (payload) =>
  putRequest(
    `${updatePrasadOrderUrls}${payload.orderId}/update-status?status=${payload.status}`,
  );

const downloadCall = (payload) => downLoadRequest(dwonloadPrasadOrderUrls);

const getByIDCall = (payload) =>
  getRequest(getPrasadOrderByIdUrls + `${payload}`);

const getCallSeva = (payload) => getRequest(getAllSevaOrderUls + payload);

const updateCallSeva = (payload) =>
  putRequest(
    `${updateSevaOrderUrls}${payload.orderId}/update-status?status=${payload.status}`,
  );

const downloadCallSeva = () => downLoadRequest(dwonloadSevaOrderUrls);

const getByIDCallSeva = (payload) =>
  getRequest(getSevaOrderByIdUrls + `${payload}`);

function* getAllPrasadOrderCall(action) {
  try {
    const response = yield call(getCall, action.payload);

    if (response.status === 200) {
      let res = response?.data?.prasadOrder;
      let page = response?.data?.totalPages;
      yield put(getPrasadOrderSuccess({ res, page }));
    } else {
      yield put(getPrasadOrderFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(getPrasadOrderFailure(error?.response?.data));
  }
}

function* getOrderPrasadByIdCall(action) {
  try {
    const response = yield call(getByIDCall, action.payload);
    if (response.status === 200) {
      yield put(getPrasadOrderByIdSuccess(response?.data));
    } else {
      yield put(getPrasadOrderByIdFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(getPrasadOrderByIdFailure(error?.response?.data));
  }
}
function* updatePrasadOrderCall(action) {
  try {
    const response = yield call(updateCall, action.payload);
    if (response.status === 200) {
      yield put(updatePrasadOrderByIdSuccess(response?.data));
    } else {
      yield put(updatePrasadOrderByIdFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(updatePrasadOrderByIdFailure(error?.response?.data));
  }
}
function* downloadPrasadOrderCall(action) {
  try {
    const response = yield call(downloadCall, action.payload);
    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      saveAs(blob, "PrasadamOrder.xlsx");
      yield put(downloadPrasadOrderSuccess(response));
    } else {
      yield put(downloadPrasadOrderFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(downloadPrasadOrderFailure(error?.response?.data));
  }
}
function* getAllSevaOrderCall(action) {
  try {
    const response = yield call(getCallSeva, action.payload);

    if (response.status === 200) {
      let res = response?.data?.sevaOrder;
      let page = response?.data?.totalPages;

      yield put(getSevaOrderSuccess({ res, page }));
    } else {
      yield put(getSevaOrderFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(getSevaOrderFailure(error?.response?.data));
  }
}

function* getOrderSevaByIdCall(action) {
  try {
    const response = yield call(getByIDCallSeva, action.payload);
    if (response.status === 200) {
      yield put(getSevaOrderByIdSuccess(response?.data));
    } else {
      yield put(getSevaOrderByIdFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(getSevaOrderByIdFailure(error?.response?.data));
  }
}
function* updateSevaOrderCall(action) {
  try {
    const response = yield call(updateCallSeva, action.payload);
    if (response.status === 200) {
      yield put(updateSevaOrderByIdSuccess(response?.data));
    } else {
      yield put(updateSevaOrderByIdFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(updateSevaOrderByIdFailure(error?.response?.data));
  }
}
function* downloadSevaOrderCall(action) {
  try {
    const response = yield call(downloadCallSeva, action.payload);
    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      saveAs(blob, "SevaOrder.xlsx");
      yield put(downloadSevaOrderSuccess(response?.data));
    } else {
      yield put(downloadSevaOrderFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(downloadSevaOrderFailure(error?.response?.data));
  }
}

export function* orderSaga() {
  yield takeLatest(getPrasadOrder.type, getAllPrasadOrderCall);
  yield takeLatest(getPrasadOrderById.type, getOrderPrasadByIdCall);
  yield takeLatest(updatePrasadOrderById.type, updatePrasadOrderCall);
  yield takeLatest(downloadPrasadOrder.type, downloadPrasadOrderCall);
  yield takeLatest(getSevaOrder.type, getAllSevaOrderCall);
  yield takeLatest(getSevaOrderById.type, getOrderSevaByIdCall);
  yield takeLatest(updateSevaOrderById.type, updateSevaOrderCall);
  yield takeLatest(downloadSevaOrder.type, downloadSevaOrderCall);
}
