import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Close } from "@mui/icons-material";
import { AddButton } from "../../../components/Addbutton";
import ModalDatePicker from "../../../components/ModalDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDonationUploadRequest,
  toggleStatus,
  fetchDonationGetByIdRequest,
  fetchDonationUpdateRequest,
} from "../../../features/slices/donationSlice";
import { CustomLoader } from "../../../components/CustomLoader";

const AddEditDonationCampaign = React.memo(({ actionType }) => {
  const initialFormState = {
    donationName: "",
    donationCategory: "",
    minimumAmount: "",
    goal: "",
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    donationDescription: "",
    donationImage: [],
    donationCertificate: [],
  };

  const initialErrorState = {
    donationName: "",
    donationCategory: "",
    minimumAmount: "",
    goal: "",
    startDate: "",
    endDate: "",
    donationDescription: "",
    donationImage: [],
    donationCertificate: [],
  };

  const donationCategoryList = [
    { label: "Seva Donation", value: "SEVA_DONATION" },
    { label: "Education", value: "EDUCATION" },
    { label: "Annadhanam", value: "ANNADHANAM" },
    { label: "Others", value: "OTHERS" },
  ];
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const donationId = query.get("donationId");
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [donationData, setDonationData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();
  const {
    donationUploadStatus,
    donationUpdateStatus,
    loading,
    donationDataById,
  } = useSelector((state) => state.donation);

  const handleChange = (field) => (event, newValue) => {
    const value = event.target.value;

    setDonationData((prev) => ({
      ...prev,
      [field]: newValue ? newValue?.value : value,
    }));

    if (field === "availableStock" || field === "bookingAvailability") {
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const MAX_IMAGE_SIZE = 20 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

  const handleImageUpload = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;
    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (
      selectedFiles?.length + (donationData[field]?.length || 0) >
      MAX_FILES
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type === "video/mp4";
      const isValidImageFormat = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ].includes(file.type);
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (
        (uploadType === "image/*" && (!isImage || !isValidImageFormat)) ||
        (uploadType === "video/mp4" && !isVideo)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Invalid file type. Please upload ${
            uploadType === "image/*" ? "JPEG, JPG, or PNG images" : "MP4 videos"
          }.`,
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "20MB" : "50MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setDonationData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeUpload = (field, index) => {
    setDonationData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  const OnDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = useCallback(() => {
    const newErrors = { ...initialErrorState };
    let isValid = true;

    if (!donationData.donationName) {
      newErrors.donationName = "Donation Name is required";
      isValid = false;
    }

    if (!donationData.donationCategory) {
      newErrors.donationCategory = "Donation Category is required";
      isValid = false;
    }

    if (!donationData.donationDescription) {
      newErrors.donationDescription = "Description is required";
      isValid = false;
    }

    if (donationData.donationImage.length === 0) {
      newErrors.donationImage = "At least one image is required";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      let formData = new FormData();

      if (!donationData?.donationImage[0]?.etag) {
        formData.append("file", donationData?.donationImage[0]?.file);
      }
      formData.append("name", donationData?.donationName);
      formData.append("category", donationData?.donationCategory);

      formData.append("minimumAmount", donationData?.minimumAmount);

      if (donationData?.goal) {
        formData.append("goalAmount", donationData?.goal);
      }

      if (donationData?.startDate) {
        formData.append("startDate", donationData?.startDate);
      }

      if (donationData?.endDate) {
        formData.append("endDate", donationData?.endDate);
      }

      formData.append("description", donationData?.donationDescription);
      if (donationData?.donationCertificate.length > 0) {
        formData.append(
          "cerficiatePhoto",
          donationData?.donationCertificate[0].file,
        );
      }
      if (donationId) {
        dispatch(fetchDonationUpdateRequest({ formData, donationId }));
      } else {
        dispatch(fetchDonationUploadRequest(formData));
      }
    }
  }, [dispatch, donationData]);

  useEffect(() => {
    if (donationUploadStatus || donationUpdateStatus) {
      navigate(`/donation-management/Donation`);
      dispatch(toggleStatus());
    }
  }, [donationUploadStatus, donationUpdateStatus]);

  useEffect(() => {
    if (donationId) {
      dispatch(fetchDonationGetByIdRequest(donationId));
    }
  }, [donationId]);
  const [isDragOver, setIsDragOver] = useState(false);
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (field, e, maxFiles, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);
    handleImageUpload(field, e, maxFiles, uploadType);
  };

  useEffect(() => {
    if (donationDataById && actionType === "Edit") {
      setDonationData({
        ...donationData,
        donationName: donationDataById?.name,
        donationCategory: donationDataById?.category,
        minimumAmount: donationDataById?.minimumAmount,
        goal: donationDataById?.goalAmount,
        startDate: donationDataById?.startDate,
        endDate: donationDataById?.endDate,
        donationDescription: donationDataById?.description,

        donationImage: donationDataById?.thumbnailPhoto
          ? mapImages([donationDataById?.thumbnailPhoto])
          : [],
        donationCertificate: donationDataById?.donationCertificate
          ? mapImages([donationDataById.donationCertificate])
          : [],
      });
    }
  }, [donationDataById]);
  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };

  return (
    <>
      <ModalDatePicker
        openDatePicker={openDatePicker}
        OnDateChange={OnDateChange}
        selectedDate={selectedDate}
        onClose={() => setOpenDatePicker(false)}
        datePickerTitle="Select Date to Upload"
      />
      <CustomLoader
        loading={loading}
        message="Please wait, Uploading Donations..."
      />

      <span className="back-arrow">
        <ArrowBackIcon
          className="arrow_icon"
          onClick={() => navigate(`/donation-management/Donation`)}
        />

        <Box className={"heading"}>
          {actionType === "Add" ? "Add Donation" : "Edit Donation"}
        </Box>
      </span>

      <>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography variant="subtitle1" className="donation-form-title">
              Donation Name*
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              value={donationData?.donationName}
              onChange={handleChange("donationName")}
              className="custom-textfield"
              placeholder="Enter donation name"
            />

            {errors?.donationName && (
              <Typography color="error">{errors?.donationName}</Typography>
            )}
          </Grid2>

          <Grid2 size={{ xs: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="donation-form-title">
              Donation Category*
            </Typography>

            <FormControl fullWidth variant="outlined" className="custom-select">
              <Autocomplete
                options={donationCategoryList}
                getOptionLabel={(option) => option.label}
                onChange={handleChange("donationCategory")}
                value={
                  donationCategoryList.find(
                    (option) => option.value === donationData.donationCategory,
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Donation Category"
                    variant="outlined"
                  />
                )}
              />

              {errors.donationCategory && (
                <Typography color="error">{errors.donationCategory}</Typography>
              )}
            </FormControl>
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography variant="subtitle1" className="donation-form-title">
              Minimum Amount
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              value={donationData?.minimumAmount}
              onChange={handleChange("minimumAmount")}
              className="custom-textfield"
              placeholder="Enter Minimum Amount"
            />
          </Grid2>

          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography variant="subtitle1" className="donation-form-title">
              Goal
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              value={donationData?.goal}
              onChange={handleChange("goal")}
              className="custom-textfield"
              placeholder="Enter Goal"
            />
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="donation-form-title">
              Start Date
            </Typography>

            <div className="muiNormal-date-picker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    "& .MuiButtonBase-root": { color: "#67687c" },
                    width: "100%",
                  }}
                  value={dayjs(donationData?.startDate)}
                  onChange={(newValue) => {
                    setDonationData({
                      ...donationData,
                      startDate: dayjs(newValue).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </LocalizationProvider>
            </div>
          </Grid2>

          <Grid2 size={{ xs: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="donation-form-title">
              End Date
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  "& .MuiButtonBase-root": { color: "#67687c" },
                  width: "100%",
                }}
                value={dayjs(donationData?.endDate)}
                onChange={(newValue) => {
                  setDonationData({
                    ...donationData,
                    endDate: dayjs(newValue).format("YYYY-MM-DD"),
                  });
                }}
                defaultValue={dayjs("2022-04-17")}
              />
            </LocalizationProvider>
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="donation-form-title">
              Donation Description*
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={donationData.donationDescription}
              onChange={handleChange("donationDescription")}
              placeholder="Enter Description"
              className={["custom-textfield", "input-fields"]}
            />

            {errors?.donationDescription && (
              <Typography color="error">
                {errors?.donationDescription}
              </Typography>
            )}
          </Grid2>

          <Grid2 size={{ xs: 6, md: 6 }}>
            <Box>
              <Typography variant="subtitle1" className="donation-form-title">
                Donation Thumbnail Photo*
              </Typography>

              <Box
                className="file-upload-box"
                onDrop={(e) => handleDrop("donationImage", e, 1, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {donationData?.donationImage?.length > 0 ? (
                  <Box className="file-preview-image-container">
                    {donationData?.donationImage?.map((item, index) => (
                      <Box className="prasadam-image-container">
                        <Close
                          className="song-image-close-icon"
                          onClick={() => removeUpload("donationImage", index)}
                        />

                        <img
                          src={item.url}
                          className="prasadam-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}

                <Typography className="file-upload-instructions">
                  <UploadFileIcon className="upload-file-icon" />
                  Drop Your File Here
                </Typography>

                <Typography className="file-upload-instructions">OR</Typography>
                <input
                  ref={fileInputRef}
                  accept="image/*"
                  name="donationImage"
                  type="file"
                  className="prasadam-file-input"
                  id="prasadam-package-upload-package"
                  onChange={(e) =>
                    handleImageUpload("donationImage", e, 1, "image/*")
                  }
                />
                <label htmlFor="prasadam-package-upload-package">
                  <Button
                    variant="outlined"
                    component="span"
                    className="browse-button"
                  >
                    Browse file
                  </Button>
                </label>

                <Typography className="file-upload-instructions">
                  Max: 20MB, JPG, PNG
                </Typography>
              </Box>
            </Box>

            {errors?.donationImage && (
              <Typography color="error">{errors?.donationImage}</Typography>
            )}
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">User Benefits</div>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Box>
              <Typography variant="subtitle1" className="donation-form-title">
                Donation Certificate (if available)
              </Typography>

              <Box
                className="file-upload-box"
                onDrop={(e) =>
                  handleDrop("donationCertificate", e, 1, "image/*")
                }
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {donationData?.donationCertificate?.length > 0 ? (
                  <Box className="file-preview-image-container">
                    {donationData?.donationCertificate?.map((item, index) => (
                      <Box className="prasadam-image-container">
                        <Close
                          className="song-image-close-icon"
                          onClick={() =>
                            removeUpload("donationCertificate", index, item)
                          }
                        />

                        <img
                          src={item.url}
                          className="prasadam-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}

                <Typography className="file-upload-instructions">
                  <UploadFileIcon className="upload-file-icon" />
                  Drop Your File Here
                </Typography>

                <Typography className="file-upload-instructions">OR</Typography>
                <input
                  ref={fileInputRef}
                  accept="image/*"
                  name="thumbnailPhoto"
                  type="file"
                  className="prasadam-file-input"
                  id="prasadam-package-upload-package-donationCertificate"
                  onChange={(e) =>
                    handleImageUpload("donationCertificate", e, 1, "image/*")
                  }
                />
                <label htmlFor="prasadam-package-upload-package-donationCertificate">
                  <Button
                    variant="outlined"
                    component="span"
                    className="browse-button"
                  >
                    Browse file
                  </Button>
                </label>

                <Typography className="file-upload-instructions">
                  Max: 20MB, JPG, PNG
                </Typography>
              </Box>
            </Box>
            {errors?.donationCertificate && (
              <Typography color="error">
                {errors?.donationCertificate}
              </Typography>
            )}
          </Grid2>
        </Grid2>

        <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
          <AddButton
            name={actionType === "Add" ? "Add" : "Update"}
            onClick={handleSubmit}
            background="#A9392D"
            color="#FFFFFF"
            width="auto"
            sx={{
              marginRight: "0.5rem",
              // border: "1px solid #ACAEC3",
            }}
          />
        </Box>
      </>
    </>
  );
});

export default AddEditDonationCampaign;
