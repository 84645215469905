import { call, put, takeEvery } from "redux-saga/effects";

import {
    fetchAdminDetails,
    fetchAdminDetailsSuccess,
    fetchAdminDetailsFailure
} from "../slices/adminDetailsSlice";

import {
    getRequest
} from "../../api/apiActions";

import {
    getAdminDetailsUrl
} from "../../api/apiUrls";

const fetchAdmin = () => {
    return getRequest(getAdminDetailsUrl);
}

function* getAdminDetails(action) {
    try {
        const result = yield call(fetchAdmin);

        if(result.status === 200) {
            yield put(fetchAdminDetailsSuccess(result?.data));
        } else {
            yield put(fetchAdminDetailsFailure());
        }
    } catch (error) {
        yield put(fetchAdminDetailsFailure(error));
    }
}

export function* adminDetailsSaga() {
    yield takeEvery(fetchAdminDetails.type, getAdminDetails);
}

export default adminDetailsSaga;
