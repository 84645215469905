import { createSlice } from "@reduxjs/toolkit";

const purohitOnboardingSlice = createSlice({
  name: "PurohitOnBoarding",
  initialState: {
    loading: false,
    purohitData: [],
    docData: [],
    lineageData: [],
    serviceData: [],
    pujaOfferingData: [],
    timeAvailabiltyData: [],
    error: "",
    onBoardingStatusDtata: [],
    Purohit2GetAllStatus: false,
    Purohit2Data: [],
    onBoardingUploadStatus: false,
    onBoardingActiveStatus: false,
    purohitGetAllStatus: false,
    docGetAllStatus: false,
    lineageGetAllStatus: false,
    serviceGetAllStatus: false,
    pujaOfferingGetAllStatus: false,
    timeAvailabiltyGetAllStatus: false,
  },
  reducers: {
    fetchPurohitRequest: (state) => {
      state.loading = true;
      state.purohitGetAllStatus = false;
      state.purohitData = [];
      state.error = "";
    },
    fetchPurohitSuccess: (state, action) => {
      state.loading = false;
      state.purohitGetAllStatus = true;
      state.purohitData = action.payload;
      state.error = "";
    },
    fetchPurohitFailure: (state, action) => {
      state.loading = false;
      state.purohitGetAllStatus = false;
      state.purohitData = [];
      state.error = action.payload;
    },
    fetchPurohit2Request: (state) => {
      state.loading = true;
      state.Purohit2GetAllStatus = false;
      state.Purohit2Data = [];
      state.error = "";
    },
    fetchPurohit2Success: (state, action) => {
      state.loading = false;
      state.Purohit2GetAllStatus = true;
      state.Purohit2Data = action.payload;
      state.error = "";
    },
    fetchPurohit2Failure: (state, action) => {
      state.loading = false;
      state.Purohit2GetAllStatus = false;
      state.Purohit2Data = [];
      state.error = action.payload;
    },
    fetchtimeAvailabiltyRequest: (state) => {
      state.loading = true;
      state.timeAvailabiltyGetAllStatus = false;
      state.timeAvailabiltyData = [];
      state.error = "";
    },
    fetchtimeAvailabiltySuccess: (state, action) => {
      state.loading = false;
      state.timeAvailabiltyGetAllStatus = true;
      state.timeAvailabiltyData = action.payload;
      state.error = "";
    },
    fetchtimeAvailabiltyFailure: (state, action) => {
      state.loading = false;
      state.timeAvailabiltyGetAllStatus = false;
      state.timeAvailabiltyData = [];
      state.error = action.payload;
    },
    fetchdocRequest: (state) => {
      state.loading = true;
      state.docGetAllStatus = false;
      state.docData = [];
      state.error = "";
    },
    fetchdocSuccess: (state, action) => {
      state.loading = false;
      state.docGetAllStatus = true;
      state.docData = action.payload;
      state.error = "";
    },
    fetchdocFailure: (state, action) => {
      state.loading = false;
      state.docGetAllStatus = false;
      state.docData = [];
      state.error = action.payload;
    },
    fetchserviceRequest: (state) => {
      state.loading = true;
      state.serviceGetAllStatus = false;
      state.serviceData = [];
      state.error = "";
    },
    fetchserviceSuccess: (state, action) => {
      state.loading = false;
      state.serviceGetAllStatus = true;
      state.serviceData = action.payload;
      state.error = "";
    },
    fetchserviceFailure: (state, action) => {
      state.loading = false;
      state.serviceGetAllStatus = false;
      state.serviceData = [];
      state.error = action.payload;
    },
    fetchpujaOfferingRequest: (state) => {
      state.loading = true;
      state.pujaOfferingGetAllStatus = false;
      state.pujaOfferingData = [];
      state.error = "";
    },
    fetchpujaOfferingSuccess: (state, action) => {
      state.loading = false;
      state.pujaOfferingGetAllStatus = true;
      state.pujaOfferingData = action.payload;
      state.error = "";
    },
    fetchpujaOfferingFailure: (state, action) => {
      state.loading = false;
      state.pujaOfferingGetAllStatus = false;
      state.pujaOfferingData = [];
      state.error = action.payload;
    },
    fetchlineageRequest: (state) => {
      state.loading = true;
      state.lineageGetAllStatus = false;
      state.lineageData = [];
      state.error = "";
    },
    fetchlineageSuccess: (state, action) => {
      state.loading = false;
      state.lineageGetAllStatus = true;
      state.lineageData = action.payload;
      state.error = "";
    },
    fetchlineageFailure: (state, action) => {
      state.loading = false;
      state.lineageGetAllStatus = false;
      state.lineageData = [];
      state.error = action.payload;
    },
    fetchOnBoardingStatusRequest: (state) => {
      state.loading = true;
      state.onBoardingActiveStatus = false;
      state.onBoardingStatusDtata = [];
      state.error = "";
    },
    fetchOnBoardingStatusSuccess: (state, action) => {
      state.loading = false;
      state.onBoardingActiveStatus = true;
      state.onBoardingStatusDtata = action.payload;
      state.error = "";
    },
    fetchOnBoardingStatusFailure: (state, action) => {
      state.loading = false;
      state.onBoardingActiveStatus = false;
      state.onBoardingStatusDtata = [];
      state.error = action.payload;
    },
    uploadlineageRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadlineageSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadlineageFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },

    uploadPurohitRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadPurohitSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadPurohitFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadtimeAvailabiltyRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadtimeAvailabiltySuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadtimeAvailabiltyFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadserviceRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadserviceSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadserviceFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploaddocRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploaddocSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploaddocFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadpujaOfferingRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadpujaOfferingSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadpujaOfferingFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    toggleStatus: (state) => {
      state.onBoardingUploadStatus = false;

      state.onBoardingActiveStatus = false;
      state.onBoardingGetAllStatus = false;
    },
  },
});

export const {
  fetchOnBoardingStatusRequest,
  fetchOnBoardingStatusFailure,
  fetchOnBoardingStatusSuccess,
  fetchPurohitRequest,
  fetchPurohitSuccess,
  fetchPurohitFailure,
  fetchPurohit2Request,
  fetchPurohit2Success,
  fetchPurohit2Failure,
  uploadPurohitRequest,
  uploadPurohitSuccess,
  uploadPurohitFailure,
  fetchdocRequest,
  fetchdocSuccess,
  fetchdocFailure,
  uploaddocRequest,
  uploaddocSuccess,
  uploaddocFailure,
  fetchlineageRequest,
  fetchlineageSuccess,
  fetchlineageFailure,
  uploadlineageRequest,
  uploadlineageSuccess,
  uploadlineageFailure,
  fetchserviceRequest,
  fetchserviceSuccess,
  fetchserviceFailure,
  uploadserviceRequest,
  uploadserviceSuccess,
  uploadserviceFailure,
  fetchtimeAvailabiltyRequest,
  fetchtimeAvailabiltySuccess,
  fetchtimeAvailabiltyFailure,
  uploadpujaOfferingRequest,
  uploadpujaOfferingSuccess,
  uploadpujaOfferingFailure,
  fetchpujaOfferingRequest,
  fetchpujaOfferingSuccess,
  fetchpujaOfferingFailure,
  uploadtimeAvailabiltyRequest,
  uploadtimeAvailabiltySuccess,
  uploadtimeAvailabiltyFailure,
  toggleStatus,
} = purohitOnboardingSlice.actions;

export default purohitOnboardingSlice.reducer;
