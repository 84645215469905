import React, { useCallback, useState } from "react";
import "../../styles/dashboard.css";
import { Box, Grid2, Modal } from "@mui/material";
import CommonTable from "../../components/CommonTable";
import { useNavigate } from "react-router-dom";
import PledgeOthersCards from "../../components/cards/PledgeOthersCards";
import CloseIcon from "@mui/icons-material/Close";
import { AddButton } from "../../components/Addbutton";

const tableRows = [
  {
    bookingID: "25821",
    customerName: "Ritika K.",
    pujaName: "Ceremony 01",
    contacts: "+91 6345674567",
    location: "Location A",
    date: "19/10/2024",
    time: "10:00 AM",
  },
  {
    bookingID: "25821",
    customerName: "Ritika K.",
    pujaName: "Ceremony 01",
    contacts: "+91 6345674567",
    location: "Location A",
    date: "19/10/2024",
    time: "10:00 AM",
  },
  {
    bookingID: "25821",
    customerName: "Ritika K.",
    pujaName: "Ceremony 01",
    contacts: "+91 6345674567",
    location: "Location A",
    date: "19/10/2024",
    time: "10:00 AM",
  },
  {
    bookingID: "25821",
    customerName: "Ritika K.",
    pujaName: "Ceremony 01",
    contacts: "+91 6345674567",
    location: "Location A",
    date: "19/10/2024",
    time: "10:00 AM",
  },
  {
    bookingID: "25821",
    customerName: "Ritika K.",
    pujaName: "Ceremony 01",
    contacts: "+91 6345674567",
    location: "Location A",
    date: "19/10/2024",
    time: "10:00 AM",
  },
];

const TodaySchedule = React.memo(() => {
  const navigate = useNavigate();
  const columns = [
    { field: "bookingID", headerName: "Booking ID" },
    { field: "customerName", headerName: "Customer Name" },
    { field: "pujaName", headerName: "Puja Name" },
    { field: "contacts", headerName: "Phone No." },
    { field: "location", headerName: "Location" },
    { field: "date", headerName: "Order Date" },
    { field: "time", headerName: "Time" },
    {
      field: "action",
      headerName: "Actions",
      renderCell: (params, row) => {
        return (
          <AddButton               
            name="View"  
            background="#FFFFFF"
            borderColor="#FFFFFF"
            onClick={() => handleOpen(row)}
            padding="0"
            color="#FF8C34"   
          />  
        );
      },
    },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  return (
    <Box
      className={"heading"}
      sx={{
        textWrap: "nowrap",
        paddingY: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0rem 0px 1rem 0px",
        }}
      >
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          Today’s Schedule
        </Box>
      </div>

      <CommonTable
        columns={columns}
        data={tableRows}
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className='dashboardPopupBox' >
          {selectedRow && (
            <>
              <CloseIcon
                className="Booking-dialog-cancel-icon "
                onClick={()=>{}}
              />
              <Box
                component="img"
                src="https://via.placeholder.com/600x200" 
                alt="Preview"
                sx={{
                  width: "100%",
                  height: "40%",
                  objectFit: "cover",
                  borderRadius: "8px",
                  marginBottom: "16px",
                }}
              />
              <Box>
                <div className="onboarding-sub_heading">Service Details for Booking ID #1001</div>
                <PledgeOthersCards
                  content={
                    <div className="details_container">
                      <div className="onboarding-sub_heading">
                        Booking Details
                      </div>
                      <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6, md: 6 }}>
                          <Box>
                            <div
                              className="details_content"
                              style={{ color: "#000000" }}
                            >
                              Wedding Puja
                            </div>
                            <Box>
                              <div className="booking_span">
                                Ceremony Name:<span>Wedding Ceremony</span>
                              </div>
                              <div className="booking_span">
                                Date:<span> Oct 25, 2024</span>
                              </div>
                              <div className="booking_span">
                                Time:<span>10:00 AM</span>
                              </div>
                              <div className="booking_span">
                                Contact:<span>91-XXXXXXXXX</span>
                              </div>
                              <div className="booking_span">
                                Location:
                                <span>123 Temple Street, City Name</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 6 }}>
                          <Box>
                            <div
                              className="details_content"
                              style={{ color: "#000000" }}
                            >
                              Payment Details:
                            </div>
                            <Box>
                              <div className="booking_span">
                                Total Amount (without Samagri):<span>5000</span>
                              </div>
                              <div className="booking_span">
                                Amount Paid:<span>₹5000 (Received on Oct 20, 2024)</span>
                              </div>
                              <div className="booking_span">
                                Pending Amount:<span>₹0</span>
                              </div>
                              <div className="booking_span">
                                Payment Status:<span>Completed</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid2>
                      </Grid2>
                      <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6, md: 6 }}>
                          <Box>
                            <div
                              className="details_content"
                              style={{ color: "#000000" }}
                            >
                              Puja Description:
                            </div>
                            <Box>
                              <div className="booking_span">
                                Special Description:
                                <span>
                                  The wedding ceremony will include traditional
                                  rituals.
                                </span>
                              </div>
                            </Box>
                          </Box>
                          <Box>
                            <div
                              className="details_content"
                              style={{ color: "#000000" }}
                            >
                              Special Instructions:
                            </div>
                            <Box>
                              <div className="booking_span">
                                <li>
                                  Please arrive at least 30 minutes before the
                                  ceremony.{" "}
                                </li>
                                <li>
                                  Bring the necessary pooja items as discussed.
                                </li>
                              </div>
                            </Box>
                          </Box>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 6 }}>
                          <Box>
                            <div
                              className="details_content"
                              style={{ color: "#000000" }}
                            >
                              Samagri:
                            </div>
                            <Box>
                              <div className="booking_span">Kumkum- 100g</div>
                              <div className="booking_span">Agarbathi-1</div>
                              <div className="booking_span">Coconut-4</div>
                              <div className="booking_span">Flower- 1Kg</div>
                              <div className="booking_span">Panner-50ml</div>
                              <div className="booking_span">Milk-1L</div>
                            </Box>
                          </Box>
                        </Grid2>
                      </Grid2>
                    </div>
                  }
                  editButton={false}
                  showView={false}
                  borderColor="var(--primary-2)"
                />
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
});

export default TodaySchedule;
