import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { AddButton } from "../../components/Addbutton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/CommonSearchBar";
import "../../styles/purohit/puja-management.css";
import AntSwitch from "../../components/AntSwitch";
import CommonTable from "../../components/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllPujaRequest } from "../../features/slices/pujaSlice";

const PujaManagement = React.memo(() => {
  const columns = [
    {
      field: "name",
      headerName: "Puja Name",
      renderCell: (params, row) => <div>{params}</div>,
    },
    {
      field: "category",
      headerName: "Category",
      renderCell: (params, row) => {
        return (
          row.days
            .slice(0, 3)
            .map((day) => day.slice(0, 3))
            .join(", ") + "..."
        );
      },
    },
    { field: "price", headerName: "Service price (with Samagri)" },
    {
      field: "details",
      headerName: "Service price (without samagri)",
      renderCell: (params, row) => {
        return (
          <div
            className="seva_preview"
            onClick={() => navigate(`/seva-management/view/${row.sevaId}`)}
          >
            Preview
          </div>
        );
      },
    },
    {
      field: "online",
      headerName: "Online/Ofline",
      renderCell: (params, row) => {
        return row.online ? "Online" : "Offline";
      },
    },
    {
      field: "active",
      headerName: "Action",
      renderCell: (params, row) => (
        <span className="action-row">
          <img
            src="/edit-icon.svg"
            alt="edit"
            className="edit-icon"
            onClick={() => onEdit(row.sevaId)}
          />
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
            onChange={() => console.log("clicked")}
            checked={row?.active}
          />
          Active
        </span>
      ),
    },
  ];

  const dispatch = useDispatch();
  const puja = useSelector((state) => state.puja);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [search, setSearch] = useState("");

  const HandleAdd = () => {
    setOpenAddNew(true);
    navigate(`add`);
  };

  const onEdit = (id) => {
    navigate(`/seva-management/Edit/${id}`);
  };

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedValue, setSelectedValue] = useState(null);
  const [booleanValue, setBooleanValue] = useState(null);

  useEffect(() => {
    dispatch(getAllPujaRequest());
  }, []);

  console.log(puja);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0rem 0px 1rem 0px",
        }}
      >
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          Puja Management
        </Box>
        <AddButton name="Add" icon={<AddIcon />} onClick={HandleAdd} />
      </div>
      <div className="puja_search_right">
        <SearchInput setSearchTerm={setSearch} />
      </div>
      <CommonTable
        columns={columns}
        data={puja?.pujaData?.data ? puja?.pujaData?.data : []}
        page={page}
        count={10}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </>
  );
});
export default PujaManagement;
