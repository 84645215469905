import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { connect, getIn } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CustomDatePicker from "./CustomCalendarPicker";
import ImageUploadButton from "./ImageUploadButton";
import ImageUpload from "./ImageUpload";
import { getInitialValue, responsiveFontSize } from "./utilities";
import { InfoOutlined } from "@mui/icons-material";

const filePropType =
  typeof window !== "undefined" && typeof File !== "undefined"
    ? PropTypes.instanceOf(File).isRequired
    : PropTypes.any;

const MaterialUIFieldAdapter = ({
  formik,
  type,
  name,
  label,
  placeholder,
  rowRadio = true,
  options,
  value,
  style,
  radioImg,
  docType,
  isSmallScreen = false,
  subCategoryName,
  positionName,
  maxMultipleLength = 5,
  isDob,
  onChange,
  customValue,
  validatePasswordRules,
  disabled,
  children,
  disabledDate,
  tooltipContent,
  pageType,
  selectDefaultValue,
  show,
  setShow,
}) => {
  const { organization_auth_map, profile } = formik.values;
  const profileData = profile?.data || {};
  const {
    nature_of_business: businessNature,
    image_url: orgImgUrl,
    gst_pan,
    suppliers,
  } = organization_auth_map?.data.organization?.data || {};
  const newInitialValue =
    businessNature?.length > 0 ? getInitialValue(businessNature) : [];
  const [selected, setSelected] = useState(newInitialValue ?? []);
  const [areaList, setAreaList] = useState(
    getInitialValue(suppliers?.data?.coverage_area_list) ?? [],
  );
  const [showPassword, setShowPassword] = useState(false);
  const [multivalue, setMultiValue] = useState("" || []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  //   const handleAutoChange = (event, value, reason) => {
  //     if (reason == "selectOption") {
  //       setMultiValue(value.label);
  //       //   onChange(value.label);
  //     }
  //   };

  // const handleInputChange = debounce(async (e, value, re) => {
  //   if (re === "input" && value.length > 0) {
  //     const newCities =
  //       (await loadMoreCities(e.target.value))?.data?.cities ?? [];
  //     setOptions(newCities?.map((i) => ({ label: i.city })) ?? []);
  //   }
  // }, 500);

  const handleChange = (event) => {
    setShowToolTip(false);
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    formik.setFieldValue(name, value);

    if (type === "password" && validatePasswordRules !== null) {
      validatePasswordRules(value);
    }
  };

  const handleAutocompleteChange = (e) => {
    formik.setFieldValue(name, e.target.value);
  };

  const profileImgValue = () => {
    const isImgValid = profileData?.image_url || orgImgUrl;
    if (isImgValid) {
      return name == "organization_auth_map.data.organization.data.image_url"
        ? orgImgUrl
        : profileData.image_url;
    } else return "";
  };

  const gstPanImgValue = () => {
    const { gst, pan } = gst_pan.data;
    const isImgValid = gst || pan;
    if (isImgValid) {
      return name ===
        "organization_auth_map.data.organization.data.gst_pan.data.gst"
        ? gst
        : pan;
    } else return "";
  };

  const radioValue = () => {
    const isValidId =
      profileData?.preference?.data?.aadhar ||
      profileData?.preference?.data?.passport;
    const aadharValueLength = profileData?.preference?.data?.aadhar.length;
    if (isValidId) {
      const tempValue = aadharValueLength > 0 ? "aadhar" : "passport";
      formik.values.idType = tempValue;
      return tempValue;
    } else return value;
  };

  const handleMultipleSelect = (event) => {
    if (
      name ===
      "organization_auth_map.data.organization.data.suppliers.data.coverage_area_list"
    ) {
      const newValues = event.target.value.filter(
        (value) => !areaList.includes(value),
      );
      const updatedSelected =
        areaList.length > 0 ? [...areaList, ...newValues] : [...newValues];
      setAreaList(updatedSelected);
      formik.setFieldValue(name, updatedSelected);
    } else if (name.includes("language")) {
      const newValues = event.target.value.filter(
        (value) => !selected.includes(value),
      );

      if (selected.length + newValues.length <= maxMultipleLength) {
        const updatedSelected =
          selected.length > 0 ? [...selected, ...newValues] : [...newValues];
        setSelected(updatedSelected);
        formik.setFieldValue(name, updatedSelected);
      }
    } else {
      const newValues = event.target.value.filter(
        (value) => !selected.includes(value),
      );
      if (selected.length + newValues.length <= maxMultipleLength) {
        const updatedSelected =
          selected.length > 0 ? [...selected, ...newValues] : [...newValues];
        setSelected(updatedSelected);
        formik.setFieldValue(name, updatedSelected);
      }
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        formik.setFieldValue((prev) => ({
          ...prev,
          thumbnails: [
            ...prev.thumbnails,
            { name: file.name, url: e.target.result },
          ],
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBooleanValueChange = (event) => {
    const value = event.target.value === "true";
    formik.setFieldValue(name, value);
  };

  const handleBlur = () => {
    setShowToolTip(false);
    formik.setFieldTouched(name, true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showToolTip, setShowToolTip] = useState(false);

  const handlePopoverOpen = (event, content) => {
    setShow(true);
    setToolTipData(content);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setShow(false);
    setToolTipData("");
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [toolTipData, setToolTipData] = useState(null);

  useEffect(() => {}, [show]);

  const renderComponent = () => {
    const { values, errors, touched } = formik;
    const value = getIn(values, name);
    const error = getIn(errors, name);
    const touch = getIn(touched, name);

    switch (type) {
      case "text":
      case "number":
      case "email":
        return (
          <Box sx={{ mb: "1.5rem", width: "100%" }}>
            {label && (
              <Typography
                textAlign={"left"}
                color={"#2C2C39"}
                fontSize={"1rem"}
                mb={".4rem"}
              >
                {label}
              </Typography>
            )}
            <TextField
              type={type}
              sx={[
                {
                  borderRadius: "0.5rem",
                  width: "100%",
                  height: "3rem",
                  "& .MuiOutlinedInput-root": {
                    height: "3rem",
                    "&:hover fieldset": {
                      borderColor: "#46464F", // remove hover border color
                    },
                  },
                  //     "&.Mui-focused fieldset": {
                  //       borderColor: "#46464F", // remove focus border color
                  //     },
                  //     "&.Mui-error fieldset": {
                  //       borderColor: "#46464F", // remove error border color
                  //     },
                  //   },
                  //   border: "1px solid #46464F",
                  //   "& .MuiInputBase-root": {
                  //     border: "1px solid #46464F", // Default border color
                  //     "&.Mui-focused": {
                  //       border: "1px solid #46464F", // Border color when focused
                  //     },
                  //     "&.Mui-error": {
                  //       border: "1px solid #46464F", // Border color when error
                  //     },
                  //   },
                  "& .MuiInputBase-input": {
                    color: "var(--font-black)", // Changes the text color
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "var(--font-black)", // Changes the placeholder color
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black",
                  },
                  // backgroundColor: "var(--disabled-color)",
                },
                { ...style },
              ]}
              name={name}
              value={value || ""}
              placeholder={placeholder}
              inputProps={{ maxLength: type === "number" ? 10 : 100 }}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touch && Boolean(error)}
              helperText={touch && error}
              FormHelperTextProps={{
                textAlign: "left", // This style will align the helper text to the right
              }}
              disabled={disabled}
            />
            {/* {touch && error ? (
              <Typography color="error" fontSize={".8rem"}>
                {error}
              </Typography>
            ) : (
              <Typography
                color="error"
                fontSize={".8rem"}
                sx={{ height: ".8rem" }}
              ></Typography>
            )} */}
          </Box>
        );
      case "password":
        return (
          <Box sx={{ mb: "8px", width: "100%" }}>
            {label && (
              <>
                <Typography
                  textAlign={"left"}
                  color={"#2C2C39"}
                  fontSize={"1rem"}
                  mb={".4rem"}
                >
                  {label}
                </Typography>

                <OutlinedInput
                  id="outlined-adornment-password"
                  notched
                  type={showPassword ? "text" : type}
                  name={name}
                  placeholder={placeholder}
                  value={value || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touch && Boolean(error)}
                  autoComplete="off" // Disable browser autocomplete
                  sx={[
                    {
                      // background: "var(--black)",
                      borderRadius: "6px",
                      width: "100%",
                      height: "3rem",
                      // borderWidth: 1,
                      "& .MuiOutlinedInput-root": {
                        height: "3rem",
                        "&:hover fieldset": {
                          borderColor: "initial", // remove hover border color
                        },
                      },
                      //   "&.Mui-focused fieldset": {
                      //     borderColor: "initial", // remove focus border color
                      //   },
                      //   "&.Mui-error fieldset": {
                      //     borderColor: "initial", // remove error border color
                      //   },
                      // },
                      // border: "1px solid var(--font-black)",
                      // "& .MuiInputBase-root": {
                      //   border: "1px solid var(--font-black)", // Default border color
                      //   "&.Mui-focused": {
                      //     border: "1px solid var(--font-black)", // Border color when focused
                      //   },
                      //   "&.Mui-error": {
                      //     border: "1px solid #ffffff", // Border color when error
                      //   },
                      // },
                      "& .MuiInputBase-input": {
                        color: "var(--font-black)", // Changes the text color
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "var(--font-black)", // Changes the placeholder color
                      },
                    },
                    { ...style },
                  ]}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                        // sx={{ color: "#fff" }}
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {Boolean(error) && (
                  <FormHelperText
                    error
                    id="accountId-error"
                    sx={{
                      border: "1px solid white",
                      marginTop: "-2px",
                      borderTop: "none",
                      paddingTop: "6px",
                      paddingLeft: "15px",
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                  >
                    {touch && error}
                  </FormHelperText>
                )}
              </>
            )}
          </Box>
        );
      case "textarea":
        return (
          <Box alignSelf={"center"} fullWidth sx={{ mb: "24px" }}>
            {label && (
              <Typography
                sx={
                  pageType === "purohit_temple_settings"
                    ? { mb: "4px" }
                    : responsiveFontSize
                }
                textAlign={"left"}
              >
                {label}
              </Typography>
            )}
            <TextField
              multiline
              rows={3}
              maxRows={5}
              fullWidth
              sx={{
                minWidth: "328px",
                borderRadius: "0.5rem",
                maxHeight: "120px",
                "& .MuiInputBase-input": {
                  color: "#4B5563", // Changes the text color
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#D1D5DB", // Changes the placeholder color
                },
                background: "#fff",
                ...style,
              }}
              name={name}
              value={value || ""}
              placeholder={placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              // inputProps={{
              //   maxLength: 50,
              // }}
              // helperText={`${(value || "").length}/50`}
              // FormHelperTextProps={{
              //   xs: { textAlign: "right" }, // This style will align the helper text to the right
              // }}
              error={touch && Boolean(error)}
            />
          </Box>
        );

      case "checkbox":
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value || false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={name}
                />
              }
              label={label}
            />
          </FormGroup>
        );

      case "select":
        return (
          <Box sx={{ mb: "24px", width: "100%", position: "relative" }}>
            {show && (
              <Box
                sx={{
                  position: "absolute",
                  top: "-4.5rem",
                  right: "6rem",
                  float: "right",
                }}
              >
                <Box
                  sx={{
                    position: "fixed",
                    borderRadius: "1rem",
                    padding: "1.5rem",
                    boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.1)",
                    background: "white",
                    maxWidth: "20rem",
                  }}
                >
                  <Box className="tool_tip"></Box>
                  {toolTipData}
                </Box>
              </Box>
            )}
            {label && (
              <Typography
                sx={
                  pageType === "login"
                    ? [responsiveFontSize, { mb: "8px" }]
                    : { mb: "4px" }
                }
                textAlign={"left"}
                color={"var(--font-black)"}
              >
                {label}
              </Typography>
            )}

            <Select
              fullWidth
              MenuProps={{
                PaperProps: {
                  sx: {
                    color: "var(--font-black)",
                  },
                },
              }}
              sx={{
                borderRadius: "0.5rem",
                maxHeight: pageType === "login" ? "48px" : "32px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "initial", // reset default border color
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "initial", // reset focus border color
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "initial", // reset error border color
                },
                "& .MuiSelect-icon": {
                  color: "black", // Change the color of the icon
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "gray",
                    "& input::placeholder": {
                      color: "white", // Set placeholder color to white
                    },
                  },
                }, // Adding border to match screenshot
                "& .MuiSelect-select": {
                  color: "var(--font-black)", // Changes color based on if value is selected
                  "&::placeholder": {
                    color: "white", // Placeholder color when no value is selected
                  },
                },
                "& .MuiInputBase-root": {
                  color: "var(--font-black)", // Placeholder and input text color
                  "&.Mui-focused": {
                    color: "var(--font-black)", // Color when the select is focused
                    borderColor: "#113B73", // Border color when focused
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "var(--font-black)", // Label color
                  "&.Mui-focused": {
                    color: "var(--font-black)", // Color when the select is focused
                  },
                },
                "& .MuiMenuItem-root": {
                  "&.Mui-selected": {
                    backgroundColor: "var(--black)", // Background color for selected item
                    color: "var(--font-black)", // Text color for selected item
                    "&:hover": {
                      backgroundColor: "var(--black)", // Darker on hover
                    },
                  },
                  "&:hover": {
                    backgroundColor: "var(--black)", // Background color on hover for items
                    color: "var(--font-black)", // Text color change on hover
                  },
                },
              }}
              name={name}
              value={value || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              displayEmpty
              error={touch && Boolean(error)}
              placeholder="Select"
              onClick={() => setShow(false)}
            >
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={(e) => handlePopoverOpen(e, option?.content)}
                  onMouseLeave={handlePopoverClose}
                >
                  <Typography
                    sx={{ width: "100%" }}
                    onClick={() => setShow(false)}
                  >
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            {children}
            {touch && error ? (
              <Typography color="error" fontSize={".8rem"}>
                {error}
              </Typography>
            ) : (
              <Typography
                color="error"
                fontSize={".8rem"}
                sx={{ height: ".8rem" }}
              ></Typography>
            )}
          </Box>
        );

      case "multiselect":
        return (
          <Box fullWidth sx={{ mb: "24px" }}>
            {label && (
              <Typography
                sx={responsiveFontSize}
                textAlign={"left"}
                color={"#9CA3AF"}
              >
                {label}
              </Typography>
            )}
            <Select
              multiple
              fullWidth
              sx={{
                borderRadius: "0.5rem",
                maxHeight: "48px",
                overflow: "auto",
              }}
              name={name}
              value={
                name ===
                "organization_auth_map.data.organization.data.suppliers.data.coverage_area_list"
                  ? areaList
                  : selected
              } // Ensure the value is an array for multiple selections
              onChange={handleMultipleSelect}
              onBlur={handleBlur}
              renderValue={(selected) =>
                Array.isArray(selected)
                  ? selected
                      .map(
                        (item) =>
                          (
                            options.find((option) => option.value === item) ||
                            {}
                          ).label,
                      )
                      .join(", ")
                  : selected
              }
              error={touch && Boolean(error)}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Typography>{option.label}</Typography>
                </MenuItem>
              ))}
            </Select>
            {touch && error && <Typography color="error">{error}</Typography>}
          </Box>
        );

      case "date":
        return (
          <Box fullWidth>
            <CustomDatePicker
              disabledDate={disabledDate}
              label={label}
              value={getIn(formik.values, name)}
              onChange={(date) => {
                formik.setFieldValue(name, date);
              }}
              placeholder={placeholder || "YYYY-MM-DD"}
            />
            {touch && error ? (
              <Typography
                color="error"
                fontSize={".8rem"}
                sx={{ marginTop: ".5rem" }}
              >
                {error}
              </Typography>
            ) : (
              <Typography
                color="error"
                fontSize={".8rem"}
                sx={{ marginTop: ".5rem" }}
              ></Typography>
            )}
          </Box>
        );

      case "radio":
        return (
          <Box alignSelf={"center"} fullWidth sx={{ mb: "24px" }}>
            {label && (
              <Typography
                style={responsiveFontSize}
                textAlign={"left"}
                color={"#9CA3AF"}
              >
                {label}
              </Typography>
            )}
            <RadioGroup
              name={name}
              sx={{ width: "100%", justifyContent: "space-between" }}
              value={radioValue()}
              onChange={handleChange}
              onBlur={handleBlur}
              row={rowRadio} // Set 'row' to true if you want horizontal layout
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  sx={{
                    "& .MuiTypography-root": {
                      // This targets the label Typography component
                      color: value === option.value ? "#113B73" : "#D1D5DB", // Conditional coloring
                    },
                    "& .MuiRadio-root": {
                      color: "#D1D5DB", // Default unchecked color for the radio button
                      "&.Mui-checked": {
                        color: "#113B73", // Checked color for the radio button
                      },
                    },
                  }}
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {touch && error && <Typography color="error">{error}</Typography>}
          </Box>
        );

      case "file":
        return (
          <Box alignSelf={"center"} fullWidth sx={{ mb: "24px" }}>
            <ImageUploadButton
              onFileSelect={(file) => {
                // Update the Formik state with the file object
                formik.setFieldValue(name, file);
              }}
              valueUrl={profileImgValue()}
              label={label}
            />
            {touch && error ? (
              <Typography color="error" fontSize={".8rem"}>
                {error}
              </Typography>
            ) : (
              <Typography
                color="error"
                fontSize={".8rem"}
                sx={{ height: ".8rem" }}
              ></Typography>
            )}
          </Box>
        );

      case "radioCard":
        return (
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: "16px",
              mb: "8px",
              background: "#113B73",
              px: { xs: "8px", sm: "16px" },
              borderRadius: "10px",
              maxHeight: {
                xs: "80px",
                sm: "88px",
              },
            }}
          >
            <CardContent
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                color={"#fff"}
                fontWeight={"bold"}
                fontFamily={"Poppins"}
              >
                {label}
              </Typography>
              <RadioGroup
                row
                name={name}
                value={value !== undefined ? value.toString() : ""}
                onChange={handleBooleanValueChange}
                sx={{
                  justifyContent: "space-between",
                  maxWidth: isSmallScreen ? "100%" : "67%",
                }}
              >
                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value.toString()}
                    control={
                      <Radio
                        sx={{
                          color: "#fff",
                          "&.Mui-checked": { color: "#fff" },
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ color: "#fff" }}>
                        {option.label}
                      </Typography>
                    }
                    sx={{ mr: 2 }}
                  />
                ))}
              </RadioGroup>
            </CardContent>
            <Box
              sx={{
                maxWidth: "72px",
                maxHeight: "72px",
                display: isSmallScreen ? "none" : "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Placeholder for image or icon */}
              <img
                src={radioImg}
                alt="icon"
                style={{ width: "100%", maxHeight: "64px" }}
              />
            </Box>
          </Card>
        );

      case "documentFile":
        return (
          <Box alignSelf={"center"} fullWidth sx={{ mb: "24px" }}>
            <Box style={{ marginBottom: "2rem" }}>
              <Typography variant="subtitle1">Thumbnail Photo</Typography>
              <Box
                style={{
                  border: "1px dashed #6b6b82",
                  padding: "1.5rem",
                  marginTop: "0.5rem",
                  borderRadius: "8px",
                  textAlign: "center",
                  backgroundColor: "#2b2b3a",
                }}
              >
                <input
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                  id="thumbnail-upload"
                  onChange={(file) => formik.setFieldValue(name, file)}
                />
                <label htmlFor="thumbnail-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    style={{
                      marginTop: "1rem",
                      color: "#00bfa5",
                      borderColor: "#00bfa5",
                    }}
                  >
                    Browse file
                  </Button>
                </label>
                <Typography
                  style={{
                    fontSize: "0.8rem",
                    color: "#999",
                    marginTop: "0.5rem",
                  }}
                >
                  Max. 2MB, JPEG
                </Typography>
              </Box>
            </Box>

            {touch && error && <Typography color="error">{error}</Typography>}
          </Box>
        );

      case "autocomplete":
        return (
          <Box sx={{ width: "100%" }}>
            {label && (
              <Typography
                textAlign={"left"}
                color={"var(--font-black)"}
                sx={[{ responsiveFontSize }]}
              >
                {label}
              </Typography>
            )}
            <Autocomplete
              renderInput={(params) => <TextField {...params} />}
              disablePortal
              id="combo-box-cities"
              sx={{
                border: "1px solid var(--primary-3)",
                height: "3rem",
                borderRadius: "0.5rem",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "initial", // reset default border color
                  height: "3.2rem",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "initial", // reset focus border color
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "initial", // reset error border color
                },
                "& .MuiSelect-icon": {
                  color: "white", // Change the color of the icon
                },
                "& .MuiOutlinedInput-root": {
                  height: "3rem",
                  "& fieldset": {
                    borderColor: "gray",
                  },
                }, // Adding border to match screenshot
                "& .MuiSelect-select": {
                  color: "var(--font-black)", // Changes color based on if value is selected
                },
                "& .MuiInputBase-root": {
                  color: "var(--font-black)", // Placeholder and input text color
                  "&.Mui-focused": {
                    color: "var(--font-black)", // Color when the select is focused
                    borderColor: "#113B73", // Border color when focused
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "var(--font-black)", // Label color
                  "&.Mui-focused": {
                    color: "var(--font-black)", // Color when the select is focused
                  },
                },
                "& .MuiMenuItem-root": {
                  "&.Mui-selected": {
                    backgroundColor: "var(--black)", // Background color for selected item
                    color: "var(--font-black)", // Text color for selected item
                    "&:hover": {
                      backgroundColor: "var(--black)", // Darker on hover
                    },
                  },
                  "&:hover": {
                    backgroundColor: "var(--black)", // Background color on hover for items
                    color: "var(--font-black)", // Text color change on hover
                  },
                },
              }}
              //multiple={maxMultipleLength > 0 ? true : false}
              options={options}
              getOptionLabel={(option) => option.label}
              fullWidth
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  name,
                  newValue?.value ? newValue?.value : "",
                );
              }}
            />
            {children}
            {touch && error ? (
              <Typography color="error" fontSize={".8rem"}>
                {error}
              </Typography>
            ) : (
              <Typography
                color="error"
                fontSize={".8rem"}
                sx={{ height: ".8rem" }}
              ></Typography>
            )}
          </Box>
        );

      case "file-image":
        return (
          <Box
            alignSelf={"center"}
            fullWidth
            sx={{ mb: "24px", width: "100%" }}
          >
            <ImageUpload
              onFileSelect={(file, selectedFile) => {
                // Update the Formik state with the file object
                formik.setFieldValue(name, selectedFile);
              }}
              valueUrl={profileImgValue()}
              label={label}
            />
            {error && (
              <Typography color="error" fontSize={".8rem"}>
                {error}
              </Typography>
            )}
          </Box>
        );

      //   case "categoryAutocomplete":
      //     return (
      //       <CatergoryAutoComplete
      //         departmentName={name}
      //         subCategoryName={subCategoryName}
      //         positionName={positionName}
      //         onChange={(values) => {
      //           formik.setFieldValue(values.departmentName, values.department);
      //           formik.setFieldValue(values.subCategoryName, values.sub_category);
      //           formik.setFieldValue(values.positionName, values.position);
      //         }}
      //         {...customValue}
      //         onBlur={handleBlur}
      //         maxMultipleLength
      //         error={touch && Boolean(error)}
      //         helperText={touch && error}
      //         FormHelperTextProps={{
      //           textAlign: "left", // This style will align the helper text to the right
      //         }}
      //       />
      //     );

      default:
        return <div>Unsupported type</div>;
    }
  };

  return renderComponent();
};

MaterialUIFieldAdapter.propTypes = {
  formik: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  rowRadio: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // For date or complex objects
    PropTypes.array, // for multiSelect
    filePropType, // For file inputs
  ]),
  style: PropTypes.object,
  radioImg: PropTypes.string,
  docType: PropTypes.string,
  isSmallScreen: PropTypes.bool,
};

MaterialUIFieldAdapter.defaultProps = {
  rowRadio: true, // Default value if not provided
  isSmallScreen: false,
};

export default connect(MaterialUIFieldAdapter);
