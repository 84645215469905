import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Close } from "@mui/icons-material";
import "../../styles/onboardingForm.css";

import {
  uploadPrasadamRequest,
  updatePrasadamRequest,
  fetchPrasadamRequest,
} from "../../features/slices/onBordingSlice";
import { useDispatch, useSelector } from "react-redux";

const initialFormState = {
  prasadamName: "",
  prasadamPrice: "",
  prasadhamPhoto: [],
  packagePhoto: [],
  availableStock: "",
  quantity: "",
  prasadamDescription: "",
  bookingAvailability: "",
};

const initialErrorState = {
  prasadamName: "",
  prasadamPrice: "",
  prasadhamPhoto: [],
  packagePhoto: [],
  availableStock: "",
  prasadamDescription: "",
  bookingAvailability: "",
};

const bookingAvailabilityList = [
  { value: "ONLINE", label: "Online" },
  { value: "OFFLINE", label: "Offline" },
];
const PrasadamDetails = forwardRef((props, ref) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const prasadamId = query.get("prasadamId");
  const navigate = useNavigate();
  const [prasadamData, setPrasadamData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const dispatch = useDispatch();
  const { onBoardingUploadStatus, PrasadamData, imageDeleteStatus } =
    useSelector((state) => state.onbording);

  const fileInputRef = useRef(null);

  const handleChange = (field) => (event, newValue) => {
    const value = event.target.value;

    setPrasadamData((prev) => ({
      ...prev,
      [field]: newValue ? newValue?.value : value,
    }));

    if (field === "availableStock" || field === "bookingAvailability") {
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const MAX_IMAGE_SIZE = 20 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

  const handleImageUpload = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;
    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (
      selectedFiles?.length + (prasadamData[field]?.length || 0) >
      MAX_FILES
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type === "video/mp4";
      const isValidImageFormat = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ].includes(file.type);
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (
        (uploadType === "image/*" && (!isImage || !isValidImageFormat)) ||
        (uploadType === "video/mp4" && !isVideo)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Invalid file type. Please upload ${
            uploadType === "image/*" ? "JPEG, JPG, or PNG images" : "MP4 videos"
          }.`,
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "20MB" : "50MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPrasadamData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeUpload = (field, index, item) => {
    setPrasadamData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;

      if (!prasadamData.prasadamName) {
        newErrors.prasadamName = "Prasadam Name is required";
        isValid = false;
      }

      if (!prasadamData.prasadamPrice) {
        newErrors.prasadamPrice = "Prasadam Price is required";
        isValid = false;
      }

      if (prasadamData.prasadhamPhoto.length === 0) {
        newErrors.prasadhamPhoto = "At least one image is required";
        isValid = false;
      }

      if (prasadamData.packagePhoto.length === 0) {
        newErrors.packagePhoto = "At least one image is required";
        isValid = false;
      }

      if (!prasadamData.availableStock) {
        newErrors.availableStock = "Available Stock is required";
        isValid = false;
      }

      if (!prasadamData.prasadamDescription) {
        newErrors.prasadamDescription = "Description is required";
        isValid = false;
      }

      if (!prasadamData.bookingAvailability) {
        newErrors.bookingAvailability = "Booking Availability is required";
        isValid = false;
      }

      setErrors(newErrors);

      if (isValid) {
        let formData = new FormData();

        if (!prasadamData?.prasadhamPhoto[0]?.etag) {
          formData.append("photo", prasadamData?.prasadhamPhoto[0]?.file);
        }
        if (!prasadamData?.packagePhoto[0]?.etag) {
          formData.append("packagePhoto", prasadamData?.packagePhoto[0]?.file);
        }

        formData.append("name", prasadamData?.prasadamName);
        formData.append("price", prasadamData?.prasadamPrice);
        formData.append("availableStock", prasadamData?.availableStock);
        formData.append("quantity", prasadamData?.quantity);
        formData.append(
          "bookingAvailability",
          prasadamData?.bookingAvailability,
        );

        formData.append("description", prasadamData?.prasadamDescription);

        if (PrasadamData && PrasadamData?.id) {
          dispatch(updatePrasadamRequest({ id: PrasadamData?.id, formData }));
        } else {
          dispatch(uploadPrasadamRequest(formData));
        }
      }
    },
  }));

  useEffect(() => {
    dispatch(fetchPrasadamRequest());
  }, [dispatch, imageDeleteStatus]);

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };

  useEffect(() => {
    if (PrasadamData) {
      setPrasadamData({
        ...prasadamData,
        prasadamName: PrasadamData?.name,
        prasadamPrice: PrasadamData?.price,
        prasadamDescription: PrasadamData?.description,
        availableStock: PrasadamData?.availableStock,
        quantity: PrasadamData?.quantity,
        bookingAvailability: PrasadamData?.bookingAvailability,
        packagePhoto: PrasadamData?.packagePhoto
          ? mapImages([PrasadamData.packagePhoto])
          : [],

        prasadhamPhoto: PrasadamData?.prasadhamPhoto
          ? mapImages([PrasadamData.prasadhamPhoto])
          : [],
      });
    }
  }, [PrasadamData]);

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (field, e, maxFiles, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);

    handleImageUpload(field, e, maxFiles, uploadType);
  };

  return (
    <>
      <div className="heading">Prasadam Details</div>
      {/* <div variant="h6">
        Note: List all the prasadam that the users can purchase from your temple.
      </div> */}

      <>
        <div className="onboarding-sub_heading">Basic Details</div>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Prasadam Name*
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              disabled={props?.previewStatus ? true : false}
              value={prasadamData?.prasadamName}
              onChange={handleChange("prasadamName")}
              className="custom-textfield"
              placeholder="Enter prasadam name"
            />

            {errors?.prasadamName && (
              <Typography color="error">{errors?.prasadamName}</Typography>
            )}
          </Grid2>

          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Prasadam Price*
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              type="number"
              disabled={props?.previewStatus ? true : false}
              value={prasadamData?.prasadamPrice}
              onChange={handleChange("prasadamPrice")}
              className="custom-textfield"
              placeholder="Enter prasadam price"
            />

            {errors?.prasadamPrice && (
              <Typography color="error">{errors?.prasadamPrice}</Typography>
            )}
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Box>
              <Typography variant="subtitle1" className="prasadam-form-title">
                Prasadam Photos*
              </Typography>

              <Box
                className="file-upload-box "
                onDrop={(e) => handleDrop("prasadhamPhoto", e, 1, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {prasadamData?.prasadhamPhoto.length > 0 ? (
                  <Box className="file-preview-image-container">
                    {prasadamData?.prasadhamPhoto.map((item, index) => (
                      <Box className="song-image-container">
                        {!props?.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("prasadhamPhoto", index, item)
                            }
                          />
                        )}

                        <img
                          src={item.url}
                          className="prasadam-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props?.previewStatus && (
                  <>
                    {" "}
                    <Typography className="file-upload-instructions">
                      <UploadFileIcon className="upload-file-icon" />
                      Drop Your File Here
                    </Typography>
                    <Typography className="file-upload-instructions">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      className="prasadam-file-input"
                      id="prasadam-upload"
                      name="prasadhamPhoto"
                      onChange={(e) =>
                        handleImageUpload("prasadhamPhoto", e, 1, "image/*")
                      }
                    />
                    <label htmlFor="prasadam-upload">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse files
                      </Button>
                    </label>
                    <Typography className="file-upload-instructions">
                      Max: 20MB, JPG, PNG
                    </Typography>
                  </>
                )}
              </Box>
            </Box>

            {errors?.prasadhamPhoto && (
              <Typography color="error">{errors?.prasadhamPhoto}</Typography>
            )}
          </Grid2>

          <Grid2 size={{ xs: 6, md: 6 }}>
            <Box>
              <Typography variant="subtitle1" className="prasadam-form-title">
                Prasadam Package Photos*
              </Typography>

              <Box
                className="file-upload-box"
                onDrop={(e) => handleDrop("packagePhoto", e, 1, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {prasadamData?.packagePhoto?.length > 0 ? (
                  <Box className="file-preview-image-container">
                    {prasadamData?.packagePhoto?.map((item, index) => (
                      <Box className="prasadam-image-container">
                        {!props?.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("packagePhoto", index, item)
                            }
                          />
                        )}

                        <img
                          src={item.url}
                          className="prasadam-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props?.previewStatus && (
                  <>
                    <Typography className="file-upload-instructions">
                      <UploadFileIcon className="upload-file-icon" />
                      Drop Your File Here
                    </Typography>

                    <Typography className="file-upload-instructions">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      name="packagePhoto"
                      type="file"
                      className="prasadam-file-input"
                      id="prasadam-package-upload-package"
                      onChange={(e) =>
                        handleImageUpload("packagePhoto", e, 1, "image/*")
                      }
                    />
                    <label htmlFor="prasadam-package-upload-package">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>

                    <Typography className="file-upload-instructions">
                      Max: 20MB, JPG, PNG
                    </Typography>
                  </>
                )}
              </Box>
            </Box>

            {errors?.packagePhoto && (
              <Typography color="error">{errors?.packagePhoto}</Typography>
            )}
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2}>
          <Grid2 size={{ sx: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Available Stock*
            </Typography>

            <FormControl fullWidth variant="outlined" className="custom-select">
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                disabled={props?.previewStatus ? true : false}
                value={prasadamData?.availableStock}
                onChange={handleChange("availableStock")}
                className="custom-textfield"
                placeholder="Enter availableStock"
              />

              {errors.availableStock && (
                <Typography color="error">{errors.availableStock}</Typography>
              )}
            </FormControl>
          </Grid2>

          <Grid2 size={{ sx: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Quantity
            </Typography>

            <FormControl fullWidth variant="outlined" className="custom-select">
              <TextField
                type="string"
                fullWidth
                disabled={props?.previewStatus ? true : false}
                variant="outlined"
                value={prasadamData?.quantity}
                onChange={handleChange("quantity")}
                className="custom-textfield"
                placeholder="Enter quantity"
              />
            </FormControl>
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Prasadam Description*
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              disabled={props?.previewStatus ? true : false}
              value={prasadamData.prasadamDescription}
              onChange={handleChange("prasadamDescription")}
              placeholder="Description"
              className={["custom-textfield", "input-fields"]}
            />

            {errors?.prasadamDescription && (
              <Typography color="error">
                {errors?.prasadamDescription}
              </Typography>
            )}
          </Grid2>

          <Grid2 size={{ sx: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Booking Availablitity*
            </Typography>

            <FormControl fullWidth variant="outlined" className="custom-select">
              <Autocomplete
                options={bookingAvailabilityList}
                disableClearable
                disabled={props?.previewStatus ? true : false}
                getOptionLabel={(option) => option.label}
                onChange={handleChange("bookingAvailability")}
                value={
                  bookingAvailabilityList.find(
                    (option) =>
                      option.value === prasadamData.bookingAvailability,
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Availability"
                    variant="outlined"
                  />
                )}
              />

              {errors.bookingAvailability && (
                <Typography color="error">
                  {errors.bookingAvailability}
                </Typography>
              )}
            </FormControl>
          </Grid2>
        </Grid2>
      </>
    </>
  );
});

export default PrasadamDetails;
