import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid2,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AddButton } from "../../components/Addbutton";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import MultipleImageUpload from "../../components/MultipleImageUpload";
import "../../styles/multiple-image-modal.css";
import {
  fetchTempleData,
  fetchPaymentData,
  fetchTempleMediaData,
  updateTempleDescription,
  toggleStatus,
  updateTempleMedia,
} from "../../features/slices/templeDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatFileSize } from "../../helpers/convertFileSize";
import { CustomLoader } from "../../components/CustomLoader";
import { Close } from "@mui/icons-material";
import { DeleteImageRequest } from "../../features/slices/onBordingSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const TempleDetailsSettings = () => {
  const dispatch = useDispatch();
  const {
    templeData,
    paymentData,
    mediaData,
    editTempleDataStatus,
    editMediaDataStatus,
    editLoading,
    editMediaLoading,
    loading,
  } = useSelector((state) => state.templeDetail);

  const initialFormState = {
    templeThumbnail: [],
    deityPhoto: [],
    interiorPhoto: [],
    exteriorPhoto: [],
    eventsPhoto: [],
    videoThumbnail: [],
    templeVideo: [],
    ceremonies: [],
  };

  const initialErrorState = {
    templeThumbnail: [],
    deityPhoto: [],
    interiorPhoto: [],
    exteriorPhoto: [],
    eventsPhoto: [],
    videoThumbnail: [],
    templeVideo: [],
    ceremonies: [],
  };

  let templeMediaArray = [];
  let templeMediaVideoArray = [];
  const [open, setOpen] = React.useState(false);
  const [templeDescriptionEdit, setTempleDescriptionEdit] = useState(false);
  const [multipleFile, setMultipleFile] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const [templeImageList, setTempleImageList] = useState([]);
  const [templeVideoList, setTempleVideoList] = useState([]);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [currentImg, setCurrentImage] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);

  const handleImageClick = (url) => {
    setCurrentImage(url);
    setOpenImageDialog(true);
  };

  const handleVideoClick = (url) => {
    setCurrentVideo(url);
    setOpenVideoDialog(true);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const removeSingleFile = (fieldName) => {
    if (fieldName === "templeThumbnail" || fieldName === "videoThumbnail") {
      setFormData((prev) => ({
        ...prev,
        [fieldName]: "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [fieldName]: [],
      }));
    }
  };

  const removeFile = (index, fieldName, item) => {
    if (
      item?.etag &&
      (fieldName === "templeVideo" ||
        fieldName === "ceremonies" ||
        fieldName === "eventsPhoto" ||
        fieldName === "exteriorPhoto" ||
        fieldName === "interiorPhoto" ||
        fieldName === "deityPhoto")
    ) {
      dispatch(DeleteImageRequest({ etag: item.etag, field: fieldName }));
    }

    setFormData((prev) => ({
      ...prev,
      [fieldName]: prev[fieldName].filter((_, i) => i !== index),
    }));
  };

  const mapLocalImage = (images) => {
    if (images.length > 0) {
      let NewArr = images.map((item) => {
        return item?.file;
      });

      return NewArr;
    }
  };

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });

      return newArr;
    }
    return [];
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    prevArrow: (
      <ArrowBackIosIcon
        sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
      />
    ),
    nextArrow: (
      <ArrowForwardIosIcon
        sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var videoSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: templeVideoList.length || 3,
    initialSlide: 0,
    // centerMode: true,
    // centerPadding: "0px",
    prevArrow: (
      <ArrowBackIosIcon
        sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
      />
    ),
    nextArrow: (
      <ArrowForwardIosIcon
        sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var modalSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    prevArrow: (
      <ArrowBackIosIcon
        sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
      />
    ),
    nextArrow: (
      <ArrowForwardIosIcon
        sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getTempleAndPaymentDetails = () => {
    dispatch(fetchTempleData());
    dispatch(fetchPaymentData());
    dispatch(fetchTempleMediaData());
  };

  const handleSubmit = useCallback(
    (values) => {
      let templeObj = {
        templeHistory: values?.temple_history,
        mythology: values?.mythology,
        deity: values?.deity,
        miracles: values?.miracle,
      };

      dispatch(updateTempleDescription(templeObj));
    },
    [dispatch],
  );

  const handlePhotosVideosSubmit = useCallback(() => {
    const newErrors = { ...initialErrorState };
    let isValid = true;

    if (formData.templeThumbnail.length === 0) {
      newErrors.templeThumbnail = "Atleast 1 Thumbnail Image is required";
      isValid = false;
    }
    if (formData.deityPhoto.length === 0) {
      newErrors.deityPhoto = "Atleast 1 Deity Image is required";
      isValid = false;
    }
    if (formData.interiorPhoto.length === 0) {
      newErrors.interiorPhoto = "Atleast 1 Interior Image is required";
      isValid = false;
    }
    if (formData.exteriorPhoto.length === 0) {
      newErrors.exteriorPhoto = "Atleast 1 Exterior Image is required";
      isValid = false;
    }
    if (formData.eventsPhoto.length === 0) {
      newErrors.eventsPhoto = "Atleast 1 Events Image is required";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      let formArr = new FormData();

      const appendFilesAsArray = (key, files) => {
        if (files && files?.length > 0) {
          files.forEach((file) => {
            formArr.append(key, file);
          });
        }
      };

      if (formData?.deityPhoto.length > 0) {
        const filteredPhotos = formData?.deityPhoto.filter(
          (photo) => !photo.etag,
        );
        appendFilesAsArray("deityPhoto", mapLocalImage(filteredPhotos));
      }
      if (formData?.interiorPhoto.length > 0) {
        const filteredPhotos = formData?.interiorPhoto.filter(
          (photo) => !photo.etag,
        );
        appendFilesAsArray("interiorPhoto", mapLocalImage(filteredPhotos));
      }
      if (formData?.exteriorPhoto.length > 0) {
        const filteredPhotos = formData?.exteriorPhoto.filter(
          (photo) => !photo.etag,
        );
        appendFilesAsArray("exteriorPhoto", mapLocalImage(filteredPhotos));
      }
      if (formData?.eventsPhoto.length > 0) {
        const filteredPhotos = formData?.eventsPhoto.filter(
          (photo) => !photo.etag,
        );
        appendFilesAsArray("eventsPhoto", mapLocalImage(filteredPhotos));
      }
      if (formData?.templeVideo.length > 0) {
        const filteredPhotos = formData?.templeVideo.filter(
          (photo) => !photo.etag,
        );
        appendFilesAsArray("templeVideo", mapLocalImage(filteredPhotos));
      }
      if (formData?.ceremonies.length > 0) {
        const filteredPhotos = formData?.ceremonies.filter(
          (photo) => !photo.etag,
        );
        appendFilesAsArray("ceremonies", mapLocalImage(filteredPhotos));
      }
      if (!formData?.videoThumbnail[0]?.etag && formData?.videoThumbnail.length > 0) {
        formArr.append("videoThumbnail", formData?.videoThumbnail[0]?.file);
      }
      if (!formData?.templeThumbnail[0]?.etag && formData?.templeThumbnail.length > 0) {
        formArr.append("templeThumbnail", formData?.templeThumbnail[0]?.file);
      }

      dispatch(updateTempleMedia(formArr));
    }
  }, [dispatch, formData]);

  const setTempleMediaData = () => {
    setFormData({
      ...formData,
      templeThumbnail: mediaData?.templeThumbnail
        ? mapImages([mediaData?.templeThumbnail])
        : "",
      deityPhoto:
        mediaData?.deityPhoto?.length > 0
          ? mapImages(mediaData?.deityPhoto)
          : [],
      interiorPhoto:
        mediaData?.interiorPhoto?.length > 0
          ? mapImages(mediaData?.interiorPhoto)
          : [],
      exteriorPhoto:
        mediaData?.exteriorPhoto?.length > 0
          ? mapImages(mediaData?.exteriorPhoto)
          : [],
      eventsPhoto:
        mediaData?.eventsPhoto?.length > 0
          ? mapImages(mediaData?.eventsPhoto)
          : [],
      videoThumbnail: mediaData?.videoThumbnail
        ? mapImages([mediaData?.videoThumbnail])
        : "",
      templeVideo:
        mediaData?.templeVideo?.length > 0
          ? mapImages(mediaData?.templeVideo)
          : [],
      ceremonies:
        mediaData?.ceremonies?.length > 0
          ? mapImages(mediaData?.ceremonies)
          : [],
    });

    templeMediaArray = mediaData?.deityPhoto?.concat(
      mediaData?.interiorPhoto,
      mediaData?.exteriorPhoto,
      mediaData?.eventsPhoto,
      mediaData?.templeThumbnail,
    );
    setTempleImageList(templeMediaArray);
    templeMediaVideoArray = mediaData?.templeVideo?.concat(
      mediaData?.ceremonies,
    );
    if(templeMediaVideoArray) {
      setTempleVideoList(templeMediaVideoArray);
    }
  };

  useEffect(() => {
    getTempleAndPaymentDetails();
  }, []);

  useEffect(() => {
    if (Object.values(mediaData).length > 0) {
      setTempleMediaData();
    }
  }, [mediaData]);

  useEffect(() => {
    if (editTempleDataStatus) {
      setTempleDescriptionEdit(false);
      getTempleAndPaymentDetails();
      dispatch(toggleStatus());
    }
  }, [editTempleDataStatus]);

  useEffect(() => {
    if (editMediaDataStatus) {
      handleClose();
      getTempleAndPaymentDetails();
      dispatch(toggleStatus());
    }
  }, [editMediaDataStatus]);

  return (
    <div className="temple_details_container">
      <CustomLoader
        loading={loading}
        message="Please wait, Fetching Temple Details..."
      />

      <CustomLoader
        loading={editLoading}
        message="Please wait, Uploading Temple Description Details..."
      />

      <Dialog
        open={openImageDialog}
        onClose={() => {
          setCurrentImage(null);
          setOpenImageDialog(false);
        }}
        maxWidth="md"
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src="/close-icon.svg"
            alt="close-icon"
            className="close-icon"
            onClick={() => setOpenImageDialog(false)}
          />
        </div>
        <DialogContent>
          <img
            src={currentImg}
            alt="Selected"
            style={{ width: "100%", height: "50vh" }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openVideoDialog}
        onClose={() => {
          setCurrentVideo(null);
          setOpenVideoDialog(false);
        }}
        maxWidth="md"
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src="/close-icon.svg"
            alt="close-icon"
            className="close-icon"
            onClick={() => setOpenVideoDialog(false)}
          />
        </div>
        <DialogContent>
          <video
            width="100%"
            controls
            style={{ width: "100%", height: "50vh" }}
            autoPlay
          >
            <source src={currentVideo} type="video/mp4" />
          </video>
        </DialogContent>
      </Dialog>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CustomLoader
            loading={editMediaLoading}
            message="Please wait, Uploading Temple Media"
          />

          <div className="onboarding-sub_heading">Photos and Videos</div>
          <div className="photo_and_video_container">
            <div>Temple Thumbnail Photo</div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={false}
                fieldName="templeThumbnail"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
                MAX_FILES={1}
              />

              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                {formData?.templeThumbnail && (
                  <Slider {...modalSettings}>
                    <div
                      className="img-block bg-gray"
                      key={formData?.templeThumbnail[0]?.url}
                    >
                      <img
                        className="img-fluid2"
                        src={formData?.templeThumbnail[0]?.url}
                        alt={formData?.templeThumbnail[0]?.name}
                      />
                      <img
                        src="/delete1.svg"
                        alt="delete"
                        onClick={() => removeSingleFile("templeThumbnail")}
                        className="remove_img"
                      />
                    </div>
                  </Slider>
                )}
              </div>
            </div>
            {errors.templeThumbnail && (
              <Typography color="error">{errors.templeThumbnail}</Typography>
            )}

            <div style={errors?.templeThumbnail ? { marginTop: "1rem" } : {}}>
              Temple Deity Photo
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="deityPhoto"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
                MAX_FILES={5}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                {formData?.deityPhoto?.length > 0 && (
                  <Slider {...modalSettings}>
                    {formData?.deityPhoto?.map((image, index) => (
                      <div key={image?.url} className="col-md-2">
                        <div className="img-block bg-gray">
                          <img
                            className="img-fluid2"
                            src={image?.url}
                            alt={image?.name}
                          />
                          <span
                            className="remove_img"
                            onClick={() => removeFile(index, "deityPhoto", image)}
                          >
                            <img src="/delete1.svg" alt="delete" />
                          </span>
                        </div>
                      </div>
                    ))}
                </Slider>
                )}
              </div>
            </div>
            {errors.deityPhoto && (
              <Typography color="error">{errors.deityPhoto}</Typography>
            )}

            <div style={errors?.deityPhoto ? { marginTop: "1rem" } : {}}>
              Temple Interior Photo
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="interiorPhoto"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
                MAX_FILES={5}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                {formData?.interiorPhoto?.length > 0 && (
                  <Slider {...modalSettings}>
                    {formData?.interiorPhoto?.map((image, index) => (
                        <div key={image?.url} className="col-md-2">
                          <div className="img-block bg-gray">
                            <img
                              className="img-fluid2"
                              src={image?.url}
                              alt={image?.name}
                            />
                            <span
                              className="remove_img"
                              onClick={() => removeFile(index, "interiorPhoto", image)}
                            >
                              <img src="/delete1.svg" alt="delete" />
                            </span>
                          </div>
                        </div>
                      ))}
                  </Slider>
                )}
              </div>
            </div>
            {errors.interiorPhoto && (
              <Typography color="error">{errors.interiorPhoto}</Typography>
            )}

            <div style={errors?.interiorPhoto ? { marginTop: "1rem" } : {}}>
              Temple Exterior Photo
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="exteriorPhoto"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
                MAX_FILES={5}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                {formData?.exteriorPhoto?.length > 0 && (
                  <Slider {...modalSettings}>
                    {
                      formData?.exteriorPhoto?.map((image, index) => (
                        <div key={image?.url} className="col-md-2">
                          <div className="img-block bg-gray">
                            <img
                              className="img-fluid2"
                              src={image?.url}
                              alt={image?.name}
                            />
                            <span
                              className="remove_img"
                              onClick={() => removeFile(index, "exteriorPhoto", image)}
                            >
                              <img src="/delete1.svg" alt="delete" />
                            </span>
                          </div>
                        </div>
                      ))}
                  </Slider>
                )}
              </div>
            </div>
            {errors.exteriorPhoto && (
              <Typography color="error">{errors.exteriorPhoto}</Typography>
            )}

            <div style={errors?.exteriorPhoto ? { marginTop: "1rem" } : {}}>
              Special Event's Photo
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="eventsPhoto"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
                MAX_FILES={5}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                {formData?.eventsPhoto?.length > 0 && (
                  <Slider {...modalSettings}>
                    {formData?.eventsPhoto?.map((image, index) => (
                        <div key={image?.url} className="col-md-2">
                          <div className="img-block bg-gray">
                            <img
                              className="img-fluid2"
                              src={image?.url}
                              alt={image?.name}
                            />
                            <span
                              className="remove_img"
                              onClick={() => removeFile(index, "eventsPhoto", image)}
                            >
                              <img src="/delete1.svg" alt="delete" />
                            </span>
                          </div>
                        </div>
                      ))}
                  </Slider>
                )}
              </div>
            </div>
            {errors.eventsPhoto && (
              <Typography color="error">{errors.eventsPhoto}</Typography>
            )}

            <div style={errors?.eventsPhoto ? { marginTop: "1rem" } : {}}>
              Video Thumbnail
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={false}
                fieldName="videoThumbnail"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
                MAX_FILES={1}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                {formData?.videoThumbnail && (
                  <Slider {...modalSettings}>
                    <div
                      className="img-block bg-gray"
                      key={formData?.videoThumbnail[0]?.url}
                    >
                      <img
                        className="img-fluid2"
                        src={formData?.videoThumbnail[0]?.url}
                        alt={formData?.videoThumbnail[0]?.name}
                      />
                      <img
                        src="/delete1.svg"
                        alt="delete"
                        onClick={() => removeSingleFile("videoThumbnail")}
                        className="remove_img"
                      />
                    </div>
                  </Slider>
                )}
              </div>
            </div>
            {errors.videoThumbnail && (
              <Typography color="error">{errors.videoThumbnail}</Typography>
            )}

            <div style={errors?.videoThumbnail ? { marginTop: "1rem" } : {}}>
              Temple Videos
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="templeVideo"
                fileType="video"
                uploadType="video/mp4"
                setErrors={setErrors}
                MAX_FILES={2}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                {formData?.templeVideo?.length > 0 && (
                  <Slider {...modalSettings}>
                    {
                      formData?.templeVideo?.map((video, index) => (
                        <div key={video?.url} className="col-md-2">
                          <div className="img-block bg-gray">
                            <img
                              className="img-fluid2"
                              src={formData?.videoThumbnail[0]?.url}
                              alt={formData?.videoThumbnail[0]?.name}
                            />
                            <span
                              className="remove_img"
                              onClick={() => removeFile(index, "templeVideo", video)}
                            >
                              <img src="/delete1.svg" alt="delete" />
                            </span>
                          </div>
                        </div>
                      ))
                    }
                  </Slider>
                )}
              </div>
            </div>
            {errors.templeVideo && (
              <Typography color="error">{errors.templeVideo}</Typography>
            )}

            <div style={errors?.templeVideo ? { marginTop: "1rem" } : {}}>
              Special Ceremonies
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="ceremonies"
                fileType="video"
                uploadType="video/mp4"
                setErrors={setErrors}
                MAX_FILES={2}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                {formData?.ceremonies?.length > 0 && (
                  <Slider {...modalSettings}>
                    {
                      formData?.ceremonies?.map((video, index) => (
                        <div key={video?.url} className="col-md-2">
                          <div className="img-block bg-gray">
                            <img
                              className="img-fluid2"
                              src={formData?.videoThumbnail[0]?.url}
                              alt={formData?.videoThumbnail[0]?.name}
                            />
                            <span
                              className="remove_img"
                              onClick={() => removeFile(index, "ceremonies", video)}
                            >
                              <img src="/delete1.svg" alt="delete" />
                            </span>
                          </div>
                        </div>
                      ))
                    }
                  </Slider>
                )}
              </div>
            </div>
            {errors.ceremonies && (
              <Typography color="error">{errors.ceremonies}</Typography>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
              gap: "12px",
            }}
          >
            <AddButton
              name="Cancel"
              type="submit"
              color="var(--secondary)"
              onClick={handleClose}
              borderColor="var(--secondary)"
              background="white"
              // disabled={ isSubmitting }
              sx={{
                fontFamily: "var(--font-secondary) !important",
                fontWeight: "400 !important",
                fontSize: ".9rem",
                marginTop: "1rem",
              }}
            />

            <AddButton
              name="Add"
              type="submit"
              width="15%"
              onClick={() => handlePhotosVideosSubmit("accept")}
              background="var(--secondary)"
              // disabled={ isSubmitting }
              sx={{
                fontFamily: "var(--font-secondary) !important",
                fontWeight: "400 !important",
                fontSize: ".9rem",
                marginTop: "1rem",
              }}
            />
          </div>
        </Box>
      </Modal>

      <div>
        {/* photos and videos */}
        <div className="photo_edit">
          <div className="heading">Photos and Videos</div>

          <img
            src="/edit-icon.svg"
            alt="edit"
            className="edit-icon"
            onClick={handleOpen}
          />
        </div>
        <div>
          <div className="onboarding-sub_heading">Photo Gallery</div>

          <div
            className="slider-container"
            style={{ width: "95%", margin: "auto", marginTop: ".5rem" }}
          >
            {templeImageList?.length > 0 && (
              <Slider {...settings}>
                {
                  templeImageList?.map((image, index) => (
                    <div key={index}>
                      <img
                        className="image_and_video"
                        src={image?.filePath}
                        alt={image?.fileName}
                        onClick={() => handleImageClick(image?.filePath)}
                      />
                    </div>
                  ))
                }
              </Slider>
              )}
          </div>
        </div>

        {/* Videos */}
        <div>
          <div
            className="onboarding-sub_heading"
            style={{ marginTop: "0.5rem" }}
          >
            Videos
          </div>
          <div
            className="slider-container"
            style={{ width: "95%", margin: "auto", marginTop: "0.5rem" }}
          >
            {templeVideoList?.length > 0 && (
              <Slider {...videoSettings}>
                { 
                  templeVideoList?.map((video, index) => (
                    <div key={index} className="left-align-slick">
                      <img
                        className="image_and_video"
                        src={mediaData?.videoThumbnail?.filePath}
                        alt={video?.fileName}
                        onClick={() => handleVideoClick(video?.filePath)}
                      />
                    </div>
                  ))
                }
              </Slider>
            )}
          </div>
        </div>
      </div>

      {/* temple desc */}
      <div>
        <div className="photo_edit">
          <div className="heading">Temple Description</div>
          <div>
            {!templeDescriptionEdit ? (
              <img
                src="/edit-icon.svg"
                alt="edit"
                className="edit-icon"
                onClick={() => setTempleDescriptionEdit(true)}
              />
            ) : (
              <img
                src="/close.svg"
                alt="edit"
                className="edit-icon"
                onClick={() => setTempleDescriptionEdit(false)}
              />
            )}
          </div>
        </div>
        <Formik
          initialValues={{
            temple_history: templeData?.templeHistory,
            mythology: templeData?.mythology,
            deity: templeData?.deity,
            miracle: templeData?.miracles,
          }}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ setFieldValue, isSubmitting, errors, touched }) => (
            <Form>
              <MaterialUIFieldAdapter
                name="temple_history"
                type="textarea"
                label="History of the Temple"
                placeholder="Please enter your Daily Question"
                style={{ width: "100%", minWidth: "100%" }}
                rows={5}
                disabled={true}
              />
              <MaterialUIFieldAdapter
                name="mythology"
                type="textarea"
                label="Mythology"
                placeholder="Please enter your Daily Question"
                style={{ width: "100%", minWidth: "100%" }}
                rows={5}
                // disabled={true}
              />
              <MaterialUIFieldAdapter
                name="deity"
                type="textarea"
                label="Significance of the Deity"
                placeholder="Please enter your Daily Question"
                style={{ width: "100%", minWidth: "100%" }}
                rows={5}
                // disabled={true}
              />
              <MaterialUIFieldAdapter
                name="miracle"
                type="textarea"
                label="Miracles"
                placeholder="Please enter your Daily Question"
                style={{ width: "100%", minWidth: "100%" }}
                rows={5}
                // disabled={true}
              />
              {templeDescriptionEdit && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",

                    gap: "12px",
                  }}
                >
                  <AddButton
                    name="Cancel"
                    type="submit"
                    width="20%"
                    padding="18px"
                    color="var(--secondary)"
                    onClick={() => setTempleDescriptionEdit(false)}
                    borderColor="#FFD2B0"
                    background="white"
                    // disabled={ isSubmitting }
                    sx={{
                      fontFamily: "var(--font-secondary) !important",
                      fontWeight: "400 !important",
                      fontSize: ".9rem",
                      marginTop: "1rem",
                    }}
                  />

                  <AddButton
                    name="Add"
                    type="submit"
                    width="20%"
                    padding="18px"
                    // onClick={() => handleSubmit("accept")}
                    background="var(--secondary)"
                    // disabled={ isSubmitting }
                    sx={{
                      fontFamily: "var(--font-secondary) !important",
                      fontWeight: "400 !important",
                      fontSize: ".9rem",
                      marginTop: "1rem",
                    }}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>

      {/* temple details */}
      <div>
        <div className="photo_edit">
          <div className="heading">Temple Details</div>
        </div>

        <Formik
          initialValues={{
            temple_name: templeData?.templeName,
            registered_temple_name: templeData?.registeredTempleName,
            temple_type:
              templeData?.templeType?.charAt(0) +
              templeData?.templeType?.slice(1).toLowerCase(),
            year: templeData?.establishedYear,
            address: templeData?.address,
            city: templeData?.city,
            state: templeData?.state,
            country: templeData?.country,
            pincode: templeData?.pinCode,
            phone_number: templeData?.templePhoneNumber,
            email: templeData?.templeEmail,
            website: templeData?.templeWebsite,
          }}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ setFieldValue, isSubmitting, errors, touched }) => (
            <Form>
              <div
                className="onboarding-sub_heading"
                style={{ margin: "1rem 0" }}
              >
                Basic Temple Details
              </div>
              <div style={{ display: "flex", width: "98%", gap: "1rem" }}>
                <MaterialUIFieldAdapter
                  name="temple_name"
                  type="text"
                  label="Temple Name*"
                  placeholder="Enter Temple Name"
                  disabled
                />
                <div style={{ width: "100%" }}>
                  <MaterialUIFieldAdapter
                    name="registered_temple_name"
                    type="text"
                    label="Registered Temple Name*"
                    placeholder="Enter Registered Temple name"
                    style={{ width: "100%" }}
                    value={templeData?.registeredTempleName}
                    disabled
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="temple_type"
                  type="text"
                  label="Temple Type*"
                  placeholder="Select Temple Type"
                  style={{ width: "100%" }}
                  disabled
                />
                <MaterialUIFieldAdapter
                  name="year"
                  type="text"
                  label="Established Year*"
                  placeholder="Enter established  year of the temple"
                  style={{ width: "100%" }}
                  disabled
                />
              </div>

              <div
                className="onboarding-sub_heading"
                style={{ margin: "1rem 0" }}
              >
                Address Details
              </div>
              <div style={{ display: "flex", width: "48%", gap: "1rem" }}>
                <MaterialUIFieldAdapter
                  name="address"
                  type="text"
                  label="Address**"
                  placeholder="Enter address"
                  disabled
                />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="city"
                  type="text"
                  label="Select your city*"
                  placeholder="Select your city"
                  style={{ width: "100%" }}
                  disabled
                />
                <MaterialUIFieldAdapter
                  name="state"
                  type="text"
                  label="Select your state*"
                  placeholder="Select your state"
                  style={{ width: "100%" }}
                  disabled
                />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="country"
                  type="text"
                  label="Select your country*"
                  placeholder="Select your country"
                  style={{ width: "100%" }}
                  disabled
                />
                <MaterialUIFieldAdapter
                  name="pincode"
                  type="text"
                  label="Pincode*"
                  placeholder="Enter your pincode"
                  style={{ width: "100%" }}
                  disabled
                />
              </div>

              <div
                className="onboarding-sub_heading"
                style={{ margin: "1rem 0" }}
              >
                Contact Information of Temple
              </div>
              <div style={{ display: "flex", width: "98%", gap: "1rem" }}>
                <MaterialUIFieldAdapter
                  name="phone_number"
                  type="text"
                  label="Phone number of the Temple"
                  placeholder="Enter Phone Number"
                  disabled
                />
                <div style={{ width: "100%" }}>
                  <MaterialUIFieldAdapter
                    name="email"
                    type="text"
                    label="Email ID of the Temple"
                    placeholder="Enter Email ID"
                    style={{ width: "100%" }}
                    disabled
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="website"
                  type="text"
                  label="Official Temple Website"
                  placeholder="Enter temple’s website"
                  style={{ width: "49%" }}
                  disabled
                />
              </div>
              {/* <AddButton
                name={actionType === "Add" ? "Upload" : "Save"}
                type="submit"
              /> */}
            </Form>
          )}
        </Formik>
      </div>

      <Box>
        <div className="heading">Temple Verification & Documentation</div>
        <Box style={{ marginTop: "1rem" }}>
          <div className="onboarding-sub_heading">Identity Verification</div>
          <Grid2 container spacing={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Pan Card*
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                // value={formData.panCard}
                // onChange={handleChange("panCard")}
                disabled
                placeholder="Browse from DigiLocker"
                style={{ marginBottom: "1rem" }}
                className="onboarding-custom-textfield"
                InputProps={{
                  style: { color: "#8d8e9f" },
                }}
              />
            </Grid2>
          </Grid2>
          <Typography className="OR-Typography">OR</Typography>
          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <div style={{ width: "100%" }}>
                <div className="upload_box">
                  <img src="/jpg.png" alt="jpg" className="upload_box_pdf" />
                  <div className="upload_box_content">
                    <div>{templeData?.panCard?.fileName}</div>
                    <div className="upload_box_content_size">
                      {formatFileSize(templeData?.panCard?.fileSizeInBytes)}
                    </div>
                  </div>
                </div>
              </div>
            </Grid2>
          </Grid2>

          <div
            className="onboarding-sub_heading"
            style={{ paddingTop: "1rem" }}
          >
            Temple Registration
          </div>
          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Temple / Trust Registration Certificate*
              </Typography>
              <div style={{ width: "100%" }}>
                <div className="upload_box">
                  <img src="/jpg.png" alt="jpg" className="upload_box_pdf" />
                  <div className="upload_box_content">
                    <div>
                      {templeData?.templeRegistrationCertificate?.fileName}
                    </div>
                    <div className="upload_box_content_size">
                      {formatFileSize(
                        templeData?.templeRegistrationCertificate
                          ?.fileSizeInBytes,
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Grid2>
          </Grid2>
        </Box>
      </Box>

      {/* payment details */}
      <div style={{ paddingBottom: "2rem" }}>
        <div className="photo_edit">
          <div className="heading">Payment Details</div>
        </div>
        <Formik
          initialValues={{
            account_holder_name: paymentData?.accountHolderName,
            bank_name: paymentData?.bankName,
            account_number: paymentData?.accountNumber,
            ifsc_code: paymentData?.ifscCode,
          }}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ setFieldValue, isSubmitting, errors, touched }) => (
            <Form>
              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="account_holder_name"
                  type="text"
                  label="Account Holder Name*"
                  placeholder="Enter Account Holder Name"
                  style={{ width: "100%" }}
                  disabled
                />
                <MaterialUIFieldAdapter
                  name="bank_name"
                  type="text"
                  label="Bank Name"
                  placeholder="Enter Bank Name"
                  style={{ width: "100%" }}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="account_number"
                  type="text"
                  label="Account Number*"
                  placeholder="Enter Account Number"
                  style={{ width: "100%" }}
                  disabled
                />
                <MaterialUIFieldAdapter
                  name="ifsc_code"
                  type="text"
                  label="IFSC Code*"
                  placeholder="Enter IFSC Code"
                  style={{ width: "100%" }}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <div style={{ width: "49%" }}>
                  <div>Upload Bank Statement*</div>
                  <div className="upload_box">
                    <img src="/pdf.svg" alt="pdf" className="upload_box_pdf" />
                    <div className="upload_box_content">
                      <div>{paymentData?.passbookPhoto?.fileName}</div>
                      <div className="upload_box_content_size">
                        {formatFileSize(
                          paymentData?.passbookPhoto?.fileSizeInBytes,
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <AddButton
                name={actionType === "Add" ? "Upload" : "Save"}
                type="submit"
              /> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TempleDetailsSettings;
