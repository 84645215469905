import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { updateLogin } from "../features/slices/authSlice";

const ProtectedRoute = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    let refreshToken = localStorage.getItem("refreshToken");
    let expiryTime = localStorage.getItem("expireAt");
    if (expiryTime) {
      if (Number(expiryTime) < Date.now()) {
        let payload = {
          token: refreshToken,
        };
        dispatch(updateLogin({ payload, navigate }));
      }
    }
  }, [location.pathname]);

  return token ? <Outlet /> : <Navigate to="/welcome" />;
};

export default ProtectedRoute;
