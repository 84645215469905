function getToken() {
  let token = localStorage.getItem("token");

  return token;
}

export const config1 = () => {
  return {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
};

// for sending query parameters
export const config2 = () => {
  return {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
};

// for sending data in json format
export const config3 = () => {
  return {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  };
};

// for sending form data
export const config4 = () => {
  return {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "multipart/form-data",
    },
  };
};

export const config5 = () => {
  return {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      responseType: "blob",
    },
  };
};
