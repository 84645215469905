import { Box, Typography } from "@mui/material";
import { useState } from "react";
import "../../styles/HelpAndSupportCards.css";

const HelpAndSupportCard = ({ image, label, color, onClick, subText,iconImage  }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      className={subText ? "help-stats-container-2" : "help-stats-container"}
      onClick={() => onClick && onClick(label.value)}
    >
      <Box>
        {image && !subText ? (
          <div
            style={{
              WebkitMaskImage: `url(${image})`,
              WebkitMaskRepeat: "no-repeat",
              WebkitMaskSize: "100% 100%",
              backgroundColor: label?.status === true ? "#FF6F01" : "#0D0C11",
              width: 32,
              height: 32,
            }}
          ></div>
        ) : (
          <img src={image} alt="images" className="stat_card_img" />
        )}
      </Box>
      <Typography
        className="help-state-label"
        sx={{
          color: label?.status ? "#FF6F01 !important" : "#0D0C11 !important",
        }}
      >
        {label?.value}
      </Typography>
      {subText && (
        <Typography className="help-state-label-subtext">{subText}</Typography>
      )}
      {iconImage && (
        <Box
          component="img"
          src={iconImage}
          alt="end-icon"
          className="cardIconImage"
        />
      )}
    </Box>
  );
};

export default HelpAndSupportCard;
