import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Dialog, DialogContent, Modal, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AddButton } from "../../components/Addbutton";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import MultipleImageUpload from "../../components/MultipleImageUpload";
import "../../styles/multiple-image-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { formatFileSize } from "../../helpers/convertFileSize";
import { CustomLoader } from "../../components/CustomLoader";
import {
  toggleStatus,
  fetchExperienceData,
  fetchDocumentData,
  fetchProfileData,
  fetchMediaData,
  updateExperience
} from "../../features/slices/purohitSettingsSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const PurohitTempleSettings = () => {
  const initialFormState = {};
  const initialErrorState = {};

  const dispatch = useDispatch();
  const {
    fetchLoading,
    editExperienceDataLoading,
    experienceData,
    documentData,
    profileData,
    mediaData,
    fetchMediaDataStatus,
    editExperienceStatus
  } = useSelector((state) => state.purohitSettings);
  const [open, setOpen] = React.useState(false);
  const [multipleFile, setMultipleFile] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [experienceLineageEdit, setExperienceLineageEdit] = useState(false);
  const [currentImg, setCurrentImage] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [show, setShow] = useState(false);
  const [pujaImageList, setPujaImageList] = useState([]);
  const [pujaVideoList, setPujaVideoList] = useState([]);

  const specializationList = [
    { value: "RIGVEDA", label: "RigVeda" },
    { value: "SAMAVEDA", label: "Samaveda" },
    { value: "YAJURVEDA", label: "Yajurveda" },
    { value: "ARTHVAVEDA", label: "Atharvaveda" }
  ];

  const lineageList = [
    { value: "VEDIC", label: "Vedic" },
    { value: "SHAIVA", label: "Shaiva" },
    { value: "VAISHNAVISM", label: "Vaishnavism" },
    { value: "SMARTHA", label: "Smartha" },
    { value: "SHAKTI", label: "Shakti" }
  ];

  const handleImageClick = (url) => {
    setCurrentImage(url);
    setOpenImageDialog(true);
  };

  const handleVideoClick = (url) => {
    setCurrentVideo(url)
    setOpenVideoDialog(true);
  };

  const handleProfileImageUpload = () => {};

  const handleProfileImageDelete = () => {};

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const removeSingleFile = (fieldName) => {
    if(fieldName === "templeThumbnail" || fieldName === "videoThumbnail") {
      setFormData((prev) => ({
        ...prev,
        [fieldName]: "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [fieldName]: [],
      }));
    }
  }

  const removeFile = (index, fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: prev[fieldName].filter((_, i) => i !== index),
    }));
  };

  const mapLocalImage = (images) => {
    if (images.length > 0) {
      let NewArr = images.map((item) => {
        return item?.file;
      });

      return NewArr;
    }
  };

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          file: newFile,
        };
      });

      return newArr;
    }
    return [];
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.6,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: (
      <ArrowBackIosIcon
        sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
      />
    ),
    nextArrow: (
      <ArrowForwardIosIcon
        sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: multipleFile?.length || 3,
          slidesToScroll: multipleFile?.length || 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var modalSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: (
      <ArrowBackIosIcon
        sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
      />
    ),
    nextArrow: (
      <ArrowForwardIosIcon
        sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePurohitExperienceDetailsSubmit = useCallback((values) => {
    let experienceObject = {
      experience: values?.years_of_experience,
      lineAgeORTradition: values?.lineage,
      religiousSpecialization: values?.specialization,
      aboutYourself: values?.description
    }

    dispatch(updateExperience(experienceObject));
  }, [dispatch]);

  const getPurohitAllDetails = useCallback(() => {
    dispatch(fetchDocumentData());
    dispatch(fetchExperienceData());
    dispatch(fetchProfileData());
    dispatch(fetchMediaData());
  }, [dispatch]);

  useEffect(() => {
    getPurohitAllDetails();
  }, []);

  useEffect(() => {
    if(fetchMediaDataStatus) {
      setPujaImageList(mediaData?.pujaPictures);
      setPujaVideoList(mediaData?.pujaVideos);
    }
  }, [fetchMediaDataStatus]);

  useEffect(() => {
    if(editExperienceStatus) {
      setExperienceLineageEdit(false);
      getPurohitAllDetails();
      dispatch(toggleStatus());
    }
  }, [editExperienceStatus]);

  return (
    <div className="temple_details_container">
      <CustomLoader
        loading={fetchLoading}
        message="Please wait, Fetching Purohit's Experience Details..."
      />

      <CustomLoader
        loading={editExperienceDataLoading}
        message="Please wait, Updating Purohit's Experience Details..."
      />

      <Dialog
        maxWidth="md"
        open={openImageDialog}
        onClose={() => {
          setCurrentImage(null);
          setOpenImageDialog(false);
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src="/close-icon.svg"
            alt="close-icon"
            className="close-icon"
            onClick={() => setOpenImageDialog(false)}
          />
        </div>
        <DialogContent>
          <img
            src={currentImg}
            alt="Selected"
            style={{ width: "100%", height: "50vh" }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openVideoDialog}
        onClose={() => {
          setCurrentVideo(null);
          setOpenVideoDialog(false);
        }}
        maxWidth="md"
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src="/close-icon.svg"
            alt="close-icon"
            className="close-icon"
            onClick={() => setOpenVideoDialog(false)}
          />
        </div>
        <DialogContent>
          <video
            width="100%"
            controls
            style={{ width: "100%", height: "50vh" }}
            autoPlay
          >
            <source src={currentVideo} type="video/mp4" />
          </video>
        </DialogContent>
      </Dialog>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CustomLoader
            // loading={editMediaLoading}
            message="Please wait, Uploading Temple Media"
          />

          <div className="photo_edit_title">Photos and Videos</div>
          <div className="photo_and_video_container">
            <div>Temple Thumbnail Photo</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={false}
                fieldName="templeThumbnail"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
              />

              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                <Slider {...modalSettings}>
                  {formData?.templeThumbnail &&
                    (
                      <div 
                        className="img-block bg-gray" 
                        key={formData?.templeThumbnail[0]?.url}
                      >
                        <img 
                          className="img-fluid2" 
                          src={formData?.templeThumbnail[0]?.url} 
                          alt={formData?.templeThumbnail[0]?.name} 
                        />
                        <img 
                          src="/delete1.svg" 
                          alt="delete" 
                          onClick={() => removeSingleFile("templeThumbnail")} 
                          className="remove_img"
                        />
                      </div>
                    )
                  }
                </Slider>
              </div>
            </div>
            {errors.templeThumbnail && (
              <Typography color="error">{errors.templeThumbnail}</Typography>
            )}

            <div style={errors?.templeThumbnail ? {marginTop: '1rem'} : {}}>Temple Deity Photo</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="deityPhoto"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                <Slider {...modalSettings}>
                  {formData?.deityPhoto?.length !== 0 &&
                    formData?.deityPhoto?.map((image, index) => (
                      <div 
                        key={image?.url} 
                        className="col-md-2"
                      >
                        <div className="img-block bg-gray">
                          <img 
                            className="img-fluid2" 
                            src={image?.url} 
                            alt={image?.name} 
                          />
                          <span
                            className="remove_img"
                            onClick={() => removeFile(index, "deityPhoto")}
                          >
                            <img src="/delete1.svg" alt="delete" />
                          </span>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
            {errors.deityPhoto && (
              <Typography color="error">{errors.deityPhoto}</Typography>
            )}

            <div style={errors?.deityPhoto ? {marginTop: '1rem'} : {}}>Temple Interior Photo</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="interiorPhoto"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                <Slider {...modalSettings}>
                  {formData?.interiorPhoto?.length !== 0 &&
                    formData?.interiorPhoto?.map((image, index) => (
                      <div 
                        key={image?.url} 
                        className="col-md-2"
                      >
                        <div className="img-block bg-gray">
                          <img 
                            className="img-fluid2" 
                            src={image?.url} 
                            alt={image?.name} 
                          />
                          <span
                            className="remove_img"
                            onClick={() => removeFile(index, "interiorPhoto")}
                          >
                            <img src="/delete1.svg" alt="delete" />
                          </span>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
            {errors.interiorPhoto && (
              <Typography color="error">{errors.interiorPhoto}</Typography>
            )}

            <div style={errors?.interiorPhoto ? {marginTop: '1rem'} : {}}>Temple Exterior Photo</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="exteriorPhoto"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                <Slider {...modalSettings}>
                  {formData?.exteriorPhoto?.length !== 0 &&
                    formData?.exteriorPhoto?.map((image, index) => (
                      <div key={image?.url} className="col-md-2">
                        <div className="img-block bg-gray">
                          <img className="img-fluid2" src={image?.url} alt={image?.name} />
                          <span
                            className="remove_img"
                            onClick={() => removeFile(index, "exteriorPhoto")}
                          >
                            <img src="/delete1.svg" alt="delete" />
                          </span>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
            {errors.exteriorPhoto && (
              <Typography color="error">{errors.exteriorPhoto}</Typography>
            )}

            <div style={errors?.exteriorPhoto ? {marginTop: '1rem'} : {}}>Special Event's Photo</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="eventsPhoto"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                <Slider {...modalSettings}>
                  {formData?.eventsPhoto?.length !== 0 &&
                    formData?.eventsPhoto?.map((image, index) => (
                      <div key={image?.url} className="col-md-2">
                        <div className="img-block bg-gray">
                          <img className="img-fluid2" src={image?.url} alt={image?.name} />
                          <span
                            className="remove_img"
                            onClick={() => removeFile(index, "eventsPhoto")}
                          >
                            <img src="/delete1.svg" alt="delete" />
                          </span>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
            {errors.eventsPhoto && (
              <Typography color="error">{errors.eventsPhoto}</Typography>
            )}

            <div style={errors?.eventsPhoto ? {marginTop: '1rem'} : {}}>Video Thumbnail</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={false}
                fieldName="videoThumbnail"
                fileType="image"
                uploadType="image/*"
                setErrors={setErrors}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                <Slider {...modalSettings}>
                  {formData?.videoThumbnail &&
                    (
                      <div 
                        className="img-block bg-gray" 
                        key={formData?.videoThumbnail[0]?.url}
                      >
                          <img 
                            className="img-fluid2" 
                            src={formData?.videoThumbnail[0]?.url} 
                            alt={formData?.videoThumbnail[0]?.name} 
                          />
                          <img 
                            src="/delete1.svg" 
                            alt="delete" 
                            onClick={() => removeSingleFile("videoThumbnail")} 
                            className="remove_img"
                          />
                      </div>
                    )
                  }
                </Slider>
              </div>
            </div>
            {errors.videoThumbnail && (
              <Typography color="error">{errors.videoThumbnail}</Typography>
            )}

            <div style={errors?.videoThumbnail ? {marginTop: '1rem'} : {}}>Temple Videos</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="templeVideo"
                fileType="video"
                uploadType="video/mp4"
                setErrors={setErrors}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                <Slider {...modalSettings}>
                  {formData?.templeVideo?.length !== 0 &&
                    formData?.templeVideo?.map((video, index) => (
                      <div 
                        key={video?.url} 
                        className="col-md-2"
                      >
                        <div className="img-block bg-gray">
                          <img 
                            className="img-fluid2" 
                            src={formData?.videoThumbnail[0]?.url} 
                            alt={formData?.videoThumbnail[0]?.name}
                          />
                          <span
                            className="remove_img"
                            onClick={() => removeFile(index, "templeVideo")}
                          >
                            <img src="/delete1.svg" alt="delete" />
                          </span>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
            {errors.templeVideo && (
              <Typography color="error">{errors.templeVideo}</Typography>
            )}

            <div style={errors?.templeVideo ? {marginTop: '1rem'} : {}}>Special Ceremonies</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MultipleImageUpload
                multipleFile={formData}
                setMultipleFile={setFormData}
                isMultiple={true}
                fieldName="ceremonies"
                fileType="video"
                uploadType="video/mp4"
                setErrors={setErrors}
              />
              <div
                className="slider-container"
                style={{ margin: "auto", marginTop: "1rem", width: "30rem" }}
              >
                <Slider {...modalSettings}>
                  {formData?.ceremonies?.length !== 0 &&
                    formData?.ceremonies?.map((video, index) => (
                      <div 
                        key={video?.url} 
                        className="col-md-2"
                      >
                        <div className="img-block bg-gray">
                          <img 
                            className="img-fluid2" 
                            src={formData?.videoThumbnail[0]?.url} 
                            alt={formData?.videoThumbnail[0]?.name} 
                          />
                          <span
                            className="remove_img"
                            onClick={() => removeFile(index, "ceremonies")}
                          >
                            <img src="/delete1.svg" alt="delete" />
                          </span>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
            {errors.ceremonies && (
              <Typography color="error">{errors.ceremonies}</Typography>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
              gap: "12px",
            }}
          >
            <AddButton
              name="Cancel"
              type="submit"
              width="20%"
              padding="18px"
              color="var(--secondary)"
              onClick={handleClose}
              borderColor="#FFD2B0"
              background="white"
              // disabled={ isSubmitting }
              sx={{
                fontFamily: "var(--font-secondary) !important",
                fontWeight: "400 !important",
                fontSize: ".9rem",
                marginTop: "1rem",
              }}
            />

            <AddButton
              name="Add"
              type="submit"
              width="20%"
              padding="18px"
              // onClick={() => handlePhotosVideosSubmit("accept")}
              background="var(--secondary)"
              // disabled={ isSubmitting }
              sx={{
                fontFamily: "var(--font-secondary) !important",
                fontWeight: "400 !important",
                fontSize: ".9rem",
                marginTop: "1rem",
              }}
            />
          </div>
        </Box>
      </Modal>

      <div className="photo_edit_title">Profile</div>
      <div className="account_profile_pic_box">
        <img
            className="user_profile_image"
            src={mediaData ? mediaData?.profilePicture?.filePath : "/greet.svg"}
            alt={mediaData ? mediaData?.profilePicture?.fileName : "profile-icon-image"}
        />

        <AddButton
            name="Upload New Picture"
            type="submit"
            background="#ffffff"
            color="var(--secondary)"
            borderColor="var(--secondary)"
            onClick={handleProfileImageUpload}
        />

        <AddButton 
            name="Delete" 
            onClick={handleProfileImageDelete}
            background="var(--secondary)"
        />
      </div>

      {/* temple details */}
      <div>
        <Formik
          initialValues={{
            name: profileData?.purohitName,
            phone_number: mediaData?.phoneNumber,
            address: profileData?.address,
            pincode: profileData?.pinCode,
            country: profileData?.country,
            state: profileData?.state,
            city: profileData?.state,
            email: mediaData?.email,
            languages: mediaData?.language
          }}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ setFieldValue, isSubmitting, errors, touched }) => (
            <Form>
              <div style={{ display: "flex", width: "98%", gap: "1rem", marginTop: '1rem' }}>
                <MaterialUIFieldAdapter
                  name="name"
                  type="text"
                  label="Name*"
                  placeholder="Enter Name"
                  disabled
                />
                <div style={{ width: "100%" }}>
                  <MaterialUIFieldAdapter
                    name="phone_number"
                    type="text"
                    label="Phone No.*"
                    placeholder="Enter Phone Number"
                    disabled
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="address"
                  type="text"
                  label="Address**"
                  placeholder="Enter address"
                  disabled
                />

                <MaterialUIFieldAdapter
                  name="pincode"
                  type="text"
                  label="Pincode*"
                  placeholder="Enter your pincode"
                  style={{ width: "100%" }}
                  disabled
                />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="country"
                  type="text"
                  label="Country*"
                  placeholder="Select your country"
                  style={{ width: "100%" }}
                  disabled
                />

                <MaterialUIFieldAdapter
                  name="state"
                  type="text"
                  label="State*"
                  placeholder="Select your state"
                  style={{ width: "100%" }}
                  disabled
                />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="city"
                  type="text"
                  label="City*"
                  placeholder="Select your city"
                  style={{ width: "100%" }}
                  disabled
                />

                  <MaterialUIFieldAdapter
                    name="email"
                    type="text"
                    label="Email ID*"
                    placeholder="Enter Email ID"
                    style={{ width: "100%" }}
                    disabled
                  />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="languages"
                  type="text"
                  label="Languages known*"
                  placeholder="Enter Email ID"
                  style={{ width: "50%" }}
                  disabled
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div>
        {/* photos and videos */}
        <div className="photo_edit">
          <div className="photo_edit_title">Photos and Videos</div>
          <div>
            <img
              src="/edit-icon.svg"
              alt="edit"
              className="edit-icon"
              onClick={handleOpen}
            />
          </div>
        </div>
        <div>
          <div className="photo_gallery_title">Photos of you performing puja</div>
          <div
            className="slider-container"
            style={{ width: "95%", margin: "auto", marginTop: ".5rem" }}
          >
            <Slider {...settings}>
              {pujaImageList?.length !== 0 &&
                pujaImageList?.map((image, index) => (
                  <div key={index}>
                    <img
                      height="110"
                      width="140"
                      src={image?.filePath}
                      alt={image?.fileName}
                      onClick={() => handleImageClick(image?.filePath)}
                    />
                  </div>
                ))}
            </Slider>
          </div>
        </div>

        {/* Videos */}
        <div>
          <div className="photo_gallery_title" style={{ marginTop: "1rem" }}>
            Videos of you performing puja
          </div>
          <div
            className="slider-container"
            style={{ width: "95%", margin: "auto", marginTop: ".5rem" }}
          >
            <Slider {...settings}>
              {pujaVideoList?.length !== 0 &&
                pujaVideoList?.map((video, index) => (
                  <div key={index}>

                    <img
                      height="100"
                      src={pujaImageList[0].filePath} 
                      alt={video?.fileName}
                      onClick={() => handleVideoClick(video?.filePath)} 
                    />
                  </div>
                ))
              }
            </Slider>
          </div>
        </div>
      </div>

      {/* documentation details */}
      <div style={{ marginTop: '1rem' }}>
        <div className="photo_edit">
          <div className="photo_edit_title">Purohit Documentation</div>
        </div>

        <div style={{ marginTop: '1.5rem' }}>
          <div className="photo_gallery_title">Identity Verification</div>

          <div>
            <Formik
              initialValues={{
                account_holder_name: documentData?.accountHolderName,
                bank_name: documentData?.bankName,
                account_number: documentData?.accountNumber,
                ifsc_code: documentData?.ifscCode,
                certification_type: documentData?.certificationType
              }}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {}}
            >
              {({ setFieldValue, isSubmitting, errors, touched }) => (
                <Form>
                  <div
                    style={{
                      display: "flex",
                      width: "98%",
                      gap: "20px",
                      margin: "1rem 0 1.5rem"
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div>AADHAR Card*</div>
                      <div className="upload_box">
                        <img src="/pdf.svg" alt="pdf" className="upload_box_pdf" />
                        <div className="upload_box_content">
                          <div>{documentData?.aadhaarCard?.fileName}</div>
                          <div className="upload_box_content_size">{formatFileSize(documentData?.aadhaarCard?.fileSizeInBytes)}</div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div>PAN Card*</div>
                      <div className="upload_box">
                        <img src="/pdf.svg" alt="pdf" className="upload_box_pdf" />
                        <div className="upload_box_content">
                          <div>{documentData?.panCard?.fileName}</div>
                          <div className="upload_box_content_size">{formatFileSize(documentData?.panCard?.fileSizeInBytes)}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="photo_edit">
                    <div className="photo_edit_title">Financial Details</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "98%",
                      gap: "20px",
                      marginTop: '1rem'
                    }}
                  >
                    <MaterialUIFieldAdapter
                      name="account_holder_name"
                      type="text"
                      label="Account Holder Name*"
                      placeholder="Enter Account Holder Name"
                      style={{ width: "100%" }}
                      disabled
                    />
                    <MaterialUIFieldAdapter
                      name="bank_name"
                      type="text"
                      label="Bank Name*"
                      placeholder="Enter Bank Name"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "98%",
                      gap: "20px",
                    }}
                  >
                    <MaterialUIFieldAdapter
                      name="account_number"
                      type="text"
                      label="Account Number*"
                      placeholder="Enter Account Number"
                      style={{ width: "100%" }}
                      disabled
                    />
                    <MaterialUIFieldAdapter
                      name="ifsc_code"
                      type="text"
                      label="IFSC Code*"
                      placeholder="Enter IFSC Code"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "98%",
                      gap: "20px",
                      marginBottom: '1rem'
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <div>Upload Last 6 Months Bank Statement or Cancelled Cheque*</div>
                      <div className="upload_box">
                        <img src="/pdf.svg" alt="pdf" className="upload_box_pdf" />
                        <div className="upload_box_content">
                          <div>{documentData?.bankStatement?.fileName}</div>
                          <div className="upload_box_content_size">{formatFileSize(documentData?.bankStatement?.fileSizeInBytes)}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="photo_edit">
                    <div className="photo_edit_title">Qualification</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "98%",
                      gap: "20px",
                      margin: '1rem 0'
                    }}
                  >
                    <MaterialUIFieldAdapter
                      name="certification_type"
                      type="text"
                      label="Certification Type*"
                      placeholder="Enter Certification"
                      style={{ width: "100%" }}
                      disabled
                    />

                    <div style={{ width: "100%" }}>
                      <div>Upload Document Certificates*</div>
                      <div className="upload_box">
                        <img src="/pdf.svg" alt="pdf" className="upload_box_pdf" />
                        <div className="upload_box_content">
                          <div>{documentData?.documentCertificate?.fileName}</div>
                          <div className="upload_box_content_size">{formatFileSize(documentData?.documentCertificate?.fileSizeInBytes)}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="photo_edit">
                    <div className="photo_edit_title">Affiliation Document</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "98%",
                      gap: "20px",
                      marginTop: '1rem'
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <div className="upload_box">
                        <img src="/pdf.svg" alt="pdf" className="upload_box_pdf" />
                        <div className="upload_box_content">
                          <div>{documentData?.affilationDocument?.fileName}</div>
                          <div className="upload_box_content_size">{formatFileSize(documentData?.affilationDocument?.fileSizeInBytes)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      {/* experience and lineage */}
      <div style={{ marginTop: '1rem', marginBottom: '10rem' }}>
        <div className="photo_edit">
          <div className="photo_edit_title">Experience & Lineage</div>
          <div style={{ marginRight: '20px' }}>
            {!experienceLineageEdit ? (
              <img
                src="/edit-icon.svg"
                alt="edit"
                className="edit-icon"
                onClick={() => setExperienceLineageEdit(true)}
              />
            ) : (
              <img
                src="/close.svg"
                alt="edit"
                className="edit-icon"
                onClick={() => setExperienceLineageEdit(false)}
              />
            )}
          </div>
        </div>

        <Formik
          initialValues={{
            years_of_experience: experienceData?.experience ? experienceData?.experience : "",
            lineage: experienceData?.lineAgeORTradition ? experienceData?.lineAgeORTradition : "",
            specialization: experienceData?.religiousSpecialization ? experienceData?.religiousSpecialization : "",
            description: experienceData?.aboutYourself ? experienceData?.aboutYourself : ""
          }}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            handlePurohitExperienceDetailsSubmit(values);
          }}
        >
          {props => (
            <Form>
              <div style={{ display: "flex", width: "98%", gap: "1rem", marginTop: '1rem' }}>
                <MaterialUIFieldAdapter
                  name="years_of_experience"
                  type="text"
                  label="Experience*"
                  style={{ width: "100%" }}
                  placeholder="Enter Years of Experience"
                />

                <MaterialUIFieldAdapter
                  name="lineage"
                  type="select"
                  show={show}
                  setShow={setShow}
                  pageType="form"
                  label="Lineage or Tradition*"
                  selectDefaultValue={"Select Lineage"}
                  placeholder="Select Lineage"
                  style={{ width: "100%" }}
                  options={lineageList}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <MaterialUIFieldAdapter
                    name="specialization"
                    type="select"
                    show={show}
                    setShow={setShow}
                    pageType="form"
                    label="Religious Specialization*"
                    selectDefaultValue={"Select Specialization"}
                    placeholder="Select Your Specialization"
                    style={{ width: "50%" }}
                    options={specializationList}
                  />
                </div>

                <div style={{ width: "50%" }}>
                  <MaterialUIFieldAdapter
                    name="description"
                    type="textarea"
                    pageType="purohit_temple_settings"
                    label="About Yourself *"
                    placeholder="Give description about yourself"
                    style={{ width: "100%", minWidth: "100%" }}
                    rows={5}
                  />
                </div>
              </div>

              {experienceLineageEdit && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",
                    gap: "12px",
                    marginRight: '20px'
                  }}
                >
                  <AddButton
                    name="Cancel"
                    type="submit"
                    width="20%"
                    padding="18px"
                    color="var(--secondary)"
                    onClick={() => setExperienceLineageEdit(false)}
                    borderColor="#FFD2B0"
                    background="white"
                    // disabled={ isSubmitting }
                    sx={{
                      fontFamily: "var(--font-secondary) !important",
                      fontWeight: "400 !important",
                      fontSize: ".9rem",
                      marginTop: "1rem",
                    }}
                  />

                  <AddButton
                    name="Update"
                    type="submit"
                    width="20%"
                    padding="18px"
                    background={ !props.values.years_of_experience || !props.values.lineage || !props.values.specialization || !props.values.description ? null : "var(--secondary)"}
                    disabled={ !props.values.years_of_experience || !props.values.lineage || !props.values.specialization || !props.values.description }
                    sx={{
                      fontFamily: "var(--font-secondary) !important",
                      fontWeight: "400 !important",
                      fontSize: ".9rem",
                      marginTop: "1rem",
                    }}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PurohitTempleSettings;
