import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import "../styles/confrimationModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { AddButton } from "./Addbutton";

const DeleteModal = ({ open, onClose, onConfirm, content }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      PaperProps={{
        style: {
          backgroundColor: "#141317",
          border: "1px solid #00D48B",
          borderRadius: "20px",
          padding: "5px",
          color: "#fff",
          textAlign: "center",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        <p className="Active_title"> {content} </p>
      </DialogTitle>

      <DialogActions className="button-container">
        <AddButton
          name="Delete"
          onClick={() => onConfirm()}
          sx={{
            backgroundColor: "white !important",
            color: "var(--secondary) !important",
            border: "1px solid var(--secondary) !important",
          }}
        />
        <AddButton
          name="Cancel"
          onClick={() => onClose(false)}
          className="cancel-button"
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
