import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../features/rootReducer";
import rootSaga from "../features/rootSaga";

const sagaMiddleware = createSagaMiddleware();
const token = localStorage.getItem("token");

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
  preloadedState: {
    auth: {
      isAuthenticated: !!token,
      token: token,
      loading: false,
      error: null,
    },
  },
});

sagaMiddleware.run(rootSaga);
