import {
  getRequest,
  postRequestUsingFormData,
  postRequestUsingPayload,
  putRequest,
  postRequest4,
} from "../../api/apiActions";
import {
  createSevaUrls,
  getAllSevaUrls,
  getSevaByIdUrls,
  updateSevaStatusUrls,
  updateSevaUrls,
  deleteSevaImageUrls,
} from "../../api/apiUrls";
import {
  createSeva,
  createSevaFailure,
  createSevaSuccess,
  getSeva,
  getSevaFailure,
  getSevaSuccess,
  getSevaById,
  getSevaByIdSuccess,
  getSevaByIdFailure,
  updateSeva,
  updateSevaFailure,
  updateSevaStatus,
  updateSevaStatusFailure,
  updateSevaStatusSuccess,
  updateSevaSuccess,
  DeleteImageRequest,
  DeleteImageRequestFailure,
  DeleteImageRequestSuccess,
} from "../slices/sevaSlice";
import { call, put, takeEvery, throttle } from "redux-saga/effects";
import { openSnackbar } from "../slices/snackbarSlice";

const deleteCall = ({ etag, id }) =>
  postRequest4(`${deleteSevaImageUrls}?eTag=${etag}&sevaId=${id}`);

const getSevaRequest = (params) => {
  const url = getAllSevaUrls + params.query;

  return getRequest(url);
};

const getSevaByIdRequest = (params) => {
  return getRequest(`${getSevaByIdUrls}/${params}`);
};

const createSevaRequest = (payload) =>
  postRequestUsingFormData(createSevaUrls, payload);

const updateSevaRequest = (payload) =>
  putRequest(`${updateSevaUrls}/${payload.id}/update`, payload.formArr);

const updateSevaStatusRequest = (payload) =>
  putRequest(
    `${updateSevaStatusUrls}/${payload.sevaId}/status?active=${payload.status}`,
  );

function* handleGetSeva(action) {
  try {
    const result = yield call(getSevaRequest, action.payload);
    if (result.status === 200) {
      yield put(getSevaSuccess(result?.data));
    } else {
      yield put(getSevaFailure());
    }
  } catch (error) {
    yield put(getSevaFailure(error));
  }
}

function* handleGetSevaById(action) {
  try {
    const result = yield call(getSevaByIdRequest, action.payload);
    if (result.status === 200) {
      yield put(getSevaByIdSuccess(result?.data));
    } else {
      yield put(getSevaByIdFailure());
    }
  } catch (error) {
    yield put(getSevaByIdFailure(error));
  }
}

function* handleCreateSeva(action) {
  try {
    const result = yield call(createSevaRequest, action.payload);
    if (result.status === 200) {
      yield put(createSevaSuccess(result?.data));
      yield put(
        openSnackbar({
          message: "Seva Added Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(createSevaFailure());
    }
  } catch (error) {
    yield put(createSevaFailure(error));
  }
}

function* handleUpdateSeva(action) {
  try {
    const result = yield call(updateSevaRequest, action.payload);
    if (result.status === 200) {
      yield put(updateSevaSuccess(result?.data));
      yield put(
        openSnackbar({
          message: "Seva Update Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(updateSevaFailure());
    }
  } catch (error) {
    yield put(updateSevaFailure(error));
  }
}

function* handleUpdateSevaStatus(action) {
  try {
    const result = yield call(updateSevaStatusRequest, action.payload);
    if (result.status === 200) {
      yield put(updateSevaStatusSuccess(result?.data));
      yield put(
        openSnackbar({
          message: "Successfully updated status",
          severity: "success",
        }),
      );
    } else {
      yield put(updateSevaStatusFailure());
    }
  } catch (error) {
    yield put(updateSevaStatusFailure(error));
  }
}

function* delteImageRequestCall(action) {
  try {
    const response = yield call(deleteCall, action.payload);
    if (response.status === 200) {
      yield put(DeleteImageRequestSuccess(response?.data));
    } else {
      yield put(DeleteImageRequestFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(DeleteImageRequestFailure(error?.response?.data));
  }
}

export function* sevaSaga() {
  yield takeEvery(getSeva.type, handleGetSeva);
  yield takeEvery(DeleteImageRequest.type, delteImageRequestCall);
  yield takeEvery(getSevaById.type, handleGetSevaById);
  yield takeEvery(createSeva.type, handleCreateSeva);
  yield takeEvery(updateSeva.type, handleUpdateSeva);
  yield throttle(1000, updateSevaStatus.type, handleUpdateSevaStatus);
}

export default sevaSaga;
