import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

const LottieWithoutLibrary = ({ animationData }) => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const animationInstance = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    return () => animationInstance.destroy();
  }, []);

  return <div ref={animationContainer} style={{ width: 300, height: 300 }} />;
};

export default LottieWithoutLibrary;
