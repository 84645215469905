import React, { useEffect } from "react";

import { Typography } from "@mui/material";

const HelpCenter = React.memo(() => {
  return (
    <div>
      <Typography variant="h6" color="white">
        HelpCenter
      </Typography>
    </div>
  );
});

export default HelpCenter;
