import React, { useState } from "react";
import ReactEcharts from "echarts-for-react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import "../styles/dashboard.css";

const OptionList = ["Seva Bookings", "Donations", "Prasadam Orders"];

const SmoothLineChart = () => {
  const [dropdownValue, setDropdownValue] = useState("Seva Bookings");

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };

  const getOption = () => ({
    backgroundColor: "#ffffff",
    color: [
      dropdownValue === "Seva Bookings"
        ? "#8c72f4"
        : dropdownValue === "Donations"
          ? "#FF8C34"
          : "#3CC3DF",
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: [dropdownValue],
      bottom: 10,
      left: "center",
      textStyle: {
        color: "#000",
        fontSize: 12,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "20%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0, 0, 0, 0.7)",
        },
      },
      axisTick: { show: false },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dotted",
          color: "#e0e0e0",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0, 0, 0, 0.7)",
        },
      },
      axisTick: { show: false },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dotted",
          color: "#e0e0e0",
        },
      },
    },
    series: [
      {
        name: dropdownValue,
        type: "bar",
        barWidth: "20%",
        data: [32, 80, 60, 70, 80, 67, 80, 80, 32, 20, 57, 47],
      },
    ],
  });

  return (
    <Box
      sx={{
        width: "100%",
        p: 1,
        border: "1px solid #fff",
        borderRadius: "1rem",
        backgroundColor: "#fff",
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <FormControl
          fullWidth
          variant="outlined"
          className="onboarding-custom-select"
          sx={{ width: "20%" }}
        >
          <Select
            value={dropdownValue}
            onChange={handleDropdownChange}
            sx={{ borderRadius: "1rem" }}
          >
            {OptionList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <ReactEcharts
        option={getOption()}
        className="chartHeight"
        style={{ height: "40vh", width: "100%" }}
      />
    </Box>
  );
};

export default SmoothLineChart;
