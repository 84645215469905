import {
  Box,
  Button,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Close } from "@mui/icons-material";
import { AddButton } from "../../components/Addbutton";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {
  communityPostCreation,
  communityPostUpdation,
} from "../../features/slices/communityPostSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCommunityPostById,
  toggleStatus,
} from "../../features/slices/communityPostSlice";
import { CustomLoader } from "../../components/CustomLoader";

const AddCommunityPost = React.memo(({}) => {
  const initialFormState = {
    eventName: "",
    eventDate: "",
    description: "",
    thumbnailPhoto: [],
  };

  const initialErrorState = {
    eventName: "",
    eventDate: "",
    description: "",
    thumbnailPhoto: [],
  };

  const { actionType } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    specificCommunityPostData,
    createCommunityPostStatus,
    updateCommunityPostStatus,
    loading,
    templeId,
  } = useSelector((state) => state.communityPost);
  const [communityPostDetails, setCommunityPostDetails] =
    useState(initialFormState);
  const fileInputRef = useRef(null);
  const [errors, setErrors] = useState(initialErrorState);
  const [queryParms, setQueryParams] = useSearchParams();
  let communityPostId = queryParms.get("id");

  const handleChange = (field) => (event) => {
    const value = event.target.value;

    setCommunityPostDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // file uploading part starts from here
  const MAX_IMAGE_SIZE = 20 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

  const handleImageUpload = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;
    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (
      selectedFiles?.length + (communityPostDetails[field]?.length || 0) >
      MAX_FILES
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type === "video/mp4";
      const isValidImageFormat = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ].includes(file.type);
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (
        (uploadType === "image/*" && (!isImage || !isValidImageFormat)) ||
        (uploadType === "video/mp4" && !isVideo)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Invalid file type. Please upload ${
            uploadType === "image/*" ? "JPEG, JPG, or PNG images" : "MP4 videos"
          }.`,
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "20MB" : "50MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setCommunityPostDetails((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeUpload = (field, index) => {
    setCommunityPostDetails((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  const populateInputFieldsWithData = () => {
    if (specificCommunityPostData && actionType === "Edit") {
      setCommunityPostDetails({
        ...communityPostDetails,
        eventName: specificCommunityPostData?.eventName,
        eventDate: specificCommunityPostData?.eventDate,
        description: specificCommunityPostData?.eventDescription,
        thumbnailPhoto: specificCommunityPostData?.thumbnailPhoto
          ? mapImages([specificCommunityPostData?.thumbnailPhoto])
          : [],
      });
    }
  };

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };

  const fetchCommunityPostDetails = useCallback(() => {
    dispatch(fetchCommunityPostById(communityPostId));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    const newErrors = { ...initialErrorState };
    let isValid = true;

    if (!communityPostDetails.eventName) {
      newErrors.eventName = "Event Name is required";
      isValid = false;
    }

    if (!communityPostDetails.eventDate) {
      newErrors.eventDate = "Event Date is required";
      isValid = false;
    }

    if (communityPostDetails.thumbnailPhoto.length === 0) {
      newErrors.thumbnailPhoto = "At least one image is required";
      isValid = false;
    }

    if (!communityPostDetails.description) {
      newErrors.description = "Description is required";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      // make the API call
      let communityPostBody = {
        templeId: templeId,
        title: communityPostDetails?.eventName,
        description: communityPostDetails?.description,
        date: communityPostDetails?.eventDate,
      };

      let formData = new FormData();

      if (!communityPostDetails?.thumbnailPhoto[0]?.etag) {
        formData.append("file", communityPostDetails?.thumbnailPhoto[0]?.file);
      }
      formData.append(
        "communityPostRequest",
        JSON.stringify(communityPostBody),
      );

      if (actionType === "Add") {
        dispatch(communityPostCreation(formData));
      } else if (actionType === "Edit") {
        dispatch(communityPostUpdation({ communityPostId, formData }));
      }
    }
  }, [dispatch, communityPostDetails, templeId]);

  useEffect(() => {
    if (actionType === "Edit") {
      fetchCommunityPostDetails();
    }
  }, [actionType]);

  useEffect(() => {
    populateInputFieldsWithData();
  }, [specificCommunityPostData]);

  useEffect(() => {
    if (createCommunityPostStatus || updateCommunityPostStatus) {
      navigate("/community-management/Community_Post");
      dispatch(toggleStatus());
    }
  }, [createCommunityPostStatus, updateCommunityPostStatus]);
  const [isDragOver, setIsDragOver] = useState(false);
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (field, e, maxFiles, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);

    handleImageUpload(field, e, maxFiles, uploadType);
  };

  return (
    <>
      <CustomLoader loading={loading} message="Please wait..." />

      <span className="back-arrow">
        <ArrowBackIcon
          className="arrow_icon"
          onClick={() => navigate(`/community-management`)}
        />

        <Box className="heading">
          {actionType === "Add" ? "Add Community Post" : "Edit Community Post"}
        </Box>
      </span>

      <div className="prasadam-main-container">
        <div className="add-edit-prasadam-form-section">
          <>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 6, md: 6 }}>
                <Typography variant="subtitle1" className="prasadam-form-title">
                  Event Name*
                </Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  value={communityPostDetails?.eventName}
                  onChange={handleChange("eventName")}
                  className="custom-textfield"
                  placeholder="Enter Event name"
                />

                {errors?.eventName && (
                  <Typography color="error">{errors?.eventName}</Typography>
                )}
              </Grid2>

              <Grid2 size={{ xs: 6, sm: 6 }}>
                <Typography variant="subtitle1" className="prasadam-form-title">
                  Event Date
                </Typography>

                <div className="muiNormal-date-picker">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        width: "100%",
                      }}
                      value={dayjs(communityPostDetails?.eventDate)}
                      onChange={(newValue) => {
                        setCommunityPostDetails({
                          ...communityPostDetails,
                          eventDate: dayjs(newValue).format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </LocalizationProvider>
                </div>

                {errors?.eventDate && (
                  <Typography color="error">{errors?.eventDate}</Typography>
                )}
              </Grid2>
            </Grid2>

            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 6, sm: 6 }}>
                <Typography variant="subtitle1" className="prasadam-form-title">
                  Description*
                </Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  value={communityPostDetails.description}
                  onChange={handleChange("description")}
                  placeholder="Description"
                  className={["custom-textfield", "input-fields"]}
                />

                {errors?.description && (
                  <Typography color="error">{errors?.description}</Typography>
                )}
              </Grid2>

              <Grid2 size={{ xs: 6, md: 6 }}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    className="prasadam-form-title"
                  >
                    Thumbnail Photo*
                  </Typography>

                  <Box
                    className="file-upload-box"
                    onDrop={(e) =>
                      handleDrop("thumbnailPhoto", e, 1, "image/*")
                    }
                    onDragOver={handleDragEnter}
                    onDragLeave={handleDragLeave}
                  >
                    {communityPostDetails?.thumbnailPhoto?.length > 0 ? (
                      <Box className="file-preview-image-container">
                        {communityPostDetails?.thumbnailPhoto?.map(
                          (item, index) => (
                            <Box className="song-image-container">
                              <Close
                                className="song-image-close-icon"
                                sx={{ color: "black", cursor: "pointer" }}
                                onClick={() =>
                                  removeUpload("thumbnailPhoto", index)
                                }
                              />

                              <img
                                src={item.url}
                                className="prasadam-image"
                                alt="song_img"
                              />
                            </Box>
                          ),
                        )}
                      </Box>
                    ) : null}

                    <Typography className="file-upload-instructions">
                      <UploadFileIcon className="upload-file-icon" />
                      Drop Your File Here
                    </Typography>

                    <Typography className="file-upload-instructions">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      name="prasadamPackageImage"
                      type="file"
                      className="prasadam-file-input"
                      id="prasadam-package-upload-package"
                      onChange={(e) =>
                        handleImageUpload("thumbnailPhoto", e, 1, "image/*")
                      }
                    />
                    <label htmlFor="prasadam-package-upload-package">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>

                    <Typography className="file-upload-instructions">
                      Max: 20MB, JPG, PNG
                    </Typography>
                  </Box>
                </Box>

                {errors?.thumbnailPhoto && (
                  <Typography color="error">
                    {errors?.thumbnailPhoto}
                  </Typography>
                )}
              </Grid2>
            </Grid2>

            <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
              <AddButton
                name={actionType === "Add" ? "Add" : "Update"}
                onClick={() => {
                  handleSubmit();
                }}
                background="#A9392D"
                color="#FFFFFF"
                width="auto"
                sx={{
                  marginRight: "0.5rem",
                  // border: "1px solid #ACAEC3",
                }}
              />
            </Box>
          </>
        </div>
      </div>
      <></>
    </>
  );
});

export default AddCommunityPost;
