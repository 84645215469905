import { Box, Modal } from "@mui/material";
import React, { useRef, useState } from "react";
import TermsAndCondition from "../TermsAndCondition";

const ViewPolicy = () => {
  const childRef = useRef();
  const [openModal, setOpenModal] = useState(true);
  const [modalType, setModalType] = useState("");
  const [disabledLogin, setDisableLogin] = useState(false);

  const scrollToTop = () => {
    if (childRef.current) {
      childRef.current.scrollTop = 0;
    }
  };

  return (
    <Box>
      <div className="login_background_image"></div>
      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
        }}
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="terms_condition_box" ref={childRef}>
          <TermsAndCondition
            modalType={modalType}
            scrollToTop={scrollToTop}
            setOpenModal={setOpenModal}
            defaultPolicyStatus={true}
            setDisableLogin={setDisableLogin}
          />
        </Box>
      </Modal>
      <div className="terms_and_condition_text welcome_footer_2_mod">
        <div
          className="welcome_footer_2"
          onClick={() => {
            setModalType("terms_and_condition");
            setOpenModal(true);
          }}
        >
          Terms & conditions
        </div>
        <div className="welcome_footer_1">And</div>
        <div
          className="welcome_footer_2"
          onClick={() => {
            setModalType("privacy_policy");
            setOpenModal(true);
          }}
        >
          Privacy Policy
        </div>
      </div>
    </Box>
  );
};

export default ViewPolicy;
