import React, { useState } from "react";

import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "../styles/CustomStaticDatePicker.css";
import dayjs from "dayjs";
import { Tooltip } from "@mui/material";

const CustomStaticDatePicker = ({
  OnDateChange,
  selectedDate,
  nonSelectableDates,
}) => {
  const isNonSelectable = (day) => {
    return nonSelectableDates?.includes(day);
  };

  return (
    <div className="custom-static-datePicker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Tooltip title="hello">
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={dayjs(selectedDate)}
            onChange={(newValue) =>
              OnDateChange(dayjs(newValue).format("YYYY-MM-DD"))
            }
            shouldDisableDate={(day, _value) =>
              isNonSelectable(dayjs(day).format("YYYY-MM-DD"))
            }
          />
        </Tooltip>
      </LocalizationProvider>
    </div>
  );
};

export default CustomStaticDatePicker;
