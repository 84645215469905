import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Popover } from "@mui/material";
import { useState } from "react";
import "../../styles/StatsCard.css";
import { BorderBottom } from "@mui/icons-material";

const StatsCard = ({
  image,
  stat,
  label,
  notification,
  info,
  statStyle,
  statLabelStyle,
  boxStyle,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box className="stats-container" style={boxStyle}>
      <Box>
        <Box className="stats-stat_Card" sx={statStyle}>
          {stat}
          {info && (
            <InfoOutlinedIcon
              className="stats-info-icon"
              fontSize="1rem"
              cursor="pointer"
              onClick={handleClick}
            />
          )}
          {notification && (
            <Box className="stats-notificationCard">{notification} new</Box>
          )}
        </Box>

        <Box className="statsCard-label-info-container">
          <Box className="statsCard-label" sx={statLabelStyle}>
            {label}
          </Box>
        </Box>
      </Box>
      <Box>
        {image && <img src={image} alt="images" className="stat_card_img" />}
      </Box>

      {info && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2 }} className="stats-popover">
            {info}
          </Box>
        </Popover>
      )}
      {/* {onClick && (
        <ArrowForwardIosIcon className="forwrad-arrow" onClick={onClick} />
      )} */}
    </Box>
  );
};

export default StatsCard;
