import { createSlice } from "@reduxjs/toolkit";

const onBoardingSlice = createSlice({
  name: "onBoarding",
  initialState: {
    loading: false,
    onBoardingData: [],
    AdminData: [],
    SevaData: [],
    PrasadamData: [],
    DonationData: [],
    PaymentData: [],
    error: "",
    onBoardingStatusDtata: [],
    onBoardingUploadStatus: false,

    onBoardingActiveStatus: false,
    onBoardingGetAllStatus: false,
    AdminGetAllStatus: false,
    DonationGetAllStatus: false,
    PrasadamGetAllStatus: false,
    PaymentGetAllStatus: false,
    SevaGetAllStatus: false,
    imageDeleteStatus: false,
  },
  reducers: {
    fetchAdminRequest: (state) => {
      state.loading = true;
      state.AdminGetAllStatus = false;
      state.AdminData = [];
      state.error = "";
    },
    fetchAdminSuccess: (state, action) => {
      state.loading = false;
      state.AdminGetAllStatus = true;
      state.AdminData = action.payload;
      state.error = "";
    },
    fetchAdminFailure: (state, action) => {
      state.loading = false;
      state.AdminGetAllStatus = false;
      state.AdminData = [];
      state.error = action.payload;
    },
    fetchSevaRequest: (state) => {
      state.loading = true;
      state.SevaGetAllStatus = false;
      state.SevaData = [];
      state.error = "";
    },
    fetchSevaSuccess: (state, action) => {
      state.loading = false;
      state.SevaGetAllStatus = true;
      state.SevaData = action.payload;
      state.error = "";
    },
    fetchSevaFailure: (state, action) => {
      state.loading = false;
      state.SevaGetAllStatus = false;
      state.SevaData = [];
      state.error = action.payload;
    },
    fetchDonationRequest: (state) => {
      state.loading = true;
      state.DonationGetAllStatus = false;
      state.DonationData = [];
      state.error = "";
    },
    fetchDonationSuccess: (state, action) => {
      state.loading = false;
      state.DonationGetAllStatus = true;
      state.DonationData = action.payload;
      state.error = "";
    },
    fetchDonationFailure: (state, action) => {
      state.loading = false;
      state.DonationGetAllStatus = false;
      state.DonationData = [];
      state.error = action.payload;
    },
    fetchPrasadamRequest: (state) => {
      state.loading = true;
      state.PrasadamGetAllStatus = false;
      state.PrasadamData = [];
      state.error = "";
    },
    fetchPrasadamSuccess: (state, action) => {
      state.loading = false;
      state.PrasadamGetAllStatus = true;
      state.PrasadamData = action.payload;
      state.error = "";
    },
    fetchPrasadamFailure: (state, action) => {
      state.loading = false;
      state.PrasadamGetAllStatus = false;
      state.PrasadamData = [];
      state.error = action.payload;
    },
    fetchPaymentRequest: (state) => {
      state.loading = true;
      state.PaymentGetAllStatus = false;
      state.PaymentData = [];
      state.error = "";
    },
    fetchPaymentSuccess: (state, action) => {
      state.loading = false;
      state.PaymentGetAllStatus = true;
      state.PaymentData = action.payload;
      state.error = "";
    },
    fetchPaymentFailure: (state, action) => {
      state.loading = false;
      state.PaymentGetAllStatus = false;
      state.PaymentData = [];
      state.error = action.payload;
    },
    fetchOnBoardingRequest: (state) => {
      state.loading = true;
      state.onBoardingGetAllStatus = false;
      state.onBoardingData = [];
      state.error = "";
    },
    fetchOnBoardingSuccess: (state, action) => {
      state.loading = false;
      state.onBoardingGetAllStatus = true;
      state.onBoardingData = action.payload;
      state.error = "";
    },
    fetchOnBoardingFailure: (state, action) => {
      state.loading = false;
      state.onBoardingGetAllStatus = false;
      state.onBoardingData = [];
      state.error = action.payload;
    },
    fetchOnBoardingStatusRequest: (state) => {
      state.loading = true;
      state.onBoardingActiveStatus = false;
      state.onBoardingStatusDtata = [];
      state.error = "";
    },
    fetchOnBoardingStatusSuccess: (state, action) => {
      const { currentStatus } = action.payload;
      localStorage.setItem("Onboarding_stage", currentStatus);
      state.loading = false;
      state.onBoardingActiveStatus = true;
      state.onBoardingStatusDtata = action.payload;
      state.error = "";
    },
    fetchOnBoardingStatusFailure: (state, action) => {
      state.loading = false;
      state.onBoardingActiveStatus = false;
      state.onBoardingStatusDtata = [];
      state.error = action.payload;
    },
    uploadTempleRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadTempleSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadTempleFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadDocumentRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadDocumentSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadDocumentFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadDescriptionRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadDescriptionSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadDescriptionFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadPhotosRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadPhotosSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadPhotosFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadAdminRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadAdminSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadAdminFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    updateSevaRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    updateSevaSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    updateSevaFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    updatePrasadamRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    updatePrasadamSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    updatePrasadamFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    updateDonationRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    updateDonationSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    updateDonationFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    updatePaymentRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    updatePaymentSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    updatePaymentFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadSevaRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadSevaSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadSevaFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadPrasadamRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadPrasadamSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadPrasadamFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadDonationRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadDonationSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadDonationFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadPaymentRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadPaymentSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadPaymentFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },

    DeleteImageRequest: (state) => {
      state.imageDeleteStatus = false;
      state.loading = true;
      state.error = "";
    },
    DeleteImageRequestSuccess: (state, action) => {
      state.imageDeleteStatus = true;
      state.loading = false;

      state.error = "";
    },
    DeleteImageRequestFailure: (state, action) => {
      state.imageDeleteStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadPreviewRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadPreviewSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadPreviewFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    uploadOnboardCompleteRequest: (state) => {
      state.onBoardingUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    uploadOnboardCompleteSuccess: (state, action) => {
      state.onBoardingUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    uploadOnboardCompleteFailure: (state, action) => {
      state.onBoardingUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    toggleStatus: (state) => {
      state.onBoardingUploadStatus = false;
    },
  },
});

export const {
  fetchOnBoardingRequest,
  fetchOnBoardingSuccess,
  fetchOnBoardingFailure,

  fetchOnBoardingStatusRequest,
  fetchOnBoardingStatusFailure,
  fetchOnBoardingStatusSuccess,

  uploadTempleRequest,
  uploadTempleSuccess,
  uploadTempleFailure,

  uploadDocumentRequest,
  uploadDocumentSuccess,
  uploadDocumentFailure,
  uploadDescriptionRequest,
  uploadDescriptionSuccess,
  uploadDescriptionFailure,
  uploadPhotosRequest,
  uploadPhotosSuccess,
  uploadPhotosFailure,
  uploadAdminRequest,
  uploadAdminSuccess,
  uploadAdminFailure,
  uploadSevaRequest,
  uploadSevaSuccess,
  uploadSevaFailure,
  uploadPrasadamRequest,
  uploadPrasadamSuccess,
  uploadPrasadamFailure,
  uploadDonationRequest,
  uploadDonationSuccess,
  uploadDonationFailure,
  uploadPaymentRequest,
  uploadPaymentSuccess,
  uploadPaymentFailure,
  updateSevaRequest,
  updateSevaSuccess,
  updateSevaFailure,
  updatePrasadamRequest,
  updatePrasadamSuccess,
  updatePrasadamFailure,
  updateDonationRequest,
  updateDonationSuccess,
  updateDonationFailure,
  updatePaymentRequest,
  updatePaymentSuccess,
  updatePaymentFailure,
  uploadPreviewRequest,
  uploadPreviewSuccess,
  uploadPreviewFailure,
  uploadOnboardCompleteRequest,
  uploadOnboardCompleteSuccess,
  uploadOnboardCompleteFailure,
  fetchAdminRequest,
  fetchAdminSuccess,
  fetchAdminFailure,
  fetchSevaRequest,
  fetchSevaSuccess,
  fetchSevaFailure,
  fetchDonationRequest,
  fetchDonationSuccess,
  fetchDonationFailure,
  fetchPrasadamRequest,
  fetchPrasadamSuccess,
  fetchPrasadamFailure,
  fetchPaymentRequest,
  fetchPaymentSuccess,
  fetchPaymentFailure,
  DeleteImageRequest,
  DeleteImageRequestFailure,
  DeleteImageRequestSuccess,
  toggleStatus,
} = onBoardingSlice.actions;

export default onBoardingSlice.reducer;
