import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchDashboardRequest,
  fetchDashboardSuccess,
  fetchDashboardFailure,
} from "../slices/dashboardSlice";

function* fetchDashboard(action) {
  const { token } = action.payload;
  try {
    const data = yield call(token);
    yield put(fetchDashboardSuccess(data));
  } catch (error) {
    yield put(fetchDashboardFailure(error.message));
  }
}

export function* dashboardSaga() {
  yield takeLatest(fetchDashboardRequest.type, fetchDashboard);
}
