import { createSlice } from "@reduxjs/toolkit";

const pujaSlice = createSlice({
  name: "puja",
  initialState: {
    pujaData: [],
    loading: false,
    error: null,
  },
  reducers: {
    getAllPujaRequest: (state) => {
      state.loading = true;
    },
    getAllPujaSuccess: (state, action) => {
      state.loading = false;
      state.pujaData = action.payload;
    },
    getAllPujaFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPujaOnlineRequest: (state) => {
      state.loading = true;
    },
    getPujaOnlineSuccess: (state) => {
      state.loading = false;
    },
    getPujaOnlineFailure: (state) => {
      state.loading = false;
    },
    getPujaActiveRequest: (state) => {
      state.loading = true;
    },
    getPujaActiveSuccess: (state) => {
      state.loading = false;
    },
    getPujaActiveFailure: (state) => {
      state.loading = false;
    },
  },
});

export const {
  getAllPujaRequest,
  getAllPujaSuccess,
  getAllPujaFailure,
  getPujaOnlineRequest,
  getPujaOnlineSuccess,
  getPujaOnlineFailure,
  getPujaActiveRequest,
  getPujaActiveSuccess,
  getPujaActiveFailure,
} = pujaSlice.actions;

export default pujaSlice.reducer;
