import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import "../../styles/tabs.css";

const Tabs = ({ tabs, onClick, tabStart, setSelectedTabName, style }) => {
  const [selectedTab, setSelectedTab] = useState(tabStart ?? tabs[0]?.value); // Default to the first tab

  const handleMenuButtonClick = (value) => {
    if (setSelectedTabName) {
      setSelectedTabName(value);
    }
    setSelectedTab(value);
  };

  return (
    <>
      <List className="tabs">
        {tabs.map((tab) => (
          <ListItem
            key={tab.value}
            sx={{
              justifyContent: "center",
            }}
            className="tab"
          >
            <ListItemButton
              sx={{
                height: "3rem",
                width: "120px",
                textAlign: "center",
                // borderBottom: tabStart === tab.value && "1px solid #F7F7F7",
                boxShadow:
                  tabStart === tab.value && "0px 4px 12px rgba(0, 0, 0, 0.14)",
                backgroundColor: tabStart === tab.value && "white !important",
                borderRadius: tabStart === tab.value && ".8rem !important",
              }}
              style={style}
              selected={tabStart === tab.value}
              onClick={() => {
                onClick(tab.value);
              }}
            >
              <ListItemText
                primary={tab.label}
                sx={{
                  color: tabStart === tab.value ? "#EC9F4F" : "#8D8E9F",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {tabStart === "Payment_Due" && (
        <Box className="paymment_due_banner">
          <span className="paymment_due_banner_text">₹4950.00 </span>&nbsp; dues
          on 15th Nov, 2024
        </Box>
      )}
    </>
  );
};

export default Tabs;
