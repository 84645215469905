import { Close } from "@mui/icons-material";
import { Box, Button, Grid2, Typography } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import "../../styles/onboardingForm.css";

import { useDispatch } from "react-redux";
import {
  uploadPhotosRequest,
  DeleteImageRequest,
} from "../../features/slices/onBordingSlice";
import "../../styles/ModalDatePicker.css";

const initialFormState = {
  templeThumbnail: [],
  deityPhoto: [],
  interiorPhoto: [],
  exteriorPhoto: [],
  eventsPhoto: [],
  videoThumbnail: [],
  templeVideo: [],
  ceremonies: [],
};

const initialErrorState = {
  templeThumbnail: [],
  deityPhoto: [],
  interiorPhoto: [],
  exteriorPhoto: [],
  eventsPhoto: [],
  videoThumbnail: [],
  templeVideo: [],
  ceremonies: [],
};

const PhotosAndVideos = forwardRef((props, ref) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;
      if (formData.templeThumbnail?.length === 0) {
        newErrors.templeThumbnail = "Thumbnail Image is required";
        isValid = false;
      }
      if (formData.deityPhoto?.length === 0) {
        newErrors.deityPhoto = "Deity Image is required";
        isValid = false;
      }
      if (formData.interiorPhoto?.length === 0) {
        newErrors.interiorPhoto = "Interior Image is required";
        isValid = false;
      }
      if (formData.exteriorPhoto?.length === 0) {
        newErrors.exteriorPhoto = "Exterior Image is required";
        isValid = false;
      }
      if (formData.eventsPhoto?.length === 0) {
        newErrors.eventsPhoto = "Events Image is required";
        isValid = false;
      }

      setErrors(newErrors);

      if (isValid) {
        let formArr = new FormData();

        const appendFilesAsArray = (key, files) => {
          if (files?.length > 0) {
            files.forEach((file) => {
              formArr.append(key, file);
            });
          }
        };

        if (
          !formData?.templeThumbnail[0]?.etag &&
          formData?.templeThumbnail.length > 0
        ) {
          formArr.append("templeThumbnail", formData?.templeThumbnail[0]?.file);
        }

        if (formData?.deityPhoto.length > 0) {
          const filteredPhotos = formData?.deityPhoto.filter(
            (photo) => !photo.etag,
          );
          appendFilesAsArray("deityPhoto", mapLocalImage(filteredPhotos));
        }
        if (formData?.interiorPhoto.length > 0) {
          const filteredPhotos = formData?.interiorPhoto.filter(
            (photo) => !photo.etag,
          );
          appendFilesAsArray("interiorPhoto", mapLocalImage(filteredPhotos));
        }
        if (formData?.exteriorPhoto.length > 0) {
          const filteredPhotos = formData?.exteriorPhoto.filter(
            (photo) => !photo.etag,
          );
          appendFilesAsArray("exteriorPhoto", mapLocalImage(filteredPhotos));
        }
        if (formData?.eventsPhoto.length > 0) {
          const filteredPhotos = formData?.eventsPhoto.filter(
            (photo) => !photo.etag,
          );
          appendFilesAsArray("eventsPhoto", mapLocalImage(filteredPhotos));
        }
        if (formData?.templeVideo.length > 0) {
          const filteredPhotos = formData?.templeVideo.filter(
            (photo) => !photo.etag,
          );
          appendFilesAsArray("templeVideo", mapLocalImage(filteredPhotos));
        }
        if (formData?.ceremonies.length > 0) {
          const filteredPhotos = formData?.ceremonies.filter(
            (photo) => !photo.etag,
          );
          appendFilesAsArray("ceremonies", mapLocalImage(filteredPhotos));
        }
        if (
          !formData?.videoThumbnail[0]?.etag &&
          formData?.videoThumbnail.length > 0
        ) {
          formArr.append("videoThumbnail", formData?.videoThumbnail[0]?.file);
        }

        dispatch(uploadPhotosRequest(formArr));
      }
    },
  }));

  const mapLocalImage = (images) => {
    if (images?.length > 0) {
      let NewArr = images?.map((item) => {
        return item?.file;
      });

      return NewArr;
    }
  };

  const MAX_IMAGE_SIZE = 20 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

  const handleUpload = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;
    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (selectedFiles?.length + (formData[field]?.length || 0) > MAX_FILES) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type === "video/mp4";
      const isValidImageFormat = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ].includes(file.type);
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (
        (uploadType === "image/*" && (!isImage || !isValidImageFormat)) ||
        (uploadType === "video/mp4" && !isVideo)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Invalid file type. Please upload ${
            uploadType === "image/*" ? "JPEG, JPG, or PNG images" : "MP4 videos"
          }.`,
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "20MB" : "50MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeUpload = (field, index, item) => {
    if (
      item?.etag &&
      (field === "templeVideo" ||
        field === "ceremonies" ||
        field === "eventsPhoto" ||
        field === "exteriorPhoto" ||
        field === "interiorPhoto" ||
        field === "deityPhoto")
    ) {
      dispatch(DeleteImageRequest({ etag: item.etag, field }));
    }
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  const mapImages = (images) => {
    if (Array.isArray(images) && images?.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };

  useEffect(() => {
    let NewData = props?.OnBoardData;
    setFormData({
      ...formData,
      templeThumbnail: NewData?.templeThumbnail
        ? mapImages([NewData.templeThumbnail])
        : [],
      deityPhoto:
        NewData?.deityPhoto?.length > 0 ? mapImages(NewData?.deityPhoto) : [],
      interiorPhoto:
        NewData?.interiorPhoto?.length > 0
          ? mapImages(NewData?.interiorPhoto)
          : [],
      exteriorPhoto:
        NewData?.exteriorPhoto?.length > 0
          ? mapImages(NewData?.exteriorPhoto)
          : [],
      eventsPhoto:
        NewData?.eventsPhoto?.length > 0 ? mapImages(NewData?.eventsPhoto) : [],
      videoThumbnail: NewData?.videoThumbnail
        ? mapImages([NewData.videoThumbnail])
        : [],
      templeVideo:
        NewData?.templeVideo?.length > 0 ? mapImages(NewData?.templeVideo) : [],
      ceremonies:
        NewData?.ceremonies?.length > 0 ? mapImages(NewData?.ceremonies) : [],
    });
  }, [props?.OnBoardData]);

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (field, e, maxFiles, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    handleUpload(field, e, maxFiles, uploadType);
  };

  return (
    <>
      <Box>
        <div className="heading">Photos and Videos</div>
        <Box style={{ marginTop: "1rem" }}>
          <div className="onboarding-sub_heading">Photo Gallery</div>

          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Temple Thumbnail Photo*
              </Typography>

              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("templeThumbnail", e, 1, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.templeThumbnail?.length > 0 ? (
                  <Box className="preview-image-container">
                    {formData?.templeThumbnail?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props?.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("templeThumbnail", index, item)
                            }
                          />
                        )}

                        <img
                          src={item.url}
                          className="song-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props?.previewStatus && (
                  <>
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-image"
                      onChange={(e) =>
                        handleUpload("templeThumbnail", e, 1, "image/*")
                      }
                      multiple={false}
                    />
                    <label htmlFor="thumbnail-upload-image">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>
                    <Typography className="upload-instructions ">
                      Max. 20MB, JPEG
                    </Typography>
                  </>
                )}
              </Box>
              {errors.templeThumbnail && (
                <Typography color="error">{errors.templeThumbnail}</Typography>
              )}
            </Grid2>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Temple Deity Photo*
              </Typography>
              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("deityPhoto", e, 5, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.deityPhoto?.length > 0 ? (
                  <Box className="preview-image-container image_flex">
                    {formData?.deityPhoto?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("deityPhoto", index, item)
                            }
                          />
                        )}

                        <img
                          src={item.url}
                          className="song-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props.previewStatus && (
                  <>
                    {" "}
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-deity"
                      onChange={(e) =>
                        handleUpload("deityPhoto", e, 5, "image/*")
                      }
                      multiple={true}
                    />
                    <label htmlFor="thumbnail-upload-deity">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>
                    <Typography className="upload-instructions ">
                      Max.5 Images, 20MB Each, JPEG/PNG
                    </Typography>
                  </>
                )}
              </Box>
              {errors.deityPhoto && (
                <Typography color="error">{errors.deityPhoto}</Typography>
              )}
            </Grid2>
          </Grid2>
          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Temple Interior Photo*
              </Typography>
              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("interiorPhoto", e, 5, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.interiorPhoto?.length > 0 ? (
                  <Box className="preview-image-container image_flex">
                    {formData?.interiorPhoto?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("interiorPhoto", index, item)
                            }
                          />
                        )}

                        <img
                          src={item.url}
                          className="song-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props.previewStatus && (
                  <>
                    {" "}
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-Interior"
                      onChange={(e) =>
                        handleUpload("interiorPhoto", e, 5, "image/*")
                      }
                      multiple={true}
                    />
                    <label htmlFor="thumbnail-upload-Interior">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>
                    <Typography className="upload-instructions ">
                      Max.5 Images, 20MB Each, JPEG/PNG
                    </Typography>
                  </>
                )}
              </Box>
              {errors.interiorPhoto && (
                <Typography color="error">{errors.interiorPhoto}</Typography>
              )}
            </Grid2>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Temple Exterior Photo*
              </Typography>
              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("exteriorPhoto", e, 5, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.exteriorPhoto?.length > 0 ? (
                  <Box className="preview-image-container image_flex">
                    {formData?.exteriorPhoto?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("exteriorPhoto", index, item)
                            }
                          />
                        )}

                        <img
                          src={item.url}
                          className="song-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props.previewStatus && (
                  <>
                    {" "}
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-ext"
                      onChange={(e) =>
                        handleUpload("exteriorPhoto", e, 5, "image/*")
                      }
                      multiple={true}
                    />
                    <label htmlFor="thumbnail-upload-ext">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>
                    <Typography className="upload-instructions ">
                      Max.5 Images, 20MB Each, JPEG/PNG
                    </Typography>
                  </>
                )}
              </Box>
              {errors.exteriorPhoto && (
                <Typography color="error">{errors.exteriorPhoto}</Typography>
              )}
            </Grid2>
          </Grid2>
          <Grid2 container spacing={2} mt={1} mb={2}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Special Event’s Photo*
              </Typography>
              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("eventsPhoto", e, 5, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.eventsPhoto?.length > 0 ? (
                  <Box className="preview-image-container image_flex">
                    {formData?.eventsPhoto?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("eventsPhoto", index, item)
                            }
                          />
                        )}

                        <img
                          src={item.url}
                          className="song-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props.previewStatus && (
                  <>
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-spl"
                      onChange={(e) =>
                        handleUpload("eventsPhoto", e, 5, "image/*")
                      }
                      multiple={true}
                    />
                    <label htmlFor="thumbnail-upload-spl">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>
                    <Typography className="upload-instructions ">
                      Max.5 Images, 20MB Each, JPEG/PNG
                    </Typography>
                  </>
                )}
              </Box>
              {errors.eventsPhoto && (
                <Typography color="error">{errors.eventsPhoto}</Typography>
              )}
            </Grid2>
          </Grid2>
          <div className="onboarding-sub_heading">Videos</div>
          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Video Thumbnail
              </Typography>
              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("videoThumbnail", e, 1, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.videoThumbnail?.length > 0 ? (
                  <Box className="preview-image-container ">
                    {formData?.videoThumbnail?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("videoThumbnail", index, item)
                            }
                          />
                        )}

                        <img src={item.url} className="song-image" />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props.previewStatus && (
                  <>
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-thumb-vid"
                      onChange={(e) =>
                        handleUpload("videoThumbnail", e, 1, "image/*")
                      }
                      multiple={false}
                    />
                    <label htmlFor="thumbnail-upload-thumb-vid">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>
                    <Typography className="upload-instructions ">
                      Max. 20MB, JPEG
                    </Typography>
                  </>
                )}
              </Box>
              {errors.videoThumbnail && (
                <Typography color="error">{errors.videoThumbnail}</Typography>
              )}
            </Grid2>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Temple Video
              </Typography>
              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("templeVideo", e, 2, "video/mp4")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.templeVideo?.length > 0 ? (
                  <Box className="preview-image-container image_flex">
                    {formData?.templeVideo?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("templeVideo", index, item)
                            }
                          />
                        )}

                        <img
                          src="/mp4Icon.svg"
                          className="song-image"
                          alt="mp4_image_icon"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props.previewStatus && (
                  <>
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="video/mp4"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-temp-vid"
                      onChange={(e) =>
                        handleUpload("templeVideo", e, 2, "video/mp4")
                      }
                      multiple={true}
                    />
                    <label htmlFor="thumbnail-upload-temp-vid">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>

                    <Typography className="upload-instructions ">
                      Max. 2Videos, 50MB Each.MP4
                    </Typography>
                  </>
                )}
              </Box>
              {errors.templeVideo && (
                <Typography color="error">{errors.templeVideo}</Typography>
              )}
            </Grid2>
          </Grid2>
          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Special Ceremonies
              </Typography>
              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("ceremonies", e, 2, "video/mp4")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.ceremonies?.length > 0 ? (
                  <Box className="preview-image-container image_flex">
                    {formData?.ceremonies?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() =>
                              removeUpload("ceremonies", index, item)
                            }
                          />
                        )}

                        <img
                          src="/mp4Icon.svg"
                          className="song-image"
                          alt="mp4_image_icon"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props.previewStatus && (
                  <>
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="video/mp4"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-spl-vid"
                      onChange={(e) =>
                        handleUpload("ceremonies", e, 2, "video/mp4")
                      }
                      multiple={true}
                    />
                    <label htmlFor="thumbnail-upload-spl-vid">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>

                    <Typography className="upload-instructions ">
                      Max. 2Videos, 50MB Each,MP4
                    </Typography>
                  </>
                )}
              </Box>
              {errors.ceremonies && (
                <Typography color="error">{errors.ceremonies}</Typography>
              )}
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </>
  );
});

export default PhotosAndVideos;
