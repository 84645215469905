import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import SnackbarComponent from "./components/Snackbar";
import "./index.css";
import AppManagement from "./pages/AppManagement";
import CommunityManagement from "./pages/CommunityManagement";
import AddCommunityPost from "./pages/CommunityManagement/AddCommunityPost";
import Dashboard from "./pages/Dashboard";
import DonationManagement from "./pages/donation/Donation";
import ForgotPassword from "./pages/ForgotPassword";
import HelpAndSupport from "./pages/HelpAndSupport";
import ChartSupportPage from "./pages/HelpAndSupport/ChatSupportPage";
import ContactUs from "./pages/HelpAndSupport/ContactUs";
import HelpCenter from "./pages/HelpCenter";
import HelpRequest from "./pages/HelpRequest";
import AddNewIssue from "./pages/Issue/AddNewIssue";
import IssueResolved from "./pages/Issue/IssueResolve";
import IssueManagement from "./pages/IssueManagement";
import CreatePasswordPage from "./pages/login/CreatePasswordPage";
import DocumentChecklist from "./pages/login/DocumentChecklist";
import EmailVerified from "./pages/login/EmailVerified";
import ForgotPasswordPage from "./pages/login/ForgotPasswordPage";
import LoginPage from "./pages/login/LoginPage";
import PurohitDetailsLogin from "./pages/login/PurohitDetailsLogin";
import RoleSelect from "./pages/login/RoleSelect";
import Signup from "./pages/login/Signup";
import TempleDetails from "./pages/login/TempleDetails";
import UserContact from "./pages/login/UserContact";
import VerifyEmail from "./pages/login/VerifyEmail";
import ViewPolicy from "./pages/login/ViewPolicy";
import Onboarding from "./pages/OnBoarding";
import PrasadTracking from "./pages/Order/PrasadTracking";
import SevaTracking from "./pages/Order/SevaTracking";
import OrderManagement from "./pages/OrderManagement";
import PaymentManagement from "./pages/payment/Payment";
import Payments from "./pages/Payments";
import Prasadam from "./pages/prasadam/Prasadam";
import SettingAndPrefrences from "./pages/SettingAndPrefrences";
import Settings from "./pages/Settings";
import AdminDetails from "./pages/settings/AdminDetails";
import Notifications from "./pages/settings/Notifications";
import TempleDetailsSettings from "./pages/settings/TempleDetailsSettings";
import AddSeva from "./pages/Seva/AddSeva";
import Overview from "./pages/Seva/Overview";
import SevaManagement from "./pages/SevaManagement";
import UserManagement from "./pages/UserManagement";
import Welcome from "./pages/Welcome";
import BookingManagement from "./purohit_pages/BookingManagement/bookingManagement";
import PurohitDashboard from "./purohit_pages/Dashboard/PurohitDashboard";
import PujaOfferingsAndServices from "./purohit_pages/Onboarding/PujaOfferingsAndServices";
import SelectedPujaOfferings from "./purohit_pages/Onboarding/SelectedPujaOfferings";
import PurohitTempleSettings from "./pages/settings/PurohitTempleSettings";
import AddPuja from "./purohit_pages/PujaManagement/AddPuja";
import PujaManagement from "./purohit_pages/PujaManagement/pujaManagement";
import SelectedPujaOffering from "./purohit_pages/PujaManagement/SelectedPujaOffering";
import ScheduleAvailabilityManagement from "./purohit_pages/Schedule_AvailabilityManagement/schedule_AvailabilityManagement";
import NewBookings from "./purohit_pages/BookingManagement/NewBookings";
import NewBookingTracking from "./purohit_pages/BookingManagement/NewBookingTracking";
import BookingDetails from "./purohit_pages/Schedule_AvailabilityManagement/BookingDetails";
// import BasicTemplateDetails from "./product_pages/Onboarding/BasicTemplateDetails";
// import DashboardStartpage from "./purohit_pages/Onboarding/DashboardStartpage";
import PurohitOnboarding from "./purohit_pages/purohitOnboarding";
import InventoryManagement from "./product_pages/InventoryManagement/InventoryManagement";
import WarehouseManagement from "./product_pages/WarehouseManagement/WarehouseManagement";
import ServiceFeedback from "./purohit_pages/Dashboard/ServiceFeedback";
import TodaySchedule from "./purohit_pages/Dashboard/TodaySchedule";
import ProgressBookings from "./purohit_pages/BookingManagement/ProgressBookings";
import CompletedBookings from "./purohit_pages/BookingManagement/CompletedBookings";
import RejectedBookings from "./purohit_pages/BookingManagement/RejectedBookings";
import ProductDashboard from "./product_pages/Dashboard/ProductDashboard";
import ViewProduct from "./product_pages/InventoryManagement/ViewProduct";
import { useSelector } from "react-redux";

const App = () => {
  let Onboarding_stage = localStorage.getItem("Onboarding_stage");
  const { onBoardingStatusDtata } = useSelector((state) => state.onbording);

  return (
    <Router>
      <SnackbarComponent />
      <Routes>
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/create-password" element={<CreatePasswordPage />} />

        <Route path="/signup">
          <Route path="view-policy" element={<ViewPolicy />} />
          <Route path="document-checklist" element={<DocumentChecklist />} />
          <Route path="temple-details" element={<TempleDetails />} />
          <Route path="purohit-details" element={<PurohitDetailsLogin />} />
          <Route path="contact" element={<UserContact />} />
          <Route path="verify-email" element={<VerifyEmail />} />
          <Route path="email-verified" element={<EmailVerified />} />
          <Route path="role" element={<Signup />} />
          <Route path="" element={<RoleSelect />} />
        </Route>
        <Route
          path="/purohit-onboarding"
          element={<PurohitOnboarding />}
        ></Route>
        <Route path="pooja" element={<PujaOfferingsAndServices />} />
        <Route path="selected-pooja" element={<SelectedPujaOfferings />} />
        <Route path="/create-password" element={<ForgotPassword />} />
        <Route path="/Onboarding" element={<Onboarding />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout />}>
            <Route path="/puja-management" element={<PujaManagement />}></Route>
            <Route path="/puja-management/add" element={<AddPuja />} />
            <Route
              path="/puja-management/select"
              element={<SelectedPujaOffering />}
            />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/order-management" element={<OrderManagement />} />
            <Route
              path="/order-management/:tabType"
              element={<OrderManagement />}
            />
            <Route
              exact
              path="/order-management/Prasadam_Orders/:actionType"
              element={<PrasadTracking />}
            />
            <Route
              exact
              path="/order-management/Seva_Orders/:actionType"
              element={<SevaTracking />}
            />
            <Route path="/seva-management" element={<SevaManagement />} />
            <Route
              path="/donation-management"
              element={<DonationManagement />}
            />
            <Route
              exact
              path="/donation-management/:tabType"
              element={<DonationManagement />}
            />
            <Route
              exact
              path="/donation-management/:tabType/:actionType"
              element={<DonationManagement />}
            />
            <Route path="/prasadam-management" element={<Prasadam />} />
            <Route
              path="/prasadam-management/:actionType"
              element={<Prasadam />}
            />

            <Route path="/payment-management" element={<PaymentManagement />} />
            <Route
              path="/payment-management/:tabType"
              element={<PaymentManagement />}
            />
            <Route
              path="/community-management"
              element={<CommunityManagement />}
            />
            <Route path="/issue-management" element={<IssueManagement />} />
            <Route path="/help_and_support" element={<HelpAndSupport />} />
            <Route
              path="/setting_and_prefrences"
              element={<SettingAndPrefrences />}
            >
              <Route
                path="temple-details"
                element={<TempleDetailsSettings />}
              />
              <Route path="admin-details" element={<AdminDetails />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="profile" element={<PurohitTempleSettings />} />
            </Route>

            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/user-management/:id" element={<UserManagement />} />
            <Route
              path="/user-management/:id/:actionType"
              element={<UserManagement />}
            />
            <Route path="/app-management" element={<AppManagement />} />
            <Route path="/help-request" element={<HelpRequest />} />
            <Route path="/help-request/:tabType" element={<HelpRequest />} />
            <Route
              path="/help-request/:tabType/:actionType"
              element={<IssueResolved />}
            />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/user-management/:id" element={<UserManagement />} />
            <Route
              path="/user-management/:id/:actionType"
              element={<UserManagement />}
            />
            <Route path="/app-management" element={<AppManagement />} />
            <Route path="/help-request" element={<HelpRequest />} />
            <Route path="/help-request/:tabType" element={<HelpRequest />} />
            <Route
              path="/help-request/:tabType/:actionType"
              element={<IssueResolved />}
            />
            <Route path="/payments" element={<Payments />} />
            <Route path="/help-center" element={<HelpCenter />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/seva-management/view/:id" element={<Overview />} />
            <Route
              path="/seva-management/:actionType/:id"
              element={<AddSeva />}
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/chart-support-page" element={<ChartSupportPage />} />
            <Route
              path="/community-management/:tabType"
              element={<CommunityManagement />}
            />
            <Route
              path="/community-management/Community_Post/:actionType"
              element={<AddCommunityPost />}
            />

            {/* <Route path="/seva-management/add" element={<AddSeva />} /> */}

            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/user-management/:id" element={<UserManagement />} />
            <Route
              path="/user-management/:id/:actionType"
              element={<UserManagement />}
            />
            <Route path="/app-management" element={<AppManagement />} />
            <Route path="/help-request" element={<HelpRequest />} />
            <Route path="/help-request/:tabType" element={<HelpRequest />} />
            <Route
              path="/help-request/:tabType/:actionType"
              element={<IssueResolved />}
            />
            <Route path="/payments" element={<Payments />} />
            <Route path="/help-center" element={<HelpCenter />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/seva-management/view" element={<Overview />} />
            <Route path="/seva-management/:actionType" element={<AddSeva />} />

            <Route path="/view-product" element={<ViewProduct />} />
            <Route
              path="/inventory-management"
              element={<InventoryManagement />}
            />
            <Route
              path="/issue-management/:tabType"
              element={<IssueManagement />}
            />
            <Route
              path="/issue-management/:tabType/:actionType"
              element={<IssueResolved />}
            />

            <Route
              path="/issue-management/new-issue/:actionType"
              element={<AddNewIssue />}
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/chart-support-page" element={<ChartSupportPage />} />
            <Route
              path="/community-management/:tabType"
              element={<CommunityManagement />}
            />
            <Route
              path="/community-management/Community_Post/:actionType"
              element={<AddCommunityPost />}
            />
            <Route
              path="/schedule_availability"
              element={<ScheduleAvailabilityManagement />}
            />
            <Route path="/product-dashboard" element={<ProductDashboard />} />
            <Route path="/purohit-dashboard" element={<PurohitDashboard />}>
              <Route path="Today_Schedule" element={<TodaySchedule />} />
              <Route path="Service_Feedback" element={<ServiceFeedback />} />
            </Route>
            <Route path="/booking-management" element={<BookingManagement />}>
              <Route path="New-Bookings" element={<NewBookings />} />
              <Route path="Progress-Bookings" element={<ProgressBookings />} />
              <Route
                path="Completed-Bookings"
                element={<CompletedBookings />}
              />
              <Route path="Rejected-Bookings" element={<RejectedBookings />} />
            </Route>
            <Route path="/puja-management" element={<pujaManagement />} />
            <Route path="/payment-management" element={<Payments />} />
            <Route path="/issue-management" element={<IssueManagement />} />
            <Route path="/help_and_support" element={<HelpAndSupport />} />
            <Route
              path="/setting_and_prefrences"
              element={<SettingAndPrefrences />}
            />
            <Route
              path="/booking-management/New-Bookings/:actionType"
              element={<NewBookingTracking />}
            />
            <Route path="/view-booking-details" element={<BookingDetails />} />
            <Route
              path="/warehouse-management"
              element={<WarehouseManagement />}
            />
            <Route
              path="/warehouse-management/:actionType"
              element={<WarehouseManagement />}
            />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
