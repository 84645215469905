import { Checkbox, Chip, Dialog, DialogContent, DialogTitle, FormControl, Grid2, IconButton, ListItemText, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { AddButton } from "./Addbutton";
import AddIcon from "@mui/icons-material/Add";
import "../styles/viewProduct.css";
import { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

const warehouseList = [
    "Warehouse 7",
    "Warehouse 6",
    "Warehouse 5",
    "Warehouse 8"
];

const DropdownModal = ({ open, onClose, tableData, setTableData }) => {
    const [selectedWarehouse, setSelectedWarehouse] = useState([]);
    const [error, setError] = useState({
        selectedWarehouse: ""
    })

    const handleChange = (event) => {
        const {target: { value }} = event;

        setTableData([
            ...tableData,
            {
                id: tableData[tableData.length-1].id + 1,
                name: value[value.length-1],
                stock: 0,
                value: 0
            }
        ]);

        setSelectedWarehouse(typeof value === "string" ? value.split(",") : value);
        setError({
            error,
            selectedWarehouse: ""
        });
    };

    const handleRemoveSelectedItem = (index) => {
        const updatedDays = selectedWarehouse.filter((_, i) => i !== index);
        setSelectedWarehouse(updatedDays);
    };

    const handleAdd = () => {
        let isValid = true;

        if(selectedWarehouse.length === 0) {
            setError({
                ...error,
                selectedWarehouse: "Select Warehouse is required"
            })
            isValid = false;
        }

        if(isValid) {
            onClose();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            PaperProps={{
                style: {
                    backgroundColor: "#141317",
                    border: "1px solid #00D48B",
                    borderRadius: "20px",
                    padding: "5px",
                    color: "#fff",
                    textAlign: "left",
                    width: "38%"
                },
            }}
        >
            <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                <p className="Active_title"> Select Your Warehouse </p>
            </DialogTitle>

            <DialogContent>
                <div>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <Typography className="onboarding-form_Heading">
                            Select Warehouse*
                        </Typography>

                        <Select
                            labelId="days-label"
                            id="days-select"
                            multiple
                            value={selectedWarehouse}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) =>
                                selected.length > 0 ? selected.join(", ") : "Select Days"
                            }
                            MenuProps={MenuProps}
                        >
                            {warehouseList.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={selectedWarehouse.includes(name)} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div>
                        {selectedWarehouse.map((day, index) => (
                            <Chip
                                key={day}
                                label={day}
                                onDelete={() => handleRemoveSelectedItem(index)}
                                sx={{
                                        margin: 0.5,
                                        "& .MuiSvgIcon-root": {
                                        fill: "black !important",
                                    },
                                }}
                            />
                        ))}
                    </div>

                    {error.selectedWarehouse && (
                        <Typography color="error">{error.selectedWarehouse}</Typography>
                    )}
                </div>

                <div className="button">
                    <AddButton
                        name="Add"
                        icon={<AddIcon />}
                        onClick={handleAdd}
                        background="#A9392D"
                        color="#FFFFFF"
                        width="auto"
                        borderColor="#ACAEC3"
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default DropdownModal;
