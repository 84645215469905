import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserCards } from "../components/UserCards";
import "../styles/appManagement.css";
import android from "../util/versionData/andriodVersionData.json";

const AppManagement = React.memo((props) => {
  const navigate = useNavigate();

  useEffect(() => {
    // props.getUserStats();
  }, []);

  const handleViewVersionClick = (data) => {
    navigate(`/ViewAppVersionHistory`, { state: data });
  };

  return (
    <>
      <Box
        className={"heading"}
        sx={{
          textWrap: "nowrap",
          paddingY: "10px",
        }}
      >
        App Management
      </Box>
      <Box className="app-management-cards">
        <Box
          className="app-management-cards-box-1"
          sx={{ width: "100%", mr: 2 }}
        >
          <UserCards
            title="Total Active Users"
            count="45"
            src="/app-management-icons/active.svg"
            notification="30"
            info="Total active users & New users active today on the App."
            sx={{
              marginBottom: { xs: "1.5rem", lg: "1.5rem" },
            }}
          />
          <UserCards
            title="Total Inactive Users  "
            count="12"
            src="/app-management-icons/inactive.svg"
            info="Total inactive users are the user who didn’t Login in app in 2 months."
            sx={{ marginBottom: "1rem", minWidth: "100%" }}
          />
        </Box>
        <Box className="version_box">
          <div>
            <img
              className="user_count_icon "
              src={"/app-management-icons/andriod.svg"}
              alt="userCount-icon"
            />
            <p className="user_count_title">Android Users</p>
          </div>
          <div className="user_count_text">
            {props.userStats?.androidActiveUsers ?? 0}
          </div>
          <div className="gap-measure">
            <p className="user_count_title2"> Current App Version </p>
            <span className="version_text">
              {android[0]?.app_Version ?? "1.2.12"}
            </span>
          </div>
          <p
            onClick={() => handleViewVersionClick("Android")}
            className="user_count_subtext"
          >
            View History
          </p>
        </Box>
        <Box className="version_box" sx={{ mr: 2 }}>
          <div>
            <img
              className="user_count_icon "
              src={"/app-management-icons/ios.svg"}
              alt="userCount-icon"
            />
            <p className="user_count_title">iOS Users</p>
          </div>
          <div className="user_count_text">
            {props.userStats?.androidActiveUsers ?? 0}
          </div>
          <div className="gap-measure">
            <p className="user_count_title2"> Current App Version </p>
            <span className="version_text">
              {android[0]?.app_Version ?? "iOS 16"}
            </span>
          </div>
          <p
            onClick={() => handleViewVersionClick("iOS")}
            className="user_count_subtext"
          >
            View History
          </p>
        </Box>
      </Box>
    </>
  );
});

export default AppManagement;
