import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Grid2,
  Autocomplete,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import "../../styles/onboardingForm.css";

import "../../styles/ModalDatePicker.css";
import { AddButton } from "../../components/Addbutton";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadPaymentRequest,
  updatePaymentRequest,
  fetchPaymentRequest,
  DeleteImageRequest,
  toggleStatus,
} from "../../features/slices/onBordingSlice";

const initialFormState = {
  accountHolderName: "",
  accountNumber: "",
  bankName: "",
  ifscCode: "",

  passbookPhoto: [],
};

const initialErrorState = {
  accountHolderName: "",
  accountNumber: "",
  bankName: "",
  ifscCode: "",
  passbookPhoto: "",
};

const Payments = forwardRef((props, ref) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const { onBoardingUploadStatus, PaymentData, imageDeleteStatus } =
    useSelector((state) => state.onbording);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const MAX_PDF_SIZE = 20 * 1024 * 1024;

  const handleUpload = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;
    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (selectedFiles?.length + (formData[field]?.length || 0) > MAX_FILES) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isPDF = file.type === "application/pdf";
      const isValidFormat = ["application/pdf"].includes(file.type);
      const maxSize = isPDF ? MAX_PDF_SIZE : 0;

      if (uploadType === "application/pdf" && (!isPDF || !isValidFormat)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "Invalid file type. Please upload a PDF file.",
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${MAX_PDF_SIZE / (1024 * 1024)}MB.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeUpload = (field, index, item) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };
  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;

      if (!formData.bankName) {
        newErrors.bankName = "Bank Name is required";
        isValid = false;
      }
      if (!formData.accountNumber) {
        newErrors.accountNumber = "Acc Number is required";
        isValid = false;
      }
      const ifscRegex = /^[A-Z]{4}[0-9]{7}$/;
      if (!formData.ifscCode) {
        newErrors.ifscCode = "IFSC Code is required";
        isValid = false;
      } else if (!ifscRegex.test(formData.ifscCode)) {
        newErrors.ifscCode =
          "IFSC Code should only contain uppercase alphabets and numbers";
        isValid = false;
      }
      if (!formData.accountHolderName) {
        newErrors.accountHolderName = "Acc Holder Name is required";
        isValid = false;
      }
      if (formData.passbookPhoto.length === 0) {
        newErrors.passbookPhoto = "Bank cheque image is required";
        isValid = false;
      }

      setErrors(newErrors);

      if (isValid) {
        let formArr = new FormData();
        if (!formData?.passbookPhoto[0]?.etag) {
          formArr.append("passBookPhoto", formData?.passbookPhoto[0]?.file);
        }

        formArr.append("accountHolderName", formData?.accountHolderName);
        formArr.append("accountNumber", formData?.accountNumber);
        formArr.append("bankName", formData?.bankName);
        formArr.append("ifscCode", formData?.ifscCode);
        if (PaymentData && PaymentData?.id) {
          dispatch(updatePaymentRequest(formArr));
        } else {
          dispatch(uploadPaymentRequest(formArr));
        }
      }
    },
  }));

  useEffect(() => {
    dispatch(fetchPaymentRequest());
  }, [imageDeleteStatus]);

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };

  useEffect(() => {
    setFormData({
      ...formData,
      accountHolderName: PaymentData?.accountHolderName
        ? PaymentData?.accountHolderName
        : "",
      accountNumber: PaymentData?.accountNumber
        ? PaymentData?.accountNumber
        : "",
      bankName: PaymentData?.bankName ? PaymentData?.bankName : "",
      ifscCode: PaymentData?.ifscCode ? PaymentData?.ifscCode : "",
      passbookPhoto: PaymentData?.passbookPhoto
        ? mapImages([PaymentData.passbookPhoto])
        : [],
    });
  }, [PaymentData]);

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (field, e, maxFiles, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);

    handleUpload(field, e, maxFiles, uploadType);
  };
  return (
    <>
      <div className="heading">Payment Details</div>

      <Box style={{ marginTop: "1rem" }}>
        <div className="onboarding-sub_heading">Basic Account Details</div>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Account Holder Name*
            </Typography>
            <TextField
              disabled={props?.previewStatus ? true : false}
              fullWidth
              variant="outlined"
              value={formData.accountHolderName}
              onChange={handleChange("accountHolderName")}
              placeholder="Enter Account Holder Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.accountHolderName && (
              <Typography color="error">{errors.accountHolderName}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Bank Name
            </Typography>
            <TextField
              disabled={props?.previewStatus ? true : false}
              fullWidth
              variant="outlined"
              value={formData.bankName}
              onChange={handleChange("bankName")}
              placeholder="Enter Bank Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.bankName && (
              <Typography color="error">{errors.bankName}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Account Number*
            </Typography>
            <TextField
              type="number"
              fullWidth
              disabled={props?.previewStatus ? true : false}
              variant="outlined"
              value={formData.accountNumber}
              onChange={handleChange("accountNumber")}
              placeholder="Enter Account Number"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.accountNumber && (
              <Typography color="error">{errors.accountNumber}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              IFSC Code*
            </Typography>
            <TextField
              disabled={props?.previewStatus ? true : false}
              fullWidth
              variant="outlined"
              value={formData.ifscCode}
              onChange={handleChange("ifscCode")}
              placeholder="Enter IFSC Code"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.ifscCode && (
              <Typography color="error">{errors.ifscCode}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Upload Last 6 Months Bank Statement*
            </Typography>
            <Box
              className="upload-box_charity "
              onDrop={(e) =>
                handleDrop("passbookPhoto", e, 1, "application/pdf")
              }
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
            >
              {formData?.passbookPhoto?.length > 0 ? (
                <Box className="preview-image-container">
                  {formData?.passbookPhoto?.map((item, index) => (
                    <Box className="song-image-container">
                      {!props?.previewStatus && (
                        <Close
                          className="song-image-close-icon"
                          onClick={() =>
                            removeUpload("passbookPhoto", index, item)
                          }
                        />
                      )}

                      <img
                        src="/pdf-icon.png"
                        className="song-image"
                        alt="song_img"
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              {!props?.previewStatus && (
                <>
                  <Typography className="upload-instructions">
                    <img
                      style={{ marginBottom: "-0.25rem" }}
                      src="/upload-file-icon.svg"
                      alt="img"
                    />
                    Drag and Drop your file
                  </Typography>

                  <Typography className="onboarding-upload-instructions ">
                    OR
                  </Typography>
                  <input
                    ref={fileInputRef}
                    accept="application/pdf"
                    type="file"
                    style={{ display: "none" }}
                    id="thumbnail-upload-image"
                    onChange={(e) =>
                      handleUpload("passbookPhoto", e, 1, "application/pdf")
                    }
                    multiple={false}
                  />
                  <label htmlFor="thumbnail-upload-image">
                    <Button
                      variant="outlined"
                      component="span"
                      className="browse-button"
                    >
                      Browse file
                    </Button>
                  </label>
                  <Typography className="upload-instructions ">
                    Max. 20MB, PDF
                  </Typography>
                </>
              )}
            </Box>
            {errors.passbookPhoto && (
              <Typography color="error">{errors.passbookPhoto}</Typography>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
});

export default Payments;
