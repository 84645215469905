import React from "react";
import { Stepper, Step, StepLabel } from "@mui/material";

function AlternativeLabelStepper({ activeStep, steps }) {
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel
            sx={{
              color: label.color || "inherit",
              fontWeight: label.color ? "bold" : "normal",
              "& .MuiStepLabel-label": {
                color: label.color ? `${label.color} !important` : "",
              },
              "& .css-3hpdci-MuiSvgIcon-root-MuiStepIcon-root": {
                color: label.color ? `${label.color} !important` : "",
              },
            }}
          >
            {label.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default AlternativeLabelStepper;
