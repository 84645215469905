import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import BusinessVerificationDetails from "./BusinessVerificationDetails";
import BusinessDescription from "./BusinessDescription";
import PhotosAndVideos from "./PhotosAndVideos";
import AdminInfo from "./AdminInfo";
import ServiceDetails from "./ServiceDetails";
import PrasadamDetails from "./PrasadamDetails";
import DonationDetails from "./DonationDetails";
import Payments from "./Payments";
import BasicTemplateDetails from "./BasicTemplateDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadPreviewRequest,
  fetchOnBoardingRequest,
} from "../../features/slices/onBordingSlice";

const OnboardingPreview = forwardRef((props, ref) => {
  const { onBoardingData } = useSelector((state) => state.onbording);
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      dispatch(uploadPreviewRequest());
    },
  }));
  useEffect(() => {
    dispatch(fetchOnBoardingRequest());
  }, [dispatch]);

  const handleBack = () => {
    props.handleBack();
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <BasicTemplateDetails OnBoardData={onBoardingData} previewStatus={true} />
      <BusinessVerificationDetails
        OnBoardData={onBoardingData}
        previewStatus={true}
      />
      <BusinessDescription OnBoardData={onBoardingData} previewStatus={true} />
      <PhotosAndVideos OnBoardData={onBoardingData} previewStatus={true} />
      <AdminInfo OnBoardData={onBoardingData} previewStatus={true} />
      <ServiceDetails OnBoardData={onBoardingData} previewStatus={true} />
      <PrasadamDetails OnBoardData={onBoardingData} previewStatus={true} />
      <DonationDetails OnBoardData={onBoardingData} previewStatus={true} />
      <Payments OnBoardData={onBoardingData} previewStatus={true} />;
    </div>
  );
});
export default OnboardingPreview;
