import React from "react";
import SmoothLineChart from "../components/Linecharts";
import StatsCard from "../components/cards/StatsCard";
import "../styles/dashboard.css";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { CustomLoader } from "../components/CustomLoader";

const Dashboard = React.memo(() => {
  const navigate = useNavigate();

  return (
    <div className="dashboard_container">
      <CustomLoader loading={false} message="Fetching data, please wait..." />
      <div className="dashboard-stats-container">
        <StatsCard
          label={"Today's Seva Performed"}
          stat={"0"}
          image="/dashboard-icons/seva-performed-card.svg"
          info={"Overview of the service activities performed today."}
        />
        <StatsCard
          label={"Today's Seva Bookings"}
          stat={"0"}
          image="/dashboard-icons/seva-bookings.svg"
          info={"View all service bookings scheduled for today."}
        />
        <StatsCard
          label={"Monthly Donation Received"}
          stat={"0"}
          image="/dashboard-icons/donation-recieved.svg"
          info={"Summary of donations received this month, with total amounts."}
        />
        <StatsCard
          label={"Today's Prasadam Order"}
          stat={"0"}
          image="/dashboard-icons/prasadam-orders.svg"
          info={"Details of all prasadam orders placed today."}
        />
      </div>

      <Box
        className={"heading"}
        sx={{
          textWrap: "nowrap",
        }}
      >
        Sales Analytics
      </Box>

      <div style={{ width: "98%" }}>
        <SmoothLineChart />
      </div>

      <div className="dashboard_section3">
        <Box
          className="dashboardNavCard"
          onClick={() => navigate("/donation-management/Donation_List")}
        >
          {" "}
          View Donations{" "}
          <img src="/dashboard-icons/viewDonationNav.svg" alt="img" />
        </Box>
        <Box
          className="dashboardNavCard"
          onClick={() => navigate("/seva-management/Add")}
        >
          {" "}
          Add Seva <img src="/dashboard-icons/addSevaNav.svg" alt="img" />
        </Box>
      </div>
    </div>
  );
});

export default Dashboard;
