import { createSlice } from "@reduxjs/toolkit";

const templeDetailsSlice = createSlice({
    name: "templeDetails",
    initialState: {
        templeData: [],
        paymentData: [],
        mediaData: [],
        error: null,
        loading: false,
        editLoading: false,
        editMediaLoading: false,
        fetchTempleDataStatus: false,
        fetchPaymentDataStatus: false,
        fetchTempleMediaDataStatus: false,
        editTempleDataStatus: false,
        editMediaDataStatus: false
    },
    reducers: {
        fetchTempleData: (state) => {
            state.templeData = [];
            state.loading = true;
            state.error = null;
            state.fetchTempleDataStatus = false;
        },
        fetchTempleDataSuccess: (state, action) => {
            state.templeData = action.payload;
            state.loading = false;
            state.error = null;
            state.fetchTempleDataStatus = true;
        },
        fetchTempleDataFailure: (state, action) => {
            state.templeData = [];
            state.loading = false;
            state.error = action.payload;
            state.fetchTempleDataStatus = false;
        },
        fetchPaymentData: (state) => {
            state.paymentData = [];
            state.loading = true;
            state.error = null;
            state.fetchPaymentDataStatus = false;
        },
        fetchPaymentDataSuccess: (state, action) => {
            state.paymentData = action.payload;
            state.loading = false;
            state.error = null;
            state.fetchPaymentDataStatus = true;
        },
        fetchPaymentDataFailure: (state, action) =>{
            state.paymentData = [];
            state.loading = false;
            state.error = action.payload;
            state.fetchPaymentDataStatus = false;
        },
        fetchTempleMediaData: (state) => {
            state.mediaData = [];
            state.loading = true;
            state.error = null;
            state.fetchTempleMediaDataStatus = false;
        },
        fetchTempleMediaDataSuccess: (state, action) => {
            state.mediaData = action.payload;
            state.loading = false;
            state.error = null;
            state.fetchTempleMediaDataStatus = true;
        },
        fetchTempleMediaDataFailure: (state, action) =>{
            state.mediaData = [];
            state.loading = false;
            state.error = action.payload;
            state.fetchTempleMediaDataStatus = false;
        },
        updateTempleDescription: (state) => {
            state.editLoading = true;
            state.error = null;
            state.editTempleDataStatus = false;
        },
        updateTempleDescriptionSuccess: (state, action) => {
            state.editLoading = false;
            state.error = null;
            state.editTempleDataStatus = true;
        },
        updateTempleDescriptionFailure: (state, action) => {
            state.editLoading = false;
            state.error = action.payload;
            state.editTempleDataStatus = false;
        },
        updateTempleMedia: (state) => {
            state.editMediaLoading = true;
            state.error = null;
            state.editMediaDataStatus = false;
        },
        updateTempleMediaSuccess: (state, action) => {
            state.editMediaLoading = false;
            state.error = null;
            state.editMediaDataStatus = true;
        },
        updateTempleMediaFailure: (state, action) => {
            state.editMediaLoading = false;
            state.error = action.payload;
            state.editMediaDataStatus = false;
        },
        toggleStatus: (state) => {
            state.fetchTempleDataStatus = false;
            state.fetchPaymentDataStatus = false;
            state.fetchTempleMediaDataStatus = false;
            state.editTempleDataStatus = false;
            state.editMediaDataStatus = false;
        }
    }
});

export const {
    fetchTempleData,
    fetchTempleDataSuccess,
    fetchTempleDataFailure,
    fetchPaymentData,
    fetchPaymentDataSuccess,
    fetchPaymentDataFailure,
    fetchTempleMediaData,
    fetchTempleMediaDataSuccess,
    fetchTempleMediaDataFailure,
    updateTempleDescription,
    updateTempleDescriptionSuccess,
    updateTempleDescriptionFailure,
    updateTempleMedia,
    updateTempleMediaSuccess,
    updateTempleMediaFailure,
    toggleStatus
} = templeDetailsSlice.actions;

export default templeDetailsSlice.reducer;
