import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { AddButton } from "../../components/Addbutton";
import { useNavigate } from "react-router-dom";

const arr = [
  "Ganesh Puja",
  "Vivah Puja",
  "Diwali Puja",
  "Lakshmi Puja",
  "Holi Puja",
];

const SelectedPujaOffering = () => {
  // State to hold input values for each puja offering
  const navigate = useNavigate();

  const [servicePrices, setServicePrices] = useState(
    arr.reduce((acc, puja) => {
      acc[puja] = {
        withSamagri: "",
        withoutSamagri: "",
        onlineOffline: "offline",
      };
      return acc;
    }, {}),
  );

  const handleChange = (e, field, puja) => {
    const value = e.target.value;
    const newValue = field.includes("Samagri") ? value : value;

    setServicePrices((prev) => ({
      ...prev,
      [puja]: {
        ...prev[puja],
        [field]: newValue,
      },
    }));
  };

  const handleSubmit = () => {};

  return (
    <div style={{ background: "#F5F5F5", height: "100vh" }}>
      <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <ArrowBackIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(`/puja-management/add`)}
        />
        <Typography variant="h5" sx={{ mt: 2, mb: 2, color: "black" }}>
          Selected Puja Offerings
        </Typography>
      </span>
      <div variant="h6">
        Note: Please be aware that the pricing for the puja will be determined
        based on the cost of the puja, transportation fees, dakshina
        (offerings), and pooja samagri (ritual items).
      </div>
      <div style={{ marginTop: "2rem" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="puja offerings table">
            <TableHead>
              <TableRow>
                <TableCell>Puja List</TableCell>
                <TableCell>Service price (with Samagri)</TableCell>
                <TableCell>Service price (without samagri)</TableCell>
                <TableCell>Online/Offline</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arr.map((row) => (
                <TableRow key={row}>
                  <TableCell>{row}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={servicePrices[row].withSamagri}
                      onChange={(e) => handleChange(e, "withSamagri", row)}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="EnterService price (with Samagri)"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={servicePrices[row].withoutSamagri}
                      onChange={(e) => handleChange(e, "withoutSamagri", row)}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter Service price (without Samagri)"
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth size="small">
                      <Select
                        value={servicePrices[row].onlineOffline}
                        onChange={(e) => handleChange(e, "onlineOffline", row)}
                      >
                        <MenuItem value="online">Online</MenuItem>
                        <MenuItem value="offline">Offline</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="cancel_save" style={{ marginTop: "1rem" }}>
        <AddButton name="Save" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default SelectedPujaOffering;
