import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { AddButton } from "../../components/Addbutton";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import { signUpRequest } from "../../features/slices/authSlice";
import "../../styles/login.css";
import TermsAndCondition from "../TermsAndCondition";

const Signup = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const childRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [disabledLogin, setDisableLogin] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email Id").required("email is required"),
    new_password: Yup.string()
      .min(12, "Password is too weak")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const scrollToTop = () => {
    if (childRef.current) {
      childRef.current.scrollTop = 0;
    }
  };

  let passwordRuleList = [
    {
      name: "Minimum Length: 12 characters",
      status: false,
    },
    {
      name: "Character Composition: Must Include A Diverse Mix of Uppercase And Lowercase Letters, Numbers, And Symbols.",
      status: false,
    },
  ];

  // function that will check in real-time whether the new password fiels value is matching the password rules or not
  const validatePasswordRules = (password) => {
    // Rule 1: Check minimum length
    passwordRuleList[0].status = password.length >= 12;

    // Rule 2: Check character composition
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    passwordRuleList[1].status =
      hasUppercase && hasLowercase && hasNumber && hasSymbol;

    if (passwordRuleList[0].status && passwordRuleList[1].status) return true;
    return false;
  };

  const handleSubmit = useCallback(
    (inputValue) => {
      const { email, new_password } = inputValue;
      let SingUpObj = {
        email: email,
        password: new_password,
        roleName: searchParams.get("role"),
      };

      dispatch(signUpRequest({ SingUpObj, email, navigate }));
    },
    [dispatch, navigate, searchParams],
  );

  const [disabledState, setDisabledState] = useState(true);

  return (
    <>
      <img src="/daiv-logo.svg" alt="logo" className="daiv_logo" />
      <div container className="login_container">
        {/* this section will open the terms and conditions modal box */}
        <Modal
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
          }}
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="terms_condition_box" ref={childRef}>
            <TermsAndCondition
              modalType={modalType}
              scrollToTop={scrollToTop}
              setOpenModal={setOpenModal}
              setDisableLogin={setDisableLogin}
            />
          </Box>
        </Modal>
        <div className="login_background_image"></div>
        <div className="login_content">
          <div className="greeting_text_container">
            <div className="login_form">
              <div className="welcome_title">Sign Up Details</div>

              <div className="welcome_login_btn">
                <Formik
                  initialValues={{
                    email: "",
                    new_password: "",
                    confirm_password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    isSubmitting,
                    errors,
                    isValid,
                    dirty,
                    touched,
                  }) => (
                    <Form>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: "20px",
                          marginTop: "25px",
                        }}
                      >
                        <MaterialUIFieldAdapter
                          name="email"
                          type="text"
                          label="Email ID*"
                          placeholder="Enter email"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: "20px",
                        }}
                      >
                        <MaterialUIFieldAdapter
                          name="new_password"
                          type="password"
                          label="Password*"
                          placeholder="Password"
                          style={{ width: "100%" }}
                          validatePasswordRules={validatePasswordRules}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginTop: "0.5%",
                        }}
                      >
                        <MaterialUIFieldAdapter
                          name="confirm_password"
                          type="password"
                          placeholder="Confirm Password"
                          label="Confirm Password*"
                          style={{ width: "100%" }}
                          validatePasswordRules={null}
                        />
                      </div>

                      <div
                        style={{
                          width: "100%",
                          color: "#ffffff",
                          textAlign: "left",
                          fontSize: ".8rem",
                          marginBottom: "20px",
                        }}
                      >
                        {passwordRuleList.map((rule) => (
                          <p className="password_rules_text" key={rule.name}>
                            <span
                              style={{ color: rule.status ? "green" : "red" }}
                            >
                              {rule.status ? (
                                <img src="/correct.svg" alt="correct" />
                              ) : (
                                <img src="/cancel.svg" alt="correct" />
                              )}
                            </span>
                            {rule.name}
                          </p>
                        ))}
                      </div>

                      <AddButton
                        name="Sign Up"
                        type="submit"
                        width="100%"
                        padding="18px"
                        background="var(--secondary)"
                        sx={{
                          fontFamily: "var(--font-secondary) !important",
                          fontWeight: 600,
                          fontSize: ".9rem",
                        }}
                        disabled={!(isValid && dirty)}
                      />
                      <div className="welcome_footer">
                        <div className="welcome_footer_1">
                          Already have an account?
                        </div>
                        <div
                          className="welcome_footer_2"
                          onClick={() => navigate("/login")}
                        >
                          Log In
                        </div>
                      </div>
                      <div className="welcome_footer">
                        <div
                          className="welcome_footer_1"
                          style={{ marginTop: "1rem" }}
                        >
                          <div style={{ textAlign: "center" }}>
                            By signing up, you agree to the
                          </div>

                          <div className="terms_and_condition_text welcome_footer_2_mod">
                            <div
                              className="welcome_footer_2"
                              onClick={() => {
                                setModalType("terms_and_condition");
                                setOpenModal(true);
                              }}
                            >
                              Terms & Conditions
                            </div>
                            <div className="welcome_footer_1">and</div>
                            <div
                              className="welcome_footer_2"
                              onClick={() => {
                                setModalType("privacy_policy");
                                setOpenModal(true);
                              }}
                            >
                              Privacy Policy
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Signup;
