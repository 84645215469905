import { Tooltip, styled } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));
const truncateAtDot = (filename, days) => {
  if (days) {
    return filename.map((day) => day.slice(0, 3)).join(", ") + "...";
  }
  return filename?.length > 25 ? filename?.slice(0, 25) + "..." : filename;
};

const TableTooltip = (props) => {
  return (
    <LightTooltip title={props.days ? props.title.join(", ") : props?.title}>
      {truncateAtDot(props?.title, props?.days)}
    </LightTooltip>
  );
};
export default TableTooltip;
