import { Close } from "@mui/icons-material";
import { Box, Button, Grid2, TextField, Typography } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "../../styles/onboardingForm.css";

import { useDispatch } from "react-redux";
import { uploadDocumentRequest } from "../../features/slices/onBordingSlice";
import "../../styles/ModalDatePicker.css";

const initialFormState = {
  panCard: [],
  templeRegistrationCertificate: [],
};

const initialErrorState = {
  panCard: [],
  templeRegistrationCertificate: [],
};

const BusinessVerificationDetails = forwardRef((props, ref) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const MAX_IMAGE_SIZE = 20 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

  const handleUpload = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;
    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (selectedFiles?.length + (formData[field]?.length || 0) > MAX_FILES) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type === "video/mp4";
      const isValidImageFormat = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ].includes(file.type);
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (
        (uploadType === "image/*" && (!isImage || !isValidImageFormat)) ||
        (uploadType === "video/mp4" && !isVideo)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Invalid file type. Please upload ${
            uploadType === "image/*" ? "JPEG, JPG, or PNG images" : "MP4 videos"
          }.`,
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "20MB" : "50MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const removeUpload = (field, index) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;

      if (formData.panCard.length === 0) {
        newErrors.panCard = "Pan Card Image is required";
        isValid = false;
      }
      if (formData.templeRegistrationCertificate.length === 0) {
        newErrors.templeRegistrationCertificate =
          "Temple Certification Image is required";
        isValid = false;
      }

      setErrors(newErrors);

      if (isValid) {
        let formArr = new FormData();

        if (!formData?.panCard[0]?.etag) {
          formArr.append("panCard", formData?.panCard[0]?.file);
        }
        if (!formData?.templeRegistrationCertificate[0]?.etag) {
          formArr.append(
            "templeRegistrationCertificate",
            formData?.templeRegistrationCertificate[0]?.file,
          );
        }

        dispatch(uploadDocumentRequest(formArr));
      }
    },
  }));

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };
  useEffect(() => {
    let newData = props?.OnBoardData;
    setFormData({
      ...formData,
      panCard: newData?.panCard ? mapImages([newData.panCard]) : [],
      templeRegistrationCertificate: newData?.templeRegistrationCertificate
        ? mapImages([newData.templeRegistrationCertificate])
        : [],
    });
  }, [props?.OnBoardData]);

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (field, e, maxFiles, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    handleUpload(field, e, maxFiles, uploadType);
  };

  return (
    <>
      <Box>
        <div className="heading">Temple Verification & Documentation</div>
        <Box style={{ marginTop: "1rem" }}>
          <div className="onboarding-sub_heading">Identity Verification</div>
          <Grid2 container spacing={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Pan Card*
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                // value={formData.panCard}
                // onChange={handleChange("panCard")}
                disabled
                placeholder="Browse from DigiLocker"
                style={{ marginBottom: "1rem" }}
                className="onboarding-custom-textfield"
                InputProps={{
                  style: { color: "#8d8e9f" },
                }}
              />
              {errors.panCard && (
                <Typography color="error">{errors.panCard}</Typography>
              )}
            </Grid2>
          </Grid2>
          <Typography className="OR-Typography">OR</Typography>
          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Box
                className="upload-box_charity "
                onDrop={(e) => handleDrop("panCard", e, 1, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.panCard?.length > 0 ? (
                  <Box className="preview-image-container">
                    {formData?.panCard?.map((item, index) => (
                      <Box className="song-image-container">
                        {!props?.previewStatus && (
                          <Close
                            className="song-image-close-icon"
                            onClick={() => removeUpload("panCard", index)}
                          />
                        )}

                        <img
                          src={item.url}
                          className="song-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {!props?.previewStatus && (
                  <>
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload"
                      onChange={(e) => handleUpload("panCard", e, 1, "image/*")}
                      multiple={false}
                    />
                    <label htmlFor="thumbnail-upload">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>
                    <Typography className="upload-instructions ">
                      Max. 20MB, JPEG
                    </Typography>
                  </>
                )}
              </Box>
              {errors.panCard && (
                <Typography color="error">{errors.panCard}</Typography>
              )}
            </Grid2>
          </Grid2>

          <div className="onboarding-sub_heading">Temple Registration</div>
          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={{ xs: 6, md: 6 }}>
              <Typography className="onboarding-form_Heading">
                Temple / Trust Registration Certificate*
              </Typography>
              <Box
                className="upload-box_charity "
                onDrop={(e) =>
                  handleDrop("templeRegistrationCertificate", e, 1, "image/*")
                }
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {formData?.templeRegistrationCertificate?.length > 0 ? (
                  <Box className="preview-image-container">
                    {formData?.templeRegistrationCertificate?.map(
                      (item, index) => (
                        <Box className="song-image-container">
                          {!props?.previewStatus && (
                            <Close
                              className="song-image-close-icon"
                              onClick={() =>
                                removeUpload(
                                  "templeRegistrationCertificate",
                                  index,
                                )
                              }
                            />
                          )}

                          <img
                            src={item.url}
                            className="song-image"
                            alt="img"
                          />
                        </Box>
                      ),
                    )}
                  </Box>
                ) : null}
                {!props.previewStatus && (
                  <>
                    <Typography className="upload-instructions">
                      <img
                        style={{ marginBottom: "-0.25rem" }}
                        src="/upload-file-icon.svg"
                        alt="img"
                      />
                      Drag and Drop your file
                    </Typography>
                    <Typography className="onboarding-upload-instructions ">
                      OR
                    </Typography>
                    <input
                      ref={fileInputRef}
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      id="thumbnail-upload-2"
                      onChange={(e) =>
                        handleUpload(
                          "templeRegistrationCertificate",
                          e,
                          1,
                          "image/*",
                        )
                      }
                      multiple={false}
                    />
                    <label htmlFor="thumbnail-upload-2">
                      <Button
                        variant="outlined"
                        component="span"
                        className="browse-button"
                      >
                        Browse file
                      </Button>
                    </label>
                    <Typography className="upload-instructions ">
                      Max. 20MB, JPEG
                    </Typography>
                  </>
                )}
              </Box>
              {errors.templeRegistrationCertificate && (
                <Typography color="error">
                  {errors.templeRegistrationCertificate}
                </Typography>
              )}
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </>
  );
});

export default BusinessVerificationDetails;
