import React, { useCallback, useEffect, useState } from "react";
import "../../styles/dashboard.css";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CommonTable from "../../components/CommonTable";
import { AddButton } from "../../components/Addbutton";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import {
  fetchAllCommunityPost,
  communityPostDeletion,
  toggleStatus,
} from "../../features/slices/communityPostSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { CustomLoader } from "../../components/CustomLoader";
import { render } from "@testing-library/react";
import TableTooltip from "../../components/TableTooltip";

const CommunityPost = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    communityPostData,
    loading,
    deleteCommunityPostStatus,
    templeId,
    fetchTempleIdStatus,
  } = useSelector((state) => state.communityPost);
  const { content, pageable, totalPages } = communityPostData;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [communityPostId, setCommunityPostId] = useState(0);
  const [openAddNew, setOpenAddNew] = useState(false);
  const columns = [
    {
      field: "eventName",
      headerName: "Event Name",
      renderCell: (params, row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={row?.thumbnailPhoto ? row?.thumbnailPhoto?.filePath : ""}
              alt="titleImage"
              style={{ height: "4rem", width: "4rem" }}
            />
            <span style={{ marginLeft: "0.5rem" }}>{row?.eventName}</span>
          </div>
        );
      },
    },
    {
      field: "eventDescription",
      headerName: "Description",
      renderCell: (params, row) => <TableTooltip title={params} />,
    },
    { field: "eventDate", headerName: "Event Date" },
    {
      field: "uploadedOn",
      headerName: "Uploaded On",
      renderCell: (params, row) => {
        return (
          <div style={{ display: "flex" }}>
            <span>{dayjs(row?.createdAt).format("YYYY-MM-DD")}</span>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params, row) => (
        <span style={{ display: "flex" }}>
          <img
            src="/edit-icon.svg"
            alt="edit"
            style={{ cursor: "pointer" }}
            onClick={() => onEdit(row?.id)}
          />

          <img
            src="/delete-icon.svg"
            alt="edit"
            style={{ cursor: "pointer", marginLeft: "0.4rem" }}
            onClick={() => onDelete(row?.id)}
          />
        </span>
      ),
    },
  ];

  const handleChangePage = useCallback((val) => {
    setPage(val);
  }, []);

  const HandleAdd = () => {
    setOpenAddNew(true);
    navigate(`/community-management/Community_Post/Add`);
  };

  const onEdit = (id) => {
    setCommunityPostId(id);
    navigate(`/community-management/Community_Post/Edit?id=${id}`);
  };

  const onDelete = (id) => {
    setCommunityPostId(id);
    setOpenDeleteModal(true);
  };

  const CloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteConfirm = useCallback(() => {
    dispatch(communityPostDeletion(communityPostId));
    setOpenDeleteModal(false);
  }, [dispatch, communityPostId]);

  const getAllCommunityPostsUrl = useCallback(() => {
    const queryParamString = `page=${page - 1}&size=${rowsPerPage}`;

    dispatch(fetchAllCommunityPost({ templeId, queryParamString }));
  }, [dispatch, templeId]);

  useEffect(() => {
    if (fetchTempleIdStatus) {
      getAllCommunityPostsUrl();
    }
  }, [fetchTempleIdStatus]);

  useEffect(() => {
    if (deleteCommunityPostStatus) {
      getAllCommunityPostsUrl();
      dispatch(toggleStatus());
    }
  }, [deleteCommunityPostStatus]);

  return (
    <>
      <DeleteModal
        open={openDeleteModal}
        onClose={CloseDeleteModal}
        onConfirm={() => {
          handleDeleteConfirm();
        }}
        content="Are you sure you want to Delete the Community Post ?"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0.75rem 0",
        }}
      >
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
          }}
        >
          Community Post
        </Box>

        <AddButton name="Add" icon={<AddIcon />} onClick={HandleAdd} />
      </div>

      <CustomLoader
        loading={loading}
        message="Please wait, Uploading Community Posts..."
      />

      <CommonTable
        columns={columns}
        data={content || []}
        page={page}
        count={totalPages}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </>
  );
});

export default CommunityPost;
