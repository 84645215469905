import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
} from "@mui/material";
import "../../styles/confrimationModal.css";
import CloseIcon from "@mui/icons-material/Close";

import Carousel from "react-material-ui-carousel";

const PreviewImageModal = ({ open, onClose, previewImages, buttons, title }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      PaperProps={{
       className: "preview_image_box"
      }}
    >
      {title ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ margin: "20px 0px 0px 20px" }} variant="h4">{title}</Typography>

          <CloseIcon
            sx={{ margin: "20px 20px 0px 0px", cursor: "pointer" }}
            onClick={() => onClose(false)}
          />
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIcon
            sx={{ margin: "20px 20px 0px 0px", cursor: "pointer" }}
            onClick={() => onClose(false)}
          />
        </div>
      )}

      <DialogContent
        sx={{ display: "flex", alignItems: "center", flexDirection: "column", scrollbarWidth:"thin" }}
      >
        <div className="carousal_helpRequest">
          {previewImages.length > 0 && (
            <Carousel
              autoPlay={false}
              animation="slide"
              indicators={false}
              navButtonsAlwaysVisible
              navButtonsProps={{
                style: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "50%",
                  height: "2rem",
                  width: "2rem",
                },
              }}
              navButtonsWrapperProps={{
                style: {
                  top: "50%",
                  transform: "translateY(-50%)",
                },
              }}
            >
              {previewImages.length > 0 &&
                previewImages.map((image, index) => (
                  <Box key={index} className="preview-carousel">
                    <img
                      src={image.url}
                      alt={`Prodcut ${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                ))}
            </Carousel>
          )}

          {buttons ? buttons : null}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewImageModal;
