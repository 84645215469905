import { Box, Modal } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AddButton } from "../../components/Addbutton";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import "../../styles/login.css";
import TermsAndCondition from "../TermsAndCondition";
import { loginRequest } from "../../features/slices/authSlice";

const LoginPage = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const childRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [disabledLogin, setDisableLogin] = useState(false);

  const authState = useSelector((state) => state.auth);

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email Id").required("email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const scrollToTop = () => {
    if (childRef.current) {
      childRef.current.scrollTop = 0;
    }
  };

  const handleSubmit = useCallback(
    (inputValue) => {
      const { email, password } = inputValue;
      let LoginObj = {
        userName: email,
        password,
      };

      dispatch(loginRequest({ LoginObj, navigate }));
    },
    [dispatch, navigate],
  );

  return (
    <>
      <img src="/daiv-logo.svg" alt="logo" className="daiv_logo" />
      <div container className="login_container">
        {/* this section will open the terms and conditions modal box */}
        <Modal
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
          }}
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="terms_condition_box" ref={childRef}>
            <TermsAndCondition
              modalType={modalType}
              scrollToTop={scrollToTop}
              setOpenModal={setOpenModal}
              setDisableLogin={setDisableLogin}
            />
          </Box>
        </Modal>
        <div className="login_background_image"></div>
        <div className="login_content">
          <div className="greeting_text_container">
            <div className="login_form">
              <div className="welcome_title">Log In</div>

              <div className="welcome_login_btn">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {({
                    setFieldValue,
                    isSubmitting,
                    errors,
                    touched,
                    isValid,
                    dirty,
                  }) => (
                    <Form>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: "20px",
                          marginTop: "25px",
                        }}
                      >
                        <MaterialUIFieldAdapter
                          name="email"
                          type="text"
                          label="Email*"
                          placeholder="Enter email"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          gap: "20px",
                        }}
                      >
                        <MaterialUIFieldAdapter
                          name="password"
                          type="password"
                          placeholder="Password"
                          label="Password*"
                          style={{ width: "100%" }}
                          validatePasswordRules={null}
                        />
                        <div
                          className="login_forgot_password"
                          onClick={() => navigate("/forgot-password")}
                        >
                          Forgot Password
                        </div>
                      </div>

                      <AddButton
                        name="Log In"
                        type="submit"
                        width="100%"
                        padding="18px"
                        background="var(--secondary)"
                        sx={{
                          fontFamily: "var(--font-secondary) !important",
                          fontWeight: 600,
                          fontSize: ".9rem",
                        }}
                        disabled={!(isValid && dirty)}
                      />
                      <div className="welcome_footer">
                        <div className="welcome_footer_1">
                          Don’t have an account?
                        </div>
                        <div
                          className="welcome_footer_2"
                          onClick={() => navigate("/signup")}
                        >
                          Sign Up
                        </div>
                      </div>
                      <div className="welcome_footer">
                        <div
                          className="welcome_footer_1"
                          style={{ marginTop: "1rem" }}
                        >
                          <div style={{ textAlign: "center" }}>
                            By Logging In, you agree to the
                          </div>

                          <div className="terms_and_condition_text welcome_footer_2_mod">
                            <div
                              className="welcome_footer_2"
                              onClick={() => {
                                setModalType("terms_and_condition");
                                setOpenModal(true);
                              }}
                            >
                              Terms & Conditions
                            </div>
                            <div className="welcome_footer_1">and</div>
                            <div
                              className="welcome_footer_2"
                              onClick={() => {
                                setModalType("privacy_policy");
                                setOpenModal(true);
                              }}
                            >
                              Privacy Policy
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default LoginPage;
