import React, { useEffect } from "react";
import { Modal, Box, Typography } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import MaterialUIFieldAdapter from "../components/MaterialUiField";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import "../styles/login.css";
import loginImage from "../assets/images/login_screen.svg";
import { AddButton } from "../components/Addbutton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ResetPassWordRequest } from "../features/slices/authSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const authStates = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [queryParms, setQueryParams] = useSearchParams();

  let tokenParam = queryParms.get("token");

  let passwordRuleList = [
    {
      name: "Minimum Length: 12 characters",
      status: false,
    },
    {
      name: "Character Composition: Must Include A Diverse Mix of Uppercase And Lowercase Letters, Numbers, And Symbols.",
      status: false,
    },
  ];

  // function that will check in real-time whether the new password fiels value is matching the password rules or not
  const validatePasswordRules = (password) => {
    // Rule 1: Check minimum length
    passwordRuleList[0].status = password.length >= 12;

    // Rule 2: Check character composition
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    passwordRuleList[1].status =
      hasUppercase && hasLowercase && hasNumber && hasSymbol;
  };

  // creating a validation schema that will check whether the field values are matching with the schema or not
  const validationSchema = Yup.object({
    new_password: Yup.string()
      .min(12, "Password is too weak")
      .required("New password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handleResetPassword = (password) => {
    if (password) {
      const formData = new FormData();

      formData.append("password", password);
      formData.append("token", tokenParam);

      dispatch(ResetPassWordRequest(formData));
    }
  };

  useEffect(() => {
    if (authStates.ResetPasswordStatus) {
      navigate("/login", { replace: true });
    }
  }, [authStates.ResetPasswordStatus]);

  return (
    <div container className="login_container">
      <img
        src={loginImage}
        alt="login-screen-background-image"
        className="login_background_image"
      />

      <div className="login_content">
        <Typography component="h1" className="greeting_text">
          Welcome to <p className="sub_title">Dr. Rajkumar's Foundation</p>
        </Typography>
        <div className="greeting_text_container">
          <div className="login_form">
            <Typography
              id="modal-modal-title"
              sx={{ color: "#ffffff" }}
              variant="h5"
            >
              Create Your Own Password
            </Typography>

            <Formik
              initialValues={{
                new_password: "",
                confirm_password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleResetPassword(values.new_password);
              }}
            >
              {({ setFieldValue, isSubmitting, errors, touched }) => (
                <Form>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "20px",
                      marginTop: "25px",
                    }}
                  >
                    <MaterialUIFieldAdapter
                      name="new_password"
                      type="password"
                      label="New Password*"
                      style={{ width: "100%" }}
                      validatePasswordRules={validatePasswordRules}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "0.5%",
                    }}
                  >
                    <MaterialUIFieldAdapter
                      name="confirm_password"
                      type="password"
                      label="Confirm Password*"
                      style={{ width: "100%" }}
                      validatePasswordRules={null}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      color: "#ffffff",
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                  >
                    {passwordRuleList.map((rule) => (
                      <p className="password_rules_text" key={rule.name}>
                        <span style={{ color: rule.status ? "green" : "red" }}>
                          {rule.status ? <CheckIcon /> : <CloseIcon />}
                        </span>{" "}
                        {rule.name}
                      </p>
                    ))}
                  </div>

                  <AddButton
                    name="Submit"
                    type="submit"
                    width="100%"
                    padding="18px"
                    disabled={isSubmitting} // Disable button while submitting
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
