import { createSlice } from "@reduxjs/toolkit";

const prasadamSlice = createSlice({
  name: "prasadam",
  initialState: {
    loading: false,
    prasadamData: [],
    error: "",
    prasadamDataById: [],
    Totalpage: [],
    prasadamUploadStatus: false,
    prasadamUpdateStatus: false,
    prasadamActiveStatus: false,
    prasadamGetAllStatus: false,
    prasadamGetByIdStatus: false,
  },
  reducers: {
    fetchPrasadamRequest: (state) => {
      state.loading = true;
      state.prasadamGetAllStatus = false;
      state.prasadamData = [];
      state.error = "";
      state.Totalpage = [];
    },
    fetchPrasadamSuccess: (state, action) => {
      const { res, page } = action.payload;
      state.loading = false;
      state.prasadamGetAllStatus = true;
      state.prasadamData = res;
      state.Totalpage = page;
      state.error = "";
    },
    fetchPrasadamFailure: (state, action) => {
      state.loading = false;
      state.prasadamGetAllStatus = false;
      state.prasadamData = [];
      state.error = action.payload;
      state.Totalpage = [];
    },
    fetchPrasadamUploadRequest: (state) => {
      state.prasadamUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    fetchPrasadamUploadSuccess: (state, action) => {
      state.prasadamUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    fetchPrasadamUploadFailure: (state, action) => {
      state.prasadamUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },

    fetchPrasadamUpdateRequest: (state) => {
      state.prasadamUpdateStatus = false;
      state.loading = true;

      state.error = "";
    },
    fetchPrasadamUpdateSuccess: (state, action) => {
      state.prasadamUpdateStatus = true;
      state.loading = false;
      state.error = "";
    },
    fetchPrasadamUpdateFailure: (state, action) => {
      state.prasadamUpdateStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    fetchPrasadamUpdateByStatusRequest: (state) => {
      state.prasadamActiveStatus = false;
      state.loading = true;
      state.error = "";
    },
    fetchPrasadamUpdateByStatusSuccess: (state, action) => {
      state.prasadamActiveStatus = true;
      state.loading = false;
      state.error = "";
    },
    fetchPrasadamUpdateByStatusFailure: (state, action) => {
      state.prasadamActiveStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    fetchPrasadamGetByIdRequest: (state) => {
      state.prasadamGetByIdStatus = false;
      state.prasadamDataById = [];
      state.error = "";
      state.loading = true;
    },
    fetchPrasadamGetByIdSuccess: (state, action) => {
      state.prasadamGetByIdStatus = true;
      state.prasadamDataById = action.payload;
      state.error = "";
      state.loading = false;
    },
    fetchPrasadamGetByIdFailure: (state, action) => {
      state.prasadamGetByIdStatus = false;
      state.prasadamDataById = [];
      state.error = action.payload;
      state.loading = false;
    },

    toggleStatus: (state) => {
      state.prasadamUploadStatus = false;
      state.prasadamUpdateStatus = false;
      state.prasadamActiveStatus = false;
      state.prasadamGetAllStatus = false;
      state.prasadamGetByIdStatus = false;
    },
  },
});

export const {
  fetchPrasadamRequest,
  fetchPrasadamSuccess,
  fetchPrasadamFailure,

  fetchPrasadamUploadRequest,
  fetchPrasadamUploadSuccess,
  fetchPrasadamUploadFailure,
  fetchPrasadamUpdateRequest,
  fetchPrasadamUpdateSuccess,
  fetchPrasadamUpdateFailure,
  fetchPrasadamUpdateByStatusRequest,
  fetchPrasadamUpdateByStatusSuccess,
  fetchPrasadamUpdateByStatusFailure,
  fetchPrasadamGetByIdRequest,
  fetchPrasadamGetByIdSuccess,
  fetchPrasadamGetByIdFailure,
  toggleStatus,
} = prasadamSlice.actions;

export default prasadamSlice.reducer;
