import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { AddButton, AddButtonWhite } from "../../components/Addbutton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../../styles/purohit/puja-offering-onboarding.css";
import { Chip } from "@mui/material";
import docVerifyAnimation from "../../components/DocVerify.json";
import LottieWithoutLibrary from "../../components/LottieWithoutLibrary";

const AddPuja = () => {
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState({
    options1: [],
    options2: [],
    options3: [],
    options4: [],
    options5: [],
    options6: [],
    options7: [],
    options8: [],
    options9: [],
  });

  const handleChange = (event, setName) => {
    const value = event.target.value;
    setSelectedOptions((prevState) => ({
      ...prevState,
      [setName]: prevState[setName].includes(value)
        ? prevState[setName].filter((item) => item !== value) // Unselect if already selected
        : [...prevState[setName], value], // Select if not selected
    }));
  };

  const options1 = {
    label: "Sandhya Vandanam",
    options: ["Sandhya Vandanam", "Ganesh Puja", "Navagraha Puja"],
  };
  const options2 = {
    label: "Festival Related Puja",
    options: ["Diwali Puja", "Holi Puja", "Dussehra Puja"],
  };
  const options3 = {
    label: "Life Event",
    options: ["Birth/Naming Ceremonies", "Namakaran", "Annaprashan"],
  };
  const options4 = {
    label: "Marriage Ceremonies",
    options: ["Vivah Puja", "Saptapadi"],
  };
  const options5 = {
    label: "Festival Related Puja",
    options: ["Sandhya Vandanam", "Ganesh Puja", "Navagraha Puja"],
  };
  const options6 = {
    label: "Festival Related Puja",
    options: ["Sandhya Vandanam", "Ganesh Puja", "Navagraha Puja"],
  };
  const options7 = {
    label: "Festival Related Puja",
    options: ["Sandhya Vandanam", "Ganesh Puja", "Navagraha Puja"],
  };
  const options8 = {
    label: "Festival Related Puja",
    options: ["Sandhya Vandanam", "Ganesh Puja", "Navagraha Puja"],
  };
  const options9 = {
    label: "Festival Related Puja",
    options: ["Sandhya Vandanam", "Ganesh Puja", "Navagraha Puja"],
  };

  const handleRemoveSelectedItem = (setName, index) => {
    const updatedVariants = [...selectedOptions[setName]];

    setSelectedOptions({
      ...selectedOptions,
      [setName]: [
        ...updatedVariants.slice(0, index),
        ...updatedVariants.slice(index + 1),
      ],
    });
  };
  return (
    <div>
      <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <ArrowBackIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(`/puja-management`)}
        />
        <Typography variant="h5" sx={{ mt: 2, mb: 2, color: "black" }}>
          Add Puja
        </Typography>
      </span>
      <div className="heading">Puja Offerings & Services</div>

      <div style={{ height: "70vh", overflow: "auto" }}>
        {Object.keys(selectedOptions).map((setName, index) => {
          const options = setName === "options1" ? options1 : options2;
          return (
            <div key={setName}>
              <div className="puja_sub_heading">{options.label}</div>
              <div className="checkbox_tags_card">
                <div className="checkbox_card">
                  {options.options.map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          value={option}
                          checked={selectedOptions[setName].includes(option)}
                          onChange={(e) => handleChange(e, setName)}
                          sx={{
                            color: "#67687C", // When unchecked
                            "&.Mui-checked": {
                              color: "#FF6F01", // When checked
                            },
                            borderRadius: "16px !important", // Custom border radius
                            "& .MuiSvgIcon-root": {
                              borderRadius: "16px !important",
                            },
                          }}
                        />
                      }
                      className="checkbox"
                      label={option}
                    />
                  ))}
                </div>
                <div>
                  {selectedOptions[setName].map((color, index) => (
                    <Chip
                      key={color}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fill: "black !important", // Ensures the icon is black
                        },
                      }}
                      label={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: ".8rem",
                          }}
                        >
                          {color}
                        </div>
                      }
                      onDelete={() => handleRemoveSelectedItem(setName, index)}
                      className="product-color-select-chip"
                    />
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="cancel_save">
        <AddButton name="Add" />
      </div>
    </div>
  );
};

export default AddPuja;
