import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, FormControl, MenuItem, Popover, Select } from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddButton } from "../../components/Addbutton";
import SearchInput from "../../components/CommonSearchBar";
import CommonTable from "../../components/CommonTable";
import { CustomLoader } from "../../components/CustomLoader";
import {
  downloadSevaOrder,
  getSevaOrder,
} from "../../features/slices/orderSlice";

const OptionList = [
  "RECEIVED",
  "COMPLETED",
  "VIDEO_SENT",
  "PRASAD_DISPATCHED",
  "PRASAD_DELIVERED",
  "REJECTED",
];
const SevaOrders = React.memo(() => {
  const { sevaList, loading, pageCount } = useSelector((state) => state.order);
  const [dropdownValue, setDropdownValue] = useState("");
  const dispatch = useDispatch();
  const columns = [
    {
      field: "orderId",
      headerName: "Order ID",
      renderCell: (params, row) => {
        return (
          <span
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() =>
              navigate(`/order-management/Seva_Orders/view?OrderId=${params}`)
            }
          >
            <p style={{ marginLeft: "0.5rem" }}>{params}</p>
          </span>
        );
      },
    },
    { field: "name", headerName: "User Name" },
    { field: "phoneNumber", headerName: "Phone No." },
    { field: "sevaName", headerName: "Seva Name" },
    {
      field: "orderDate",
      headerName: "Order Date",
      renderCell: (params) => <span>{dayjs(params).format("YYYY-MM-DD")}</span>,
    },
    { field: "amount", headerName: "Amount" },
    {
      field: "status",
      headerName: "Order Status",
      renderCell: (params, row) => {
        return (
          <p
            className="statusBox"
            style={{
              color:
                params === "Completed" ||
                params === "Delivered" ||
                params === "RECEIVED"
                  ? "#47B881" // Green text for Accepted and Delivered
                  : params === "Rejected"
                    ? "#1D1D27" // Black text for Rejected
                    : params === "Pending"
                      ? "#F64C4C" // Red text for Pending
                      : "#1D1D27", // Default text color

              background:
                params === "Completed" ||
                params === "Delivered" ||
                params === "RECEIVED"
                  ? "#E9FFF4" // Green color for Accepted and Delivered
                  : params === "Rejected"
                    ? "#E4E4E4" // Grey background for Rejected
                    : params === "Pending"
                      ? "#FEF2F2" // Light pink background for Pending
                      : "transparent", // Default background color
            }}
          >
            {params}
          </p>
        );
      },
    },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  useEffect(() => {
    let query;
    if (searchTerm || dropdownValue) {
      query = `?pageNumber=0&pageSize=10&search=${searchTerm}&status=${dropdownValue}`;
    } else {
      query = `?pageNumber=${page - 1}&pageSize=10&status=`;
    }
    dispatch(getSevaOrder(query));
  }, [dispatch, searchTerm, page, dropdownValue]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [selectedValue, setSelectedValue] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (e) => {
    setDropdownValue(selectedValue);
    handleClose();
  };

  const [booleanValue, setBooleanValue] = useState(null);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setBooleanValue(value === "true" ? true : value === "false" ? false : null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      className={"heading"}
      sx={{
        textWrap: "nowrap",
        paddingY: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0rem 0px 1rem 0px",
        }}
      >
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="filter_popover">
            <div className="filter_title_container">
              <img src="/filter.svg" alt="filter" />
              <div className="filter_title">Filter</div>
            </div>
            <div>
              <div className="filter_av_reset">
                <div className="filter_label">Status*</div>
                <div
                  className="filter_reset"
                  onClick={() => setSelectedValue(null)}
                >
                  Reset
                </div>
              </div>

              <FormControl fullWidth>
                <Select value={selectedValue} onChange={handleSelectChange}>
                  {OptionList.map((option) => (
                    <MenuItem value={option}>{option}</MenuItem>
                  ))}
                </Select>
                <div style={{ marginTop: "1rem", textAlign: "center" }}>
                  <AddButton
                    name="Cancel"
                    sx={{
                      backgroundColor: "white !important",
                      color: "var(--secondary) !important",
                      border: "1px solid var(--secondary) ",
                      marginRight: "1rem",
                    }}
                    onClick={handleClose}
                  />
                  <AddButton name="Apply" onClick={handleSubmit} />
                </div>
              </FormControl>
            </div>
          </div>
        </Popover>
        <CustomLoader
          loading={loading}
          message="Please wait, We are Fetching Prasadham orders..."
        />
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          Order List
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <SearchInput setSearchTerm={setSearchTerm} />
          <AddButton
            icon={<ArrowDropDownIcon />}
            name="Filter By"
            borderColor="var(--secondary)"
            background="#F5F5F5"
            color="var(--secondary)"
            padding="1.15rem"
            onClick={handleClick}
          />
          {/* <FormControl
            fullWidth
            variant="outlined"
            className="onboarding-custom-select"
            sx={{ width: "15rem", marginBottom: "0.5rem" }}
          >
            <Autocomplete
              sx={{ borderRadius: "1rem" }}
              options={OptionList}
              value={dropdownValue}
              onChange={(e, newValue) => setDropdownValue(newValue)}
              renderTags={() => null} // Hides tags for multi-selection
              renderOption={(props, option, { selected }) => (
                <span
                  {...props}
                  style={{
                    backgroundColor: selected ? "var(--black)" : "transparent",
                    color: selected ? "var(--primary)" : "black",
                    pointerEvents: selected ? "none" : "auto",
                  }}
                >
                  {option}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Filter By Status"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {dropdownValue && (
                          <ClearIcon onClick={() => setDropdownValue("")} />
                        )}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormControl> */}
          <AddButton
            name="Download"
            background="#F5F5F5"
            color="var(--secondary)"
            borderColor="var(--secondary)"
            padding="1.15rem"
            onClick={() => dispatch(downloadSevaOrder())}
          />
        </Box>
      </div>

      <CommonTable
        columns={columns}
        data={sevaList}
        page={page}
        count={pageCount}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </Box>
  );
});

export default SevaOrders;
