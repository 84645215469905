import { Form, Formik } from "formik";
import React, { useCallback, useEffect } from "react";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import { fetchAdminDetails } from "../../features/slices/adminDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoader } from "../../components/CustomLoader";

const AdminDetails = () => {
  const dispatch = useDispatch();
  const { adminData, loading } = useSelector((state) => state.adminDetail);

  const getAdminDetails = useCallback(() => {
    dispatch(fetchAdminDetails());
  }, [dispatch]);

  useEffect(() => {
    getAdminDetails();
  }, []);

  return (
    <div>
      <CustomLoader
        loading={loading}
        message="Please wait, Fetching Admin Details..."
      />

      <div
        style={{ height: "68vh", overflowY: "auto", scrollbarWidth: "thin" }}
      >
          <div className="heading">Admin Information</div>
        

        <Formik
          initialValues={{
            name: adminData?.fullName,
            address: adminData?.address,
            city: adminData?.city,
            state: adminData?.state,
            country: adminData?.country,
            pincode: adminData?.pinCode,
            phone_number: adminData?.phoneNumber,
            email: adminData?.email,
            contact_type: adminData?.preferredContactMethod ? adminData?.preferredContactMethod?.join(", ") : "",
          }}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ setFieldValue, isSubmitting, errors, touched }) => (
            <Form style={{ paddingBottom: "2rem" }}>
              <div className="onboarding-sub_heading" style={{ margin: "1rem 0" }}>
                Basic Admin Information
              </div>
              <div style={{ display: "flex", width: "48%", gap: "1rem" }}>
                <MaterialUIFieldAdapter
                  name="name"
                  type="text"
                  label="Full Name*"
                  placeholder="Enter Full Name"
                  disabled
                />
              </div>

              <div className="onboarding-sub_heading" style={{ margin: "1rem 0" }}>
                Location Details 
              </div>
              <div style={{ display: "flex", width: "48%", gap: "1rem" }}>
                <MaterialUIFieldAdapter
                  name="address"
                  type="text"
                  label="Address*"
                  placeholder="Enter address"
                  disabled
                />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="city"
                  type="text"
                  label="Select your city*"
                  placeholder="Select your city"
                  style={{ width: "100%" }}
                  disabled={true}
                />
                <MaterialUIFieldAdapter
                  name="state"
                  type="text"
                  label="Select your state*"
                  placeholder="Select your state"
                  style={{ width: "100%" }}
                  disabled={true}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="country"
                  type="text"
                  label="Select your country*"
                  placeholder="Select your country"
                  style={{ width: "100%" }}
                  disabled={true}
                />
                <MaterialUIFieldAdapter
                  name="pincode"
                  type="text"
                  label="Pincode*"
                  placeholder="Enter your pincode"
                  style={{ width: "100%" }}
                  disabled={true}
                />
              </div>

              <div className="onboarding-sub_heading" style={{ margin: "1rem 0" }}>
                Contact Information
              </div>
              <div style={{ display: "flex", width: "98%", gap: "1rem" }}>
                <MaterialUIFieldAdapter
                  name="phone_number"
                  type="text"
                  label="Phone number*"
                  placeholder="Enter Phone Number"
                  disabled
                />
                <div style={{ width: "100%" }}>
                  <MaterialUIFieldAdapter
                    name="email"
                    type="text"
                    label="Email ID*"
                    placeholder="Enter Email ID"
                    style={{ width: "100%" }}
                    disabled
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "98%",
                  gap: "20px",
                }}
              >
                <MaterialUIFieldAdapter
                  name="contact_type"
                  type="text"
                  label="Preferred contact method"
                  placeholder="Select preferred contact method  "
                  style={{ width: "49%" }}
                  disabled={true}
                />
              </div>
              {/* <AddButton
                name={actionType === "Add" ? "Upload" : "Save"}
                type="submit"
              /> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminDetails;
