import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddButton } from "../../components/Addbutton";
import { useNavigate } from "react-router-dom";
import { fetchOnBoardingStatusRequest } from "../../features/slices/onBordingSlice";
import { useDispatch, useSelector } from "react-redux";
import { documentStatusUpdateRequest } from "../../features/slices/authSlice";

const DocumentChecklist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentState, setCurrentState] = useState();

  const state = useSelector((state) => state.onbording);

  useEffect(() => {
    dispatch(fetchOnBoardingStatusRequest());
  }, [dispatch]);

  useEffect(() => {
    if (
      state.onBoardingStatusDtata?.currentStatus === "BASIC_DETAILS" ||
      state.onBoardingStatusDtata?.currentStatus === "DOCUMENTS" ||
      state.onBoardingStatusDtata?.currentStatus === "DESCRIPTION" ||
      state.onBoardingStatusDtata?.currentStatus === "PHOTO_VIDEO" ||
      state.onBoardingStatusDtata?.currentStatus === "ADMIN_INFO" ||
      state.onBoardingStatusDtata?.currentStatus === "SEVA_DETAILS" ||
      state.onBoardingStatusDtata?.currentStatus === "PRASHADAM" ||
      state.onBoardingStatusDtata?.currentStatus === "DONATION" ||
      state.onBoardingStatusDtata?.currentStatus === "PAYMENT" ||
      state.onBoardingStatusDtata?.currentStatus === "APPROVED" ||
      state.onBoardingStatusDtata?.currentStatus === "WAITING_FOR_APPROVAL" ||
      state.onBoardingStatusDtata?.currentStatus === "REJECTED" ||
      state.onBoardingStatusDtata?.currentStatus === "PREVIEW"
    ) {
      const role = localStorage.getItem("role");
      if (role === "ROLE_TEMPLE_OWNER") {
        navigate("/Onboarding");
      } else if (role === "ROLE_PUROHIT") {
        navigate("/purohit-onboarding");
      } else if (role === "ROLE_SELLER_ECOMM") {
        navigate("/product-onboarding");
      }
    }
  }, [state.onBoardingStatusDtata.currentStatus]);

  const handleSubmit = () => {
    dispatch(documentStatusUpdateRequest({ navigate }));
  };

  return (
    <>
      <div className="document_background_image"></div>
      <Box className="document_checklist_container">
        <Box className="document_checklist">
          <Box className="document_checklist_title">Document Checklist</Box>
          <Box className="document_checklist_sub">
            To ensure a smooth onboarding experience, please have these
            documents ready before we begin.
          </Box>
          <Box>
            <ul className="document_checklist_list">
              <li>
                ID Proof:{" "}
                <span className="document_checklist_list_sub">
                  Temple PAN Card & Aadhar Card & Pan Card Of Admin
                </span>
              </li>
              <li>
                Documentation & Certificates:{" "}
                <span className="document_checklist_list_sub">
                  Temple / Trust Registration Certificate, Donation Certificate
                  (If applicable)
                </span>
              </li>
              <li>
                Bank Details:{" "}
                <span className="document_checklist_list_sub">
                  Account name, Account number, IFSC code, Bank statement
                </span>
              </li>
              <li>
                Photos & Videos:{" "}
                <span className="document_checklist_list_sub">
                  Photos & Videos of the Temple
                </span>
              </li>
            </ul>
          </Box>
        </Box>
        <Box className="login_form continue_onboarding">
          <Box className="continue_onboarding_title">
            Welcome to DAIV Onboarding
          </Box>
          <Box className="continue_onboarding_sub">
            Namaste! We are so happy to have you with us at DAIV. May your path
            be filled with light and your heart with devotion.
          </Box>
          <img
            src="/onboarding.svg"
            alt="onboarding"
            className="onboarding_img"
          />
          <AddButton
            name="Continue to Onboarding"
            type="submit"
            width="100%"
            padding="1.3rem"
            background="var(--secondary)"
            // disabled={ isSubmitting }
            sx={{
              fontFamily: "var(--font-secondary) !important",
              fontWeight: "400 !important",
              fontSize: ".9rem",
              marginTop: "1rem",
            }}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </>
  );
};

export default DocumentChecklist;
