import { Box, Grid2, InputAdornment, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import "../../styles/viewProduct.css";
import MultipleImageUpload from "../../components/MultipleImageUpload";
import Slider from "react-slick";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import PledgeOthersCards from "../../components/cards/PledgeOthersCards";
import CommonTable from "../../components/CommonTable";
import { AddButton } from "../../components/Addbutton";
import DropdownModal from "../../components/DropdownModal";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const tableRows = [
    {
        id: 1,
        name: "Warehouse 1",
        stock: 50,
        value: 0
    },
    {
        id: 2,
        name: "Warehouse 2",
        stock: 90,
        value: 0
    },
    {
        id: 3,
        name: "Warehouse 3",
        stock: 10,
        value: 0
    },
    {
        id: 4,
        name: "Warehouse 4",
        stock: 90,
        value: 0
    },
    {
        id: 5,
        name: "Warehouse 5",
        stock: 10,
        value: 0
    }
];

const ViewProduct = React.memo(() => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(5);
    const [open, setOpen] = useState(false);
    const [tableData, setTableData] = useState(tableRows);

    const handleChangePage = useCallback((val) => {
        setPage(val);
    });

    const handleModalOpen = () => {
        setOpen(!open);
    };

    const handleIncrement = (row) => {
        row.value += 10;
        row.stock += row.value;

        setTableData(tableData.map((item) => {
            return item.id === row.id? row: item;
        }));
    }

    const handleDecrement = (row) => {
        if(row.value > 0 && row.stock > 0) {
            row.stock -= row.value;
            row.value -= 10;
        }

        setTableData(tableData.map((item) => {
            return item.id === row.id? row: item;
        }));
    }

    const columns  = [
        { field: "name", headerName: "Warehouse Name" },
        { field: "stock", headerName: "Available Stock" },
        {
            field: "value",
            headerName: "Add Stock",
            renderCell: (params, row) => (
              <span style={{ display: "flex" }}>
                <TextField
                    sx={{height: '0.65rem', width: '7rem', mb: 4}}
                    variant="outlined"
                    value={row?.value}
                    disabled
                    //   onChange={(e) => setMessage(e.target.value)}
                    // className="customTextField"
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end" sx={{ display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
                            <ArrowDropUpIcon
                                onClick={() => { handleIncrement(row) }}
                                className="icon_button_inputbox"
                                size="small"
                            />
                            <ArrowDropDownIcon
                                onClick={() => { handleDecrement(row) }}
                                className="icon_button_inputbox"
                                size="small"
                            />
                        </InputAdornment>
                        ),
                    }}
                />
              </span>
            ),
        }
    ];

    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: (
          <ArrowBackIosIcon
            sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
          />
        ),
        nextArrow: (
          <ArrowForwardIosIcon
            sx={{ color: "black", ":hover": { color: "#EC9F4F" } }}
          />
        ),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
    };

    return (
        <>
            {
                open && (
                    <DropdownModal 
                        open={open}
                        onClose={handleModalOpen}
                        tableData={tableData}
                        setTableData={setTableData}
                    />
                )
            }

            <Box className="product-main-container">
                <span style={{ display: "flex", alignItems: "center", gap: "1rem",  marginBottom: "1rem"}}>
                    <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={() => navigate(`/inventory-management`)} />

                    <Box
                        className={"heading"}
                        sx={{
                            textWrap: "nowrap",
                            paddingY: "10px"
                        }}
                    >
                        Inventory Management
                    </Box>
                </span>

                <Box className="product-details-container">
                    <>
                        <Box
                            component="img"
                            src="https://via.placeholder.com/600x200" 
                            alt="Preview"
                            sx={{
                                width: "100%",
                                height: "22%",
                                objectFit: "cover",
                                borderRadius: "8px",
                                marginBottom: "16px",
                            }}
                        />

                        <Box>
                            <div className="heading">Puja Thali</div>

                            <PledgeOthersCards
                                editButton={false}
                                showView={false}
                                borderColor="var(--primary-2)"
                                content={
                                    <div>
                                        <Grid2 container spacing={5}>
                                            <Grid2 size={{ xs: 7, md: 6 }}>
                                                <Box>
                                                    <div className="sub_heading">Product Details</div>

                                                    <Box>
                                                        <div className="booking_span">
                                                            Product Name: <span> Puja Thali</span>
                                                        </div>
                                                        <div className="booking_span">
                                                            Category: <span> Oct 25, 2024</span>
                                                        </div>
                                                        <div className="booking_span">
                                                            Sub Category: <span>10:00 AM</span>
                                                        </div>
                                                        <div className="booking_span">
                                                            Warehouse: <span>91-XXXXXXXXX</span>
                                                        </div>
                                                        <div className="booking_span">
                                                            Location:
                                                            <span>123 Temple Street, City Name</span>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            </Grid2>

                                            <Grid2 size={{ xs: 5, md: 6 }}>
                                                <Box>
                                                    <div className="sub_heading">Billing Details</div>

                                                    <Box>
                                                        <div className="booking_span">
                                                            MRP (without Samagri): <span>5000</span>
                                                        </div>
                                                        <div className="booking_span">
                                                            Selling Price: <span>₹5000</span>
                                                        </div>
                                                        <div className="booking_span">
                                                            GST: <span>₹250</span>
                                                        </div>
                                                        <div className="booking_span">
                                                            Shipping Cost: <span></span>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            </Grid2>
                                        </Grid2>

                                        <Grid2 container spacing={5                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }>
                                            <Grid2 size={{ xs: 12, md: 12 }}>
                                                <Box>
                                                    <div className="sub_heading">Product Description</div>

                                                    <Box>
                                                        <div className="booking_span">
                                                            Units (without Samagri): <span>5000</span>
                                                        </div>
                                                        <div className="booking_span">
                                                            Description About Product:&nbsp;
                                                            <span>
                                                                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis 
                                                                tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus 
                                                                elit sed risus. Maecenas eget condimentum velit 
                                                            </span>
                                                        </div>
                                                        <div className="booking_span">
                                                            Feature & Benefits:&nbsp;
                                                            <span>
                                                            Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis 
                                                            tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit 
                                                            sed risus. Maecenas eget condimentum velit
                                                            </span>
                                                        </div>
                                                        <div className="booking_span">
                                                            Keywords / Tags: <span>Dorem ipsum dolor sit amet</span>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            </Grid2>
                                        </Grid2>
                                    </div>
                                }
                            />
                        </Box>

                        <Box sx={{ mb: 3 }}>
                            <div className="heading">Warehouse List</div>
                            
                            <div style={{ margin: '2.5rem 0', padding: '0 1rem' }}>
                                <CommonTable
                                    columns={columns}
                                    data={tableData}
                                    page={page}
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                />

                                <div style={{ margin: '1.5rem 0' }}>   
                                    <AddButton 
                                        name="Add More" 
                                        icon={<AddIcon />} 
                                        onClick={handleModalOpen}
                                        borderColor="var(--secondary)"
                                        background="var(--grey-dark)"
                                        color="var(--secondary)"
                                        padding="1.3rem 1.15rem"
                                    />
                                </div>
                            </div>

                            
                        </Box>
                    </>
                </Box>
            </Box>
        </>
    )
});

export default ViewProduct;
