import React, { useState, useEffect } from "react";
import { Button, Box, Avatar, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import "../styles/image-upload.css";
import { FileUploader } from "react-drag-drop-files";
import CloseIcon from "@mui/icons-material/Close";

const fileTypes = ["jpeg", "png", "jpg"];

function ImageUpload({ onFileSelect, label, valueUrl = "", fileFormat }) {
  const [file, setFile] = useState(null); // State to hold the uploaded file
  const [previewUrl, setPreviewUrl] = useState(valueUrl || ""); // State to hold the preview URL

  const handleUploadClick = (event) => {
    const selectedFile = event[0];
    if (selectedFile) {
      // Handle file processing or updating state
      setFile(selectedFile);
      const fileUrl = URL.createObjectURL(selectedFile);
      onFileSelect(fileUrl, selectedFile);
      setPreviewUrl(fileUrl);
    }
  };

  useEffect(() => {
    if (valueUrl) {
      setPreviewUrl(valueUrl);
    }
  }, [valueUrl]);

  const handleClose = () => {
    setFile(null);
    setPreviewUrl("");
  };

  const content = (
    <Box className="image_upload_box_2">
      <Box className="image_upload_content_draganddrop">
        <img src="/draganddrog.svg" alt="draganddrop" />
        Drag and Drop your file here
      </Box>
      <Box className="image_upload_content_or">OR</Box>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id={`file-upload-${label}`}
        multiple
        type="file"
        className="image_upload_img"
        onChange={handleUploadClick}
      />
      <Button
        component="span"
        sx={{
          color: "#000",
          marginBottom: "8px",
        }}
        className="image_upload_btn"
      >
        Browse More Files
      </Button>
      <Box className="img_upload_text">
        Maximum File Size:10MB <br />
        Supported File Types: PNG, JPG <br />
      </Box>
      <label htmlFor={`file-upload-${label}`}></label>
    </Box>
  );

  return (
    <Box className="image_upload_container">
      <Box
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
          mb: ".5rem",
        }}
      >
        <Typography sx={{ color: "var(--off-white)" }}>
          {label || "Upload Image"}
        </Typography>
      </Box>
      <Box
        className="image_upload_content"
        sx={{
          justifyContent: previewUrl ? "space-between" : "center",
        }}
      >
        <Box>
          {previewUrl && (
            <Box className="img_upload_close_container">
              <CloseIcon className="img_upload_close" onClick={handleClose} />
            </Box>
          )}
          {previewUrl && (
            <img
              src={previewUrl}
              alt="previewUrl"
              style={{
                width: "10rem",
                height: "10rem",
                marginBottom: "8px",
              }}
            />
          )}
        </Box>
        <Box>
          <FileUploader
            handleChange={handleUploadClick}
            name="file"
            types={fileTypes}
            multiple={true}
            label="Upload or drop two CSV files here"
            children={content}
          />
        </Box>
      </Box>
    </Box>
  );
}

ImageUpload.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  valueUrl: PropTypes.string,
};

export default ImageUpload;
