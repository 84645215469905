import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSettingRequest,
  fetchSettingSuccess,
  fetchSettingFailure,
  updateSetting,
  updateSettingSuccess,
  updateSettingFailure,
  removeImageSuccess,
  removeImageFailure,
  removeImage,
} from "../slices/settingSlice";
import {
  getRequest,
  postRequestUsingFormData,
  putRequest,
} from "../../api/apiActions";
import {
  getSettingUrls,
  removeProfileImageUrl,
  updateSettingUrl,
} from "../../api/apiUrls";

const getSettingCall = () => getRequest(getSettingUrls);
const postSettingCall = (data) =>
  postRequestUsingFormData(updateSettingUrl, data);
const removeImageCall = () => putRequest(removeProfileImageUrl);

function* fetchSettings(action) {
  try {
    const response = yield call(getSettingCall);

    if (response.status === 200) {
      yield put(fetchSettingSuccess(response.data));
    } else {
      yield put(fetchSettingFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchSettingFailure(error?.response?.data));
  }
}

function* updateSettings(action) {
  try {
    const response = yield call(postSettingCall, action.payload.data);

    if (response.status === 200) {
      yield put(updateSettingSuccess(response.data));
    } else {
      yield put(updateSettingFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(updateSettingFailure(error?.response?.data));
  }
}

function* removeProfileImage(action) {
  try {
    const response = yield call(removeImageCall);

    if (response.status === 200) {
      yield put(removeImageSuccess(response.data));
    } else {
      yield put(removeImageFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(removeImageFailure(error?.response?.data));
  }
}

export function* settingSaga() {
  yield takeLatest(fetchSettingRequest.type, fetchSettings);
  yield takeLatest(updateSetting.type, updateSettings);
  yield takeLatest(removeImage.type, removeProfileImage);
}
