import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Close } from "@mui/icons-material";
import { AddButton } from "../../../components/Addbutton";
import {
  fetchPrasadamUploadRequest,
  toggleStatus,
  fetchPrasadamGetByIdRequest,
  fetchPrasadamUpdateRequest,
} from "../../../features/slices/prasadamSlice";

import { useDispatch, useSelector } from "react-redux";

import { CustomLoader } from "../../../components/CustomLoader";

const AddEditPrasadam = React.memo(({ actionType }) => {
  const initialFormState = {
    prasadamName: "",
    prasadamPrice: "",
    prasadamImage: [],
    prasadamPackageImage: [],
    availableStock: "",
    quantity: "",
    prasadamDescription: "",
    bookingAvailability: "",
  };

  const initialErrorState = {
    prasadamName: "",
    prasadamPrice: "",
    prasadamImage: "",
    prasadamPackageImage: "",
    availableStock: "",
    prasadamDescription: "",
    bookingAvailability: "",
  };

  const stockList = [
    { label: "50", value: "50" },
    { label: "100", value: "100" },
    { label: "250", value: "250" },
  ];

  const quantityList = [
    { label: "50g", value: "50g" },
    { label: "100g", value: "100g" },
    { label: "250g", value: "250g" },
  ];

  const bookingAvailabilityList = [
    { value: "ONLINE", label: "Online" },
    { value: "OFFLINE", label: "Offline" },
  ];
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const prasadamId = query.get("prasadamId");
  const navigate = useNavigate();
  const [prasadamData, setPrasadamData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const {
    prasadamUploadStatus,
    prasadamUpdateStatus,
    loading,
    prasadamDataById,
  } = useSelector((state) => state.prasadam);

  const handleChange = (field) => (event, newValue) => {
    const value = event.target.value;

    setPrasadamData((prev) => ({
      ...prev,
      [field]: newValue ? newValue?.value : value,
    }));

    if (field === "availableStock" || field === "bookingAvailability") {
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const MAX_IMAGE_SIZE = 2 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 20 * 1024 * 1024;

  const handleImageUpload = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;
    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (
      selectedFiles?.length + (prasadamData[field]?.length || 0) >
      MAX_FILES
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type === "video/mp4";
      const isValidImageFormat = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ].includes(file.type);
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (
        (uploadType === "image/*" && (!isImage || !isValidImageFormat)) ||
        (uploadType === "video/mp4" && !isVideo)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Invalid file type. Please upload ${
            uploadType === "image/*" ? "JPEG, JPG, or PNG images" : "MP4 videos"
          }.`,
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "20MB" : "50MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPrasadamData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const removeUpload = (field, index) => {
    setPrasadamData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = useCallback(() => {
    const newErrors = { ...initialErrorState };
    let isValid = true;

    if (!prasadamData.prasadamName) {
      newErrors.prasadamName = "Prasadam Name is required";
      isValid = false;
    }

    if (!prasadamData.prasadamPrice) {
      newErrors.prasadamPrice = "Prasadam Price is required";
      isValid = false;
    }

    if (prasadamData.prasadamImage.length === 0) {
      newErrors.prasadamImage = "At least one image is required";
      isValid = false;
    }

    if (prasadamData.prasadamPackageImage.length === 0) {
      newErrors.prasadamPackageImage = "At least one image is required";
      isValid = false;
    }

    if (!prasadamData.availableStock) {
      newErrors.availableStock = "Available Stock is required";
      isValid = false;
    }

    if (!prasadamData.prasadamDescription) {
      newErrors.prasadamDescription = "Description is required";
      isValid = false;
    }

    if (!prasadamData.bookingAvailability) {
      newErrors.bookingAvailability = "Booking Availability is required";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      let formData = new FormData();

      if (!prasadamData?.prasadamPackageImage[0]?.etag) {
        formData.append(
          "packagePhoto",
          prasadamData?.prasadamPackageImage[0]?.file,
        );
      }
      if (!prasadamData?.prasadamImage[0]?.etag) {
        formData.append("photo", prasadamData?.prasadamImage[0]?.file);
      }

      formData.append("name", prasadamData?.prasadamName);
      formData.append("price", prasadamData?.prasadamPrice);
      formData.append("availableStock", prasadamData?.availableStock);
      formData.append("quantity", prasadamData?.quantity);
      formData.append("bookingAvailability", prasadamData?.bookingAvailability);

      formData.append("description", prasadamData?.prasadamDescription);
      if (prasadamId) {
        dispatch(fetchPrasadamUpdateRequest({ formData, prasadamId }));
      } else {
        dispatch(fetchPrasadamUploadRequest(formData));
      }
    }
  }, [dispatch, prasadamData]);

  useEffect(() => {
    if (prasadamUploadStatus || prasadamUpdateStatus) {
      navigate(`/prasadam-management`);
      dispatch(toggleStatus());
    }
  }, [prasadamUploadStatus, prasadamUpdateStatus]);

  useEffect(() => {
    if (prasadamId) {
      dispatch(fetchPrasadamGetByIdRequest(prasadamId));
    }
  }, [prasadamId]);

  useEffect(() => {
    if (prasadamDataById && actionType === "Edit") {
      setPrasadamData({
        ...prasadamData,
        prasadamName: prasadamDataById?.name,
        prasadamPrice: prasadamDataById?.price,
        prasadamDescription: prasadamDataById?.description,
        availableStock: prasadamDataById?.availableStock,
        quantity: prasadamDataById?.quantity,
        bookingAvailability: prasadamDataById?.bookingAvailability,

        prasadamPackageImage: prasadamDataById?.packagePhoto
          ? mapImages([prasadamDataById?.packagePhoto])
          : [],

        prasadamImage: prasadamDataById?.prasadhamPhoto
          ? mapImages([prasadamDataById?.prasadhamPhoto])
          : [],
      });
    }
  }, [prasadamDataById]);

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };
  const [isDragOver, setIsDragOver] = useState(false);
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (field, e, maxFiles, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);

    handleImageUpload(field, e, maxFiles, uploadType);
  };

  return (
    <>
      <CustomLoader
        loading={loading}
        message="Please wait, Uploading Prasadam..."
      />

      <span className="back-arrow">
        <ArrowBackIcon
          className="arrow_icon"
          onClick={() => navigate(`/prasadam-management`)}
        />

        <Box className={["heading"]}>
          {actionType === "Add" ? "Add Prasadam" : "Edit Prasadam"}
        </Box>
      </span>

      <Box style={{ marginTop: "1rem" }}>
        <div className="onboarding-sub_heading">Prasadam Details</div>

        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Prasadam Name*
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              value={prasadamData?.prasadamName}
              onChange={handleChange("prasadamName")}
              className="custom-textfield"
              placeholder="Enter prasadam name"
            />

            {errors?.prasadamName && (
              <Typography color="error">{errors?.prasadamName}</Typography>
            )}
          </Grid2>

          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Prasadam Price*
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              value={prasadamData?.prasadamPrice}
              onChange={handleChange("prasadamPrice")}
              className="custom-textfield"
              placeholder="Enter prasadam price"
            />

            {errors?.prasadamPrice && (
              <Typography color="error">{errors?.prasadamPrice}</Typography>
            )}
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Box>
              <Typography variant="subtitle1" className="prasadam-form-title">
                Prasadam Photos*
              </Typography>

              <Box
                className="file-upload-box "
                onDrop={(e) => handleDrop("prasadamImage", e, 1, "image/*")}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {prasadamData?.prasadamImage?.length > 0 ? (
                  <Box className="file-preview-image-container">
                    {prasadamData?.prasadamImage?.map((item, index) => (
                      <Box className="prasadam-image-container">
                        <Close
                          className="song-image-close-icon"
                          onClick={() => removeUpload("prasadamImage", index)}
                        />

                        <img
                          src={item.url}
                          className="prasadam-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}

                <Typography className="file-upload-instructions">
                  <UploadFileIcon className="upload-file-icon" />
                  Drop Your File Here
                </Typography>

                <Typography className="file-upload-instructions">OR</Typography>
                <input
                  ref={fileInputRef}
                  accept="image/*"
                  type="file"
                  className="prasadam-file-input"
                  id="prasadam-upload"
                  name="prasadamImage"
                  onChange={(e) =>
                    handleImageUpload("prasadamImage", e, 1, "image/*")
                  }
                />
                <label htmlFor="prasadam-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    className="browse-button"
                  >
                    Browse files
                  </Button>
                </label>

                <Typography className="file-upload-instructions">
                  Max: 20MB, JPG, PNG
                </Typography>
              </Box>
            </Box>

            {errors?.prasadamImage && (
              <Typography color="error">{errors?.prasadamImage}</Typography>
            )}
          </Grid2>

          <Grid2 size={{ xs: 6, md: 6 }}>
            <Box>
              <Typography variant="subtitle1" className="prasadam-form-title">
                Prasadam Package Photos*
              </Typography>

              <Box
                className="file-upload-box"
                onDrop={(e) =>
                  handleDrop("prasadamPackageImage", e, 1, "image/*")
                }
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {prasadamData?.prasadamPackageImage?.length > 0 ? (
                  <Box className="file-preview-image-container">
                    {prasadamData?.prasadamPackageImage?.map((item, index) => (
                      <Box className="prasadam-image-container">
                        <Close
                          className="song-image-close-icon"
                          onClick={() =>
                            removeUpload("prasadamPackageImage", index)
                          }
                        />

                        <img
                          src={item.url}
                          className="prasadam-image"
                          alt="song_img"
                        />
                      </Box>
                    ))}
                  </Box>
                ) : null}

                <Typography className="file-upload-instructions">
                  <UploadFileIcon className="upload-file-icon" />
                  Drop Your File Here
                </Typography>

                <Typography className="file-upload-instructions">OR</Typography>
                <input
                  ref={fileInputRef}
                  accept="image/*"
                  name="prasadamPackageImage"
                  type="file"
                  className="prasadam-file-input"
                  id="prasadam-package-upload-package"
                  onChange={(e) =>
                    handleImageUpload("prasadamPackageImage", e, 1)
                  }
                />
                <label htmlFor="prasadam-package-upload-package">
                  <Button
                    variant="outlined"
                    component="span"
                    className="browse-button"
                  >
                    Browse file
                  </Button>
                </label>

                <Typography className="file-upload-instructions">
                  Max: 20MB, JPG, PNG
                </Typography>
              </Box>
            </Box>

            {errors?.prasadamPackageImage && (
              <Typography color="error">
                {errors?.prasadamPackageImage}
              </Typography>
            )}
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ sx: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Available Stock*
            </Typography>

            <FormControl fullWidth variant="outlined" className="custom-select">
              <TextField
                fullWidth
                variant="outlined"
                value={prasadamData?.availableStock}
                onChange={handleChange("availableStock")}
                className="custom-textfield"
                placeholder="Enter availableStock"
              />

              {errors.availableStock && (
                <Typography color="error">{errors.availableStock}</Typography>
              )}
            </FormControl>
          </Grid2>

          <Grid2 size={{ sx: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Quantity
            </Typography>

            <FormControl fullWidth variant="outlined" className="custom-select">
              <TextField
                fullWidth
                variant="outlined"
                value={prasadamData?.quantity}
                onChange={handleChange("quantity")}
                className="custom-textfield"
                placeholder="Enter quantity"
              />
            </FormControl>
          </Grid2>
        </Grid2>

        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Prasadam Description*
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              value={prasadamData.prasadamDescription}
              onChange={handleChange("prasadamDescription")}
              placeholder="Description"
              className={["custom-textfield", "input-fields"]}
            />

            {errors?.prasadamDescription && (
              <Typography color="error">
                {errors?.prasadamDescription}
              </Typography>
            )}
          </Grid2>

          <Grid2 size={{ sx: 6, sm: 6 }}>
            <Typography variant="subtitle1" className="prasadam-form-title">
              Booking Availablitity*
            </Typography>

            <FormControl fullWidth variant="outlined" className="custom-select">
              <Autocomplete
                options={bookingAvailabilityList}
                getOptionLabel={(option) => option.label}
                disableClearable
                onChange={handleChange("bookingAvailability")}
                value={
                  bookingAvailabilityList.find(
                    (option) =>
                      option.value === prasadamData.bookingAvailability,
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Availability"
                    variant="outlined"
                  />
                )}
              />

              {errors.bookingAvailability && (
                <Typography color="error">
                  {errors.bookingAvailability}
                </Typography>
              )}
            </FormControl>
          </Grid2>
        </Grid2>

        <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
          <AddButton
            name={actionType === "Add" ? "Add" : "Update"}
            onClick={handleSubmit}
            background="#A9392D"
            color="#FFFFFF"
            width="auto"
            sx={{
              marginRight: "0.5rem",
              // border: "1px solid #ACAEC3",
            }}
          />
        </Box>
      </Box>
    </>
  );
});

export default AddEditPrasadam;
