import { createSlice } from "@reduxjs/toolkit";

const communityMemberSlice = createSlice({
    name: "communityMember",
    initialState: {
        communityMemberData: [],
        error: null,
        loading: false,
        fetchCommunityMemberStatus: false,
        removeCommunityMemberStatus: false
    },
    reducers: {
        fetchAllCommunityMembers: (state) => {
            state.communityMemberData = [];
            state.loading = true;
            state.error = null;
            state.fetchCommunityMemberStatus = false;
        },
        fetchAllCommunityMembersSuccess: (state, action) => {
            state.communityMemberData = action.payload;
            state.loading = false;
            state.error = null;
            state.fetchCommunityMemberStatus = true;
        },
        fetchAllCommunityMembersFailure: (state, action) => {
            state.communityMemberData = [];
            state.loading = false;
            state.error = action.payload;
            state.fetchCommunityMemberStatus = false;
        },
        removeCommunityMembers: (state) => {
            state.loading = true;
            state.error = null;
            state.removeCommunityMemberStatus = false;
        },
        removeCommunityMembersSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.removeCommunityMemberStatus = true;
        },
        removeCommunityMembersFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.removeCommunityMemberStatus = false;
        },
        toggleStatus: (state) => {
            state.fetchCommunityMemberStatus = false;
            state.removeCommunityMemberStatus = false;
        }
    }
});

export const {
    fetchAllCommunityMembers,
    fetchAllCommunityMembersSuccess,
    fetchAllCommunityMembersFailure,
    removeCommunityMembers,
    removeCommunityMembersSuccess,
    removeCommunityMembersFailure,
    toggleStatus
} = communityMemberSlice.actions;

export default communityMemberSlice.reducer;
