import React, { useState, useEffect } from "react";
import "../../styles/HelpSupport.css";
import "../../styles/FAQCard.css";
import { useNavigate } from "react-router-dom";
import { helpAndSupportRequestByStatus } from "../../features/slices/helpAndSupportSlice";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoader } from "../../components/CustomLoader";

const OrderProcessingFaq = React.memo(() => {
  const { helpAndSupportData, helpAndSupportStatus } = useSelector(
    (state) => state.helpAndSupport,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(helpAndSupportRequestByStatus("ORDER_PROCESSING"));
  }, [dispatch]);
  const navigate = useNavigate();
  const hadnleContactUs = () => navigate(`/contact-us`);
  return (
    <>
      <div className="faq-section">
        <h2>Order Processing FAQs</h2>
        <CustomLoader
          loading={helpAndSupportStatus}
          message="Please wait, Fetching Order Processing FAQs..."
        />
        {helpAndSupportData && helpAndSupportData.length > 0 ? (
          <div>
            {helpAndSupportData.map((item) => (
              <FaqCard question={item.question} answer={item.answer} />
            ))}
          </div>
        ) : (
          "No Data"
        )}
      </div>
    </>
  );
});
const FaqCard = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="faq-card">
      <div className="faq-header" onClick={toggleExpand}>
        <li className="question">{question}</li>
        <div className="toggle-icon">{isExpanded ? "-" : "+"}</div>
      </div>
      {isExpanded && <div className="answer">{answer}</div>}
    </div>
  );
};
export default OrderProcessingFaq;
