import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchDonationRequest,
  fetchDonationSuccess,
  fetchDonationFailure,
  fetchDonationUploadRequest,
  fetchDonationUploadSuccess,
  fetchDonationUploadFailure,
  fetchDonationUpdateRequest,
  fetchDonationUpdateSuccess,
  fetchDonationUpdateFailure,
  fetchDonationUpdateByStatusRequest,
  fetchDonationUpdateByStatusSuccess,
  fetchDonationUpdateByStatusFailure,
  fetchDonationGetByIdRequest,
  fetchDonationGetByIdSuccess,
  fetchDonationGetByIdFailure,
} from "../slices/donationSlice";
import {
  deleteRequest,
  getRequest,
  postRequest4,
  putRequest,
  postRequestUsingFormData,
} from "../../api/apiActions";

import {
  donationPostUrls,
  donationGetAllUrls,
  donationGetByIdUrls,
  donationUpdateUrls,
  donationUpdateByStatusUrls,
} from "../../api/apiUrls";
import { openSnackbar } from "../slices/snackbarSlice";

const getCall = (payload) => getRequest(donationGetAllUrls + payload);

const updateCall = (payload) =>
  putRequest(`${donationUpdateUrls}/${payload.donationId}`, payload.formData);

const createCall = (payload) =>
  postRequestUsingFormData(donationPostUrls, payload);

const activeCall = (payload) =>
  putRequest(donationUpdateByStatusUrls + `/${payload}`);
const getByIDCall = (payload) =>
  getRequest(donationGetByIdUrls + `/${payload}`);

function* fetchDonationsAll(action) {
  try {
    const response = yield call(getCall, action.payload);
    if (response.status === 200) {
      let res = response?.data?.content;
      let page = response?.data?.totalPages;
      yield put(fetchDonationSuccess({ res, page }));
    } else {
      yield put(fetchDonationFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchDonationFailure(error?.response?.data));
  }
}

function* fetchDonationsById(action) {
  try {
    const response = yield call(getByIDCall, action.payload);
    if (response.status === 200) {
      yield put(fetchDonationGetByIdSuccess(response?.data));
    } else {
      yield put(fetchDonationGetByIdFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchDonationGetByIdFailure(error?.response?.data));
  }
}
function* postDonation(action) {
  try {
    const response = yield call(createCall, action.payload);
    if (response.status === 200) {
      yield put(fetchDonationUploadSuccess(response?.data));
      yield put(
        openSnackbar({
          message: "Donation Added Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(fetchDonationUploadFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchDonationUploadFailure(error?.response?.data));
  }
}
function* updateDonation(action) {
  try {
    const response = yield call(updateCall, action.payload);
    if (response.status === 200) {
      yield put(fetchDonationUpdateSuccess(response?.data));
      yield put(
        openSnackbar({
          message: "Donation update Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(fetchDonationUpdateFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchDonationUpdateFailure(error?.response?.data));
  }
}

function* updateByStatusDonation(action) {
  try {
    const response = yield call(activeCall, action.payload);
    if (response.status === 200) {
      yield put(fetchDonationUpdateByStatusSuccess(response?.data));
      yield put(
        openSnackbar({
          message: "Status Update Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(fetchDonationUpdateByStatusFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchDonationUpdateByStatusFailure(error?.response?.data));
  }
}

export function* donationSaga() {
  yield takeLatest(fetchDonationRequest.type, fetchDonationsAll);
  yield takeLatest(fetchDonationGetByIdRequest.type, fetchDonationsById);
  yield takeLatest(fetchDonationUploadRequest.type, postDonation);
  yield takeLatest(fetchDonationUpdateRequest.type, updateDonation);
  yield takeLatest(
    fetchDonationUpdateByStatusRequest.type,
    updateByStatusDonation,
  );
}
