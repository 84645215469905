// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/HelpSupport.css */

.help-support-container {
  height: 70vh;
  padding: 10px;
}

.tab-section {
  display: flex;
  gap: 1.25rem;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 15px;
  border-radius: 5px;
  color: #ff914d; /* Tab text color */
  cursor: pointer;
}

.tab.active {
  background-color: #ffede5;
  color: #ff5a1f;
}

.faq-section {
  background-color: #fff;
  height: 46vh;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 1rem;
  border-radius: 8px;
}

.contact-section {
  text-align: center;
}

.contact-button {
  background-color: #ff5a1f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/styles/HelpSupport.css"],"names":[],"mappings":"AAAA,mCAAmC;;AAEnC;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,cAAc,EAAE,mBAAmB;EACnC,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;EACrB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":["/* src/components/HelpSupport.css */\n\n.help-support-container {\n  height: 70vh;\n  padding: 10px;\n}\n\n.tab-section {\n  display: flex;\n  gap: 1.25rem;\n  margin-bottom: 20px;\n}\n\n.tab {\n  padding: 10px 15px;\n  border-radius: 5px;\n  color: #ff914d; /* Tab text color */\n  cursor: pointer;\n}\n\n.tab.active {\n  background-color: #ffede5;\n  color: #ff5a1f;\n}\n\n.faq-section {\n  background-color: #fff;\n  height: 46vh;\n  overflow-y: auto;\n  scrollbar-width: thin;\n  padding: 1rem;\n  border-radius: 8px;\n}\n\n.contact-section {\n  text-align: center;\n}\n\n.contact-button {\n  background-color: #ff5a1f;\n  color: #fff;\n  border: none;\n  padding: 10px 20px;\n  border-radius: 5px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
