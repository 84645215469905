import { combineReducers } from "redux";
import authReducer from "./slices/authSlice";
import communityPostReducer from "./slices/communityPostSlice";
import communityMemberReducer from "./slices/communityMemberSlice";
import dashboardReducer from "./slices/dashboardSlice";
import snackbarReducer from "./slices/snackbarSlice";
import templeDetailsReducer from "./slices/templeDetailsSlice";
import thoughtReducer from "./slices/thoughtSlice";
import settingReducer from "./slices/settingSlice";
import helpAndSupportReducer from "./slices/helpAndSupportSlice";
import sevaReducer from "./slices/sevaSlice";
import donationReducer from "./slices/donationSlice";
import prasadamReducer from "./slices/prasadamSlice";
import adminDetailsReducer from "./slices/adminDetailsSlice";
import onBoardingReducer from "./slices/onBordingSlice";
import oderReducer from "./slices/orderSlice";
import PurohitOnboardReducer from "./slices/purohitOnboardingSlice";
import purohitSettingsReducer from "./slices/purohitSettingsSlice";
import pujaReducer from "./slices/pujaSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  communityPost: communityPostReducer,
  communityMember: communityMemberReducer,
  dashboard: dashboardReducer,
  snackbar: snackbarReducer,
  templeDetail: templeDetailsReducer,
  thought: thoughtReducer,
  setting: settingReducer,
  helpAndSupport: helpAndSupportReducer,
  seva: sevaReducer,
  donation: donationReducer,
  prasadam: prasadamReducer,
  adminDetail: adminDetailsReducer,
  onbording: onBoardingReducer,
  order: oderReducer,
  purohitOnboarding: PurohitOnboardReducer,
  purohitSettings: purohitSettingsReducer,
  puja: pujaReducer,
});

export default rootReducer;
