import React, { useState, useEffect, useCallback } from "react";
import Tabs from "../components/common/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../styles/ProductManagement.css";
import { Box } from "@mui/material";
import CommunityPost from "./CommunityManagement/CommunityPost";
import CommunityMembers from "./CommunityManagement/CommunityMembers";
import { useDispatch } from "react-redux";
import { fetchTempleId } from "../features/slices/communityPostSlice";

const tabItems = [
  { label: "Community Post", value: "Community_Post" },
  { label: "Community Members", value: "Community_Members" },
];

const CommunityManagement = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { actionType } = useParams();
  const [selectedMenu, setSelectedMenu] = useState(() => {
    let pathName = location.pathname.split("/");

    return pathName[2] ? pathName[2] : "Community_Post";
  });
  const [fileOption, setFileOption] = React.useState("");

  const handleChange = (event) => {
    setFileOption(event.target.value);
  };

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
    navigate(`/community-management/${menu}`);
  };

  const getTempleId = useCallback(() => {
    dispatch(fetchTempleId());
  }, [dispatch]);

  useEffect(() => {
    let pathName = location.pathname.split("/");

    const filteredData = tabItems.filter((item) =>
      pathName.includes(item.value),
    );

    if (filteredData.length > 0) {
      setSelectedMenu(filteredData[0].value);
      navigate(
        `/community-management${filteredData[0].value ? `/${filteredData[0].value}` : ""}${actionType ? `/${actionType}` : ""}`,
      );
    }
    if (filteredData.length === 0) {
      setSelectedMenu("Community_Post");
      navigate(`/community-management/Community_Post`);
    }
  }, [pathName]);

  useEffect(() => {
    getTempleId();
  }, []);

  return (
    <>
      <Box
        className={"heading"}
        sx={{
          textWrap: "nowrap",

        }}
      >
        Community Management
      </Box>
      
        <div style={{ marginTop: "1rem" }}>
          <Tabs
            tabs={tabItems}
            tabStart={selectedMenu}
            onClick={handleMenuButtonClick}
          />
        </div>
        {selectedMenu === "Community_Post" ? (
          <CommunityPost />
        ) : selectedMenu === "Community_Members" ? (
          <CommunityMembers />
        ) : null}
      
    </>
  );
});
export default CommunityManagement;
