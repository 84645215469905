export const getRole = (roles) => {
  const set = new Set(roles);
  if (set.has("ROLE_SYSTEM_ADMIN")) {
    // return "ROLE_SYSTEM_ADMIN";
    // return "ROLE_PUROHIT";
    // return "ROLE_SELLER_ECOMM";
    return "ROLE_TEMPLE_OWNER";
  } else if (set.has("ROLE_TEMPLE_OWNER")) {
    return "ROLE_TEMPLE_OWNER";
  } else if (set.has("ROLE_PUROHIT")) {
    return "ROLE_PUROHIT";
  } else if (set.has("ROLE_SELLER_ECOMM")) {
    return "ROLE_SELLER_ECOMM";
  } else {
    return "ROLE_USER";
  }
};
