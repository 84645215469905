import React, { useCallback, useState } from "react";
import "../../styles/dashboard.css";
import { Box } from "@mui/material";
import CommonTable from "../../components/CommonTable";
import { useNavigate } from "react-router-dom";
import { AddButton } from "../../components/Addbutton";
import StarBorderIcon from '@mui/icons-material/StarBorder';

const tableRows = [
  {
    customerName: "Ritika K.",
    pujaName: "Ceremony 01",
    contacts: "+91 6345674567",
    amount: "₹10,000",
    date: "19/10/2024",
    feedback : "4.5",
    paymentStatus: "Received",
  },
  {
    customerName: "Ritika K.",
    pujaName: "Ceremony 01",
    contacts: "+91 6345674567",
    amount: "₹10,000",
    date: "19/10/2024",
    feedback : "0",
    paymentStatus: "Pending",
  },
  {
    customerName: "Ritika K.",
    pujaName: "Ceremony 01",
    contacts: "+91 6345674567",
    amount: "₹10,000",
    date: "19/10/2024",
    feedback : "4.5",
    paymentStatus: "Received",
  },
  {
    customerName: "Ritika K.",
    pujaName: "Ceremony 01",
    contacts: "+91 6345674567",
    amount: "₹10,000",
    date: "19/10/2024",
    feedback : "4.5",
    paymentStatus: "Received",
  },
  {
    customerName: "Ritika K.",
    pujaName: "Ceremony 01",
    contacts: "+91 6345674567",
    amount: "₹10,000",
    date: "19/10/2024",
    feedback : "0",
    paymentStatus: "Pending",
  },
];

const ServiceFeedback = React.memo(() => {
  const navigate = useNavigate();
  const columns = [
    { field: "customerName", headerName: "Customer Name" },
    { field: "pujaName", headerName: "Puja Name" },
    { field: "contacts", headerName: "Phone No." },
    { field: "amount", headerName: "Amount" },
    { field: "date", headerName: "Date" },
    { field: " feedback", headerName: "Feedback",  
      renderCell: (params, row) => {
      return (
          <AddButton
              width="40%"
              name={params}
              background="#FFF0D0"
              borderColor="#FFF0D0"
              onClick={()=>{}} 
              color="#0D0C11"
              icon={<StarBorderIcon/>}
          />
      )
      }, },
    {
        field: "paymentStatus",
        headerName: "Payment Status",
        renderCell: (params, row) => {
          return (
            <p
              className="statusBox"
              style={{
                color:
                  params === "Received" 
                    ? "#47B881"
                      : params === "Pending"
                        ? "#F64C4C"
                        : "#0D0C11",
                background:
                  params === "Received" 
                    ? "#E9FFF4"
                      : params === "Pending"
                        ? "#FEF2F2"
                        : "#E4E4E4",
              }}
            >
              {params}
            </p>
          );
        },
    },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  return (
    <Box
      className={"heading"}
      sx={{
        textWrap: "nowrap",
        paddingY: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0rem 0px 1rem 0px",
        }}
      >
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          Service Feedback
        </Box>
        
      </div>

      <CommonTable
        columns={columns}
        data={tableRows}
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </Box>
  );
});

export default ServiceFeedback;