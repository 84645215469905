import { Box, Card, CardContent, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const UserCards = React.memo(
  ({ title, count, src, notification, info, sx }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <>
        <Card
          sx={{
            width: "100%",
            minWidth: "24rem",
            height: "8rem",
            background: "#181822",
            borderRadius: "8px",
            padding: ".5rem",
            ...sx,
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                gutterBottom
                component="div"
                sx={{
                  color: "#F7F7F7",
                  fontSize: "1.2rem",
                  fontWeight: 400,
                  mb: 2,
                }}
              >
                {title ? title : null}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#F7F7F7",
                  fontSize: "2rem",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  gap: "1.5rem",
                }}
              >
                {count ? count : null}{" "}
                {notification && (
                  <Box
                    className="stats-notification"
                    sx={{ position: "relative" }}
                  >
                    +{notification}
                  </Box>
                )}
              </Typography>
            </span>
            <Box>
              <Box>
                <img
                  src={src ? src : null}
                  style={{ width: "3rem", height: "3rem" }}
                />
              </Box>
              <Box>
                {info && (
                  <InfoOutlinedIcon
                    className="stats-info-icon"
                    sx={{ fontSize: "1.5rem", mt: ".8rem", ml: 1 }}
                    onClick={handleClick}
                  />
                )}
              </Box>
            </Box>
            {info && (
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box sx={{ p: 2 }} className="stats-popover">
                  {info}
                </Box>
              </Popover>
            )}
          </CardContent>
        </Card>
      </>
    );
  },
);
