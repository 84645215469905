// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
    text-decoration: none !important;
    color: #ff8c34 !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/purohit/inventory.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,yBAAyB;AAC7B","sourcesContent":[".link {\n    text-decoration: none !important;\n    color: #ff8c34 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
