import React, { useCallback, useEffect, useState } from "react";
import "../../styles/dashboard.css";
import { Box } from "@mui/material";
import CommonTable from "../../components/CommonTable";
import { AddButton } from "../../components/Addbutton";
import DeleteModal from "../../components/DeleteModal";
import {
  fetchAllCommunityMembers,
  removeCommunityMembers,
  toggleStatus,
} from "../../features/slices/communityMemberSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { CustomLoader } from "../../components/CustomLoader";

const CommunityMembers = React.memo(() => {
  const columns = [
    { field: "userId", headerName: "User ID" },
    { field: "userName", headerName: "User Name" },
    { field: "communityPostName", headerName: "Event Name" },
    { field: "phoneNo", headerName: "Phone No." },
    {
      field: "enrolledDate",
      headerName: "Date",
      renderCell: (params, row) => {
        return (
          <div style={{ display: "flex" }}>
            <span>{dayjs(row?.enrolledDate).format("YYYY-MM-DD")}</span>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params, row) => (
        <AddButton
          name="Remove"
          borderColor="#C23030"
          background="#FFFFFF"
          color="#C23030"
          padding="1.15rem"
          onClick={() => onDelete(row?.communityPostId, row?.userId)}
        />
      ),
    },
  ];

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [communityPostId, setCommunityPostId] = useState(0);
  const [userId, setUserId] = useState(0);
  const { templeId, fetchTempleIdStatus } = useSelector(
    (state) => state.communityPost,
  );
  const { communityMemberData, loading, removeCommunityMemberStatus } =
    useSelector((state) => state.communityMember);
  const { content, pageable, totalPages } = communityMemberData;

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  const onDelete = (communityPostId, userId) => {
    setCommunityPostId(communityPostId);
    setUserId(userId);
    setOpenDeleteModal(true);
  };

  const CloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteConfirm = useCallback(() => {
    const queryParams = `communityPostId=${communityPostId}&userId=${userId}`;

    dispatch(removeCommunityMembers(queryParams));
    setOpenDeleteModal(false);
  }, [dispatch, communityPostId, userId]);

  const getAllCommunityMembers = useCallback(() => {
    const queryParams = `page=${page - 1}&size=${rowsPerPage}`;

    dispatch(fetchAllCommunityMembers({ templeId, queryParams }));
  }, [dispatch, templeId]);

  useEffect(() => {
    if (fetchTempleIdStatus) {
      getAllCommunityMembers();
    }
  }, [fetchTempleIdStatus]);

  useEffect(() => {
    if (removeCommunityMemberStatus) {
      getAllCommunityMembers();
      dispatch(toggleStatus());
    }
  }, [removeCommunityMemberStatus]);

  return (
    <>
      <DeleteModal
        open={openDeleteModal}
        onClose={CloseDeleteModal}
        onConfirm={handleDeleteConfirm}
        content="Are you sure you want to Delete the Community Post ?"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0rem 0px 1rem 0px",
        }}
      >
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
          }}
        >
          Community Members
        </Box>
      </div>

      <CustomLoader
        loading={loading}
        message="Please wait, Uploading Community Members..."
      />

      <CommonTable
        columns={columns}
        data={content || []}
        page={page}
        count={totalPages}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </>
  );
});

export default CommunityMembers;
