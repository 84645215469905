import axios from "axios";
import { store } from "../store";
import { config1, config2, config3, config4, config5 } from "./apiConfig";

export const postRequestUsingPayload = async (apiUrl, payload) => {
  const result = await axios.post(apiUrl, payload, config3());
  return result;
};

export const postRequest4 = async (apiUrl, payload) => {
  const result = await axios.post(apiUrl, payload, config3());
  return result;
};

export const postRequestUsingFormData = async (apiUrl, formData) => {
  const result = await axios.post(apiUrl, formData, config4());
  return result;
};

export const getRequest = async (apiUrl) => {
  const result = await axios.get(apiUrl, config3());
  return result;
};

export const getRequestWithparams = async (apiUrl) => {
  const result = await axios.get(apiUrl, config2());
  return result;
};

export const putRequest = async (apiUrl, payload) => {
  const result = await axios.put(apiUrl, payload, config1());
  return result;
};

export const putRequestWithFormData = async (apiUrl, payload) => {
  const result = await axios.put(apiUrl, payload, config4());
  return result;
};

export const deleteRequest = async (apiUrl) => {
  const result = await axios.delete(apiUrl, config1());
  return result;
};

export const downLoadRequest = async (apiUrl) => {
  const result = await axios.get(apiUrl, config5());
  return result;
};
