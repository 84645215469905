import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import "../../styles/confrimationModal.css";
import { AddButton } from "../../components/Addbutton";

const StatusModal = ({ open, onClose, onConfirm, content }) => {
  const [dropMessage, setDropMessage] = useState("");
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      PaperProps={{
        style: {
          backgroundColor: "var(--black-2)",
          border: "1px solid var(--black-2)",
          borderRadius: "1rem",
          padding: "0.25rem",
          color: "var(--off-white)",
          textAlign: "center",
          width: "25%",
          height:"25vh"
        },
      }}
    >
      <DialogContent sx={{ display: "flex", alignItems: "center", flexDirection: "column" }} >
        <p className="Active_title"> Reason For {content} </p>
        <TextField
          variant="outlined"
          sx={{ width: "100%" }}
          placeholder="Enter your closing comments"
          value={dropMessage}
          onChange={(e) => setDropMessage(e.target.value)}
          className="text-field_category"
        />
      </DialogContent>

      <DialogActions className="button-container">
        
        <AddButton name="Cancel" background="var(--black-2)" color="var(--off-white)" borderColor="var(--primary-3)" onClick={() => onClose(false)} />
        <AddButton name="Submit" onClick={() => onConfirm()} />
      </DialogActions>
    </Dialog>
  );
};

export default StatusModal;
