import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Grid2,
  Autocomplete,
  IconButton,
  Button,
  Select,
  OutlinedInput,
  MenuItem,
  Chip,
} from "@mui/material";

import "../../styles/onboardingForm.css";
import "../../styles/ModalDatePicker.css";
import { Close } from "@mui/icons-material";

import {
  fetchPurohitRequest,
  fetchPurohit2Request,
  uploadPurohitRequest,
} from "../../features/slices/purohitOnboardingSlice";
import { useDispatch, useSelector } from "react-redux";

const initialFormState = {
  name: "",
  langauageKnown: [],
  profilePicture: [],
  pujaPictures: [],
  pujaVideos: [],
  phoneNumber: "",
  email: "",
  city: "",
  Country: "",
  address: "",
  state: "",
  pinCode: "",
  preferredContactMethod: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const preffered = ["EMAIL", "PHONE_NUMBER"];

const initialErrorState = {
  name: "",
  langauageKnown: "",
  profilePicture: "",
  pujaPictures: "",
  pujaVideos: "",
  phoneNumber: "",
  email: "",
  city: "",
  Country: "",
  address: "",
  state: "",
  pinCode: "",
  preferredContactMethod: "",
};

const PurohitDetails = forwardRef((props, ref) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);

  const { purohitData, Purohit2Data, purohitGetAllStatus } = useSelector(
    (state) => state.purohitOnboarding,
  );

  const langauages = [
    "Hindi",
    "Tamil",
    "Sanskrit",
    "Malyalam",
    "Telugu",
    "Bengali",
    "Marathi",
    "Gujarati",
    "Punjabi",
    "Kannada",
    "Odia",
    "Assamese",
    "Rajasthani",
    "Maithili",
    "Konkani",
  ];
  const dispatch = useDispatch();

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    dispatch(fetchPurohitRequest());
    dispatch(fetchPurohit2Request());
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;

      if (!formData.name) {
        newErrors.name = "Name is required";
        isValid = false;
      }
      if (!formData.langauageKnown) {
        newErrors.langauageKnown = "Select a Langauage";
        isValid = false;
      }
      if (!formData.profilePicture) {
        newErrors.profilePicture = "Profile Picture is required";
        isValid = false;
      }
      if (!formData.pujaPictures) {
        newErrors.pujaPictures = "Puja Picture is required";
        isValid = false;
      }
      if (!formData.pujaVideos) {
        newErrors.pujaVideos = "Puja Video is required";
        isValid = false;
      }
      if (!formData.address) {
        newErrors.address = "Address is required";
        isValid = false;
      }
      if (!formData.Country) {
        newErrors.Country = "Country is required";
        isValid = false;
      }
      if (!formData.state) {
        newErrors.state = "State is required";
        isValid = false;
      }
      if (!formData.city) {
        newErrors.city = "City is required";
        isValid = false;
      }
      if (!formData.pinCode) {
        newErrors.pinCode = "Pincode is required";
        isValid = false;
      }
      if (!formData.phoneNumber) {
        newErrors.phoneNumber = "Phone Number is required";
        isValid = false;
      }
      if (!formData.email) {
        newErrors.email = "Email ID is required";
        isValid = false;
      }
      if (!formData.preferredContactMethod) {
        newErrors.preferredContactMethod = "Select Contact Method";
        isValid = false;
      }
      setErrors(newErrors);
      if (isValid) {
      }
    },
  }));

  const MAX_IMAGE_SIZE = 2 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 20 * 1024 * 1024;

  const handleUpload = (field, event, MAX_FILES) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length + (formData[field]?.length || 0) > MAX_FILES) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "2MB" : "20MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleMultiChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData((prev) => ({
      ...prev,
      days: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleRemoveSelectedItem = (index) => {
    const updatedVariants = [...formData.days];

    setFormData((prev) => ({
      ...prev,
      days: [
        ...updatedVariants.slice(0, index),
        ...updatedVariants.slice(index + 1),
      ],
    }));
  };

  const removeUpload = (field, index) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  const mapLocalImage = (images) => {
    if (images.length > 0) {
      let NewArr = images.map((item) => {
        return item?.file;
      });

      return NewArr;
    }
  };

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };

  useEffect(() => {
    if (Purohit2Data) {
      setFormData({
        ...formData,
        phoneNumber: Purohit2Data?.phoneNumber,
        email: Purohit2Data?.email,
        // preferredContactMethod: Purohit2Data?.preferredContactMethod,
        // langauageKnown: Purohit2Data?.language,
        profilePicture: Purohit2Data?.profilePicture
          ? mapImages([Purohit2Data.profilePicture])
          : [],
        pujaVideos:
          Purohit2Data?.pujaVideos?.length > 0
            ? mapImages(Purohit2Data.pujaVideos)
            : [],
        pujaPictures:
          Purohit2Data?.pujaPictures?.length > 0
            ? mapImages(Purohit2Data.pujaPictures)
            : [],
      });
    }
  }, [Purohit2Data]);

  return (
    <>
      <div className="heading">Purohit Details</div>
      <Box style={{ marginTop: "0.5rem" }}>
        <div className="onboarding-sub_heading">Basic Purohit Information</div>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">Name*</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={handleChange("name")}
              placeholder="Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.templeName && (
              <Typography color="error">{errors.templeName}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <div>
              <FormControl fullWidth>
                <Typography className="onboarding-form_Heading">
                  Langauage Known*
                </Typography>
                <Select
                  multiple
                  displayEmpty
                  value={formData.langauageKnown}
                  onChange={handleMultiChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Select Langauage</em>;
                    }
                    return selected.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Placeholder</em>
                  </MenuItem>
                  {/* {langauages.map((lang) => (
                    <MenuItem key={lang} value={lang}>
                      {lang}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </div>
            {formData.langauageKnown.map((color, index) => (
              <Chip
                key={color}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fill: "black !important", // Ensures the icon is black
                  },
                }}
                label={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: ".8rem",
                    }}
                  >
                    {color}
                  </div>
                }
                onDelete={() => handleRemoveSelectedItem(index)}
                className="product-color-select-chip"
              />
            ))}
            {errors.langauageKnown && (
              <Typography color="error">{errors.langauageKnown}</Typography>
            )}
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">Photos and Videos</div>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Upload Profile Picture with Traditional Attire**
            </Typography>
            <Box className="upload-box_charity ">
              {formData?.profilePicture?.length > 0 ? (
                <Box className="preview-image-container">
                  {formData?.profilePicture?.map((item, index) => (
                    <Box className="song-image-container">
                      <IconButton className="close-icon-container">
                        <Close
                          className="song-image-close-icon"
                          onClick={() => removeUpload("profilePicture", index)}
                        />
                      </IconButton>

                      <img
                        src={item.url}
                        className="song-image"
                        alt="song_img"
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              <Typography className="upload-instructions">
                <img
                  style={{ marginBottom: "-0.25rem" }}
                  src="/upload-file-icon.svg"
                  alt="img"
                />
                Drag and Drop your file
              </Typography>
              <Typography className="onboarding-upload-instructions ">
                OR
              </Typography>
              <input
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                id="thumbnail-upload-Interior"
                onChange={(e) => handleUpload("profilePicture", e, 5)}
                multiple={true}
              />
              <label htmlFor="thumbnail-upload-Interior">
                <Button
                  variant="outlined"
                  component="span"
                  className="browse-button"
                >
                  Browse file
                </Button>
              </label>
              <Typography className="upload-instructions ">
                Max.5 Images, 2MB Each, JPEG/PNG
              </Typography>
            </Box>
            {errors.profilePicture && (
              <Typography color="error">{errors.profilePicture}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Upload Multiple Picture of you performing puja*
            </Typography>
            <Box className="upload-box_charity ">
              {formData?.pujaPictures?.length > 0 ? (
                <Box className="preview-image-container">
                  {formData?.pujaPictures?.map((item, index) => (
                    <Box className="song-image-container">
                      <IconButton className="close-icon-container">
                        <Close
                          className="song-image-close-icon"
                          onClick={() => removeUpload("pujaPictures", index)}
                        />
                      </IconButton>

                      <img
                        src={item.url}
                        className="song-image"
                        alt="song_img"
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              <Typography className="upload-instructions">
                <img
                  style={{ marginBottom: "-0.25rem" }}
                  src="/upload-file-icon.svg"
                  alt="img"
                />
                Drag and Drop your file
              </Typography>
              <Typography className="onboarding-upload-instructions ">
                OR
              </Typography>
              <input
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                id="thumbnail-upload-ext"
                onChange={(e) => handleUpload("pujaPictures", e, 5)}
                multiple={true}
              />
              <label htmlFor="thumbnail-upload-ext">
                <Button
                  variant="outlined"
                  component="span"
                  className="browse-button"
                >
                  Browse file
                </Button>
              </label>
              <Typography className="upload-instructions ">
                Max.5 Images, 2MB Each, JPEG/PNG
              </Typography>
            </Box>
            {errors.pujaPictures && (
              <Typography color="error">{errors.pujaPictures}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1} mb={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Upload Multiple Video of you performing puja
            </Typography>
            <Box className="upload-box_charity ">
              {formData?.pujaVideos?.length > 0 ? (
                <Box className="preview-image-container">
                  {formData?.pujaVideos?.map((item, index) => (
                    <Box className="song-image-container">
                      <Close
                        className="song-image-close-icon"
                        onClick={() => removeUpload("pujaVideos", index)}
                      />

                      <img
                        src="/mp4Icon.svg"
                        className="song-image"
                        alt="mp4_image_icon"
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              <Typography className="upload-instructions">
                <img
                  style={{ marginBottom: "-0.25rem" }}
                  src="/upload-file-icon.svg"
                  alt="img"
                />
                Drag and Drop your file
              </Typography>
              <Typography className="onboarding-upload-instructions ">
                OR
              </Typography>
              <input
                accept="video/mp4"
                type="file"
                style={{ display: "none" }}
                id="thumbnail-upload-spl-vid"
                onChange={(e) => handleUpload("pujaVideos", e, 2)}
                multiple={true}
              />
              <label htmlFor="thumbnail-upload-spl-vid">
                <Button
                  variant="outlined"
                  component="span"
                  className="browse-button"
                >
                  Browse file
                </Button>
              </label>

              <Typography className="upload-instructions ">
                Max. 2Videos, 20MB Each,MP4
              </Typography>
            </Box>
            {errors.pujaVideos && (
              <Typography color="error">{errors.pujaVideos}</Typography>
            )}
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">Location Details*</div>
        <Grid2 style={{ marginTop: "0.5rem" }} size={{ xs: 12, md: 12 }}>
          <Typography className="onboarding-form_Heading">Address*</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={formData.address}
            onChange={handleChange("address")}
            placeholder="Enter address"
            style={{ marginBottom: "1rem" }}
            className="onboarding-custom-textfield"
            InputProps={{
              style: { color: "#8d8e9f" },
            }}
          />
          {errors.address && (
            <Typography color="error">{errors.address}</Typography>
          )}
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Select Your Country*
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-select"
            >
              <Autocomplete
                options={["Red", "Blue", "Green", "Yellow", "Black"]}
                value={formData.Country}
                disableClearable
                renderTags={() => null}
                onChange={handleChange("Country")}
                renderOption={(props, option, { selected }) => {
                  return (
                    <span
                      {...props}
                      style={{
                        backgroundColor: selected
                          ? "var(--black)"
                          : "transparent",
                        color: selected ? "#FFD1A2" : "black",

                        pointerEvents: selected ? "none" : "auto",
                      }}
                    >
                      {option}
                    </span>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Your Country"
                    variant="outlined"
                    style={{ marginBottom: "1rem" }}
                  />
                )}
              />
              {errors.Country && (
                <Typography color="error">{errors.Country}</Typography>
              )}
            </FormControl>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Select Your State*
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-select"
            >
              <Autocomplete
                options={["Red", "Blue", "Green", "Yellow", "Black"]}
                value={formData.state}
                disableClearable
                renderTags={() => null}
                onChange={handleChange("state")}
                renderOption={(props, option, { selected }) => {
                  return (
                    <span
                      {...props}
                      style={{
                        backgroundColor: selected
                          ? "var(--black)"
                          : "transparent",
                        color: selected ? "#FFD1A2" : "black",

                        pointerEvents: selected ? "none" : "auto",
                      }}
                    >
                      {option}
                    </span>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Your State"
                    variant="outlined"
                    style={{ marginBottom: "1rem" }}
                  />
                )}
              />
              {errors.state && (
                <Typography color="error">{errors.state}</Typography>
              )}
            </FormControl>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Select Your City*
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-select"
            >
              <Autocomplete
                options={["Red", "Blue", "Green", "Yellow", "Black"]}
                value={formData.city}
                disableClearable
                renderTags={() => null}
                onChange={handleChange("city")}
                renderOption={(props, option, { selected }) => {
                  return (
                    <span
                      {...props}
                      style={{
                        backgroundColor: selected
                          ? "var(--black)"
                          : "transparent",
                        color: selected ? "#FFD1A2" : "black",

                        pointerEvents: selected ? "none" : "auto",
                      }}
                    >
                      {option}
                    </span>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Your City"
                    variant="outlined"
                    style={{ marginBottom: "1rem" }}
                  />
                )}
              />
              {errors.city && (
                <Typography color="error">{errors.city}</Typography>
              )}
            </FormControl>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Pincode*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.pinCode}
              onChange={handleChange("pinCode")}
              placeholder="Enter Pincode"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.pinCode && (
              <Typography color="error">{errors.pinCode}</Typography>
            )}
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">Contact Details</div>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Phone number*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.phoneNumber}
              onChange={handleChange("phoneNumber")}
              placeholder="Enter Phone number"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.phoneNumber && (
              <Typography color="error">{errors.phoneNumber}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Email ID*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleChange("email")}
              placeholder="Enter Email ID of the Temple*"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.email && (
              <Typography color="error">{errors.email}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <div>
              <FormControl fullWidth>
                <Typography className="onboarding-form_Heading">
                  Preferred Contact Method
                </Typography>
                <Select
                  multiple
                  displayEmpty
                  value={formData.preferredContactMethod}
                  onChange={handleMultiChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Select preferred contact method</em>;
                    }

                    return selected.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Placeholder</em>
                  </MenuItem>
                  {preffered.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {formData.preferredContactMethod.map((color, index) => (
              <Chip
                key={color}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fill: "black !important", // Ensures the icon is black
                  },
                }}
                label={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: ".8rem",
                    }}
                  >
                    {color}
                  </div>
                }
                onDelete={() => handleRemoveSelectedItem(index)}
                className="product-color-select-chip"
              />
            ))}
            {errors.preferredContactMethod && (
              <Typography color="error">
                {errors.preferredContactMethod}
              </Typography>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
});

export default PurohitDetails;
