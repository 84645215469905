import { all, call, takeEvery, put } from "redux-saga/effects";
import authSaga from "./sagas/authSaga";
import { communityPostSaga } from "./sagas/communityPostSaga";
import { communityMemberSaga } from "./sagas/communityMemberSaga";
import { dashboardSaga } from "./sagas/dashboardSaga";
import { openSnackbar } from "./slices/snackbarSlice";
import templeDetailsSaga from "./sagas/templeDetailsSaga";
import thoughtSaga from "./sagas/thoughtSaga";
import { settingSaga } from "./sagas/settingSaga";
import { helpAndSupportSaga } from "./sagas/helpAndSupportSaga";
import sevaSaga from "./sagas/sevaSaga";
import { donationSaga } from "./sagas/donationSaga";
import { prasadamSaga } from "./sagas/prasadamSaga";
import { adminDetailsSaga } from "./sagas/adminDetailsSaga";
import { onBoardingSaga } from "./sagas/onBoardingSaga";
import { orderSaga } from "./sagas/orderSaga";
import { purohitOnboardingSaga } from "./sagas/purohitOnboardingSaga";
import { purohitSettingsSaga } from "./sagas/purohitSettingsSaga";
import { watchPujaSaga } from "./sagas/pujaSaga";

function* handleApiError(action) {
  yield put(
    openSnackbar({
      message: action?.message ? action?.message : "Something Went Wrong.",
      severity: "error",
    }),
  );
}

function* watchApiErrors() {
  yield takeEvery("*", function* (action) {
    if (action.type.endsWith("Failure")) {
      yield call(handleApiError, action.payload);
    }
  });
}

export default function* rootSaga() {
  yield all([
    authSaga(),

    communityPostSaga(),
    communityMemberSaga(),

    sevaSaga(),

    dashboardSaga(),
    templeDetailsSaga(),
    thoughtSaga(),
    settingSaga(),
    watchApiErrors(),
    helpAndSupportSaga(),
    donationSaga(),
    prasadamSaga(),
    adminDetailsSaga(),
    onBoardingSaga(),
    orderSaga(),
    purohitOnboardingSaga(),
    purohitSettingsSaga(),
    watchPujaSaga(),
  ]);
}
