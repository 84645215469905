import { call, put, takeEvery } from "redux-saga/effects";
import {
  getAllPujaFailure,
  getAllPujaRequest,
  getAllPujaSuccess,
} from "../slices/pujaSlice";
import { getRequest } from "../../api/apiActions";
import { getAllPujaUrl } from "../../api/apiUrls";

const getAllPujaCall = () => getRequest(getAllPujaUrl);

function* getAllPuja(action) {
  try {
    const response = yield call(getAllPujaCall);
    if (response.status === 200) {
      const data = response.data;
      yield put(getAllPujaSuccess({ data }));
    } else {
      yield put(getAllPujaFailure(response.message));
    }
  } catch (error) {
    yield put(getAllPujaFailure(error?.response?.data));
  }
}

export function* watchPujaSaga() {
  yield takeEvery(getAllPujaRequest.type, getAllPuja);
}
