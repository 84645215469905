import { call, put, takeEvery } from "redux-saga/effects";

import {
    fetchTempleData,
    fetchTempleDataSuccess,
    fetchTempleDataFailure,
    fetchPaymentData,
    fetchPaymentDataSuccess,
    fetchPaymentDataFailure,
    fetchTempleMediaData,
    fetchTempleMediaDataSuccess,
    fetchTempleMediaDataFailure,
    updateTempleDescription,
    updateTempleDescriptionSuccess,
    updateTempleDescriptionFailure,
    updateTempleMedia,
    updateTempleMediaSuccess,
    updateTempleMediaFailure
} from "../slices/templeDetailsSlice";

import {
    getRequest,
    putRequest
} from "../../api/apiActions";

import {
    getTempleDetaisUrl,
    getPaymentDetailsUrl,
    editTempleDescriptionUrl,
    editTempleMediaUrl,
    getTempleMediaUrl
} from "../../api/apiUrls";

const getTempleDetails = () => {
    return getRequest(getTempleDetaisUrl);
}

const getPaymentDetails = () => {
    return getRequest(getPaymentDetailsUrl);
}

const getTempleMediaDetails = () => {
    return getRequest(getTempleMediaUrl);
}

const editTempleDetails = (payload) => {
    return putRequest(editTempleDescriptionUrl, payload);
}

const editTempleMediaDetails = (payload) => {
    return putRequest(editTempleMediaUrl, payload);
}

function* fetchTempleDetails(action) {
    try {
        const result = yield call(getTempleDetails);

        if(result.status === 200) {
            yield put(fetchTempleDataSuccess(result?.data));
        } else {
            yield put(fetchTempleDataFailure());
        }
    } catch (error) {
        yield put(fetchTempleDataFailure(error));
    }
}

function* fetchPaymentDetails(action) {
    try {
        const result = yield call(getPaymentDetails);

        if(result.status === 200) {
            yield put(fetchPaymentDataSuccess(result?.data));
        } else {
            yield put(fetchPaymentDataFailure());
        }
    } catch (error) {
        yield put(fetchPaymentDataFailure(error));
    }
}

function* fetchMediaDetails(action) {
    try {
        const result = yield call(getTempleMediaDetails);

        if(result.status === 200) {
            yield put(fetchTempleMediaDataSuccess(result?.data));
        } else {
            yield put(fetchTempleMediaDataFailure());
        }
    } catch (error) {
        yield put(fetchTempleMediaDataFailure(error));
    }
}

function* editTempleDescription(action) {
    try {
        const result = yield call(editTempleDetails, action.payload);

        if(result.status === 200) {
            yield put(updateTempleDescriptionSuccess(result?.data));
        } else {
            yield put(updateTempleDescriptionFailure());
        }
    } catch (error) {
        yield put(updateTempleDescriptionFailure(error));
    }
}

function* editTempleMedia(action) {
    try {
        const result = yield call(editTempleMediaDetails, action.payload);

        if(result.status === 200) {
            yield put(updateTempleMediaSuccess(result?.data));
        } else {
            yield put(updateTempleMediaFailure());
        }
    } catch (error) {
        yield put(updateTempleMediaFailure(error));
    }
}

export function* templeDetailsSaga() {
    yield takeEvery(fetchTempleData.type, fetchTempleDetails);
    yield takeEvery(fetchPaymentData.type, fetchPaymentDetails);
    yield takeEvery(fetchTempleMediaData.type, fetchMediaDetails);
    yield takeEvery(updateTempleDescription.type, editTempleDescription);
    yield takeEvery(updateTempleMedia.type, editTempleMedia);
}

export default templeDetailsSaga;
