import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { AddButton } from "../../components/Addbutton";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import SearchInput from "../../components/CommonSearchBar";
import "../../styles/purohit/puja-management.css";
import AntSwitch from "../../components/AntSwitch";
import CommonTable from "../../components/CommonTable";
import AddEditWarehouse from "./AddEditWarehouse";

const tableRows = [
    {
      name: "Campaign 1",
      address: "Bengaluru",
      poc_name: "Cow",
      poc_contact: "+91 7819282828"
    },
    {
      name: "Campaign 2",
      address: "Bengaluru",
      poc_name: "Cow",
      poc_contact: "+91 7819282828"
    },
    {
      name: "Campaign 3",
      address: "Bengaluru",
      poc_name: "Cow",
      poc_contact: "+91 7819282828"
    },
    {
      name: "Campaign 4",
      address: "Bengaluru",
      poc_name: "Cow",
      poc_contact: "+91 7819282828"
    },
    {
      name: "Campaign 5",
      address: "Bengaluru",
      poc_name: "Cow",
      poc_contact: "+91 7819282828"
    },
];

const WarehouseManagement = React.memo(() => {
  const columns = [
    { field: "name", headerName: "Warehouse Name" },
    { field: "address", headerName: "Address" },
    { field: "poc_name", headerName: "POC Name" },
    { field: "poc_contact", headerName: "POC Phone No." },
    {
      field: "active",
      headerName: "Action",
      renderCell: (params, row) => (
        <span className="action-row">
          <img
            src="/edit-icon.svg"
            alt="edit"
            className="edit-icon"
            onClick={() => onEdit(row.sevaId)}
          />
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
            onChange={() => console.log("clicked")}
            checked={row?.active}
          />
          Active
        </span>
      ),
    },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  let pathName = location.pathname;
  pathName = pathName.split("/");

  const onAdd = () => {
    navigate('/warehouse-management/Add');
  }

  const onEdit = (id) => {
    navigate(`/warehouse-management/Edit`);
  };

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  return (
    <>
      {
        pathName[2] && (pathName[2] === "Add" || pathName[2] === "Edit") ?
        (
          <AddEditWarehouse />
        ) :
        (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0rem 0px 1rem 0px",
              }}
            > 
              <Box
                className={"heading"}
                sx={{
                  textWrap: "nowrap",
                  paddingY: "10px",
                }}
              >
                Warehouse Management
              </Box>
              <AddButton name="Add" icon={<AddIcon />} onClick={onAdd} />
            </div>

            <div className="puja_search_right">
              <SearchInput setSearchTerm={setSearch} />
            </div>

            <CommonTable
              columns={columns}
              data={tableRows}
              page={page}
              count={10}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          </>
        )
      }
    </>
  );
});

export default WarehouseManagement;
