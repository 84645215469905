import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import Tabs from "../components/common/Tabs";
import AntSwitch from "../components/AntSwitch";
import profileIcon from "../assets/images/profile_icon.svg";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import MaterialUIFieldAdapter from "../components/MaterialUiField";
import { AddButton } from "../components/Addbutton";
import FileUploadModal from "../components/FileUploadModal";
import "../styles/setting.css";
import DeleteModal from "../components/DeleteModal";
import {
  fetchSettingRequest,
  removeImage,
  updateSetting,
} from "../features/slices/settingSlice";

const Settings = React.memo(() => {
  const { userData, removeImageStatus, updateSettingStatus } = useSelector(
    (state) => state.setting,
  );

  const tabList = [
    { label: "Account", value: "Account" },
    { label: "Notification", value: "Notification" },
  ];

  let notificationList = [
    { name: "New Help Requests", status: true },
    { name: "Fan Story Approvals", status: false },
    { name: "Merchandise Reviews Approval", status: false },
    { name: "New Orders", status: false },
    { name: "Pending Daily Uploads", status: false },
  ];

  const dispatch = useDispatch();
  const [selectedTabName, setSelectedTabName] = useState("Account");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [actionType, setActionType] = useState("");
  const [imageFile, setImageFile] = useState([]);

  const [profileImage, setProfileImage] = useState(null);

  const [userObject, setUserObject] = useState({
    name: "",
    email: "",
    phone_number: "",
    role: "",
  });

  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("name is required"),
    email: Yup.string().email("Invalid email Id").required("email is required"),
    phone_number: Yup.string().length(10).required("phone number is required"),
    role: Yup.string().required("Role is required"),
  });

  useEffect(() => {
    dispatch(fetchSettingRequest());
  }, [dispatch, removeImageStatus, updateSettingStatus]);

  // function that switches between the tabs
  const handleMenuButtonClick = (menu) => {
    setSelectedTabName(menu);
  };

  // function that is responsible for setting file type both for normal as well as drag and drop file upload
  const handleOpen = (
    type,
    fileType,
    dragDropFileFormat,
    normalUploadFileFormat,
  ) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setUploadType(type);
  };

  // function that will open the file upload modal
  const handleImageUpload = () => {
    handleOpen("single", "JPEG", ["JPEG"], [".jpeg"]);
    setDialogOpen(!dialogOpen);
  };

  const handleImageDelete = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  // function that will remove the uploaded image once the delete button is clicked
  const handleDeleteConfirm = () => {
    setActionType("cancel");
    setImageFile([]);
    setOpenDeleteModal(false);
    dispatch(removeImage());
  };

  // function that will change the notification status
  const handleChangeStatus = (event, index) => {
    notificationList[index].status = event.target.checked;
  };

  // Memoize the handleSubmit function
  const handleSubmit = useCallback(
    (inputValue, file) => {
      if (
        inputValue.name &&
        inputValue.email &&
        inputValue.phone_number &&
        inputValue.role
      ) {
        const data = new FormData();

        data.append("profilePicture", file);

        data.append("fullName", inputValue.name);
        data.append("email", inputValue.email);
        data.append("countryCode", "+91");
        data.append("phoneNumber", inputValue.phone_number);
        data.append("address", "bengal;uru");
        //
        dispatch(updateSetting({ data }));
      }
    },
    [
      dispatch,
      userObject.name,
      userObject.email,
      userObject.phone_number,
      userObject.role,
    ],
  );

  let roles = localStorage.getItem("roles");

  const fetchRoleType = (roles) => {
    const set = new Set();
    if (roles)
      for (let role of roles?.split(",")) {
        set.add(role);
      }

    if (set.has("ROLE_ADMIN")) {
      return "ADMIN";
    } else if ("ROLE_SUB_ADMIN") {
      return "SUBADMIN";
    }
  };

  useEffect(() => {
    if (userData) {
      setUserObject({
        name: userData.fullName || "",
        email: userData.email || "",
        phone_number: userData.phoneNumber || "",
        role: fetchRoleType(roles) || "",
      });
    }
  }, [userData, roles]);

  return (
    <>
      {dialogOpen && (
        <FileUploadModal
          imageFile={imageFile}
          setImageFile={setImageFile}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadType={uploadType}
          setActionType={setActionType}
          setProfileImage={setProfileImage}
        />
      )}

      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          onClose={setOpenDeleteModal}
          onConfirm={handleDeleteConfirm}
          content="Are you sure you want to Remove Your Profile Picture ?"
        />
      )}

      <div className="settings_container">
        <Tabs
          tabs={tabList}
          tabStart={selectedTabName}
          onClick={handleMenuButtonClick}
        />

        {selectedTabName === "Account" ? (
          // opens the account tab
          <>
            <Typography variant="h6" sx={{ mt: 2 }} color="white">
              Your Profile Picture
            </Typography>

            <div className="account_profile_pic_box">
              <img
                className="user_profile_image"
                src={
                  imageFile?.length === 1 && actionType === "submit"
                    ? imageFile[0]?.url
                    : userData.profilePicturePath
                      ? userData.profilePicturePath
                      : profileIcon
                }
                alt="profile-icon-image"
              />

              <AddButton
                name="Upload New"
                type="submit"
                // width="17%"
                // padding="17px"
                onClick={handleImageUpload}
                // disabled={ !isChecked }
              />

              <AddButton
                name="Remove Profile Picture"
                type="submit"
                // width="22%"
                // padding="17px"
                background="#181822"
                color="#ACAEC3"
                onClick={handleImageDelete}
                borderColor="#67687C"
              />
            </div>

            <div className="user_profile_input_field_box">
              <Formik
                initialValues={userObject}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values, profileImage[0]);
                }}
              >
                {({ setFieldValue, isSubmitting, errors, touched }) => (
                  <Form>
                    <div className="input_field_container">
                      <MaterialUIFieldAdapter
                        name="name"
                        type="text"
                        label="User Name"
                        placeholder="Enter name"
                        sx={{ width: "100%" }}
                      />

                      <MaterialUIFieldAdapter
                        name="email"
                        type="email"
                        label="Email Address"
                        placeholder="Enter email"
                        sx={{ width: "100%" }}
                        disabled={true}
                      />
                    </div>

                    <div className="input_field_container">
                      <MaterialUIFieldAdapter
                        name="phone_number"
                        type="text"
                        label="Phone Number"
                        placeholder="Enter phone number"
                        sx={{ width: "100%" }}
                      />

                      <MaterialUIFieldAdapter
                        name="role"
                        type="text"
                        label="User Role"
                        placeholder="Enter user role"
                        sx={{ width: "100%" }}
                        disabled={true}
                      />
                    </div>

                    <div className="update_profile_button">
                      <AddButton
                        name="Update Profile"
                        type="submit"
                        // width="15%"
                        // padding="18px"
                        disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <>
            {/* opens the notifications tab */}
            <Typography variant="h6" sx={{ mt: 2 }} color="white">
              Notification Settings
            </Typography>

            <p className="sub_header">
              Select the kind of notification you get about your activities and
              recommendations.
            </p>

            <hr />

            <div className="notification_content_box">
              <div className="notification_info_box">
                <p>Email Settings</p>

                <div className="sub_header">
                  Get emails to find out what's going on when you're not online.
                  You can turn these off.
                </div>
              </div>

              <div className="notification_list_box">
                {notificationList.map((notification, index) => (
                  <div className="notification_item" key={notification.name}>
                    <div>
                      <AntSwitch
                        defaultChecked
                        inputProps={{ "aria-label": "ant design" }}
                        // onClick={(event) => { handleChangeStatus(event, index) }}
                        // checked={ notification?.status }
                      />
                    </div>

                    <span>
                      <p>{notification.name}</p>
                      <p className="sub_header">New</p>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
});

export default Settings;
