// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Active_title {
  font-family: "CabinetGrotesk-Medium";
  font-weight: 600;
  font-size: 1.5rem;
  color: #000000;
  margin-top: 0;
}

.sub_Active_title {
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 2rem;
  color: #000000;
}

.delete-button {
  color: var(--off-white) !important;
  background-color: var(--black-2) !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  border: 1px solid #67687c !important;
  width: 100px !important;
  height: 32px !important;
}

.cancel-button {
  color: var(--black-2) !important;
  background-color: #00d48b !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  border: 1px solid #67687c !important;
  width: 100px !important;
  height: 32px !important;
}

.button-container {
  display: flex !important;
  align-items: center !important;
  background-color: var(--black-2) !important;
  justify-content: center !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/confrimationModal.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kCAAkC;EAClC,2CAA2C;EAC3C,+BAA+B;EAC/B,2BAA2B;EAC3B,4BAA4B;EAC5B,oCAAoC;EACpC,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;EAChC,oCAAoC;EACpC,+BAA+B;EAC/B,2BAA2B;EAC3B,4BAA4B;EAC5B,oCAAoC;EACpC,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,2CAA2C;EAC3C,kCAAkC;AACpC","sourcesContent":[".Active_title {\n  font-family: \"CabinetGrotesk-Medium\";\n  font-weight: 600;\n  font-size: 1.5rem;\n  color: #000000;\n  margin-top: 0;\n}\n\n.sub_Active_title {\n  font-family: var(--font-primary);\n  font-weight: 600;\n  font-size: 2rem;\n  color: #000000;\n}\n\n.delete-button {\n  color: var(--off-white) !important;\n  background-color: var(--black-2) !important;\n  text-transform: none !important;\n  font-weight: 500 !important;\n  font-size: 1.2rem !important;\n  border: 1px solid #67687c !important;\n  width: 100px !important;\n  height: 32px !important;\n}\n\n.cancel-button {\n  color: var(--black-2) !important;\n  background-color: #00d48b !important;\n  text-transform: none !important;\n  font-weight: 500 !important;\n  font-size: 1.2rem !important;\n  border: 1px solid #67687c !important;\n  width: 100px !important;\n  height: 32px !important;\n}\n\n.button-container {\n  display: flex !important;\n  align-items: center !important;\n  background-color: var(--black-2) !important;\n  justify-content: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
