import React, { useEffect } from "react";
import * as animationData from "../../assets/animations/Confitti.json";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddButton } from "../../components/Addbutton";
import LottieWithoutLibrary from "../../components/LottieWithoutLibrary";
import { getRole } from "../../util/util";
import { uploadOnboardCompleteRequest } from "../../features/slices/onBordingSlice";

const DashboardStartpage = React.memo(() => {
  const { onBoardingUploadStatus } = useSelector((state) => state.onbording);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(uploadOnboardCompleteRequest());
  };

  useEffect(() => {
    let roles = localStorage.getItem("role");
    if (onBoardingUploadStatus) {
      if (roles === "ROLE_TEMPLE_OWNER") {
        navigate("/dashboard");
      } else if (roles === "ROLE_PUROHIT") {
        navigate("/purohit-dashboard");
      } else if (roles === "ROLE_SELLER_ECOMM") {
        navigate("/product-dashboard");
      }
    }
  }, [onBoardingUploadStatus]);
  return (
    <div
      className="login_form"
      style={{
        width: "30%",
        marginLeft: "32%",
        marginTop: "5rem",
      }}
    >
      <div className="congratulations">
        <div className="welcome_title" style={{ textAlign: "center" }}>
          Congratulations!
        </div>
        <div className="animationDiv">
          <LottieWithoutLibrary animationData={animationData} />
        </div>

        <AddButton
          name="Start"
          type="submit"
          width="100%"
          padding="18px"
          background="var(--secondary)"
          sx={{
            fontFamily: "var(--font-secondary) !important",
            fontWeight: 600,
            fontSize: ".9rem",
          }}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
});

export default DashboardStartpage;
