import React, { useState, useCallback } from "react";
import { Box } from "@mui/material";
import SearchInput from "../../components/CommonSearchBar";
import { AddButton } from "../../components/Addbutton";
import CommonTable from "../../components/CommonTable";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useNavigate } from "react-router-dom";

const tableRows = [
  {
    issueNo: "12345",
    userId: "56858",
    userName: "Santosh",
    category: "Others",
    lastActivity: "22/11/2024",
    status: "Open",
  },
  {
    issueNo: "12345",
    userId: "56858",
    userName: "Santosh",
    category: "Others",
    lastActivity: "22/11/2024",
    status: "Closed",
  },
  {
    issueNo: "12345",
    userId: "56858",
    userName: "Santosh",
    category: "Others",
    lastActivity: "22/11/2024",
    status: "Pending",
  },
  {
    issueNo: "12345",
    userId: "56858",
    userName: "Santosh",
    category: "Others",
    lastActivity: "22/11/2024",
    status: "Closed",
  },
];
const OtherTickets = React.memo(() => {
  const columns = [
    {
      field: "issueNo",
      headerName: "Issue No",
      renderCell: (param) => {
        return (
          <p
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/issue-management/Other-Issues/ResolveIssue`)
            }
          >
            {param}
          </p>
        );
      },
    },
    { field: "userId", headerName: "User ID" },
    { field: "userName", headerName: "User Name" },
    { field: "category", headerName: "Category" },
    { field: "lastActivity", headerName: "Last Activity" },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params, row) => {
        return (
          <p
            className="statusBox"
            style={{
              color:
                params === "Open"
                  ? "#47B881"
                  : params === "Closed"
                    ? "#0D0C11"
                    : params === "Pending"
                      ? "#F64C4C"
                      : "#0D0C11",
              background:
                params === "Open"
                  ? "#E9FFF4"
                  : params === "Closed"
                    ? "#E4E4E4"
                    : params === "Pending"
                      ? "#FEF2F2"
                      : "#E4E4E4",
            }}
          >
            {params}
          </p>
        );
      },
    },
  ];
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          Order List
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            margin: "1rem 0",
            justifyContent: "flex-end",
          }}
        >
          <SearchInput />
          <AddButton
            icon={<ArrowDropDownIcon />}
            name="Filter By"
            borderColor="var(--secondary)"
            background="#F5F5F5"
            color="var(--secondary)"
            padding="1.15rem"
          />
        </Box>

      </div>

      <CommonTable
        columns={columns}
        data={tableRows}
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </>
  );
});

export default OtherTickets;
