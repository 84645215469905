import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid2
} from "@mui/material";

import "../../styles/warehouseManagement.css";
import AddIcon from "@mui/icons-material/Add";
import "../../styles/ModalDatePicker.css";
import { AddButton } from "../../components/Addbutton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";

const initialFormState = {
  name: "",
  pocName: "",
  pocContact: "",
  address: "",
  pinCode: "",
  country: "",
  state: "",
  city: ""
};

const initialErrorState = {
  name: "",
  pocName: "",
  pocContact: "",
  address: "",
  pinCode: "",
  country: "",
  state: "",
  city: ""
};

const AddEditWarehouse = () => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const {actionType} = useParams();
  const navigate = useNavigate();

  const handleChange = (field) => (event) => {
    const value = event.target.value;

    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const SaveAndNext = () => {
    const newErrors = { ...initialErrorState };
    let isValid = true;

    if (!formData.name) {
        newErrors.name = "Warehouse Name is required";
        isValid = false;
    }

    if (!formData.pocName) {
      newErrors.pocName = "Point Of Contact Name is required";
      isValid = false;
    }

    if (!formData.pocContact) {
      newErrors.pocContact = "Point Of Contact Phone Number is required";
      isValid = false;
    }

    if(!formData.address) {
        newErrors.address = "Address is required";
        isValid = false;
    }

    if(!formData.pinCode) {
        newErrors.pinCode = "Pincode is required";
        isValid = false;
    }

    if(!formData.country) {
        newErrors.country = "Country is required";
        isValid = false;
    }

    if(!formData.state) {
        newErrors.state = "State is required";
        isValid = false;
    }

    if(!formData.city) {
        newErrors.city = "City is required";
        isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {}
  };

  return (
    <>
      <Box className="warehouse-main-container">
        <span style={{ display: "flex", alignItems: "center", gap: "1rem",  marginBottom: "1rem"}}>
          <ArrowBackIcon
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(`/warehouse-management`)}
          />

          <Box
            className={"heading"}
            sx={{
              textWrap: "nowrap",
              paddingY: "10px"
            }}
          >
            {actionType} Warehouse
          </Box>
        </span>

        <div className="heading">Warehouse Information</div>

        <Box style={{ marginTop: "1.5rem" }}>
            <div className="heading">Warehouse Details</div>
            <Grid2 container spacing={2} mt={2}>
                <Grid2 size={{ xs: 6, md: 6 }}>
                    <Typography className="onboarding-form_Heading">
                        Warehouse Name*
                    </Typography>

                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChange("name")}
                        placeholder="Enter Warehouse Name"
                        style={{ marginBottom: "1rem" }}
                        className="onboarding-custom-textfield"
                        InputProps={{
                        style: { color: "#8d8e9f" },
                        }}
                    />
                    {errors.name && (
                        <Typography color="error">{errors.name}</Typography>
                    )}
                </Grid2>
            </Grid2>

            <Grid2 container spacing={2} mt={2}>
                <Grid2 size={{ xs: 6, md: 6 }}>
                    <Typography className="onboarding-form_Heading">
                        Point Of Contact Name*
                    </Typography>

                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formData.pocName}
                        onChange={handleChange("pocName")}
                        placeholder="Enter Point Of Contact Name"
                        style={{ marginBottom: "1rem" }}
                        className="onboarding-custom-textfield"
                        InputProps={{
                        style: { color: "#8d8e9f" },
                        }}
                    />
                    {errors.pocName && (
                        <Typography color="error">{errors.pocName}</Typography>
                    )}
                </Grid2>

                <Grid2 size={{ xs: 6, md: 6 }}>
                    <Typography className="onboarding-form_Heading">
                        Point Of Contact Phone Number*
                    </Typography>

                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formData.pocContact}
                        onChange={handleChange("pocContact")}
                        placeholder="Enter Point Of Contact Phone Number"
                        style={{ marginBottom: "1rem" }}
                        className="onboarding-custom-textfield"
                        InputProps={{
                        style: { color: "#8d8e9f" },
                        }}
                    />
                    {errors.pocContact && (
                        <Typography color="error">{errors.pocContact}</Typography>
                    )}
                </Grid2>
            </Grid2>
        </Box>

        <Box style={{ marginTop: "1.5rem" }}>
            <div className="heading">Warehouse Location Details</div>

            <Grid2 container spacing={2} mt={2}>
                <Grid2 size={{ xs: 12, md: 12 }}>
                    <Typography className="onboarding-form_Heading">
                        Address*
                    </Typography>

                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formData.address}
                        onChange={handleChange("address")}
                        placeholder="Enter Address"
                        style={{ marginBottom: "1rem" }}
                        className="onboarding-custom-textfield"
                        InputProps={{
                        style: { color: "#8d8e9f" },
                        }}
                    />
                    {errors.address && (
                        <Typography color="error">{errors.address}</Typography>
                    )}
                </Grid2>
            </Grid2>

            <Grid2 container spacing={2} mt={2}>
                <Grid2 size={{ xs: 6, md: 6 }}>
                    <Typography className="onboarding-form_Heading">
                        Pincode*
                    </Typography>

                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formData.pinCode}
                        onChange={handleChange("pinCode")}
                        placeholder="Enter Your Pincode"
                        style={{ marginBottom: "1rem" }}
                        className="onboarding-custom-textfield"
                        InputProps={{
                        style: { color: "#8d8e9f" },
                        }}
                    />
                    {errors.pinCode && (
                        <Typography color="error">{errors.pinCode}</Typography>
                    )}
                </Grid2>

                <Grid2 size={{ xs: 6, md: 6 }}>
                    <Typography className="onboarding-form_Heading">
                        Country*
                    </Typography>

                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formData.country}
                        onChange={handleChange("country")}
                        placeholder="Enter Your Country"
                        style={{ marginBottom: "1rem" }}
                        className="onboarding-custom-textfield"
                        InputProps={{
                        style: { color: "#8d8e9f" },
                        }}
                    />
                    {errors.country && (
                        <Typography color="error">{errors.country}</Typography>
                    )}
                </Grid2>
            </Grid2>

            <Grid2 container spacing={2} mt={2}>
                <Grid2 size={{ xs: 6, md: 6 }}>
                    <Typography className="onboarding-form_Heading">
                        State*
                    </Typography>

                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formData.state}
                        onChange={handleChange("state")}
                        placeholder="Enter Your State"
                        style={{ marginBottom: "1rem" }}
                        className="onboarding-custom-textfield"
                        InputProps={{
                        style: { color: "#8d8e9f" },
                        }}
                    />
                    {errors.state && (
                        <Typography color="error">{errors.state}</Typography>
                    )}
                </Grid2>

                <Grid2 size={{ xs: 6, md: 6 }}>
                    <Typography className="onboarding-form_Heading">
                        City*
                    </Typography>

                    <TextField
                        fullWidth
                        variant="outlined"
                        value={formData.city}
                        onChange={handleChange("city")}
                        placeholder="Enter Your City"
                        style={{ marginBottom: "1rem" }}
                        className="onboarding-custom-textfield"
                        InputProps={{
                        style: { color: "#8d8e9f" },
                        }}
                    />
                    {errors.city && (
                        <Typography color="error">{errors.city}</Typography>
                    )}
                </Grid2>
            </Grid2>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
          <AddButton
            name="Add"
            icon={<AddIcon />}
            onClick={SaveAndNext}
            background="#A9392D"
            color="#FFFFFF"
            width="auto"
            borderColor="#ACAEC3"
            sx={{
              marginRight: "1rem",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default AddEditWarehouse;
