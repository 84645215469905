import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  Typography,
  TextField,
  Grid2,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Chip,
} from "@mui/material";
import "../../styles/onboardingForm.css";
import "../../styles/ModalDatePicker.css";

import {
  fetchtimeAvailabiltyRequest,
  uploadtimeAvailabiltyRequest,
} from "../../features/slices/purohitOnboardingSlice";
import { useDispatch, useSelector } from "react-redux";
import { AddButton } from "../../components/Addbutton";

const initialFormState = {
  pujaDays: [],
  offlineLocation: "",
};

const initialErrorState = {
  pujaDays: "",
  offlineLocation: "",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDENESDAT",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

const TimingAndAvailability = forwardRef((props, ref) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);

  const { timeAvailabiltyGetAllStatus, timeAvailabiltyData } = useSelector(
    (state) => state.purohitOnboarding,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchtimeAvailabiltyRequest());
  }, [dispatch]);

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;

      if (formData.pujaDays.length === 0) {
        newErrors.pujaDays = "Selection of pujaDays is required";
        isValid = false;
      }
      if (!formData.offlineLocation) {
        newErrors.offlineLocation = "offlineLocation is required";
        isValid = false;
      }
      setErrors(newErrors);
      if (isValid) {
        dispatch(uploadtimeAvailabiltyRequest(formData));
      }
    },
  }));

  const handleMultiChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData((prev) => ({
      ...prev,
      pujaDays: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleRemoveSelectedItem = (index) => {
    const updatedVariants = [...formData.pujaDays];

    setFormData((prev) => ({
      ...prev,
      pujaDays: [
        ...updatedVariants.slice(0, index),
        ...updatedVariants.slice(index + 1),
      ],
    }));
  };

  useEffect(() => {
    if (timeAvailabiltyGetAllStatus) {
      setFormData({
        ...formData,
        pujaDays: timeAvailabiltyData?.pujaDay,
        offlineLocation: timeAvailabiltyData?.offlineLocation,
      });
    }
  }, [timeAvailabiltyGetAllStatus]);

  return (
    <>
      <div className="heading">Timing & Availability</div>
      <div variant="h6">
        Note: Select a pujaDays what you will be available to perform Pujas
      </div>
      <Box style={{ marginTop: "1rem" }}>
        <div className="onboarding-sub_heading">Puja Offerings</div>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <div>
              <FormControl fullWidth>
                <Typography className="onboarding-form_Heading">
                  Day*
                </Typography>
                <Select
                  multiple
                  displayEmpty
                  value={formData.pujaDays}
                  onChange={handleMultiChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Select pujaDays</em>;
                    }

                    return selected.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Placeholder</em>
                  </MenuItem>
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {formData.pujaDays.map((color, index) => (
              <Chip
                key={color}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fill: "black !important",
                  },
                }}
                label={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: ".8rem",
                    }}
                  >
                    {color}
                  </div>
                }
                onDelete={() => handleRemoveSelectedItem(index)}
                className="product-color-select-chip"
              />
            ))}
            {errors.pujaDays && (
              <Typography color="error">{errors.pujaDays}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Primary Service Offline offlineLocation*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.offlineLocation}
              onChange={handleChange("offlineLocation")}
              placeholder="Enter your primary service offline offlineLocation"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.offlineLocation && (
              <Typography color="error">{errors.offlineLocation}</Typography>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
});

export default TimingAndAvailability;
