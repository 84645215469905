import React, { useCallback, useState, useEffect } from "react";
import "../../styles/dashboard.css";
import { Box, Typography } from "@mui/material";
import CommonTable from "../../components/CommonTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PledgeOthersCards from "../../components/cards/PledgeOthersCards";
import AlternativeLabelStepper from "../../components/LabelStepper";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/PledgeCards.css";
import { AddButton } from "../../components/Addbutton";
import {
  getPrasadOrderById,
  updatePrasadOrderById,
} from "../../features/slices/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoader } from "../../components/CustomLoader";

const PrasadTracking = React.memo(() => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const orderId = query.get("OrderId");

  const columns = [
    { field: "name", headerName: "Prasadam Name" },
    { field: "quantity", headerName: "Quantity" },
    { field: "price", headerName: "Price" },
  ];

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { label: "Order Received", key: "RECEIVED" },
    { label: "Order Pending", key: "PROCESSING" },
    { label: "Package is Ready", key: "READY" },
    { label: "Dispatch", key: "DISPATCHED" },
    { label: "Out for Delivery", key: "OUT_FOR_DELIVERY" },
    { label: "Delivered", key: "DELIVERED" },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { prasadDataById, loading, pageCount, updatePrasadStatus } =
    useSelector((state) => state.order);

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  useEffect(() => {
    if (prasadDataById?.statusDetails?.length > 0) {
      const lastCompletedIndex = steps.findIndex(
        (step) =>
          step.key ===
          prasadDataById?.statusDetails[
            prasadDataById?.statusDetails.length - 1
          ].status,
      );
      setActiveStep(lastCompletedIndex);
    }
  }, [prasadDataById]);

  useEffect(() => {
    if (orderId) {
      dispatch(getPrasadOrderById(orderId));
    }
  }, [orderId, updatePrasadStatus]);

  return (
    <>
      <div className="header-title">
        <ArrowBackIcon
          sx={{
            color: "var(--off-white)",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/order-management/Prasadam_Orders`)}
        />
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          Order Id: {prasadDataById?.orderId || ""}
        </Box>
      </div>
      <div style={{ height: "80vh", overflow: "auto", scrollbarWidth: "thin" }}>
        <Box className="trackingDetails_Box">
          <Box style={{ width: "75%" }}>
            <PledgeOthersCards
              content={
                <div className="details_container">
                  <div className="status_text" style={{ color: "#FF6F01" }}>
                    {prasadDataById?.userName || ""}
                  </div>
                  <div className="details_content">Customer Details:</div>
                  <div className="details_text">
                    User Id :
                    <span className="details_text">
                      {" "}
                      {prasadDataById?.userId || ""}
                    </span>
                  </div>
                  <div className="details_text">
                    User Name :
                    <span className="details_text">
                      {" "}
                      {prasadDataById?.userName || ""}
                    </span>
                  </div>
                  <div className="details_text">
                    Amount Paid :
                    <span className="details_text">
                      {" "}
                      {prasadDataById?.totalPrice || ""}
                    </span>
                  </div>
                  <div className="details_text">
                    Phone Number :
                    <span className="details_text">
                      {" "}
                      {prasadDataById?.phoneNumber || ""}
                    </span>
                  </div>
                  <div className="details_text">
                    Mail Id :{" "}
                    <span className="details_text">
                      {" "}
                      {prasadDataById?.email || ""}
                    </span>
                  </div>
                  <div className="details_content">Delivery Address:</div>
                  <div className="details_text">
                    {prasadDataById?.deliveryAddress?.name || ""}
                    <span className="details_text">
                      {" | "}
                      {prasadDataById?.deliveryAddress?.phoneNumber || ""}
                    </span>
                  </div>
                  <div className="details_text">
                    {prasadDataById?.deliveryAddress?.addressLine1 || ""}
                    <span className="details_text">
                      {" ,"}
                      {prasadDataById?.deliveryAddress?.addressLine2 ||
                        ""}, {prasadDataById?.deliveryAddress?.city || ""},{" "}
                      {prasadDataById?.deliveryAddress?.state || ""},
                      {prasadDataById?.deliveryAddress?.country || ""},
                      {prasadDataById?.deliveryAddress?.pinCode || ""}
                    </span>
                  </div>
                </div>
              }
              editButton={false}
              showView={false}
              borderColor="var(--primary-2)"
            />
          </Box>

          <Box className="trackingBox">
            <div className="details_content">Tracking Details</div>
            <div className="details_text">
              Tracking Link:
              <href> Link</href>
            </div>
          </Box>
        </Box>

        <Typography
          sx={{ margin: "1rem 0 !important" }}
          className="details_content"
        >
          Total No. of Items: 5
        </Typography>
        <CommonTable
          columns={columns}
          data={prasadDataById?.items || []}
          page={page}
          count={1}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
        />
        {prasadDataById?.statusDetails &&
          (() => {
            const rejectedIndex = prasadDataById.statusDetails.findIndex(
              (detail) => detail.status === "READY",
            );
            if (rejectedIndex !== -1) {
              return (
                <Box className="onWay_box">
                  <img src="../../assets/images/two_way_icon.svg" alt="test" />
                  <Box>
                    <div className="details_content">On the Way</div>
                    <div></div>
                  </Box>
                </Box>
              );
            } else {
              return null;
            }
          })()}

        <Box sx={{ margin: "1rem 0 !important" }}>
          <Typography className="form_Heading">Order Status</Typography>
          {prasadDataById?.statusDetails &&
            (() => {
              let dynamicSteps = [...steps];

              let dynamicActiveStep = activeStep;

              const rejectedIndex = prasadDataById.statusDetails.findIndex(
                (detail) => detail.status === "REJECTED",
              );

              if (rejectedIndex !== -1) {
                const cancelledStep = {
                  label: "Cancelled",
                  key: "CANCELLED",
                  color: "red",
                };

                dynamicSteps.splice(rejectedIndex, 0, cancelledStep);

                dynamicActiveStep = rejectedIndex;
              }

              return (
                <AlternativeLabelStepper
                  activeStep={dynamicActiveStep}
                  steps={dynamicSteps}
                  stepStyles={(index) => ({
                    color: dynamicSteps[index]?.color || "inherit",
                  })}
                />
              );
            })()}

          <Box className="cancel_and_submit">
            {prasadDataById?.statusDetails?.length > 0 &&
              (() => {
                const currentStatus =
                  prasadDataById?.statusDetails[
                    prasadDataById?.statusDetails.length - 1
                  ]?.status;

                switch (currentStatus) {
                  case "RECEIVED":
                    return (
                      <>
                        <AddButton
                          name="Reject"
                          background="#F5F5F5"
                          color="var(--secondary)"
                          borderColor="var(--secondary)"
                          onClick={() => {
                            dispatch(
                              updatePrasadOrderById({
                                orderId,
                                status: "REJECTED",
                              }),
                            );
                          }}
                        />
                        <AddButton
                          name="Accept"
                          onClick={() => {
                            dispatch(
                              updatePrasadOrderById({
                                orderId,
                                status: "PROCESSING",
                              }),
                            );
                          }}
                        />
                      </>
                    );

                  case "PROCESSING":
                    return (
                      <>
                        <AddButton
                          name="Cancel"
                          background="#F5F5F5"
                          color="var(--secondary)"
                          borderColor="var(--secondary)"
                          onClick={() => {
                            dispatch(
                              updatePrasadOrderById({
                                orderId,
                                status: "REJECTED",
                              }),
                            );
                          }}
                        />
                        <AddButton
                          name="Update"
                          onClick={() => {
                            dispatch(
                              updatePrasadOrderById({
                                orderId,
                                status: "READY",
                              }),
                            );
                          }}
                        />
                      </>
                    );

                  case "READY":
                    return null;

                  default:
                    return null;
                }
              })()}
          </Box>
        </Box>
      </div>
    </>
  );
});

export default PrasadTracking;
