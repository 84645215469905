import { createSlice } from "@reduxjs/toolkit";
import { getRole } from "../../util/util";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    loading: false,
    error: null,
    token: null,
    ForgotPasswordStatus: false,
    ResetPasswordStatus: false,
    termsAndPolicyStatus: false,
    otpSentStatus: false,
    loginData: [],
    signUpData: [],
    loginEmail: "",
  },
  reducers: {
    loginRequest: (state, action) => {
      const { LoginObj } = action.payload;
      state.loading = true;
      state.error = null;
      state.loginEmail = LoginObj.userName;

      state.loginData = [];
      localStorage.clear();
    },
    loginSuccess: (state, action) => {
      let payload = action.payload;
      const {
        accessToken,
        expireAt,
        refreshToken,
        termPolicyViewed,
        roles,
        tokenType,
        signUpStage,
      } = payload.data;

      state.loading = false;
      state.isAuthenticated = true;
      state.loginData = payload.data;
      localStorage.setItem("roles", roles);
      localStorage.setItem("role", getRole(roles));
      localStorage.setItem("token", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("expireAt", expireAt);

      switch (signUpStage) {
        case "EMAIL_NOT_VERIFIED":
          payload.navigate("/signup/verify-email");
          break;
        case "EMAIL_VERIFIED":
          payload.navigate("/signup/contact");
          break;
        case "ONBOARDING_INITIATED":
          if (termPolicyViewed === false) {
            payload.navigate("/signup/view-policy");
            break;
          } else {
            const role = localStorage.getItem("role");
            if (role === "ROLE_TEMPLE_OWNER") {
              payload.navigate("/signup/temple-details");
            } else if (role === "ROLE_PUROHIT") {
              payload.navigate("/signup/purohit-details");
            } else if (role === "ROLE_SELLER_ECOMM") {
            }
            break;
          }
        case "ONBOARDING_COMPLETED":
          if (roles?.includes("ROLE_TEMPLE_OWNER")) {
            payload.navigate("/dashboard");
          } else if (roles?.includes("ROLE_PUROHIT")) {
            payload.navigate("/purohit-dashboard");
          } else if (roles?.includes("ROLE_SELLER_ECOMM")) {
            payload.navigate("/product-dashboard");
          }
          break;
        default:
          if (roles?.includes("ROLE_TEMPLE_OWNER")) {
            payload.navigate("/dashboard");
          } else if (roles?.includes("ROLE_PUROHIT")) {
            payload.navigate("/purohit-dashboard");
          } else if (roles?.includes("ROLE_SELLER_ECOMM")) {
            payload.navigate("/product-dashboard");
          }
      }
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;

      state.loginData = [];
      localStorage.clear();
    },
    signUpRequest: (state, action) => {
      let payload = action.payload;
      state.email = payload.email;
      state.loading = true;
      state.error = null;
      state.signUpData = [];
      localStorage.clear();
    },
    signUpSuccess: (state, action) => {
      let payload = action.payload;
      const {
        accessToken,
        expireAt,
        refreshToken,
        termPolicyViewed,
        roles,
        tokenType,
      } = payload.data;
      state.loading = false;
      state.isAuthenticated = true;
      state.signUpData = payload.data;
      localStorage.setItem("roles", roles);
      localStorage.setItem("email", payload.email);
      localStorage.setItem("role", getRole(roles));
      localStorage.setItem("token", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("expireAt", expireAt);
      payload.navigate(`/signup/verify-email?email=${payload.email}`);
    },
    signUpFailure: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      localStorage.clear();
      state.signUpData = [];
    },
    emailRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    emailSuccess: (state, action) => {
      state.loading = false;
    },
    emailFailure: (state, action) => {
      state.loading = false;
    },
    verifyEmailRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    verifyEmailSuccess: (state, action) => {
      state.loading = false;
    },
    verifyEmailFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      action.payload.navigate("/login");
    },
    sendOtpRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.otpSentStatus = false;
    },
    sendOtpSuccess: (state, action) => {
      state.loading = false;
      state.otpSentStatus = true;
    },
    sendOtpFailure: (state, action) => {
      state.loading = false;
      state.otpSentStatus = false;
    },
    verifyOtpRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    verifyOtpSuccess: (state, action) => {
      state.loading = false;
      action.payload.navigate(action.payload.url);
    },
    verifyOtpFailure: (state, action) => {
      state.loading = false;
    },
    updateLogin: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateLoginSuccess: (state, action) => {
      let payload = action.payload;
      const {
        accessToken,
        expireAt,
        refreshToken,
        termPolicyViewed,
        roles,
        tokenType,
      } = payload.data;
      state.loading = false;
      state.isAuthenticated = true;

      localStorage.setItem("token", accessToken);
      localStorage.setItem("roles", roles);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("expireAt", expireAt);
      payload.navigate("/dashboard");
    },
    updateLoginFailure: (state, action) => {
      state.loading = false;
      state.error = "";
      const { navigate } = action.payload;
      localStorage.clear();
      navigate("/login");
    },
    ForgotPassWordRequest: (state, action) => {
      state.loading = true;
      state.ForgotPasswordStatus = false;
    },
    ForgotPassWordRequestSuccess: (state, action) => {
      state.loading = false;
      state.ForgotPasswordStatus = true;
    },
    ForgotPassWordRequestFailure: (state, action) => {
      state.loading = false;
      state.ForgotPasswordStatus = false;
    },
    ResetPassWordRequest: (state, action) => {
      state.loading = true;
      state.ResetPasswordStatus = false;
    },
    ResetPassWordRequestSuccess: (state, action) => {
      state.loading = false;
      state.ResetPasswordStatus = true;
    },
    ResetPassWordRequestFailure: (state, action) => {
      state.loading = false;
      state.ResetPasswordStatus = false;
    },
    TermsAndPolicyRequest: (state, action) => {
      state.termsAndPolicyStatus = false;
    },
    TermsAndPolicySuccess: (state, action) => {
      const { navigate } = action.payload;
      state.termsAndPolicyStatus = true;

      const role = localStorage.getItem("role");
      if (role === "ROLE_TEMPLE_OWNER") {
        navigate("/signup/temple-details");
      } else if (role === "ROLE_PUROHIT") {
        navigate("/signup/purohit-details");
      } else if (role === "ROLE_SELLER_ECOMM") {
      }
    },
    TermsAndPolicyFailure: (state, action) => {
      state.termsAndPolicyStatus = false;
    },
    logout: (state, action) => {
      state.isAuthenticated = false;
      state.loginData = [];
      state.token = null;
      localStorage.clear();
      action.payload("/login");
    },
    templeDetailsRequest: (state, action) => {
      state.loading = true;
    },
    templeDetailsSuccess: (state, action) => {
      let payload = action.payload;
      state.loading = false;
      payload.navigate(`/signup/document-checklist`);
    },
    templeDetailsFailure: (state, action) => {
      state.loading = false;
    },
    purohitDetailsRequest: (state, action) => {
      state.loading = true;
    },
    purohitDetailsSuccess: (state, action) => {
      let payload = action.payload;
      state.loading = false;
      payload.navigate(`/signup/document-checklist`);
    },
    purohitDetailsFailure: (state, action) => {
      state.loading = false;
    },
    documentStatusUpdateRequest: (state, action) => {
      state.loading = true;
    },
    documentStatusUpdateSuccess: (state, action) => {
      let payload = action.payload;
      state.loading = false;

      const role = localStorage.getItem("role");
      if (role === "ROLE_TEMPLE_OWNER") {
        payload.navigate("/Onboarding");
      } else if (role === "ROLE_PUROHIT") {
        payload.navigate("/purohit-onboarding");
      } else if (role === "ROLE_SELLER_ECOMM") {
        payload.navigate("/product-onboarding");
      }
    },
    documentStatusUpdateFailure: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  signUpRequest,
  signUpSuccess,
  signUpFailure,
  emailSuccess,
  emailRequest,
  emailFailure,
  sendOtpRequest,
  sendOtpSuccess,
  sendOtpFailure,
  verifyOtpRequest,
  verifyOtpSuccess,
  verifyOtpFailure,
  verifyEmailRequest,
  verifyEmailSuccess,
  verifyEmailFailure,
  templeDetailsRequest,
  templeDetailsSuccess,
  templeDetailsFailure,
  purohitDetailsRequest,
  purohitDetailsSuccess,
  purohitDetailsFailure,
  logout,
  updateLogin,
  updateLoginSuccess,
  updateLoginFailure,
  ForgotPassWordRequest,
  ForgotPassWordRequestSuccess,
  ForgotPassWordRequestFailure,
  ResetPassWordRequest,
  ResetPassWordRequestSuccess,
  ResetPassWordRequestFailure,
  TermsAndPolicyRequest,
  TermsAndPolicySuccess,
  TermsAndPolicyFailure,
  documentStatusUpdateRequest,
  documentStatusUpdateSuccess,
  documentStatusUpdateFailure,
} = authSlice.actions;

export default authSlice.reducer;
