import { createSlice } from "@reduxjs/toolkit";

const adminDetailsSlice = createSlice({
    name: "adminDetails",
    initialState: {
        adminData: [],
        error: null,
        loading: false,
        fetchadminDataStatus: false
    },
    reducers: {
        fetchAdminDetails: (state) => {
            state.adminData = [];
            state.loading = true;
            state.error = null;
            state.fetchadminDataStatus = false;
        },
        fetchAdminDetailsSuccess: (state, action) => {
            state.adminData = action.payload;
            state.loading = false;
            state.error = null;
            state.fetchadminDataStatus = true;
        },
        fetchAdminDetailsFailure: (state, action) => {
            state.adminData = [];
            state.loading = false;
            state.error = action.payload;
            state.fetchadminDataStatus = false;
        }
    }
});

export const {
    fetchAdminDetails,
    fetchAdminDetailsSuccess,
    fetchAdminDetailsFailure
} = adminDetailsSlice.actions;

export default adminDetailsSlice.reducer;
