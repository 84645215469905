import { Typography } from "@mui/material";
import { City, Country } from "country-state-city";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Pincode from "react-pincode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AddButton } from "../../components/Addbutton";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import { templeDetailsRequest } from "../../features/slices/authSlice";
import "../../styles/login.css";
import { fetchOnBoardingStatusRequest } from "../../features/slices/onBordingSlice";
import { CustomLoader } from "../../components/CustomLoader";

const TempleDetails = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pincodeData, setPincodeData] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const state = useSelector((state) => state.onbording);

  const validationSchema = Yup.object({
    name: Yup.string().required("Temple Name is required"),
    address: Yup.string().required("Temple Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    pinCode: Yup.string().required("Pin Code is required"),
  });

  const [formValues, setFormValues] = useState({
    name: name,
    address: address,
    pinCode: pincodeData?.pincode || "",
    country: "",
    state: pincodeData?.stateName || "",
    city: pincodeData?.city || "",
  });

  useEffect(() => {
    if (pincodeData) {
      setFormValues({
        name: name,
        address: address,
        pinCode: pincodeData.pincode || "",
        country: Country.getAllCountries().filter(
          (country) =>
            country.isoCode ===
            City.getAllCities().filter(
              (city) =>
                city.name ===
                (pincodeData.district === "Bangalore"
                  ? "Bengaluru"
                  : pincodeData.district),
            )[0]?.countryCode,
        )[0]?.name,
        state: pincodeData.stateName || "",
        city: pincodeData.city || "",
      });
      setName(formValues.name);
      setAddress(formValues.address);
    }
  }, [pincodeData]);

  const handleSubmit = (values) => {
    let TempleObj = {
      templeName: values.name,
      address: values.address,
      country: values.country,
      state: values.state,
      city: values.city,
      pinCode: values.pinCode,
    };

    dispatch(templeDetailsRequest({ TempleObj, navigate }));
  };

  useEffect(() => {
    dispatch(fetchOnBoardingStatusRequest());
  }, []);

  useEffect(() => {
    let roles = localStorage.getItem("role");
    if (
      state?.onBoardingStatusDtata?.currentStatus === "WELCOME_TO_ONBOARDING"
    ) {
      navigate("/signup/document-checklist");
    } else if (
      state.onBoardingStatusDtata?.currentStatus === "BASIC_DETAILS" ||
      state.onBoardingStatusDtata?.currentStatus === "DOCUMENTS" ||
      state.onBoardingStatusDtata?.currentStatus === "DESCRIPTION" ||
      state.onBoardingStatusDtata?.currentStatus === "PHOTO_VIDEO" ||
      state.onBoardingStatusDtata?.currentStatus === "ADMIN_INFO" ||
      state.onBoardingStatusDtata?.currentStatus === "SEVA_DETAILS" ||
      state.onBoardingStatusDtata?.currentStatus === "PRASHADAM" ||
      state.onBoardingStatusDtata?.currentStatus === "DONATION" ||
      state.onBoardingStatusDtata?.currentStatus === "PAYMENT" ||
      state.onBoardingStatusDtata?.currentStatus === "APPROVED" ||
      state.onBoardingStatusDtata?.currentStatus === "WAITING_FOR_APPROVAL" ||
      state.onBoardingStatusDtata?.currentStatus === "REJECTED" ||
      state.onBoardingStatusDtata?.currentStatus === "PREVIEW"
    ) {
      navigate("/Onboarding");
    } else if (
      state.onBoardingStatusDtata?.currentStatus === "ONBOARDING_COMPLETED"
    ) {
      if (roles === "ROLE_TEMPLE_OWNER") {
        navigate("/dashboard");
      } else if (roles === "ROLE_PUROHIT") {
        navigate("/purohit-dashboard");
      } else if (roles === "ROLE_SELLER_ECOMM") {
        navigate("/product-dashboard");
      }
    }
  }, [state?.onBoardingStatusDtata?.currentStatus]);

  if (state.loading) {
    return <CustomLoader loading={state.loading} message="" />;
  }

  return (
    <>
      <img src="/daiv-logo.svg" alt="logo" className="daiv_logo" />
      <div container className="login_container">
        {/* this section will open the terms and conditions modal box */}

        <div className="login_background_image"></div>
        <div className="login_content ">
          <div className="greeting_text_container">
            <div
              className="temple_form"
              style={{ textAlign: "left", maxHeight: "90vh", overflow: "auto" }}
            >
              <div className="welcome_title">Temple Details</div>
              <div style={{ marginTop: "-1rem" }}>
                <Formik
                  initialValues={formValues}
                  validationSchema={validationSchema} // Assuming you have a Yup schema
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {({ setFieldValue, isSubmitting, errors, touched }) => (
                    <Form>
                      <div
                        style={{
                          width: "100%",
                          marginTop: "25px",
                        }}
                      >
                        <MaterialUIFieldAdapter
                          name="name"
                          type="text"
                          label="Temple Name*"
                          placeholder="Enter Temple Name"
                          style={{ width: "100%", marginTop: "-.5rem" }}
                        />
                        <div style={{ marginTop: "-.5rem" }}>
                          <MaterialUIFieldAdapter
                            name="address"
                            type="text"
                            label="Temple Address*"
                            placeholder="Enter Temple Address"
                            style={{ width: "100%", marginTop: "-.5rem" }}
                          />
                        </div>
                        <div style={{ marginTop: "-.5rem" }}>
                          <Typography
                            textAlign={"left"}
                            color={"#2C2C39"}
                            fontSize={"1rem"}
                          >
                            Pin Code*
                          </Typography>
                        </div>
                        <Pincode
                          invalidError="Please check pincode"
                          lengthError="Check length"
                          name="pinCode"
                          showState={false}
                          showArea={false}
                          showCity={false}
                          showDistrict={false}
                          pincodeInput={{
                            height: "2rem",
                            width: "95%",
                            border: "1px solid black",
                            marginBottom: "1rem",
                          }}
                          getData={(data) => {
                            setName(formValues.name);
                            setName(formValues.address);
                            setPincodeData(data); // Assuming this updates the pincodeData state
                            setFieldValue("pinCode", data.pincode);
                            setFieldValue("state", data.stateName);
                            setFieldValue("city", data.city);
                            setFieldValue(
                              "country",
                              Country.getAllCountries().filter(
                                (country) =>
                                  country.isoCode ===
                                  City.getAllCities().filter(
                                    (city) =>
                                      city.name ===
                                      (data.district === "Bangalore"
                                        ? "Bengaluru"
                                        : data.district),
                                  )[0]?.countryCode,
                              )[0]?.name,
                            );
                          }}
                        />
                        {errors.pinCode && touched.pinCode ? (
                          <div
                            style={{
                              color: "#d32f2f",
                              fontSize: ".75rem",
                              marginTop: "-.75rem",
                              marginLeft: "1rem",
                              fontWeight: 400,
                              fontFamily: " sans-serif",
                            }}
                          >
                            {errors.pinCode}
                          </div>
                        ) : null}

                        <div style={{ marginTop: "-0rem" }}>
                          <MaterialUIFieldAdapter
                            name="country"
                            type="text"
                            label="Enter Your Country*"
                            placeholder="Enter Your Country"
                            style={{ width: "100%", marginTop: "-.5rem" }}
                          />
                        </div>
                        <div style={{ marginTop: "-.5rem" }}>
                          <MaterialUIFieldAdapter
                            name="state"
                            type="text"
                            label="Enter Your State*"
                            placeholder="Enter Your State"
                            style={{ width: "100%", marginTop: "-.5rem" }}
                          />
                        </div>
                        <div style={{ marginTop: "-.5rem" }}>
                          <MaterialUIFieldAdapter
                            name="city"
                            type="text"
                            label="Enter Your City*"
                            placeholder="Enter Your City"
                            style={{ width: "100%", marginTop: "-.5rem" }}
                          />
                        </div>
                      </div>

                      <AddButton
                        name="Submit"
                        type="submit"
                        width="100%"
                        padding="1.3rem"
                        background="var(--secondary)"
                        sx={{
                          fontFamily: "var(--font-secondary) !important",
                          fontWeight: "400 !important",
                          fontSize: ".9rem",
                        }}
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default TempleDetails;
