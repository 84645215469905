// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-static-datePicker {
  background-color: var(--grey-dark);
  border-radius: 1.5rem !important;
  padding: 0.8rem;
  text-align: center;
}

.custom-static-datePicker .MuiDateCalendar-root {
  margin: 0px auto !important;
}

.css-cyfsxc-MuiPickersCalendarHeader-labelContainer{
  letter-spacing:0  !important;
}

.custom-static-datePicker .MuiPickersCalendarHeader-labelContainer {
  color: #ff6f01 !important;
  display: flex !important;
  padding: 0.25rem 0.5rem !important;
  background: #fff1e6 !important;
  font-size: 1rem !important;
  border-radius: 0.5rem !important;
}
.custom-static-datePicker .MuiPickersDay-root.Mui-selected {
  background-color: #ff6f01 !important;
}

.custom-static-datePicker .MuiPickersDay-root {
  color: #0d0c11 !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

.custom-static-datePicker .MuiDayCalendar-weekDayLabel {
  color: #0d0c11 !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

.custom-static-datePicker .MuiPickersCalendarHeader-label {
  color: #ff6f01 !important;
  font-size: 1.2rem !important;
}

.custom-static-datePicker .MuiPickersDay-root.Mui-disabled {
  color: #acaec3 !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CustomStaticDatePicker.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,gCAAgC;EAChC,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,kCAAkC;EAClC,8BAA8B;EAC9B,0BAA0B;EAC1B,gCAAgC;AAClC;AACA;EACE,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".custom-static-datePicker {\n  background-color: var(--grey-dark);\n  border-radius: 1.5rem !important;\n  padding: 0.8rem;\n  text-align: center;\n}\n\n.custom-static-datePicker .MuiDateCalendar-root {\n  margin: 0px auto !important;\n}\n\n.css-cyfsxc-MuiPickersCalendarHeader-labelContainer{\n  letter-spacing:0  !important;\n}\n\n.custom-static-datePicker .MuiPickersCalendarHeader-labelContainer {\n  color: #ff6f01 !important;\n  display: flex !important;\n  padding: 0.25rem 0.5rem !important;\n  background: #fff1e6 !important;\n  font-size: 1rem !important;\n  border-radius: 0.5rem !important;\n}\n.custom-static-datePicker .MuiPickersDay-root.Mui-selected {\n  background-color: #ff6f01 !important;\n}\n\n.custom-static-datePicker .MuiPickersDay-root {\n  color: #0d0c11 !important;\n  font-size: 1.2rem !important;\n  font-weight: 500 !important;\n}\n\n.custom-static-datePicker .MuiDayCalendar-weekDayLabel {\n  color: #0d0c11 !important;\n  font-size: 1.2rem !important;\n  font-weight: 500 !important;\n}\n\n.custom-static-datePicker .MuiPickersCalendarHeader-label {\n  color: #ff6f01 !important;\n  font-size: 1.2rem !important;\n}\n\n.custom-static-datePicker .MuiPickersDay-root.Mui-disabled {\n  color: #acaec3 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
