import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
} from "@mui/material";
import "../../styles/userCard.css";

const UserCard = ({
  profilePic,
  title,
  date,
  content,
}) => {
  return (
    <Card
      sx={{
        backgroundColor: "transparent !important",
        color: "var(--off-white)",
       
      }}
    >
      <CardHeader
        sx={{ padding: "0.25rem" }}
        avatar={<Avatar src={profilePic} alt={title} />}
        title={
          <Typography className="card_date">{date}</Typography> 
        }
      />
      <CardContent className="chat_Card">
        <Typography variant="body2" className="user-card-content">
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UserCard;
