// src/components/MultiStepForm.tsx
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CustomLoader } from "../components/CustomLoader";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddButton } from "../components/Addbutton";
import {
  fetchOnBoardingRequest,
  fetchOnBoardingStatusRequest,
  toggleStatus,
} from "../features/slices/onBordingSlice";
import BasicTemplateDetails from "../pages/Onboarding/BasicTemplateDetails";
import "../styles/Onboard.css";
import AdminInfo from "./Onboarding/AdminInfo";
import BusinessDescription from "./Onboarding/BusinessDescription";
import BusinessVerificationDetails from "./Onboarding/BusinessVerificationDetails";
import DashboardStartpage from "./Onboarding/DashboardStartpage";
import DocVerificationPage from "./Onboarding/DocVerifyPage";
import DonationDetails from "./Onboarding/DonationDetails";
import IncompleteSetup from "./Onboarding/IncompleteSetup";
import OnboardingPreview from "./Onboarding/OnboardingPreview";
import Payments from "./Onboarding/Payments";
import PhotosAndVideos from "./Onboarding/PhotosAndVideos";
import PrasadamDetails from "./Onboarding/PrasadamDetails";
import ServiceDetails from "./Onboarding/ServiceDetails";

const steps = [
  {
    label: "Basic Temple Details",
    key: "BASIC_DETAILS",
    subSteps: [
      { label: "Basic Details", key: "Basic_Info" },
      { label: "Temple Description", key: "TEMPLE_DESC" },
      { label: "Contact Information", key: "Contact_info" },
    ],
  },
  {
    label: "Temple Verification & Documentation",
    key: "DOCUMENTS",
    subSteps: [
      { label: "Identity Verification", key: "UPLOAD_DOCS" },
      { label: "Temple Registration", key: "VERIFY_PROCESS" },
    ],
  },
  { label: "Temple Description", key: "DESCRIPTION" },
  {
    label: "Photos and Videos",
    key: "PHOTO_VIDEO",
    subSteps: [
      { label: "Photo Gallery", key: "Photo_Gallery" },
      { label: "Videos", key: "Videos" },
    ],
  },
  {
    label: "Admin Information",
    key: "ADMIN_INFO",
    subSteps: [
      { label: "Basic Admin Information", key: "Basic_Info" },
      { label: "Location Details", key: "Loc" },
      { label: "Contact Information", key: "Contact_Info" },
      { label: "Identity Verification", key: "Id_Info" },
    ],
  },
  {
    label: "Seva Details",
    key: "SEVA_DETAILS",
    subSteps: [
      { label: "Basic Details", key: "Basic_Info" },
      { label: "Participation Information", key: "Part_info" },
      { label: "Schedule Information", key: "Schedule_Info" },
      { label: "Additional Verification", key: "Add_Info" },
    ],
  },
  {
    label: "Prasadam Details",
    key: "PRASHADAM",
    subSteps: [
      { label: "Basic Details", key: "Basic_Info" },

      { label: "Prasadam Information", key: "Prasad_Info" },
      { label: "Media", key: "Media" },
    ],
  },
  {
    label: "Donation Details",
    key: "DONATION",
    subSteps: [
      { label: "Basic Details", key: "Basic_Info" },

      { label: "Schedule Information", key: "Schedule_Info" },
      { label: "User Benifits", key: "User_Info" },
    ],
  },
  {
    label: "Payment",
    key: "PAYMENT",
    subSteps: [{ label: "Basic Account Details", key: "Account_Info" }],
  },
];

const Onboarding = () => {
  const [activeStep, setActiveStep] = useState(0);

  const BasicTemplateDetailsRef = useRef(null);
  const BusinessVerificationDetailsRef = useRef(null);
  const BusinessDescriptionRef = useRef(null);
  const PhotosAndVideosRef = useRef(null);
  const AdminInfoRef = useRef(null);
  const ServiceDetailsRef = useRef(null);
  const PrasadamDetailsRef = useRef(null);
  const DonationDetailsRef = useRef(null);
  const PaymentsRef = useRef(null);
  const PreviewRef = useRef(null);
  const isInitialLoad = useRef(true);
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(null);

  const {
    loading,
    onBoardingData,
    onBoardingStatusDtata,
    onBoardingUploadStatus,
    imageDeleteStatus,
  } = useSelector((state) => state.onbording);

  const dispatch = useDispatch();

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        BasicTemplateDetailsRef.current.handleSubmit();

        break;
      case 1:
        BusinessVerificationDetailsRef.current.handleSubmit();
        break;
      case 2:
        BusinessDescriptionRef.current.handleSubmit();
        break;
      case 3:
        PhotosAndVideosRef.current.handleSubmit();
        break;
      case 4:
        AdminInfoRef.current.handleSubmit();
        break;
      case 5:
        ServiceDetailsRef.current.handleSubmit();
        break;
      case 6:
        PrasadamDetailsRef.current.handleSubmit();
        break;
      case 7:
        DonationDetailsRef.current.handleSubmit();
        break;
      case 8:
        PaymentsRef.current.handleSubmit();
        break;
      case 9:
        PreviewRef.current.handleSubmit();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (onBoardingUploadStatus && activeStep <= 9) {
      setActiveStep((prevStep) => prevStep + 1);

      dispatch(toggleStatus());
    }
  }, [onBoardingUploadStatus]);
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);

    dispatch(toggleStatus());
    dispatch(fetchOnBoardingRequest());
  };

  useEffect(() => {
    let roles = localStorage.getItem("role");
    if (onBoardingStatusDtata?.currentStatus === "ONBOARDING_COMPLETED") {
      if (roles === "ROLE_TEMPLE_OWNER") {
        navigate("/dashboard");
      } else if (roles === "ROLE_PUROHIT") {
        navigate("/purohit-dashboard");
      } else if (roles === "ROLE_SELLER_ECOMM") {
        navigate("/product-dashboard");
      }
    }
  }, [onBoardingStatusDtata?.currentStatus]);

  useEffect(() => {
    if (onBoardingStatusDtata?.completedStatuses?.includes("PAYMENT")) {
      setExpanded("");
    } else {
      setExpanded(`panel${activeStep}`);
    }
  }, [activeStep]);

  useEffect(() => {
    if (
      isInitialLoad.current &&
      onBoardingStatusDtata?.completedStatuses?.length > 0
    ) {
      const lastCompletedIndex = steps.findIndex(
        (step) =>
          step.key ===
          onBoardingStatusDtata?.completedStatuses[
            onBoardingStatusDtata?.completedStatuses.length - 1
          ],
      );

      if (
        onBoardingStatusDtata.completedStatuses.includes("PREVIEW") &&
        lastCompletedIndex === steps.length - 1
      ) {
        setActiveStep(lastCompletedIndex);
      } else {
        setActiveStep(lastCompletedIndex + 1);
      }
      isInitialLoad.current = false;
    }
  }, [onBoardingStatusDtata]);
  useEffect(() => {
    dispatch(fetchOnBoardingRequest());
  }, [activeStep, dispatch]);

  useEffect(() => {
    dispatch(fetchOnBoardingStatusRequest());
  }, [onBoardingUploadStatus]);

  const renderFormStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <BasicTemplateDetails
            OnBoardData={onBoardingData}
            ref={BasicTemplateDetailsRef}
          />
        );
      case 1:
        return (
          <BusinessVerificationDetails
            OnBoardData={onBoardingData}
            ref={BusinessVerificationDetailsRef}
          />
        );
      case 2:
        return (
          <BusinessDescription
            OnBoardData={onBoardingData}
            ref={BusinessDescriptionRef}
          />
        );

      case 3:
        return (
          <PhotosAndVideos
            OnBoardData={onBoardingData}
            ref={PhotosAndVideosRef}
          />
        );
      case 4:
        return <AdminInfo OnBoardData={onBoardingData} ref={AdminInfoRef} />;
      case 5:
        return (
          <ServiceDetails
            OnBoardData={onBoardingData}
            ref={ServiceDetailsRef}
          />
        );
      case 6:
        return (
          <PrasadamDetails
            OnBoardData={onBoardingData}
            ref={PrasadamDetailsRef}
          />
        );
      case 7:
        return (
          <DonationDetails
            OnBoardData={onBoardingData}
            ref={DonationDetailsRef}
          />
        );
      case 8:
        return <Payments OnBoardData={onBoardingData} ref={PaymentsRef} />;
      case 9:
        return (
          <OnboardingPreview ref={PreviewRef} OnBoardData={onBoardingData} />
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", background: "white" }}>
      <CustomLoader loading={loading} message="Updating Data, Please Wait..." />

      <Box sx={{ width: 280, pt: 2 }}>
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "16px",
          }}
        >
          <img src="/daiv-icon.svg" alt="img" />
          <p className="daivFontstyle">Daiv</p>
        </Typography>
        <div className="stepper-head">
          {activeStep <= 8 ? activeStep + 1 : activeStep}/9 Progress
        </div>
        <div
          style={{ height: "76vh", overflow: "auto", scrollbarWidth: "thin" }}
        >
          {steps.map((step, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              sx={{
                boxShadow: "none",
                marginBottom: "0.5rem",
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={index !== 2 && <ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  background: "#fff",
                  padding: "0.5rem 1rem",
                  "&.Mui-expanded": {
                    background: "#f0f0f0",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    gap: ".5rem",
                  }}
                >
                  {index < activeStep ? (
                    <img src="/tick.svg" alt="" />
                  ) : index === activeStep ? (
                    <img src="/circle-filled.svg" alt="" />
                  ) : (
                    <img src="/circle-unfilled.svg" alt="" />
                  )}
                  <Typography
                    sx={{
                      fontFamily: "var(--font-secondary)",
                      fontWeight: 600,
                      fontSize: "1rem",
                      flexGrow: 1,
                    }}
                  >
                    {step.label}
                  </Typography>
                </Box>
              </AccordionSummary>
              {step.subSteps && (
                <AccordionDetails
                  sx={{
                    background: "#f9f9f9",
                    padding: "0.5rem 1rem",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      height: "80%",
                      borderLeft: "1px solid #CA843A",
                      left: "1.4rem",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        content: "",
                        top: 0,
                        left: "-.18rem",
                        height: ".3rem",
                        width: ".3rem",
                        background: " #CA843A",
                        transform: "rotate(45deg)",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        content: "",
                        bottom: 0,
                        left: "-.18rem",
                        height: ".3rem",
                        width: ".3rem",
                        background: " #CA843A",
                        transform: "rotate(45deg)",
                      }}
                    ></div>
                  </div>
                  <List>
                    {step.subSteps.map((subStep, subIndex) => (
                      <ListItem
                        key={subIndex}
                        button
                        sx={{
                          padding: "0.3rem 0.3rem",
                          marginLeft: ".9rem",
                          "&:hover": { backgroundColor: "#e0e0e0" },
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "var(--font-secondary)",
                            fontWeight: "400 !important",
                          }}
                        >
                          {subStep.label}
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              )}
            </Accordion>
          ))}
        </div>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <div style={{ height: "5rem" }}>
          <p className="daivFontstyle" style={{ padding: 0 }}>
            Welcome To Daiv
          </p>
          <div className="heading" style={{ margin: "-0.75rem 0.5rem" }}>
            Please Setup The Following Details
          </div>
        </div>
        <Box sx={{ flexGrow: 1, p: 1 }}>
          <Box className="onboarding-main-container">
            {onBoardingStatusDtata?.currentStatus ===
              "WAITING_FOR_APPROVAL" && <DocVerificationPage />}
            {onBoardingStatusDtata?.currentStatus === "REJECTED" && (
              <IncompleteSetup />
            )}
            {(onBoardingStatusDtata?.currentStatus === "APPROVED" ||
              onBoardingStatusDtata?.currentStatus === "WAITING_FOR_APPROVAL" ||
              onBoardingStatusDtata?.currentStatus === "REJECTED") && (
              <DashboardStartpage />
            )}
            {/* {onBoardingStatusDtata?.currentStatus === "PREVIEW"  && <OnboardingPreview handleBack={handleBack} />} */}
            {(onBoardingStatusDtata?.currentStatus === "BASIC_DETAILS" ||
              onBoardingStatusDtata?.currentStatus === "DOCUMENTS" ||
              onBoardingStatusDtata?.currentStatus === "DESCRIPTION" ||
              onBoardingStatusDtata?.currentStatus === "PHOTO_VIDEO" ||
              onBoardingStatusDtata?.currentStatus === "ADMIN_INFO" ||
              onBoardingStatusDtata?.currentStatus === "SEVA_DETAILS" ||
              onBoardingStatusDtata?.currentStatus === "PRASHADAM" ||
              onBoardingStatusDtata?.currentStatus === "DONATION" ||
              onBoardingStatusDtata?.currentStatus === "PAYMENT" ||
              onBoardingStatusDtata?.currentStatus === "PREVIEW" ||
              onBoardingStatusDtata?.currentStatus === undefined) && (
              <div style={{ paddingRight: "1rem" }}>{renderFormStep()}</div>
            )}
          </Box>

          {(onBoardingStatusDtata?.currentStatus === "BASIC_DETAILS" ||
            onBoardingStatusDtata?.currentStatus === "DOCUMENTS" ||
            onBoardingStatusDtata?.currentStatus === "DESCRIPTION" ||
            onBoardingStatusDtata?.currentStatus === "PHOTO_VIDEO" ||
            onBoardingStatusDtata?.currentStatus === "ADMIN_INFO" ||
            onBoardingStatusDtata?.currentStatus === "SEVA_DETAILS" ||
            onBoardingStatusDtata?.currentStatus === "PRASHADAM" ||
            onBoardingStatusDtata?.currentStatus === "DONATION" ||
            onBoardingStatusDtata?.currentStatus === "PAYMENT" ||
            onBoardingStatusDtata?.currentStatus === "PREVIEW" ||
            onBoardingStatusDtata?.currentStatus === undefined) && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
              {activeStep !== 0 && (
                <AddButton
                  name="Back"
                  onClick={handleBack}
                  background="#FFFFFF"
                  color="#A9392D"
                  borderColor="var(--secondary)"
                  sx={{
                    marginRight: "1rem",
                  }}
                />
              )}

              <AddButton
                name={
                  activeStep === 9
                    ? "Submit"
                    : activeStep === steps.length - 1
                      ? "Finish"
                      : "Save & Continue"
                }
                onClick={handleNext}
                background="#A9392D"
                color="#FFFFFF"
                borderColor="var(--secondary)"
                sx={{
                  marginRight: "1rem",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Onboarding;
