import React, { useState, useEffect } from "react";
import Tabs from "../../components/common/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StatsCard from "../../components/cards/StatsCard";
import PaymentHistory from "./PaymentHistory";
import PaymentDue from "./PaymentDue";
import "../../styles/payment.css";
import { Box } from "@mui/material";

const tabItems = [
  { label: "Payment History", value: "Payment_History" },
  { label: "Payment Due", value: "Payment_Due" },
];

const PaymentManagement = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { tabType } = useParams();

  const [selectedMenu, setSelectedMenu] = useState(() => {
    let pathName = location.pathname.split("/");
    return pathName[2] ? pathName[2] : "Payment_History";
  });

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
    navigate(`/payment-management/${menu}`);
  };

  useEffect(() => {
    let pathName = location.pathname.split("/");

    const filteredData = tabItems.filter((item) =>
      pathName.includes(item.value),
    );

    if (filteredData.length > 0 && !selectedMenu) {
      setSelectedMenu(filteredData[0].value);
      navigate(
        `/payment-management${filteredData[0].value ? `/${filteredData[0].value}` : ""}${tabType ? `/${tabType}` : ""}`,
      );
    }

    if (filteredData.length === 0 && !selectedMenu) {
      setSelectedMenu("Payment_History");
      navigate(`/payment-management/Payment_History`);
    }
  }, [pathName]);

  return (
    <>
      <Box
        className={"heading"}
        sx={{
          textWrap: "nowrap",
          paddingY: "10px",
        }}
      >
        Payment Management
      </Box>
      <div className="payment-stats-container">
        <StatsCard
          label={"Total Payment Received"}
          stat={"0"}
          image="/payment-icons/total-payment-icon.svg"
          info="Total Payments received"
        />
        <StatsCard
          label={"Pending Payment"}
          stat={"0"}
          image="/payment-icons/pending-payment-icon.svg"
        />
        <StatsCard
          label={"Last Month Payment"}
          stat={"0"}
          image="/payment-icons/past-payment-icon.svg"
        />
      </div>

      <div style={{ margin: "1rem 0rem" }}>
        <Tabs
          tabs={tabItems}
          tabStart={selectedMenu}
          onClick={handleMenuButtonClick}
        />
      </div>

      {selectedMenu === "Payment_History" ? (
        <PaymentHistory />
      ) : selectedMenu === "Payment_Due" ? (
        <PaymentDue />
      ) : null}
    </>
  );
});

export default PaymentManagement;
