import React from "react";
import AntSwitch from "../../components/AntSwitch";

const Notifications = () => {
  const userRole = localStorage.getItem("role");

  return (
    <div className="notification_container">
      <div>
        <div className="onboarding-sub_heading">Notification</div>
        <div className="notfi_desc">
          Select the types of notifications you would like to receive regarding
          your activities and recommendations.
        </div>
      </div>

      {userRole === "ROLE_TEMPLE_OWNER" ?
        (
          <div className="notification_email_order">
            <div>
              <div className="onboarding-sub_heading">Email Settings</div>
              <div className="notfi_desc" style={{ width: "80%" }}>
                Get emails to find out what’s going on when you’re not online. You
                can turn these off.
              </div>
            </div>
            <div className="notification_orders">
              <div>
                <div className="photo_edit_title">New Orders</div>
                <span className="action-row">
                  <AntSwitch
                    defaultChecked
                    inputProps={{ "aria-label": "ant design" }}
                    // onChange={() => handleStatus()}
                    // checked={row?.active}
                  />
                  On
                </span>
              </div>
              <div>
                <div className="photo_edit_title">Return Requests</div>
                <span className="action-row">
                  <AntSwitch
                    defaultChecked
                    inputProps={{ "aria-label": "ant design" }}
                    // onChange={() => handleStatus()}
                    // checked={row?.active}
                  />
                  On
                </span>
              </div>
              <div>
                <div className="photo_edit_title">Low Stock Alerts</div>
                <span className="action-row">
                  <AntSwitch
                    defaultChecked
                    inputProps={{ "aria-label": "ant design" }}
                    // onChange={() => handleStatus()}
                    // checked={row?.active}
                  />
                  On
                </span>
              </div>
              <div>
                <div className="photo_edit_title">Out of Stock Alerts</div>
                <span className="action-row">
                  <AntSwitch
                    defaultChecked
                    inputProps={{ "aria-label": "ant design" }}
                    // onChange={() => handleStatus()}
                    // checked={row?.active}
                  />
                  On
                </span>
              </div>
            </div>
          </div>
        ) : userRole === "ROLE_PUROHIT" ? (
          <div className="notification_email_order">
            <div className="notification_email_vertical_order">
              <div>
                <div className="photo_edit_title">Booking Notifications</div>
                <div className="notfi_desc" style={{ width: "80%" }}>
                  Choose whether to receive notifications for new bookings, cancellations, and 
                  modifications to stay updated on your schedule.
                </div>
              </div>

              <div>
                <div className="photo_edit_title">Payment Alerts</div>
                <div className="notfi_desc" style={{ width: "80%" }}>
                  Manage alerts for when payments are received to keep track of your finances efficiently.
                </div>
              </div>
            </div>

            <div className="notification_orders">
              <div>
                <span className="action-row">
                  <AntSwitch
                    defaultChecked
                    inputProps={{ "aria-label": "ant design" }}
                    // onChange={() => handleStatus()}
                    // checked={row?.active}
                  />
                  On
                </span>
              </div>
              <div style={{ marginTop: '2.5rem' }}>
                <span className="action-row">
                  <AntSwitch
                    defaultChecked
                    inputProps={{ "aria-label": "ant design" }}
                    // onChange={() => handleStatus()}
                    // checked={row?.active}
                  />
                  On
                </span>
              </div>
            </div>
          </div>
        ) : null
      }
    </div>
  );
};

export default Notifications;
