import React from "react";
import HelpAndSupportCard from "../../components/cards/HelpAndSupportCard";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ContactUs = React.memo(() => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/chart-support-page`);
  };

  return (
    <>
      <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <ArrowBackIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(`/help_and_support`)}
        />
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          Contact Us
        </Box>
      </span>
      <div style={{ display: "flex", alignContent: "center", gap: "2rem" }}>
        <HelpAndSupportCard
          label={{value: "Write to Us"}}
          image="/HelpAndSupport/writeToUs.svg"
          onClick={handleCardClick}
          subText={`"Send us a message anytime"`}
          iconImage ="/HelpAndSupport/contactUs.svg"
        />
        <HelpAndSupportCard
          label={{value: "Email Us"}}
          image="/HelpAndSupport/emailUs.svg"
          subText={
            <>"Drop us an email at {" "}<span style={{ color: "#FF6F01" }}>(support@daiv.co.in)</span>, and we’ll respond on top priority."</>
          }
        />
        <HelpAndSupportCard
          label={{value: "Call Us"}}
          image="/HelpAndSupport/callUs.svg"
          subText={
            <>
              "Need assistance? Feel free to call us at{" "}<span style={{ color: "#FF6F01" }}>(+91-7022498467)</span> and speak directly with our support team for instant help."
            </>
          }
        />
      </div>
    </>
  );
});
export default ContactUs;
