import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AddButton } from "../components/Addbutton";
import Tabs from "../components/common/Tabs";
import LogisticIssues from "./Issue/LogisticIssues";
import OrderTickets from "./Issue/OrderTickets";
import OtherTickets from "./Issue/OtherTickets";
import PaymentTickets from "./Issue/PaymentTickets";
import BookingTickets from "./Issue/BookingTicket";

const templeTabItems = [
  { label: "Order Issues", value: "Order-Issues" },
  { label: "Payment Issues", value: "Payment-Issues" },
  { label: "Logistic Issues", value: "Logistic-Issues" },
  { label: "Other Issues", value: "Other-Issues" },
];

const purohitTabItems = [
  { label: "Booking Issues", value: "Booking-Issues" },
  { label: "Payment Issues", value: "Payment-Issues" },
  { label: "Other Issues", value: "Other-Issues" },
];

const IssueManagement = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { subTabType, actionType, tabType } = useParams();
  const [selectedMenu, setSelectedMenu] = useState("");
  const [tabItems, setTabItems] = useState([]);
  const role = localStorage.getItem("role");

  useEffect(() => {
    if (role === "ROLE_TEMPLE_OWNER") {
      setTabItems(templeTabItems);
    } else if (role === "ROLE_PUROHIT") {
      setTabItems(purohitTabItems);
    }
  }, [role]);

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
    navigate(`/issue-management/${menu}`);
  };

  useEffect(() => {
    let pathName = location.pathname.split("/");
    const filteredData = tabItems.filter((item) =>
      pathName.includes(item.value),
    );

    if (filteredData.length > 0) {
      setSelectedMenu(filteredData[0].value);
      navigate(
        `/issue-management/${filteredData[0].value}${subTabType ? `/${subTabType}` : ""}${actionType ? `/${actionType}` : ""}`,
      );
    }
    if (filteredData.length === 0) {
      setSelectedMenu(tabItems[0]?.value);
      navigate(`/issue-management/${tabItems[0]?.value}`);
    }
  }, [pathName]);

  const HandleAdd = () => {
    navigate(`/issue-management/new-issue/Add`);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // margin: "0rem 0px 1rem 0px",
        }}
      >
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
          }}
        >
          Issue Management
        </Box>
        <AddButton name="Add" icon={<AddIcon />} onClick={HandleAdd} />
      </div>

      <div style={{ marginTop: "0.5rem" }}>
        <Tabs
          tabs={tabItems}
          tabStart={selectedMenu}
          onClick={handleMenuButtonClick}
        />
      </div>

      {selectedMenu === "Order-Issues" ? (
        <OrderTickets />
      ) : selectedMenu === "Other-Issues" ? (
        <OtherTickets />
      ) : selectedMenu === "Booking-Issues" ? (
        <BookingTickets />
      ) : selectedMenu === "Logistic-Issues" ? (
        <LogisticIssues />
      ) : selectedMenu === "Payment-Issues" ? (
        <PaymentTickets />
      ) : null}
    </>
  );
});

export default IssueManagement;
