import { call, put, takeEvery } from "redux-saga/effects";

import {
    fetchExperienceData,
    fetchExperienceDataSuccess,
    fetchExperienceDataFailure,
    fetchDocumentData,
    fetchDocumentDataSuccess,
    fetchDocumentDataFailure,
    fetchProfileData,
    fetchProfileDataSuccess,
    fetchProfileDataFailure,
    fetchMediaData,
    fetchMediaDataSuccess,
    fetchMediaDataFailure,
    updateExperience,
    updateExperienceSuccess,
    updateExperienceFailure
} from "../slices/purohitSettingsSlice";

import {
    getRequest,
    putRequest
} from "../../api/apiActions";

import {
    getPurohitExperienceUrl,
    editPurohitExperienceUrl,
    getPurohitDocumentUrl,
    getPurohitProfileUrl,
    getPurohitMediaUrl
} from "../../api/apiUrls";

const getPurohitExperience = () => {
    return getRequest(getPurohitExperienceUrl);
}

const getPurohitDocument = () => {
    return getRequest(getPurohitDocumentUrl);
}

const getPurohitProfile = () => {
    return getRequest(getPurohitProfileUrl);
}

const getPurohitMedia = () => {
    return getRequest(getPurohitMediaUrl);
}

const editPurohitExperience = (payload) => {
    return putRequest(editPurohitExperienceUrl, payload);
}

function* fetchPurohitExperience(action) {
    try {
        const result = yield call(getPurohitExperience);
        
        if(result.status === 200) {
            yield put(fetchExperienceDataSuccess(result?.data));
        } else {
            yield put(fetchExperienceDataFailure());
        }
    } catch (error) {
        yield put(fetchExperienceDataFailure(error));
    }
}

function* fetchPurohitDocument(action) {
    try {
        const result = yield call(getPurohitDocument);
        
        if(result.status === 200) {
            yield put(fetchDocumentDataSuccess(result?.data));
        } else {
            yield put(fetchDocumentDataFailure());
        }
    } catch (error) {
        yield put(fetchDocumentDataFailure(error));
    }
}

function* fetchPurohitProfile(action) {
    try {
        const result = yield call(getPurohitProfile);
        
        if(result.status === 200) {
            yield put(fetchProfileDataSuccess(result?.data));
        } else {
            yield put(fetchProfileDataFailure());
        }
    } catch (error) {
        yield put(fetchProfileDataFailure(error));
    }
}

function* fetchPurohitMedia(action) {
    try {
        const result = yield call(getPurohitMedia);
        
        if(result.status === 200) {
            yield put(fetchMediaDataSuccess(result?.data));
        } else {
            yield put(fetchMediaDataFailure());
        }
    } catch (error) {
        yield put(fetchMediaDataFailure(error));
    }
}

function* updatePurohitExperience(action) {
    try {
        const result = yield call(editPurohitExperience, action.payload);

        if(result.status === 200) {
            yield put(updateExperienceSuccess(result?.data));
        } else {
            yield put(updateExperienceFailure());
        }
    } catch (error) {
        yield put(updateExperienceFailure(error));
    }
}

export function* purohitSettingsSaga() {
    yield takeEvery(fetchExperienceData.type, fetchPurohitExperience);
    yield takeEvery(updateExperience.type, updatePurohitExperience);
    yield takeEvery(fetchDocumentData.type, fetchPurohitDocument);
    yield takeEvery(fetchProfileData.type, fetchPurohitProfile);
    yield takeEvery(fetchMediaData.type, fetchPurohitMedia);
}

export default purohitSettingsSaga;
