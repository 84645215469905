import React, { useCallback, useState } from "react";
import "../../styles/dashboard.css";
import CommonTable from "../../components/CommonTable";
import { useNavigate } from "react-router-dom";
import { AddButton } from "../../components/Addbutton";

const tableRows = [
  {
    bookingID: "25821",
    pujaName: "Wedding",
    samagri: "With Samagri",
    date: "19/10/2024",
    price: "₹1000",
  },
  {
    bookingID: "25821",
    pujaName: "Wedding",
    samagri: "With Samagri",
    date: "19/10/2024",
    price: "₹1000",
  },
  {
    bookingID: "25821",
    pujaName: "Wedding",
    samagri: "With Samagri",
    date: "19/10/2024",
    price: "₹1000",
  },
  {
    bookingID: "25821",
    pujaName: "Wedding",
    samagri: "With Samagri",
    date: "19/10/2024",
    price: "₹1000",
  },
  {
    bookingID: "25821",
    pujaName: "Wedding",
    samagri: "With Samagri",
    date: "19/10/2024",
    price: "₹1000",
  },
];

const RejectedBookings = React.memo(() => {
  const navigate = useNavigate();

  const columns = [
    {  field: "bookingID",  headerName: "Booking ID", 
        renderCell: (params, row) => {
        return (
          <span
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() =>
              navigate(
                `/booking-management/Rejected-Bookings/view?OrderId=${params}`,
              )
            }
          >
            <p style={{ marginLeft: "0.5rem" }}>{params}</p>
          </span>
        );
      },
    },
    { field: "pujaName", headerName: "Puja Name" },
    { field: "samagri", headerName: "Samagri" },
    { field: "date", headerName: "Date" },
    { field: "price", headerName: "Price" },
    {
        field: "action",
        headerName: "Action",
        renderCell: (params, row) => (
            <AddButton               
                name="View"  
                background="#FFFFFF"
                borderColor="#FFFFFF"
                onClick={()=>{}} 
                padding="0"
                color="#FF8C34"   
            />  
        ),
      },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  return (
    <>
      <CommonTable
        columns={columns}
        data={tableRows}
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </>
  );
});

export default RejectedBookings;