// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-main-container {
    padding: 1rem !important;
    width: 100%;
    background-color: #f5f5f5 !important;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
}

.product-details-container {
    padding: 1rem !important;
    width: 96%;
    background-color: var(--grey-dark) !important;
    height: auto;
}

.image {
    width: 100vw;
    height: 50vh;
    object-fit: contain !important;
}

.heading, .sub_heading {
    color: #000000 !important;
    font-family: var(--font-primary) !important;
}

.heading {
    margin: 0.5rem 0 !important;
    font-size: 1.5rem !important;
    font-weight: 700 !important;
}

.sub_heading {
    margin: 1rem 0 !important;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
}

.button {
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 1440px) {
    .heading {     
        font-size: 1.4rem !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/viewProduct.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,WAAW;IACX,oCAAoC;IACpC,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;IACxB,UAAU;IACV,6CAA6C;IAC7C,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,2CAA2C;AAC/C;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;IAC5B,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;IAC5B,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".product-main-container {\n    padding: 1rem !important;\n    width: 100%;\n    background-color: #f5f5f5 !important;\n    height: 80vh;\n    overflow-y: auto;\n    overflow-x: hidden;\n    scrollbar-width: thin;\n}\n\n.product-details-container {\n    padding: 1rem !important;\n    width: 96%;\n    background-color: var(--grey-dark) !important;\n    height: auto;\n}\n\n.image {\n    width: 100vw;\n    height: 50vh;\n    object-fit: contain !important;\n}\n\n.heading, .sub_heading {\n    color: #000000 !important;\n    font-family: var(--font-primary) !important;\n}\n\n.heading {\n    margin: 0.5rem 0 !important;\n    font-size: 1.5rem !important;\n    font-weight: 700 !important;\n}\n\n.sub_heading {\n    margin: 1rem 0 !important;\n    font-size: 1.2rem !important;\n    font-weight: 600 !important;\n}\n\n.button {\n    margin-top: 3rem;\n    display: flex;\n    justify-content: flex-end;\n}\n\n@media only screen and (max-width: 1440px) {\n    .heading {     \n        font-size: 1.4rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
