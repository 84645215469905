import { createSlice } from "@reduxjs/toolkit";

const sevaSlice = createSlice({
  name: "seva",
  initialState: {
    sevaData: [],
    seva: null,
    loading: false,
    error: null,
    createStatus: false,
    updateStatus: false,
    updateSevaStatus: false,
    imageDeleteStatus: false,
  },
  reducers: {
    getSeva: (state, action) => {
      state.loading = true;
      state.error = null;
      state.sevaData = [];
    },
    getSevaSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.sevaData = action.payload;
    },
    getSevaFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.sevaData = [];
    },
    getSevaById: (state, action) => {
      state.loading = true;
      state.error = null;
      state.sevaData = [];
    },
    getSevaByIdSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.seva = action.payload;
    },
    getSevaByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.seva = [];
    },
    createSeva: (state, action) => {
      state.loading = true;
      state.error = null;
      state.createStatus = false;
    },
    createSevaSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.createStatus = true;
    },
    createSevaFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.createStatus = false;
    },
    updateSeva: (state, action) => {
      state.loading = true;
      state.error = null;
      state.updateStatus = false;
    },
    updateSevaSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateStatus = true;
      // action.payload.navigate("/seva-management");
    },
    updateSevaFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.updateStatus = false;
    },
    updateSevaStatus: (state, action) => {
      state.loading = true;
      state.error = null;
      state.updateSevaStatus = false;
    },
    updateSevaStatusSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateSevaStatus = true;
    },
    updateSevaStatusFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.updateSevaStatus = false;
    },
    DeleteImageRequest: (state) => {
      state.imageDeleteStatus = false;
      state.loading = true;
      state.error = "";
    },
    DeleteImageRequestSuccess: (state, action) => {
      state.imageDeleteStatus = true;
      state.loading = false;

      state.error = "";
    },
    DeleteImageRequestFailure: (state, action) => {
      state.imageDeleteStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    toggleStatus: (state, action) => {
      state.createStatus = false;
      state.updateSevaStatus = false;
      state.updateStatus = false;
    },
  },
});

export const {
  getSeva,
  getSevaSuccess,
  getSevaFailure,
  getSevaById,
  getSevaByIdSuccess,
  getSevaByIdFailure,
  createSeva,
  createSevaSuccess,
  createSevaFailure,
  updateSeva,
  updateSevaSuccess,
  updateSevaFailure,
  updateSevaStatus,
  updateSevaStatusSuccess,
  updateSevaStatusFailure,
  DeleteImageRequest,
  DeleteImageRequestFailure,
  DeleteImageRequestSuccess,
  toggleStatus,
} = sevaSlice.actions;
export default sevaSlice.reducer;
