// src/components/HelpSupport.js
import React, { useEffect, useState } from "react";
import "../styles/HelpSupport.css";
import "../styles/FAQCard.css";
import HelpAndSupportCard from "../components/cards/HelpAndSupportCard";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GeneralFaq from "./HelpAndSupport/GeneralFaq";
import SevaListingFaq from "./HelpAndSupport/SevaListingFaq";
import PaymentsFaq from "./HelpAndSupport/PaymentsFaq";
import OrderProcessingFaq from "./HelpAndSupport/OrderProcessingFaq";
import PrasadamManagementFaq from "./HelpAndSupport/PrasadamManagementFaq";
import { AddButton } from "../components/Addbutton";

let templeTabItems = [
  {
    value: "General FAQs",
    status: true,
    src: "/HelpAndSupport/general-icon.svg",
  },
  {
    value: "Seva Listing",
    status: false,
    src: "/HelpAndSupport/puja-list-icon.svg",
  },
  {
    value: "Prasadam Management",
    status: false,
    src: "/HelpAndSupport/prasadam_management.svg",
  },
  { value: "Payments", status: false, src: "/HelpAndSupport/payment-icon.svg" },
  {
    value: "Order Processing",
    status: false,
    src: "/HelpAndSupport/booking-icon.svg",
  },
];

let purohitTabItems = [
  {
    value: "General FAQs",
    status: true,
    src: "/HelpAndSupport/general-icon.svg",
  },
  {
    value: "Puja Offering",
    status: false,
    src: "/HelpAndSupport/puja-list-icon.svg",
  },
  { value: "Payments", status: false, src: "/HelpAndSupport/payment-icon.svg" },
  {
    value: "Booking Processing",
    status: false,
    src: "/HelpAndSupport/booking-icon.svg",
  },
];

const HelpAndSupport = () => {
  const [tabItems, setTabItems] = useState([]);
  const [selected, setSelected] = useState(tabItems[0]);

  const role = localStorage.getItem("role");

  useEffect(() => {
    if (role === "ROLE_TEMPLE_OWNER") {
      setTabItems(templeTabItems);
      setSelected(templeTabItems[0]);
    } else if (role === "ROLE_PUROHIT") {
      setTabItems(purohitTabItems);
      setSelected(purohitTabItems[0]);
    }
  }, [role]);

  const navigate = useNavigate();
  const handleCardClick = (val) => {
    let tabeNe = tabItems.map((item) => {
      if (item.value === val) {
        let item2 = { ...item, status: true };

        return item2;
      } else {
        let item2 = { ...item, status: false };

        return item2;
      }
    });

    let SelectedData = tabItems.filter((item) => item.value === val);
    setTabItems(tabeNe);
    setSelected(SelectedData[0]);
  };

  const handleContactUs = () => navigate(`/contact-us`);

  return (
    <>
      <Box
        className={"heading"}
        sx={{
          textWrap: "nowrap",
          paddingY: "10px",
        }}
      >
        Help & Support 
      </Box>
      <div className="help-support-container">
        <div className="tab-section">
          {tabItems.map((item) => (
            <HelpAndSupportCard
              label={item}
              image={item.src}
              onClick={handleCardClick}
              color=""
            />
          ))}
        </div>
        {selected?.value === "General FAQs" ? (
          <GeneralFaq />
        ) : selected?.value === "Seva Listing" ? (
          <SevaListingFaq />
        ) : selected?.value === "Prasadam Management" ? (
          <PrasadamManagementFaq />
        ) : selected?.value === "Payments" ? (
          <PaymentsFaq />
        ) : selected?.value === "Order Processing" ? (
          <OrderProcessingFaq />
        ) : null}

        <div className="contact-section">
          <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 4 }}>
            <AddButton
              name="Contact us"
              onClick={handleContactUs}
              borderColor="var(--secondary)"
              background="#FFF"
              color="var(--secondary)"
            />
          </Box>
        </div>
      </div>
    </>
  );
};

export default HelpAndSupport;
