import React, { useEffect, useState } from "react";
import StatsCard from "../../components/cards/StatsCard";
import "../../styles/dashboard.css";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Tabs from "../../components/common/Tabs";
import { CustomLoader } from "../../components/CustomLoader";
import TodaySchedule from "./TodaySchedule";
import ServiceFeedback from "./ServiceFeedback";

const tabItems = [
  { label: "Today’s Schedule", value: "Today_Schedule" },
  { label: "Service Feedback", value: "Service_Feedback" },
];

const PurohitDashboard = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { actionType } = useParams();

  const [selectedMenu, setSelectedMenu] = useState(() => {
    let pathName = location.pathname.split("/");
    return pathName[2] ? pathName[2] : "Today_Schedule";
  });

  useEffect(() => {
    navigate(selectedMenu);
  }, [pathName]);

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
    navigate(`/purohit-dashboard/${menu}`);
  };

  useEffect(() => {
    let pathName = location.pathname.split("/");

    const filteredData = tabItems.filter((item) =>
      pathName.includes(item.value),
    );

    if (filteredData.length > 0 && !selectedMenu) {
      setSelectedMenu(filteredData[0].value);
      navigate(
        `/purohit-dashboard${filteredData[0].value ? `/${filteredData[0].value}` : ""}${actionType ? `/${actionType}` : ""}`,
      );
    }
    if (filteredData.length === 0 && !selectedMenu) {
      setSelectedMenu("Today_Schedule");
      navigate(`/purohit-dashboard/Today_Schedule`);
    }
  }, [pathName]);

  return (
    <div className="dashboard_container">
      <CustomLoader loading={false} message="Fetching data, please wait..." />
      <div className="dashboard-stats-container">
        <StatsCard
          label={"Total Bookings This Month"}
          stat={"125"}
          image="/dashboard-icons/seva-bookings.svg"
          info={"Overview of the service activities performed today."}
        />
        <StatsCard
          label={"Upcoming Bookings This Month"}
          stat={"100"}
          image="/dashboard-icons/seva-bookings.svg"
        />
        <StatsCard
          label={"Completed Bookings This Month"}
          stat={"25"}
          image="/dashboard-icons/donation-recieved.svg"
        />
        <StatsCard
          label={"Potential earnings this month"}
          stat={"₹3725"}
          image="/payment-icons/total-payment-icon.svg"
        />
      </div>

      <div style={{ marginTop: "1rem" }}>
        <Tabs
          tabs={tabItems}
          tabStart={selectedMenu}
          onClick={handleMenuButtonClick}
        />
      </div>
      <Outlet />
      {/* {selectedMenu === "Today_Schedule" ? (
        <TodaySchedule />
      ) : selectedMenu === "Service_Feedback" ? (
        <ServiceFeedback />
      ) : null} */}
    </div>
  );
});

export default PurohitDashboard;
