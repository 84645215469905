// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./assets/font/Fonts/WEB/css/cabinet-grotesk.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Host+Grotesk:ital,wght@0,300..800;1,300..800&family=Manrope:wght@200..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --grey-subtext: #acaec3;
  --grey-subheader: #8d8e9f;
  --grey-dark: white;
  --font-black: #2c2c39;
  --primary: #ff6f01;
  --secondary: #a9392d;
  --primary-1: #ff6f01;
  --primary-2: #0bfdc6;
  --primary-3: #666666;
  /* --off-white: #f7f7f7; */
  --black: #f5f5f5;
  --black-2: #fff;
  --red: #f64c4c;
  --red-2: #dc3e3e;
  --font-primary: "CabinetGrotesk-Regular";
  --font-secondary: "Manrope", sans-serif;
  --green-text-color: #03b087;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-grey: #46464f;
  --green-text-color: #ff6f01;
  --disabled-color: #e5e5e5;
  --disabled-font-color: #0d0c11;
  font-size: 16px;
}

body {
  margin: 0 !important;
}

/* @media only screen and (max-width: 1440px) {
  :root {
    font-size: 15px;
  }
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;EACpB,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,wCAAwC;EACxC,uCAAuC;EACvC,2BAA2B;EAC3B,sBAAsB;EACtB,sBAAsB;EACtB,qBAAqB;EACrB,2BAA2B;EAC3B,yBAAyB;EACzB,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;;;;GAIG;;AAEH;;;;EAIE,qDAAqD;AACvD","sourcesContent":["@import url(\"../src/assets/font/Fonts/WEB/css/cabinet-grotesk.css\");\n@import url(\"https://fonts.googleapis.com/css2?family=Host+Grotesk:ital,wght@0,300..800;1,300..800&family=Manrope:wght@200..800&display=swap\");\n\n:root {\n  --grey-subtext: #acaec3;\n  --grey-subheader: #8d8e9f;\n  --grey-dark: white;\n  --font-black: #2c2c39;\n  --primary: #ff6f01;\n  --secondary: #a9392d;\n  --primary-1: #ff6f01;\n  --primary-2: #0bfdc6;\n  --primary-3: #666666;\n  /* --off-white: #f7f7f7; */\n  --black: #f5f5f5;\n  --black-2: #fff;\n  --red: #f64c4c;\n  --red-2: #dc3e3e;\n  --font-primary: \"CabinetGrotesk-Regular\";\n  --font-secondary: \"Manrope\", sans-serif;\n  --green-text-color: #03b087;\n  --color-white: #ffffff;\n  --color-black: #000000;\n  --color-grey: #46464f;\n  --green-text-color: #ff6f01;\n  --disabled-color: #e5e5e5;\n  --disabled-font-color: #0d0c11;\n  font-size: 16px;\n}\n\nbody {\n  margin: 0 !important;\n}\n\n/* @media only screen and (max-width: 1440px) {\n  :root {\n    font-size: 15px;\n  }\n} */\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active {\n  -webkit-box-shadow: 0 0 0 30px white inset !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
