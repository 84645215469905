import React from "react";
import { Outlet } from "react-router-dom";
import { AppBar, Drawer, Box } from "@mui/material";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  return (
    <Box className="main_box" >   
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "var(--black)",
          boxShadow: "none",
          width: "100%", 
        }}
      >
        <Header />
      </AppBar>
    
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          marginTop: "5rem", 
        }}
      >
       
        <Drawer
          variant="permanent"
          className="header_drawer"        
        >
          <Sidebar />
        </Drawer>
        
        <Box
          component="main"
          className="outlet_box"
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
