import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { AddButton } from "../../components/Addbutton";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import { ForgotPassWordRequest } from "../../features/slices/authSlice";
import "../../styles/login.css";

const ForgotPasswordPage = React.memo(() => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [sent, setSent] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email Id").required("email is required"),
  });

  const handleSubmit = (values) => {
    dispatch(ForgotPassWordRequest({ email: values.email }));
  };

  useEffect(() => {
    if (authState.ForgotPasswordStatus) {
      setSent(true);
    }
  }, [dispatch, authState]);

  return (
    <>
      <img src="/daiv-logo.svg" alt="logo" className="daiv_logo" />
      <div container className="login_container">
        {/* this section will open the terms and conditions modal box */}
        <div className="login_background_image"></div>
        <div className="login_content">
          <div className="greeting_text_container">
            <div className="login_form">
              {!sent ? (
                <>
                  <div className="welcome_title">Forgot your password</div>
                  <div className="welcome_login_btn">
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {({ setFieldValue, isSubmitting, errors, touched }) => (
                        <Form>
                          <div
                            style={{
                              width: "100%",
                              gap: "20px",
                              marginTop: "25px",
                            }}
                          >
                            <div className="forgot_password_desc">
                              enter email we will send you the password reset
                              link*
                            </div>
                            <MaterialUIFieldAdapter
                              name="email"
                              type="text"
                              placeholder="Enter Email"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <AddButton
                            name="Send"
                            type="submit"
                            width="100%"
                            padding="18px"
                            background="var(--secondary)"
                            sx={{
                              fontFamily: "var(--font-secondary) !important",
                              fontWeight: 600,
                              fontSize: ".9rem",
                              marginTop: "2rem",
                            }}
                          />
                        </Form>
                      )}
                    </Formik>
                  </div>
                </>
              ) : (
                <>
                  <div className="forgot_password_desc">
                    We have sent reset link to the mail, kindly check your inbox
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default ForgotPasswordPage;
