import React, { useCallback, useEffect, useState } from "react";
import "../../styles/dashboard.css";
import { Box, Typography } from "@mui/material";
import CommonTable from "../../components/CommonTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PledgeOthersCards from "../../components/cards/PledgeOthersCards";
import AlternativeLabelStepper from "../../components/LabelStepper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AddButton } from "../../components/Addbutton";
import {
  getSevaOrderById,
  updateSevaOrderById,
} from "../../features/slices/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoader } from "../../components/CustomLoader";

const SevaTracking = React.memo(() => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const orderId = query.get("OrderId");
  const dispatch = useDispatch();

  const columns = [
    { field: "name", headerName: "Name" },
    { field: "gotra", headerName: "Gotra" },
    { field: "rashi", headerName: "Rashi" },
    { field: "nakshatra", headerName: "Nakshatra" },
  ];

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { label: "Order Received", key: "RECEIVED" },
    { label: "Seva Completed", key: "COMPLETED" },
    { label: "Recorded Video Sent", key: "VIDEO_SENT" },
    { label: "Prasadam Dispatched", key: "DISPATCHED" },
    { label: "Prasadam Delivered", key: "DELIVERED" },
  ];
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const { sevaDataById, loading, pageCount, updateSevaStatus } = useSelector(
    (state) => state.order,
  );

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  useEffect(() => {
    if (sevaDataById?.statusDetails?.length > 0) {
      const lastCompletedIndex = steps.findIndex(
        (step) =>
          step.key ===
          sevaDataById?.statusDetails[sevaDataById?.statusDetails.length - 1]
            .status,
      );
      setActiveStep(lastCompletedIndex);
    }
  }, [sevaDataById]);

  useEffect(() => {
    if (orderId) {
      dispatch(getSevaOrderById(orderId));
    }
  }, [orderId, updateSevaStatus]);

  return (
    <>
      <div className="header-title">
        <ArrowBackIcon
          sx={{
            color: "var(--off-white)",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/order-management/Seva_Orders`)}
        />
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          Order Id: {sevaDataById?.orderId || ""}
        </Box>
      </div>

      <div style={{ height: "80vh", overflow: "auto", scrollbarWidth: "thin" }}>
        <Box className="trackingDetails_Box">
          <Box style={{ width: "75%" }}>
            <PledgeOthersCards
              content={
                <div className="details_container">
                  <div className="status_text" style={{ color: "#FF6F01" }}>
                    {sevaDataById?.customerDetails?.fullName || ""}
                  </div>
                  <div className="details_content">Customer Details:</div>
                  <div className="details_text">
                    User Id :
                    <span className="details_text">
                      {" "}
                      {sevaDataById?.userName?.userId || ""}
                    </span>
                  </div>
                  <div className="details_text">
                    User Name :
                    <span className="details_text">
                      {" "}
                      {sevaDataById?.customerDetails?.fullName || ""}
                    </span>
                  </div>
                  <div className="details_text">
                    Amount Paid :
                    <span className="details_text">
                      {" "}
                      {sevaDataById?.amount || ""}
                    </span>
                  </div>
                  <div className="details_text">
                    Phone Number :
                    <span className="details_text">
                      {" "}
                      {sevaDataById?.customerDetails?.phoneNumber || ""}
                    </span>
                  </div>
                  <div className="details_text">
                    Mail Id :{" "}
                    <span className="details_text">
                      {" "}
                      {sevaDataById?.customerDetails?.email || ""}
                    </span>
                  </div>
                  <div className="details_content">Delivery Address:</div>
                  <div className="details_text">
                    {sevaDataById?.deliveryAddress?.name || ""}
                    <span className="details_text">
                      {" | "}
                      {sevaDataById?.deliveryAddress?.phoneNumber || ""}
                    </span>
                  </div>
                  <div className="details_text">
                    {sevaDataById?.deliveryAddress?.addressLine1 || ""}
                    <span className="details_text">
                      {" ,"}
                      {sevaDataById?.deliveryAddress?.addressLine2 || ""},{" "}
                      {sevaDataById?.deliveryAddress?.city || ""},{" "}
                      {sevaDataById?.deliveryAddress?.state || ""},
                      {sevaDataById?.deliveryAddress?.country || ""},
                      {sevaDataById?.deliveryAddress?.pinCode || ""}
                    </span>
                  </div>
                </div>
              }
              editButton={false}
              showView={false}
              borderColor="var(--primary-2)"
            />
          </Box>

          <Box className="trackingBox">
            <div className="details_content">Tracking Details</div>
            <div className="details_text">
              Tracking Link
              <href>Link</href>
            </div>
          </Box>
        </Box>

        <Typography
          sx={{ margin: "1rem 0 !important" }}
          className="details_content"
        >
          Total No. of Tickets: 5
        </Typography>
        <CommonTable
          columns={columns}
          data={sevaDataById?.participants || []}
          page={page}
          count={1}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
        />

        <Box sx={{ margin: "1rem 0 !important" }}>
          <Typography className="form_Heading">Order Status</Typography>
          {sevaDataById?.statusDetails &&
            (() => {
              let dynamicSteps = [...steps];

              let dynamicActiveStep = activeStep;

              const rejectedIndex = sevaDataById.statusDetails.findIndex(
                (detail) => detail.status === "REJECTED",
              );

              if (rejectedIndex !== -1) {
                const cancelledStep = {
                  label: "Cancelled",
                  key: "CANCELLED",
                  color: "red",
                };

                dynamicSteps.splice(rejectedIndex, 0, cancelledStep);

                dynamicActiveStep = rejectedIndex;
              }

              return (
                <AlternativeLabelStepper
                  activeStep={dynamicActiveStep}
                  steps={dynamicSteps}
                  stepStyles={(index) => ({
                    color: dynamicSteps[index]?.color || "inherit",
                  })}
                />
              );
            })()}
          <Box className="cancel_and_submit">
            {sevaDataById?.statusDetails?.length > 0 &&
              (() => {
                const currentStatus =
                  sevaDataById?.statusDetails[
                    sevaDataById?.statusDetails.length - 1
                  ]?.status;

                switch (currentStatus) {
                  case "RECEIVED":
                    return (
                      <>
                        <AddButton
                          name="Reject"
                          background="#F5F5F5"
                          color="var(--secondary)"
                          borderColor="var(--secondary)"
                          onClick={() => {
                            dispatch(
                              updateSevaOrderById({
                                orderId,
                                status: "REJECTED",
                              }),
                            );
                          }}
                        />
                        <AddButton
                          name="Accept"
                          onClick={() => {
                            dispatch(
                              updateSevaOrderById({
                                orderId,
                                status: "COMPLETED",
                              }),
                            );
                          }}
                        />
                      </>
                    );

                  case "COMPLETED":
                    return (
                      <>
                        <AddButton
                          name="Cancel"
                          background="#F5F5F5"
                          color="var(--secondary)"
                          borderColor="var(--secondary)"
                          onClick={() => {
                            dispatch(
                              updateSevaOrderById({
                                orderId,
                                status: "REJECTED",
                              }),
                            );
                          }}
                        />
                        <AddButton
                          name="Update"
                          onClick={() => {
                            dispatch(
                              updateSevaOrderById({
                                orderId,
                                status: "VIDEO_SENT",
                              }),
                            );
                          }}
                        />
                      </>
                    );

                  case "VIDEO_SENT":
                    return null;

                  default:
                    return null;
                }
              })()}
          </Box>
        </Box>
      </div>
    </>
  );
});

export default SevaTracking;
