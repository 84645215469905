import { call, put, takeLatest } from "redux-saga/effects";
import {
  helpAndSupportRequestByStatus,
  helpAndSupportByStatusSuccess,
  helpAndSupportByStatusFailure,
} from "../slices/helpAndSupportSlice";
import { getRequest } from "../../api/apiActions";

import { getAllFaqsUrls } from "../../api/apiUrls";

const getCall = (payload) => getRequest(getAllFaqsUrls + `/${payload}`);

function* fetchHelpAndSupportByStatus(action) {
  try {
    const response = yield call(getCall, action.payload);

    if (response.status === 200) {
      yield put(helpAndSupportByStatusSuccess(response?.data?.content));
    } else {
      yield put(helpAndSupportByStatusFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(helpAndSupportByStatusFailure(error?.response?.data));
  }
}

export function* helpAndSupportSaga() {
  yield takeLatest(
    helpAndSupportRequestByStatus.type,
    fetchHelpAndSupportByStatus,
  );
}
