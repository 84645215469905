// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product2-stats-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 99%;
  gap: 1rem;
}
.AddButton-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ProductManagement.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,UAAU;EACV,SAAS;AACX;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;AACvB","sourcesContent":[".product2-stats-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 99%;\n  gap: 1rem;\n}\n.AddButton-container {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
