import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { FileUploader } from "react-drag-drop-files";
import { AddButton } from "./Addbutton";
import "../styles/fileUploadModal.css";

const FileUploadModal = ({
  dialogOpen,
  setDialogOpen,
  imageFile,
  setImageFile,
  toBeUploadedFileFormat,
  uploadType,
  setActionType,
  setProfileImage,
}) => {
  // function that will fetch the uploaded image file details and will display it in the modal
  const handleImageUpload = (event) => {
    const file = event.target ? event.target.files[0] : event;

    if (uploadType === "single") {
      // handle single file upload
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageFile([
            ...imageFile,
            { name: file.name, url: e.target.result },
          ]);
        };
        setProfileImage([file]);

        reader.readAsDataURL(file);
      }
    } else if (uploadType === "multiple") {
      // handle multiple files upload
    }
    setImageFile([]);
  };

  // Function to close the dialog
  const handleDialogClose = (action) => {
    setActionType(action);
    setDialogOpen(false);
    if (action === "close" || action === "cancel") setImageFile([]);
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        handleDialogClose("cancel");
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#C2EBEC",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h6" className="dialogBox-title">
          Upload Photo
        </Typography>
        <CloseIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleDialogClose("cancel");
          }}
        />
      </DialogTitle>

      <DialogContent>
        <hr className="line_through" />

        {imageFile.length === 0 ? (
          <Box
            justifyContent={"center"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minWidth: { xs: "200px", sm: "300px" },
              height: "auto",
              marginBottom: "1.5rem",
              p: 1,
              borderRadius: 2,
              border: "1px dashed #2C2C39",
              overflow: "hidden",
            }}
          >
            {/* File uploader component with drag-and-drop functionality */}
            <FileUploader
              handleChange={handleImageUpload}
              name="file"
              types={toBeUploadedFileFormat?.acceptedFileFormatForDragDrop}
              children={
                <img
                  src="/fileUploadIcon.svg"
                  alt="fileUpload-icon"
                  className="drag_drop_icon"
                />
              }
              multiple={uploadType === "multiple"} // Allow multiple files only for multiple
            />

            {/* Text and button components for file upload instructions */}
            <small className="helper_text">
              Drag & Drop or&nbsp;
              <Button
                variant="outlined"
                component="label"
                value={imageFile?.name}
                sx={{
                  color: "var(--primary)",
                  border: "none",
                  padding: "0px",
                  textTransform: "none",
                  fontFamily: "Jakarta Sans",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Choose File
                <input
                  hidden
                  type="file"
                  accept={
                    toBeUploadedFileFormat?.acceptedFileFormatForNormalFileUpload
                  }
                  multiple={uploadType === "multiple"}
                  onChange={(event) => {
                    handleImageUpload(event);
                  }}
                />
              </Button>{" "}
              to Upload <br />
              {toBeUploadedFileFormat?.fileType}
            </small>
          </Box>
        ) : (
          <Box
            justifyContent={"center"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minWidth: { xs: "200px", sm: "300px" },
              height: "auto",
              marginBottom: "1.5rem",
              p: 1,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <img
              src={imageFile[0]?.url}
              alt="user avatar"
              className="profile_image"
            />
          </Box>
        )}

        <div className="button_container">
          {/* Button to open file picker dialog */}
          <AddButton
            name="Cancel"
            type="submit"
            // width="50%"
            // padding="17px"
            background="#181822"
            color="#ACAEC3"
            onClick={() => {
              handleDialogClose("cancel");
            }}
            borderColor="#67687C"
          />

          <AddButton
            name="Upload"
            type="submit"
            // width="50%"
            // padding="17px"
            background="#181822"
            color="#ACAEC3"
            onClick={() => {
              handleDialogClose("submit");
            }}
            borderColor="#67687C"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadModal;
