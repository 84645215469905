import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { AddButton, AddButtonWhite } from "../../components/Addbutton";
import {
  fetchpujaOfferingRequest,
  uploadpujaOfferingRequest,
} from "../../features/slices/purohitOnboardingSlice";
import { useDispatch, useSelector } from "react-redux";

const SelectedPujaOfferings = forwardRef((props, ref) => {
  const [modifiedOfferings, setModifiedOfferings] = useState([]);

  const { pujaOfferingData } = useSelector((state) => state.purohitOnboarding);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchpujaOfferingRequest());
  }, [dispatch]);

  useEffect(() => {
    setModifiedOfferings(
      pujaOfferingData.map((item) => ({
        ...item,
        onlineOffline: item.onlineOffline ? "online" : "offline",
      })),
    );
  }, [pujaOfferingData]);

  const handleChange = (e, field, id) => {
    const value = field === "onlineOffline" ? e.target.value : e.target.value;

    setModifiedOfferings((prev) =>
      prev.map((item) => (item.id === id ? { ...item, [field]: value } : item)),
    );
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const dataToSubmit = modifiedOfferings.map((item) => ({
        ...item,
        onlineOffline: item.onlineOffline === "online",
      }));
      dispatch(uploadpujaOfferingRequest(dataToSubmit));
    },
  }));

  return (
    <div style={{ padding: "2rem", background: "#F5F5F5", height: "100vh" }}>
      <div className="heading">Puja Offerings & Services</div>
      <div variant="h6">
        Note: Please be aware that the pricing for the puja will be determined
        based on the cost of the puja, transportation fees, dakshina
        (offerings), and pooja samagri (ritual items).
      </div>
      <div style={{ marginTop: "2rem" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="puja offerings table">
            <TableHead>
              <TableRow>
                <TableCell>Puja List</TableCell>
                <TableCell>Service price (with Samagri)</TableCell>
                <TableCell>Service price (without Samagri)</TableCell>
                <TableCell>Online/Offline</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modifiedOfferings.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.subCategoryName}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={row.samagriWithPrice}
                      onChange={(e) =>
                        handleChange(e, "samagriWithPrice", row.id)
                      }
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter Service price (with Samagri)"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={row.samagriWithoutPrice}
                      onChange={(e) =>
                        handleChange(e, "samagriWithoutPrice", row.id)
                      }
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter Service price (without Samagri)"
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth size="small">
                      <Select
                        value={row.onlineOffline}
                        onChange={(e) =>
                          handleChange(e, "onlineOffline", row.id)
                        }
                      >
                        <MenuItem value="online">Online</MenuItem>
                        <MenuItem value="offline">Offline</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
});

export default SelectedPujaOfferings;
