import React, { useState, useEffect } from "react";
import {
  Box,
} from "@mui/material";
import StatsCard from "../components/cards/StatsCard";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import AllUser from "./user/AllUser";
import AddIcon from "@mui/icons-material/Add";
import { AddButton } from "../components/Addbutton";
import AddUser from "./user/AddUser";

const UserManagement = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, actionType } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);
  const [tabsLabel, setTabsLabel] = useState("All");

  useEffect(() => {
    if (id === "All") {
      setSelectedTab(0);
      setTabsLabel("All");
    } else if (id === "Active") {
      setSelectedTab(1);
      setTabsLabel("Active");
    } else if (id === "InActive") {
      setSelectedTab(2);
      setTabsLabel("InActive");
    } else {
      setSelectedTab(0);
      setTabsLabel("All");
    }
  }, [location, id]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setTabsLabel(event.target.textContent);
    navigate(`/user-management/${event.target.textContent}`);
  };

  const [openAddNew, setOpenAddNew] = useState(false);

  const HandleAdd = () => {
    setOpenAddNew(true);
    navigate(`/user-management/${tabsLabel}/Add`);
  };
  return (
    <div style={{ width: "100%" }}>
      {actionType === "Add" ? (
        <AddUser type="Add" />
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "",
            }}
          >
            <Box
              className={"heading"}
              sx={{
                textWrap: "nowrap",
                paddingY:  "10px", 
              }}
            >
              User Management
            </Box>

            <AddButton name="New" icon={<AddIcon />} onClick={HandleAdd} />
          </div>
          <div className="stats-card-container"
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <StatsCard
              label={"Total Users"}
              stat={"15"}
              image="/dashboard-icons/active-users-card.svg"
            />
            <StatsCard
              label={"Admin Users"}
              stat={"4"}
              image="/dashboard-icons/admins-icons.svg"
            />
            <StatsCard
              label={"Sub Admin users"}
              stat={"11"}
              image="/dashboard-icons/admins-icons.svg"
            />
          </div>
          {/* <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              width: "30vw",

              backgroundColor: "transparent",
              "& .MuiTab-root": {
                height: "4vh",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },

              "& .MuiTabs-indicator": {
                backgroundColor: "rgba(247, 247, 247, 0.2)",
              },
              "& .Mui-selected": {
                color: "rgba(4, 204, 157, 1) !important",
              },
            }}
          >
            <Tab
              label="All"
              sx={{
                color: "#ACAEC3",
                backgroundColor: "none !important",
                width: "10vw",
              }}
            />
            <Tab
              label="Active"
              sx={{
                color: "#ACAEC3",
                backgroundColor: "none !important",
                width: "10vw",
              }}
            />
            <Tab
              label="InActive"
              sx={{
                color: "#ACAEC3",
                backgroundColor: "none !important",
                width: "10vw",
              }}
            />
          </Tabs> */}
          {/* {tabsLabel === "All" ? (
            <AllUser />
          ) : tabsLabel === "Active" ? (
            <ActiveUser />
          ) : tabsLabel === "InActive" ? (
            <InactiveUser />
          ) : null} */}
          <AllUser />
        </>
      )}
    </div>
  );
});

export default UserManagement;
