import React from "react";
import * as animationData from "../../assets/animations/Confitti.json";

import { AddButton } from "../../components/Addbutton";
import { useNavigate } from "react-router-dom";
import LottieWithoutLibrary from "../../components/LottieWithoutLibrary";
const DashboardStartpage = React.memo(() => {
  const navigate = useNavigate();
  const defaultOptions = {
    autoplay: true,
    loop: true,
    animationData: animationData,
  };

  const eventListeners = [
    {
      eventName: "loopComplete",
      callback: () => console.log("a loop complete"),
    },
  ];
  return (
    <div
      className="login_form"
      style={{
        width: "30%",
        height: "28rem",
      }}
    >
      <div className="congratulations">
        <div className="welcome_title" style={{ textAlign: "center" }}>
          Congratulations!
        </div>
        <LottieWithoutLibrary animationData={animationData} />
        <div
          className="welcome_desc"
          style={{ textAlign: "center", marginTop: "13rem" }}
        >
          Congratulations! Your temple has been successfully verified and is now
          live on our platform. You can now manage your temple and start
          engaging with the community.
        </div>
        <AddButton
          name="Start"
          type="submit"
          width="100%"
          padding="18px"
          background="var(--secondary)"
          sx={{
            fontFamily: "var(--font-secondary) !important",
            fontWeight: 600,
            fontSize: ".9rem",
          }}
          onClick={() => navigate("/dashboard")}
        />
      </div>
    </div>
  );
});

export default DashboardStartpage;
