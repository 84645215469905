import React from "react";
import SmoothLineChart from "../../components/Linecharts";
import StatsCard from "../../components/cards/StatsCard";
import "../../styles/dashboard.css";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { CustomLoader } from "../../components/CustomLoader";
import ProductDashboardChart from "../../components/ProductDashboardChart";
import DashboardTable from "./DashboardTable";

const ProductDashboard = React.memo(() => {
  const navigate = useNavigate();

  return (
    <div className="dashboard_container">
      <CustomLoader loading={false} message="Fetching data, please wait..." />
      <div className="dashboard-stats-container">
        <StatsCard
          label={"Today’s Order"}
          stat={"45"}
          image="/dashboard-icons/seva-performed-card.svg"
          info={"View all received orders for today."}
        />
        <StatsCard
          label={"Active Products"}
          stat={"656"}
          image="/dashboard-icons/seva-bookings.svg"
        />
        <StatsCard
          label={"Total Orders for this Month"}
          stat={"400"}
          image="/dashboard-icons/donation-recieved.svg"
        />
        <StatsCard
          label={"Today's Prasadam Order"}
          stat={"₹ 13L"}
          image="/dashboard-icons/prasadam-orders.svg"
        />
      </div>

      <div
        style={{ overflowY: "auto", height: "75vh", scrollbarWidth: "thin" }}
      >
        <div className="version_chart_Container1">
          <div style={{ width: "74%" }}>
            <ProductDashboardChart />
          </div>

          <div className="Vertical_stats">
            <StatsCard
              label={"Low Stock"}
              stat={"15"}
              image="/inventory-box.svg"
              info={
                "View items that are running low on inventory and need restocking."
              }
              boxStyle={{ borderBottom: "2px solid #FFD1A2" }}
            />
            <StatsCard
              label={"Pending orders"}
              stat={"15"}
              image="/pending-box.svg"
              info={"Check orders that are yet to be processed or shipped."}
              boxStyle={{ borderBottom: "2px solid #FFD1A2" }}
            />
            <StatsCard
              label={"Pending Payments"}
              stat={"15"}
              image="/pending-payment-box.svg"
              info={
                "Monitor orders awaiting payment confirmation or clearance."
              }
              boxStyle={{ borderBottom: "2px solid #FFD1A2" }}
            />
          </div>
        </div>
        <div className="dashboard_section3">
          <div className="dashboard_fanchalleng">
            <DashboardTable />
          </div>
          <div className="dashboard_dailyupload">
            {/* <DailyUploadsCard /> */}
            asgsd
          </div>
        </div>
      </div>

      {/* <div className="dashboard_section3">
        <Box
          className="dashboardNavCard"
          onClick="/donation-management/Donation_List"
        >
          {" "}
          View Donations{" "}
          <img src="/dashboard-icons/viewDonationNav.svg" alt="img" />
        </Box>
        <Box className="dashboardNavCard" onClick="/seva-management/Add">
          {" "}
          Add Seva <img src="/dashboard-icons/addSevaNav.svg" alt="img" />
        </Box>
      </div> */}
    </div>
  );
});

export default ProductDashboard;
