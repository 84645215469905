import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AddButton } from "../../components/Addbutton";
import { verifyEmailRequest } from "../../features/slices/authSlice";
import "../../styles/login.css";

const EmailVerified = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const verifyCode = queryParams.get("token");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyEmailRequest({ verifyCode, navigate }));
  }, []);

  return (
    <>
      <img src="/daiv-logo.svg" alt="logo" className="daiv_logo" />
      <div container className="login_container">
        <div className="login_background_image"></div>
        <div className="login_content">
          <div className="greeting_text_container">
            <div className="login_form">
              <>
                <div className="welcome_title1">
                  Thank you for verifying your email address.
                </div>
                <div className="welcome_desc verify_email_desc">
                  Your account has been successfully verified. We’re grateful to
                  have you with us on this journey.
                </div>
              </>
              <img
                src="/verify-email.svg"
                alt="verify email"
                className="verify_email_image"
              />
              <AddButton
                name="Continue"
                type="submit"
                width="100%"
                padding="18px"
                background="var(--secondary)"
                sx={{
                  fontFamily: "var(--font-secondary) !important",
                  fontWeight: 600,
                  fontSize: ".9rem",
                }}
                onClick={() => navigate("/signup/contact")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default EmailVerified;
