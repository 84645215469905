import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../../styles/purohit/puja-offering-onboarding.css";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchserviceRequest,
  uploadserviceRequest,
} from "../../features/slices/purohitOnboardingSlice";

const PujaOfferingsAndServices = forwardRef((props, ref) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const { serviceData } = useSelector((state) => state.purohitOnboarding);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchserviceRequest());
  }, [dispatch]);

  const handleCheckboxChange = (event, categoryId) => {
    const { value } = event.target;
    const subcategoryId = parseInt(value);

    setSelectedOptions((prevState) => ({
      ...prevState,
      [categoryId]: prevState[categoryId]?.includes(subcategoryId)
        ? prevState[categoryId].filter((id) => id !== subcategoryId)
        : [...(prevState[categoryId] || []), subcategoryId],
    }));
  };

  const handleRemoveSelectedItem = (categoryId, subcategoryId) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [categoryId]: prevState[categoryId]?.filter((id) => id !== subcategoryId),
    }));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      const payload = Object.keys(selectedOptions).map((categoryId) => ({
        categoryId: parseInt(categoryId),
        subCategoryIds: selectedOptions[categoryId],
      }));

      dispatch(uploadserviceRequest(payload));
    },
  }));

  return (
    <div style={{ padding: "2rem", background: "#F5F5F5" }}>
      <div className="heading">Puja Offerings & Services</div>

      {serviceData &&
        serviceData.length > 0 &&
        serviceData?.map((item) => (
          <div key={item.id}>
            <div className="puja_sub_heading">{item.categoryName}</div>
            <div className="checkbox_tags_card">
              <div className="checkbox_card">
                {item?.subCategoryDetails?.map((option) => (
                  <FormControlLabel
                    key={option.subcategoryId}
                    control={
                      <Checkbox
                        value={option.subcategoryId}
                        checked={
                          selectedOptions[item.id]?.includes(
                            option.subcategoryId,
                          ) || false
                        }
                        onChange={(e) => handleCheckboxChange(e, item.id)}
                        sx={{
                          color: "#67687C",
                          "&.Mui-checked": { color: "#FF6F01" },
                        }}
                      />
                    }
                    label={option.subCategoryName}
                  />
                ))}
              </div>
              <div>
                {selectedOptions[item.id]?.map((subcategoryId) => {
                  const subcategoryName = item.subCategoryDetails.find(
                    (sub) => sub.subcategoryId === subcategoryId,
                  )?.subCategoryName;

                  return (
                    <Chip
                      key={subcategoryId}
                      label={subcategoryName}
                      onDelete={() =>
                        handleRemoveSelectedItem(item.id, subcategoryId)
                      }
                      sx={{
                        margin: "4px",
                        "& .MuiSvgIcon-root": {
                          fill: "black !important",
                        },
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
});

export default PujaOfferingsAndServices;
