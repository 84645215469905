import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import * as animationData from "../../assets/animations/Confitti.json";
import { AddButton } from "../../components/Addbutton";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import { ResetPassWordRequest } from "../../features/slices/authSlice";
import "../../styles/login.css";
import LottieWithoutLibrary from "../../components/LottieWithoutLibrary";

const CreatePasswordPage = React.memo(() => {
  const dispatch = useDispatch();
  const [created, setCreated] = useState(false);
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [queryParms, setQueryParams] = useSearchParams();

  let tokenParam = queryParms.get("token");

  let passwordRuleList = [
    {
      name: "Minimum Length: 12 characters",
      status: false,
    },
    {
      name: "Character Composition: Must Include A Diverse Mix of Uppercase And Lowercase Letters, Numbers, And Symbols.",
      status: false,
    },
  ];

  // function that will check in real-time whether the new password fiels value is matching the password rules or not
  const validatePasswordRules = (password) => {
    // Rule 1: Check minimum length
    passwordRuleList[0].status = password.length >= 12;

    // Rule 2: Check character composition
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    passwordRuleList[1].status =
      hasUppercase && hasLowercase && hasNumber && hasSymbol;
  };

  // creating a validation schema that will check whether the field values are matching with the schema or not
  const validationSchema = Yup.object({
    new_password: Yup.string()
      .min(12, "Password is too weak")
      .required("New password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handleResetPassword = (password) => {
    if (password) {
      const formData = new FormData();
      formData.append("password", password);
      formData.append("token", tokenParam);
      dispatch(ResetPassWordRequest(formData));
    }
  };

  const defaultOptions = {
    autoplay: true,
    loop: true,
    animationData: animationData,
  };

  useEffect(() => {
    if (authState.ResetPasswordStatus) {
      setCreated(true);
    }
  }, [dispatch, authState]);

  return (
    <>
      <img src="/daiv-logo.svg" alt="logo" className="daiv_logo" />
      <div container className="login_container">
        <div className="login_background_image"></div>
        <div className="login_content">
          <div className="greeting_text_container">
            <div className="login_form">
              {!created ? (
                <>
                  <div className="welcome_title">Create Password</div>

                  <Formik
                    initialValues={{
                      new_password: "",
                      confirm_password: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      handleResetPassword(values.new_password);
                    }}
                  >
                    {({ setFieldValue, isSubmitting, errors, touched }) => (
                      <Form>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            gap: "20px",
                            marginTop: "25px",
                          }}
                        >
                          <MaterialUIFieldAdapter
                            name="new_password"
                            type="password"
                            label="New Password*"
                            style={{ width: "100%" }}
                            validatePasswordRules={validatePasswordRules}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "0.5%",
                          }}
                        >
                          <MaterialUIFieldAdapter
                            name="confirm_password"
                            type="password"
                            label="Confirm Password*"
                            style={{ width: "100%" }}
                            validatePasswordRules={null}
                          />
                        </div>

                        <div
                          style={{
                            width: "100%",
                            color: "#ffffff",
                            textAlign: "left",
                            marginBottom: "20px",
                          }}
                        >
                          {passwordRuleList.map((rule) => (
                            <p className="password_rules_text" key={rule.name}>
                              {rule.status ? (
                                <img src="/correct.svg" alt="correct" />
                              ) : (
                                <img src="/cancel.svg" alt="correct" />
                              )}{" "}
                              {rule.name}
                            </p>
                          ))}
                        </div>

                        <AddButton
                          name="Create"
                          type="submit"
                          width="100%"
                          padding="18px"
                          disabled={isSubmitting} // Disable button while submitting
                          background="var(--secondary)"
                          sx={{
                            fontFamily: "var(--font-secondary) !important",
                            fontWeight: 600,
                            fontSize: ".9rem",
                          }}
                        />
                      </Form>
                    )}
                  </Formik>
                </>
              ) : (
                <div className="congratulations">
                  <div
                    className="welcome_title"
                    style={{ textAlign: "center" }}
                  >
                    Congratulations!
                  </div>
                  <LottieWithoutLibrary animationData={animationData} />
                  <div className="welcome_desc" style={{ textAlign: "center" }}>
                    Congratulations! Password created Successfully!
                  </div>
                  <AddButton
                    name="Continue"
                    type="submit"
                    width="100%"
                    padding="18px"
                    background="var(--secondary)"
                    sx={{
                      fontFamily: "var(--font-secondary) !important",
                      fontWeight: 600,
                      fontSize: ".9rem",
                    }}
                    onClick={() => {
                      localStorage.clear();
                      navigate("/login");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default CreatePasswordPage;
