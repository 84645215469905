import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    loading: false,
    error: null,
    prasadList: [],
    pageCount: 0,
    prasadDataById: [],
    updatePrasadStatus: false,
    downloadPrasadStatus: false,
    sevaList: [],
    sevaDataById: [],
    updateSevaStatus: false,
    downloadSevaStatus: false,
  },
  reducers: {
    getPrasadOrder: (state, action) => {
      state.loading = true;
      state.error = null;
      state.prasadList = [];
      state.pageCount = "";
    },
    getPrasadOrderSuccess: (state, action) => {
      const { page, res } = action.payload;
      state.loading = false;
      state.error = null;
      state.prasadList = res;
      state.pageCount = page;
    },
    getPrasadOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.prasadList = [];
      state.pageCount = "";
    },
    getPrasadOrderById: (state, action) => {
      state.loading = true;
      state.error = null;
      state.prasadDataById = [];
    },
    getPrasadOrderByIdSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.prasadDataById = action.payload;
    },
    getPrasadOrderByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.prasadDataById = [];
    },

    updatePrasadOrderById: (state, action) => {
      state.loading = true;
      state.error = null;
      state.updatePrasadStatus = false;
    },
    updatePrasadOrderByIdSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updatePrasadStatus = true;
    },
    updatePrasadOrderByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.updatePrasadStatus = false;
    },
    downloadPrasadOrder: (state, action) => {
      state.error = null;
      state.downloadPrasadStatus = false;
    },
    downloadPrasadOrderSuccess: (state, action) => {
      state.error = null;
      state.downloadPrasadStatus = true;
    },
    downloadPrasadOrderFailure: (state, action) => {
      state.error = action.payload;
      state.downloadPrasadStatus = false;
    },
    getSevaOrder: (state, action) => {
      state.loading = true;
      state.error = null;
      state.sevaList = [];
      state.pageCount = "";
    },
    getSevaOrderSuccess: (state, action) => {
      const { page, res } = action.payload;

      state.loading = false;
      state.error = null;
      state.sevaList = res;
      state.pageCount = page;
    },
    getSevaOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.sevaList = [];
      state.pageCount = "";
    },
    getSevaOrderById: (state, action) => {
      state.loading = true;
      state.error = null;
      state.sevaDataById = [];
    },
    getSevaOrderByIdSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.sevaDataById = action.payload;
    },
    getSevaOrderByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.sevaDataById = [];
    },

    updateSevaOrderById: (state, action) => {
      state.loading = true;
      state.error = null;
      state.updateSevaStatus = false;
    },
    updateSevaOrderByIdSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateSevaStatus = true;
    },
    updateSevaOrderByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.updateSevaStatus = false;
    },
    downloadSevaOrder: (state, action) => {
      state.error = null;
      state.downloadSevaStatus = false;
    },
    downloadSevaOrderSuccess: (state, action) => {
      state.error = null;
      state.downloadSevaStatus = true;
    },
    downloadSevaOrderFailure: (state, action) => {
      state.error = action.payload;
      state.downloadSevaStatus = false;
    },
  },
});

export const {
  getPrasadOrder,
  getPrasadOrderSuccess,
  getPrasadOrderFailure,
  getPrasadOrderById,
  getPrasadOrderByIdSuccess,
  getPrasadOrderByIdFailure,
  updatePrasadOrderById,
  updatePrasadOrderByIdSuccess,
  updatePrasadOrderByIdFailure,
  downloadPrasadOrder,
  downloadPrasadOrderSuccess,
  downloadPrasadOrderFailure,
  getSevaOrder,
  getSevaOrderSuccess,
  getSevaOrderFailure,
  getSevaOrderById,
  getSevaOrderByIdSuccess,
  getSevaOrderByIdFailure,
  updateSevaOrderById,
  updateSevaOrderByIdSuccess,
  updateSevaOrderByIdFailure,
  downloadSevaOrder,
  downloadSevaOrderSuccess,
  downloadSevaOrderFailure,
} = orderSlice.actions;
export default orderSlice.reducer;
