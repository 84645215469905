const BASE_URL = process.env.REACT_APP_BASE_URL;

// login api
export const loginUrls = BASE_URL + "auth/login";
export const signUpUrls = BASE_URL + "auth/signup/email";
export const sendOtpUrls = BASE_URL + "user/profiles/send/otp";
export const verifyOtpUrls = BASE_URL + "user/profiles/add/phone";
export const verifyEmailUrls = BASE_URL + "user/profiles/verify/email";
export const emailVerificationUrls = BASE_URL + "user/profiles/email/verified";
export const updateloginUrls = BASE_URL + "auth/refreshToken";
export const templeDetailsUrls = BASE_URL + "temple/create";
export const purohitDetailsUrls = BASE_URL + "purohit/create";

// templeId api
export const getTempleIdUrl = `${BASE_URL}temple/get/temple/id`;

// community post api
export const createCommunityPostUrl = `${BASE_URL}community/post/add`;
export const getAllCommunityPostsUrl = `${BASE_URL}community/post`;
export const deleteCommunityPostUrl = `${BASE_URL}community/post`;
export const getCommunityPostByIdUrl = `${BASE_URL}community/post/id`;
export const updateCommunityPostUrl = `${BASE_URL}community/post`;

// community members api
export const getAllCommunityMemberUrl = `${BASE_URL}community/member`;
export const removeCommunityMemberUrl = `${BASE_URL}community/member/remove`;

// settings & preferences api
export const getTempleDetaisUrl = `${BASE_URL}temple/preview`;
export const getPaymentDetailsUrl = `${BASE_URL}temple/payment`;
export const getAdminDetailsUrl = `${BASE_URL}temple/owner`;
export const getTempleMediaUrl = `${BASE_URL}temple/media`;
export const editTempleDescriptionUrl = `${BASE_URL}temple/description`;
export const editTempleMediaUrl = `${BASE_URL}temple/update/media`;

// thoughts api
export const getThoughtsByStatusUrl = `${BASE_URL}thoughts/status?`;
export const createThoughtUrl = `${BASE_URL}thoughts/create-thought`;
export const updateThoughtUrl = `${BASE_URL}thoughts/update`;
export const deleteThoughtUrl = `${BASE_URL}thoughts/:id`;
export const ForgotPasswordUrls = BASE_URL + "auth/forgot-password?email=";

export const ResetPasswordUrls = BASE_URL + "auth/reset-password";

export const termsPolicyUrls = BASE_URL + "auth/user/termpolicyViewed";

export const documentStatus = BASE_URL + "temple/welcome-to-onboarding";

export const getSettingUrls = BASE_URL + "user/profiles/getprofile";

//Questions Api's

//get all ?page=0&size=10&sortBy=UPLOAD_DATE&sortDirection=asc

export const getAllQuestionsUrls =
  BASE_URL + "quiz/questions/getQuestionsByDate";

export const postQuestionsUrls = BASE_URL + "quiz/questions/save";

export const updateQuestionsUrls = BASE_URL + "quiz/questions/update/";

export const deleteQuestionsUrls = BASE_URL + "quiz/questions/delete/";

export const updateSettingUrl = BASE_URL + "user/profiles/update";

export const removeProfileImageUrl = BASE_URL + "user/profiles/remove/picture";

//HelpAndSupport Api

export const getAllFaqsUrls = BASE_URL + "faq/ROLE_TEMPLE_OWNER/";

// seva API
export const getAllSevaUrls = BASE_URL + "temple/seva";
export const getSevaByIdUrls = BASE_URL + "temple/seva";
export const createSevaUrls = BASE_URL + "temple/seva/add";
export const updateSevaUrls = BASE_URL + "temple/seva";
export const updateSevaStatusUrls = BASE_URL + "temple/seva";
export const deleteSevaImageUrls = BASE_URL + "temple/seva/delete/s3";
//Donation's Api

export const donationPostUrls = BASE_URL + "donation/create";

export const donationGetAllUrls = BASE_URL + "donation/temple/all";

export const donationGetByIdUrls = BASE_URL + "donation/get";

export const donationUpdateUrls = BASE_URL + "donation/update";

export const donationUpdateByStatusUrls = BASE_URL + "donation/toggle/active";

//Prasadm Api's

export const prasadamPostUrls = BASE_URL + "prasadham/create";

export const prasadamGetAllUrls = BASE_URL + "prasadham/temple/getAll";

export const prasadamGetByIdUrls = BASE_URL + "prasadham/get";

export const prasadamUpdateUrls = BASE_URL + "prasadham/update";

export const prasadamUpdateByStatusUrls = BASE_URL + "prasadham/toggle";

//Onboarding Api's

export const onBoardPreviewUrls = BASE_URL + "temple/preview";

export const onBoardStatusUrls = BASE_URL + "temple/status";

export const onBoardUpdateUrls = BASE_URL + "temple/update";

export const onBoardDocumentUrls = BASE_URL + "temple/documents";

export const onBoardDescriptionUrls = BASE_URL + "temple/description";

export const onBoardPhotoVideoUrls = BASE_URL + "temple/update/media";

export const onBoardAdminUrls = BASE_URL + "temple/create/owner";

export const onBoardSevaUrls = BASE_URL + "temple/seva/add";

export const onBoardPrasadamUrls = BASE_URL + "prasadham/create";

export const onBoardDonationUrls = BASE_URL + "donation/create";

export const onBoardPayementsUrls = BASE_URL + "temple/payment/create";

export const onboardAdmingetUrls = BASE_URL + "temple/owner";

export const onboardPaymentgetUrls = BASE_URL + "temple/payment";

export const onboardSevagetUrls = BASE_URL + "temple/seva";

export const onboardParsadamgetUrls = BASE_URL + "prasadham/temple/getAll";

export const onboardDonationgetUrls = BASE_URL + "donation/temple/all";

export const onBoardCompletedUrls = BASE_URL + "temple/congratulations";

export const updateOnboardDonation = BASE_URL + "donation/update/";

export const updateOnboardSeva = BASE_URL + "temple/seva/";

export const updateOnboardPrasadam = BASE_URL + "prasadham/update/";

export const updateOnboardPayment = BASE_URL + "temple/payment/update";

export const deleteImageUrls = BASE_URL + "temple/delete/s3";

//Order Management Api's

export const getAllPrasadOrderUls = BASE_URL + "temple/order/prasad";

export const getPrasadOrderByIdUrls = BASE_URL + "temple/order/details/prasad/";

export const updatePrasadOrderUrls = BASE_URL + "temple/order/prasad/";

export const dwonloadPrasadOrderUrls =
  BASE_URL + "temple/order/prasadham/download";

export const getAllSevaOrderUls = BASE_URL + "temple/order/seva";

export const getSevaOrderByIdUrls = BASE_URL + "temple/order/details/seva/";

export const updateSevaOrderUrls = BASE_URL + "temple/order/seva/";

export const dwonloadSevaOrderUrls = BASE_URL + "temple/order/seva/download";

//Purohit Onboarding Urls

export const getPurohitOnboardStatusUrls = BASE_URL + "purohit/status";

export const getPurohitDetailsOnboardUrls = BASE_URL + "purohit/post";

export const getPurohitDetailsPhotoOnboardUrls = BASE_URL + "purohit/update";

export const getPurohitDocOnboardUrls = BASE_URL + "purohit/documents";

export const getPurohitLineageOnboardUrls = BASE_URL + "purohit/experience";

export const getPurohitPujaServiceOnboardUrls = BASE_URL + "purohit/pujaoffer";

export const getPurohitPujaOfferOnboardUrls = BASE_URL + "purohit/selectedpuja";

export const getPurohitTimeAvailOnboardUrls = BASE_URL + "purohit/availability";

export const updatePurohitDetailsOnboardUrls = BASE_URL + "purohit/update";

export const updatePurohitDocOnboardUrls =
  BASE_URL + "purohit/upload-documents";

export const updatePurohitLineageOnboardUrls = BASE_URL + "purohit/experience";

export const updatePurohitPujaServiceOnboardUrls =
  BASE_URL + "purohit/pujaoffer";

export const updatePurohitPujaOfferOnboardUrls =
  BASE_URL + "purohit/selectedpuja";

export const updatePurohitTimeAvailOnboardUrls =
  BASE_URL + "purohit/availability";

// Purohit Settings & Preferences API Urls
export const getPurohitExperienceUrl = `${BASE_URL}purohit/experience`;
export const getPurohitDocumentUrl = `${BASE_URL}purohit/documents`;
export const getPurohitProfileUrl = `${BASE_URL}purohit/post`;
export const getPurohitMediaUrl = `${BASE_URL}purohit/update`;
export const editPurohitExperienceUrl = `${BASE_URL}purohit/experience`;

// Puja Management Api Urls
export const getAllPujaUrl = `${BASE_URL}purohit/selectedpuja`;
