// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warehouse-main-container {
    padding: 1rem !important;
    width: 100%;
    background-color: #f5f5f5 !important;
    height: 65vh;
    overflow-y: auto;
    scrollbar-width: thin;
}

/* media queries */
@media only screen and (max-width: 1024px ) {
    .warehouse-main-container {
        height: 65vh;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .warehouse-main-container {
        height: 79vh;
    }
}

@media (min-width: 1281px) and (max-width: 1440px) {
    .warehouse-main-container {
        height: 66vh;
    }
}

@media (min-width: 1441px) and (max-width: 1680px) {
    .warehouse-main-container {
        height: 67vh;
    }
}

@media only screen and (min-width: 1681px) {
    .warehouse-main-container {
        height: 86vh;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/warehouseManagement.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,WAAW;IACX,oCAAoC;IACpC,YAAY;IACZ,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA,kBAAkB;AAClB;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".warehouse-main-container {\n    padding: 1rem !important;\n    width: 100%;\n    background-color: #f5f5f5 !important;\n    height: 65vh;\n    overflow-y: auto;\n    scrollbar-width: thin;\n}\n\n/* media queries */\n@media only screen and (max-width: 1024px ) {\n    .warehouse-main-container {\n        height: 65vh;\n    }\n}\n\n@media (min-width: 1025px) and (max-width: 1280px) {\n    .warehouse-main-container {\n        height: 79vh;\n    }\n}\n\n@media (min-width: 1281px) and (max-width: 1440px) {\n    .warehouse-main-container {\n        height: 66vh;\n    }\n}\n\n@media (min-width: 1441px) and (max-width: 1680px) {\n    .warehouse-main-container {\n        height: 67vh;\n    }\n}\n\n@media only screen and (min-width: 1681px) {\n    .warehouse-main-container {\n        height: 86vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
