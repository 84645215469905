import { createSlice } from "@reduxjs/toolkit";

const thoughtSlice = createSlice({
    name: "thought",
    initialState: {
        thoughtData: [],
        error: null,
        loading: false
    },
    reducers: {
        thoughtCreation: (state) => {
            state.loading = true;
            state.error = null;
        },
        thoughtCreationSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        thoughtCreationFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        fetchThought: (state) => {
            state.thoughtData = [];
            state.loading = true;
            state.error = null;
        },
        fetchThoughtSuccess: (state, action) => {
            state.thoughtData = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchThoughtFailure: (state, action) => {
            state.thoughtData = [];
            state.loading = false;
            state.error = action.payload;
        },
        thoughtUpdation: (state) => {
            state.loading = true;
            state.error = null;
        },
        thoughtUpdationSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        thoughtUpdationFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        thoughtDeletion: (state) => {
            state.loading = true;
            state.error = null;
        },
        thoughtDeletionSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        thoughtDeletionFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const {
    thoughtCreation,
    thoughtCreationSuccess,
    thoughtCreationFailure,
    fetchThought,
    fetchThoughtSuccess,
    fetchThoughtFailure,
    thoughtUpdation,
    thoughtUpdationSuccess,
    thoughtUpdationFailure,
    thoughtDeletion,
    thoughtDeletionSuccess,
    thoughtDeletionFailure
} = thoughtSlice.actions;

export default thoughtSlice.reducer;
