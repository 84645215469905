import React, { useRef, useState } from "react";
import "../styles/multiple-image-modal.css";

const MultipleImageUpload = ({
  multipleFile,
  setMultipleFile,
  isMultiple,
  fieldName,
  fileType,
  uploadType,
  setErrors,
  MAX_FILES
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef(null);

  const MAX_IMAGE_SIZE = 20 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

  const uploadMultipleFiles = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;

    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (selectedFiles?.length + (multipleFile[field]?.length || 0) > MAX_FILES) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type === "video/mp4";
      const isValidImageFormat = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ].includes(file.type);
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (
        (uploadType === "image/*" && (!isImage || !isValidImageFormat)) ||
        (uploadType === "video/mp4" && !isVideo)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Invalid file type. Please upload ${
            uploadType === "image/*" ? "JPEG, JPG, or PNG images" : "MP4 videos"
          }.`,
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "20MB" : "50MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setMultipleFile((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const uploadFiles = (e) => {
    e.preventDefault();
  };

  const removeImage = (index) => {
    setMultipleFile([
      ...multipleFile.slice(0, index),
      ...multipleFile.slice(index + 1, multipleFile.length),
    ]);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (fieldName, e, MAX_FILES, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    uploadMultipleFiles(fieldName, e, MAX_FILES, uploadType)
  };

  return (
    <>
      <form>
        <div
          className="container"
          onDrop={(e) => handleDrop(fieldName, e, MAX_FILES, uploadType)}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <div className="form-group multi-preview">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <div className="upload-btn-wrapper">
                    {isMultiple && (
                      <button className="image-btn">+ Add More</button>
                    )}
                    <input
                      accept={fileType === "image" ? "image/*" : "video/mp4"}
                      type="file"
                      name="myfile"
                      onChange={(e) => {uploadMultipleFiles(fieldName, e, MAX_FILES, uploadType)}}
                      multiple={isMultiple}
                      ref={fileInputRef}
                    />
                    <div className="image-btn-text">
                      <img src="/folder.svg" alt="folder" />
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.25rem",
                        }}
                      >
                        <p style={{ padding: 0, margin: 0 }}>
                          Drop Your File Here or
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            color: "#EC9F4F",
                            cursor: "pointer",
                          }}
                        >
                          Choose File
                        </p>
                      </span>
                    </div>
                    {fileType === "image" ? (
                      <div className="image-btn-text">Max:20 MB, JPG,PNG</div>
                    ) : (
                      <>
                        <div className="image-btn-text helper_text_top">
                          Supported Format: MP4
                        </div>
                        <div className="image-btn-text helper_text_bottom">
                          Max:2 videos, 50MB each
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default MultipleImageUpload;
