import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Dialog, DialogContent, Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PledgeOthersCards from "../../components/cards/PledgeOthersCards";
import "../../styles/SevaOverview.css";
import { useDispatch, useSelector } from "react-redux";
import { getSevaById } from "../../features/slices/sevaSlice";
const Overview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();

  const seva = useSelector((state) => state.seva.seva);

  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleImageClick = (url) => {
    setCurrentImage(url);
    setOpenImageDialog(true);
  };

  const handleVideoClick = () => {
    setOpenVideoDialog(true);
    setOpenVideoDialog(true);
  };

  useEffect(() => {
    dispatch(getSevaById(param.id));
  }, [param, dispatch]);

  const [currentImg, setCurrentImage] = useState(null);

  return (
    <Box
      sx={{
        background: "none",
      }}
    >
      <Dialog
        open={openImageDialog}
        onClose={() => {
          setCurrentImage(null);
          setOpenImageDialog(false);
        }}
        maxWidth="md"
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src="/close-icon.svg"
            alt="close-icon"
            className="close-icon"
            onClick={() => setOpenImageDialog(false)}
          />
        </div>
        <DialogContent>
          <img
            src={currentImg}
            alt="Selected"
            style={{ width: "100%", height: "50vh" }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openVideoDialog}
        onClose={() => setOpenVideoDialog(false)}
        maxWidth="md"
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src="/close-icon.svg"
            alt="close-icon"
            className="close-icon"
            onClick={() => setOpenVideoDialog(false)}
          />
        </div>
        <DialogContent>
          <video
            width="100%"
            controls
            style={{ width: "100%", height: "50vh" }}
            autoPlay
          >
            <source src={seva?.videoUrl?.filePath} type="video/mp4" />
          </video>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <ArrowBackIcon
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(`/seva-management`)}
          />
          <Typography variant="h5" sx={{ mt: 2, mb: 2, color: "black" }}>
            {seva?.name && seva?.name}
          </Typography>
        </span>

        <img
          src="/edit-icon.svg"
          alt="edit"
          className="edit-icon"
          onClick={() =>
            navigate(`/seva-management/Edit/${param.id}`, { replace: true })
          }
        />
      </Box>

      <Box
        sx={{
          height: "80vh",
          overflow: "auto",
          scrollbarWidth: "thin",
          p: 2,
        }}
      >
        {/* Photo Gallery */}
        <Typography className="seva-title">Photo Gallery</Typography>
        <Grid2
          container
          gap={2}
          spacing={2}
          style={{
            width: "100%",
            overflowX: "auto",
            flexWrap: "noWrap",
            gap: "10rem",
            scrollbarWidth: "thin",
          }}
        >
          {seva?.photosUrl.map((photo, index) => (
            <Grid2 item xs={2} key={index}>
              <img
                src={photo?.filePath}
                alt="img"
                className="seva-song-image"
                onClick={() => handleImageClick(photo?.filePath)}
              ></img>
            </Grid2>
          ))}
        </Grid2>

        {/* Videos */}
        <Typography className="seva-title" mt={2}>
          Videos
        </Typography>
        <Box sx={{ display: "flex", mt: 1, mb: 2 }}>
          {!isVideoPlaying ? (
            <>
              <div className="" style={{ position: "relative" }}>
                {" "}
                <img
                  src={seva?.thumbnailUrl?.filePath}
                  alt="img"
                  className="seva-song-image"
                  // onClick={handleVideoClick}
                ></img>
                <PlayArrowIcon
                  fontSize="large"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    cursor: "pointer",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    borderRadius: "50%",
                    padding: 1,
                  }}
                  onClick={handleVideoClick}
                />
              </div>
            </>
          ) : (
            <video width="100%" controls autoPlay>
              <source src={seva?.videoUrl?.filePath} type="video/mp4" />
            </video>
          )}
        </Box>

        {/* Details Section */}
        <Box style={{ paddingBottom: "5rem" }}>
          <PledgeOthersCards
            content={
              <div className="details_container">
                <div className="seva_p">
                  Seva Name: <span className="seva_span">{seva?.name}</span>
                </div>
                <div className="seva_p">
                  Price: <span className="seva_span">{seva?.price}</span>
                </div>
                <div className="seva_p">
                  Prasadam:{" "}
                  <span className="seva_span">
                    {seva?.price ? "Yes" : "No"}
                  </span>
                </div>
                <div className="seva_p">
                  Time:{" "}
                  <span className="seva_span">
                    {seva?.startAt + "-" + seva?.endAt}
                  </span>
                </div>
                <div className="seva_p">
                  Seva Availability:
                  <span className="seva_span">
                    {seva?.online ? "Online" : "Offline"}
                  </span>
                </div>
                <div className="seva_p">
                  Maximum participants:
                  <span className="seva_span">{seva?.maxParticipant}</span>
                </div>
                <div>
                  <div className="seva_h" style={{ marginTop: "1rem" }}>
                    Samagri:{" "}
                  </div>
                  {seva?.poojaSamagri?.split(",").map((s) => (
                    <div className="seva_list">{s}</div>
                  ))}
                </div>
                <div>
                  <div className="seva_h" style={{ marginTop: "1rem" }}>
                    Description
                  </div>
                  <div className="seva_desc">{seva?.meaning}</div>
                </div>
                <div>
                  <div className="seva_h" style={{ marginTop: "1rem" }}>
                    What would the devotees receive?
                  </div>
                  <div className="seva_desc">{seva?.prasadamDetails}</div>
                </div>
              </div>
            }
            editButton={false}
            showView={false}
            borderColor="var(--primary-2)"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
