import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import CommonTable from "../../components/CommonTable";
import SearchInput from "../../components/CommonSearchBar";
import { AddButton } from "../../components/Addbutton";
import "../../styles/payment.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const tableRows = [
  {
    name: "Campaign 1",
    orderId: "25821",
    transactionId: "25821",
    prasadamName: "Laddu, Ghee, MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Accepted",
  },
  {
    name: "Campaign 2",
    orderId: "25821",
    transactionId: "25821",
    prasadamName: "Laddu, Ghee, MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Delivered",
  },
  {
    name: "Campaign 3",
    orderId: "25821",
    transactionId: "25821",
    prasadamName: "Laddu, Ghee, MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Rejected",
  },
  {
    name: "Campaign 4",
    orderId: "25821",
    transactionId: "25821",
    prasadamName: "Laddu, Ghee, MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Pending",
  },
  {
    name: "Campaign 5",
    orderId: "25821",
    transactionId: "25821",
    prasadamName: "Laddu, Ghee, MatchBox",
    startDate: "19/10/2024",
    endDate: "22/10/2024",
    amount: "₹200.00",
    orderStatus: "Delivered",
  },
];

const PaymentHistory = React.memo(() => {
  const userRole = localStorage.getItem("role");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);

  const columns = userRole === "ROLE_TEMPLE_OWNER" ? [
    { field: "transactionId", headerName: "Transaction ID" },
    { field: "orderId", headerName: "Order ID" },
    { field: "paymentType", headerName: "Payment Type" },
    { field: "startDate", headerName: "Date of payment" },
    { field: "amount", headerName: "Amount Paid" },
    {
        field: "action",
        headerName: "Download Invoice",
        renderCell: (params) => (
          <span className="action-row">
              <img
                  src="/payment-icons/download-icon.svg"
                  alt="edit"
                  className="edit-icon"
                  onClick={() => handleSingleFileDownload()}
              />
          </span>
        ),
      },
  ] : userRole === "ROLE_PUROHIT" ? [
    { field: "transactionId", headerName: "Transaction ID" },
    { field: "orderId", headerName: "Booking ID" },
    { field: "paymentType", headerName: "Payment Type" },
    { field: "startDate", headerName: "Date of payment" },
    { field: "amount", headerName: "Amount Paid" },
    {
      field: "action",
      headerName: "Download Details",
      renderCell: (params) => (
        <span className="action-row">
            <img
                src="/payment-icons/download-icon.svg"
                alt="edit"
                className="edit-icon"
                onClick={() => handleSingleFileDownload()}
            />
        </span>
      ),
    },
  ] : [];

  const handleSingleFileDownload = () => {};
  const handleBulkFileDownload = () => {};

  const handleChangePage = useCallback((val) => {
    setPage(val);
  });

  return (
    <>       
      <div className="payment-header">
          <Box className="heading">
              Payment History
          </Box>

          <Box sx={{ display: "flex", alignItems: 'center', gap: "0.5rem" }}>
              <SearchInput />

              <AddButton
                  icon={<ArrowDropDownIcon />}
                  name="Filter By"
                  borderColor="var(--secondary)"
                  background="#F5F5F5"
                  color="var(--secondary)"
                  padding="1.15rem"
                  onClick={handleBulkFileDownload}
              />

              <AddButton
                  name="Download"
                  background="#F5F5F5"
                  color="var(--secondary)"
                  borderColor="var(--secondary)"
                  padding="1.15rem"
              />
          </Box>
      </div>

      <CommonTable
          columns={columns}
          data={tableRows}
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
      />      
    </>
  );
});

export default PaymentHistory;
