import { Box, Modal, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AddButton } from "../../components/Addbutton";
import MaterialUIFieldAdapter from "../../components/MaterialUiField";
import "../../styles/login.css";
import TermsAndCondition from "../TermsAndCondition";

const RoleSelect = React.memo(() => {
  const navigate = useNavigate();
  const childRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [disabledLogin, setDisableLogin] = useState(false);

  const validationSchema = Yup.object({
    role: Yup.string().required("Role is required"),
  });

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const scrollToTop = () => {
    if (childRef.current) {
      childRef.current.scrollTop = 0;
    }
  };

  const [show, setShow] = useState(false);

  return (
    <>
      <img src="/daiv-logo.svg" alt="logo" className="daiv_logo" />
      <div container className="login_container">
        {/* this section will open the terms and conditions modal box */}
        <Modal
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
          }}
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="terms_condition_box" ref={childRef}>
            <TermsAndCondition
              modalType={modalType}
              scrollToTop={scrollToTop}
              setOpenModal={setOpenModal}
              setDisableLogin={setDisableLogin}
            />
          </Box>
        </Modal>
        <div className="login_background_image"></div>
        <div className="login_content">
          <div className="greeting_text_container">
            <div className="login_form">
              <div className="welcome_title">Welcome to DAIV</div>

              <div className="welcome_login_btn">
                <Formik
                  initialValues={{
                    role: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    navigate(`role?role=${values.role}`);
                  }}
                >
                  {({
                    setFieldValue,
                    isSubmitting,
                    errors,
                    touched,
                    isValid,
                    dirty,
                  }) => (
                    <Form>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: "20px",
                          marginTop: "25px",
                        }}
                      >
                        <MaterialUIFieldAdapter
                          name="role"
                          type="select"
                          pageType="login"
                          label="Select Your Role*"
                          placeholder="Select Type"
                          style={{ width: "100%" }}
                          show={show}
                          setShow={setShow}
                          options={[
                            {
                              value: "ROLE_TEMPLE_OWNER",
                              label: "Temple Owner",
                              content:
                                "Manage temple operations, events, and community engagement",
                            },
                            {
                              value: "ROLE_PUROHIT",
                              label: "Purohit",
                              content:
                                "Perform rituals, provide spiritual guidance, and conduct ceremonies.",
                            },
                            {
                              value: "ROLE_SELLER_ECOMM",
                              label: "Seller (E-Commerce Products)",
                              content:
                                "List and sell religious items or services to the community.",
                            },
                          ]}
                        />
                      </div>

                      <AddButton
                        name="Continue"
                        type="submit"
                        width="100%"
                        padding="1.3rem"
                        background="var(--secondary)"
                        sx={{
                          fontFamily: "var(--font-secondary) !important",
                          fontWeight: 600,
                          fontSize: ".9rem",
                        }}
                        disabled={!(isValid && dirty)}
                      />
                      <div className="welcome_footer">
                        <div className="welcome_footer_1">
                          Already have an account?
                        </div>
                        <div
                          className="welcome_footer_2"
                          onClick={() => navigate("/login")}
                        >
                          Log In
                        </div>
                      </div>
                      <div className="welcome_footer">
                        <div
                          className="welcome_footer_1"
                          style={{ marginTop: "1rem" }}
                        >
                          <div style={{ textAlign: "center" }}>
                            By signing up, you agree to the
                          </div>

                          <div className="terms_and_condition_text">
                            <div
                              className="welcome_footer_2"
                              onClick={() => {
                                setModalType("terms_and_condition");
                                setOpenModal(true);
                              }}
                            >
                              Terms & Conditions
                            </div>
                            <div className="welcome_footer_1">and</div>
                            <div
                              className="welcome_footer_2"
                              onClick={() => {
                                setModalType("privacy_policy");
                                setOpenModal(true);
                              }}
                            >
                              Privacy Policy
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default RoleSelect;
