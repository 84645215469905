import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchOnBoardingStatusRequest,
  fetchOnBoardingStatusFailure,
  fetchOnBoardingStatusSuccess,
  fetchPurohitRequest,
  fetchPurohitSuccess,
  fetchPurohitFailure,
  uploadPurohitRequest,
  uploadPurohitSuccess,
  uploadPurohitFailure,
  fetchdocRequest,
  fetchdocSuccess,
  fetchdocFailure,
  uploaddocRequest,
  uploaddocSuccess,
  uploaddocFailure,
  fetchlineageRequest,
  fetchlineageSuccess,
  fetchlineageFailure,
  uploadlineageRequest,
  uploadlineageSuccess,
  uploadlineageFailure,
  fetchserviceRequest,
  fetchserviceSuccess,
  fetchserviceFailure,
  uploadserviceRequest,
  uploadserviceSuccess,
  uploadserviceFailure,
  fetchtimeAvailabiltyRequest,
  fetchtimeAvailabiltySuccess,
  fetchtimeAvailabiltyFailure,
  uploadpujaOfferingRequest,
  uploadpujaOfferingSuccess,
  uploadpujaOfferingFailure,
  fetchpujaOfferingRequest,
  fetchpujaOfferingSuccess,
  fetchpujaOfferingFailure,
  uploadtimeAvailabiltyRequest,
  uploadtimeAvailabiltySuccess,
  uploadtimeAvailabiltyFailure,
  fetchPurohit2Request,
  fetchPurohit2Success,
  fetchPurohit2Failure,
} from "../slices/purohitOnboardingSlice";
import {
  deleteRequest,
  getRequest,
  postRequest4,
  putRequestWithFormData,
  postRequestUsingFormData,
  putRequest,
} from "../../api/apiActions";

import {
  getPurohitOnboardStatusUrls,
  getPurohitDetailsOnboardUrls,
  getPurohitDocOnboardUrls,
  getPurohitLineageOnboardUrls,
  getPurohitPujaServiceOnboardUrls,
  getPurohitPujaOfferOnboardUrls,
  getPurohitTimeAvailOnboardUrls,
  updatePurohitDetailsOnboardUrls,
  updatePurohitDocOnboardUrls,
  updatePurohitLineageOnboardUrls,
  updatePurohitPujaServiceOnboardUrls,
  updatePurohitPujaOfferOnboardUrls,
  updatePurohitTimeAvailOnboardUrls,
  getPurohitDetailsPhotoOnboardUrls,
} from "../../api/apiUrls";

const updatedocCall = (payload) =>
  putRequestWithFormData(updatePurohitDocOnboardUrls, payload);
const updateserviceCall = (payload) =>
  putRequest(updatePurohitPujaServiceOnboardUrls, payload);
const updatePurohitCall = (payload) =>
  postRequestUsingFormData(updatePurohitDetailsOnboardUrls, payload);
const updatepujaOfferingCall = (payload) =>
  putRequest(updatePurohitPujaOfferOnboardUrls, payload);
const updatetimeAvailabilitytCall = (payload) =>
  putRequest(updatePurohitTimeAvailOnboardUrls, payload);
const updatelineageCall = (payload) =>
  putRequest(updatePurohitLineageOnboardUrls, payload);

const getCall = () => getRequest(getPurohitDetailsPhotoOnboardUrls);
const getCall2 = () => getRequest(getPurohitOnboardStatusUrls);
const getdocCall = () => getRequest(getPurohitDocOnboardUrls);
const getPurohitCall = () => getRequest(getPurohitDetailsOnboardUrls);
const getlineageCall = () => getRequest(getPurohitLineageOnboardUrls);
const getpujaOfferingCall = () => getRequest(getPurohitPujaOfferOnboardUrls);
const gettimeAvailabiltyCall = () => getRequest(getPurohitTimeAvailOnboardUrls);
const getserviceCall = () => getRequest(getPurohitPujaServiceOnboardUrls);

function* fetchdocAll(action) {
  try {
    const response = yield call(getdocCall);

    if (response.status === 200) {
      yield put(fetchdocSuccess(response?.data));
    } else {
      yield put(fetchdocFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchdocFailure(error?.response?.data));
  }
}
function* fetchPurohitAll(action) {
  try {
    const response = yield call(getPurohitCall);

    if (response.status === 200) {
      yield put(fetchPurohitSuccess(response?.data));
    } else {
      yield put(fetchPurohitFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchPurohitFailure(error?.response?.data));
  }
}

function* fetchPurohit2All(action) {
  try {
    const response = yield call(getCall);

    if (response.status === 200) {
      yield put(fetchPurohit2Success(response?.data));
    } else {
      yield put(fetchPurohit2Failure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchPurohit2Failure(error?.response?.data));
  }
}

function* fetchpujaOfferingAll(action) {
  try {
    const response = yield call(getpujaOfferingCall);

    if (response.status === 200) {
      yield put(fetchpujaOfferingSuccess(response?.data));
    } else {
      yield put(fetchpujaOfferingFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchpujaOfferingFailure(error?.response?.data));
  }
}
function* fetchlineageAll(action) {
  try {
    const response = yield call(getlineageCall);

    if (response.status === 200) {
      yield put(fetchlineageSuccess(response?.data));
    } else {
      yield put(fetchlineageFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchlineageFailure(error?.response?.data));
  }
}
function* fetchtimeAvailabiltyAll(action) {
  try {
    const response = yield call(gettimeAvailabiltyCall);

    if (response.status === 200) {
      yield put(fetchtimeAvailabiltySuccess(response?.data));
    } else {
      yield put(fetchtimeAvailabiltyFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchtimeAvailabiltyFailure(error?.response?.data));
  }
}
function* fetchserviceAll(action) {
  try {
    const response = yield call(getserviceCall);

    if (response.status === 200) {
      yield put(fetchserviceSuccess(response?.data));
    } else {
      yield put(fetchserviceFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchserviceFailure(error?.response?.data));
  }
}

function* fetchOnBoardingStatus(action) {
  try {
    const response = yield call(getCall2);

    if (response.status === 200) {
      yield put(fetchOnBoardingStatusSuccess(response?.data));
    } else {
      yield put(fetchOnBoardingStatusFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchOnBoardingStatusFailure(error?.response?.data));
  }
}

function* postOnBoardingpujaOffering(action) {
  try {
    const response = yield call(updatepujaOfferingCall, action.payload);
    if (response.status === 200) {
      yield put(uploadpujaOfferingSuccess(response?.data));
    } else {
      yield put(uploadpujaOfferingFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadpujaOfferingFailure(error?.response?.data));
  }
}

function* postOnBoardingdoc(action) {
  try {
    const response = yield call(updatedocCall, action.payload);
    if (response.status === 200) {
      yield put(uploaddocSuccess(response?.data));
    } else {
      yield put(uploaddocFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploaddocFailure(error?.response?.data));
  }
}
function* postOnBoardingPurohit(action) {
  try {
    const response = yield call(updatePurohitCall, action.payload);
    if (response.status === 200) {
      yield put(uploadPurohitSuccess(response?.data));
    } else {
      yield put(uploadPurohitFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadPurohitFailure(error?.response?.data));
  }
}
function* postOnBoardingtimeAvailabilty(action) {
  try {
    const response = yield call(updatetimeAvailabilitytCall, action.payload);
    if (response.status === 200) {
      yield put(uploadtimeAvailabiltySuccess(response?.data));
    } else {
      yield put(uploadtimeAvailabiltyFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadtimeAvailabiltyFailure(error?.response?.data));
  }
}
function* postOnBoardinglineage(action) {
  try {
    const response = yield call(updatelineageCall, action.payload);
    if (response.status === 200) {
      yield put(uploadlineageSuccess(response?.data));
    } else {
      yield put(uploadlineageFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadlineageFailure(error?.response?.data));
  }
}
function* postOnBoardingservice(action) {
  try {
    const response = yield call(updateserviceCall, action.payload);
    if (response.status === 200) {
      yield put(uploadserviceSuccess(response?.data));
    } else {
      yield put(uploadserviceFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(uploadserviceFailure(error?.response?.data));
  }
}

export function* purohitOnboardingSaga() {
  yield takeLatest(fetchdocRequest.type, fetchdocAll);
  yield takeLatest(fetchPurohit2Request.type, fetchPurohit2All);
  yield takeLatest(fetchPurohitRequest.type, fetchPurohitAll);
  yield takeLatest(fetchlineageRequest.type, fetchlineageAll);
  yield takeLatest(fetchtimeAvailabiltyRequest.type, fetchtimeAvailabiltyAll);
  yield takeLatest(fetchserviceRequest.type, fetchserviceAll);
  yield takeLatest(fetchpujaOfferingRequest.type, fetchpujaOfferingAll);
  yield takeLatest(fetchOnBoardingStatusRequest.type, fetchOnBoardingStatus);

  yield takeLatest(uploadpujaOfferingRequest.type, postOnBoardingpujaOffering);

  yield takeLatest(uploaddocRequest.type, postOnBoardingdoc);
  yield takeLatest(uploadPurohitRequest.type, postOnBoardingPurohit);
  yield takeLatest(
    uploadtimeAvailabiltyRequest.type,
    postOnBoardingtimeAvailabilty,
  );
  yield takeLatest(uploadlineageRequest.type, postOnBoardinglineage);
  yield takeLatest(uploadserviceRequest.type, postOnBoardingservice);
}
