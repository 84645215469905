import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";

import "../styles/ModalDatePicker.css";

const ModalDatePicker = ({
  openDatePicker,
  OnDateChange,
  selectedDate,
  onClose,
  nonSelectableDates,
  datePickerTitle,
}) => {
  const isNonSelectable = (day) => {
    return nonSelectableDates?.includes(day.$D);
  };

  return (
    <div>
      <Dialog
        open={openDatePicker}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        className="custom-date-picker"
      >
        <DialogContent className="Date-dialog-content">
          <div className="Date-dialog-title-container">
            <Typography variant="h6" className="Date-dialog-title">
              {datePickerTitle ? datePickerTitle : "Select Date"}
            </Typography>
            <CloseIcon className="Date-dialog-cancel-icon " onClick={onClose} />
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={selectedDate}
              onChange={(newValue) => OnDateChange(newValue)}
              shouldDisableDate={(day, _value) => isNonSelectable(day)}
            />
          </LocalizationProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalDatePicker;
