// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter_popover {
  width: 20rem;
  padding: 1.5rem;
}

.filter_title_container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-bottom: 1px solid #67687c;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
}

.filter_title {
  font-family: "CabinetGrotesk-Medium";
  font-weight: 700;
  font-size: 1.2rem;
}

.filter_av_reset {
  display: flex;
  justify-content: space-between;
}

.filter_label {
  font-family: var(--font-secondary);
  font-weight: 500;
  color: #2c2c39;
  font-size: 0.9rem;
}

.filter_reset {
  font-family: "CabinetGrotesk-Medium";
  font-size: 0.8rem;
  color: #e39441;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/styles/filter.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,gCAAgC;EAChC,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;EACpC,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB","sourcesContent":[".filter_popover {\n  width: 20rem;\n  padding: 1.5rem;\n}\n\n.filter_title_container {\n  display: flex;\n  gap: 0.5rem;\n  align-items: center;\n  border-bottom: 1px solid #67687c;\n  padding: 0.5rem 0;\n  margin-bottom: 1rem;\n}\n\n.filter_title {\n  font-family: \"CabinetGrotesk-Medium\";\n  font-weight: 700;\n  font-size: 1.2rem;\n}\n\n.filter_av_reset {\n  display: flex;\n  justify-content: space-between;\n}\n\n.filter_label {\n  font-family: var(--font-secondary);\n  font-weight: 500;\n  color: #2c2c39;\n  font-size: 0.9rem;\n}\n\n.filter_reset {\n  font-family: \"CabinetGrotesk-Medium\";\n  font-size: 0.8rem;\n  color: #e39441;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
