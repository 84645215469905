import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AddButton } from "../../components/Addbutton";
import { emailRequest } from "../../features/slices/authSlice";
import "../../styles/login.css";

const VerifyEmail = React.memo(() => {
  const { loginEmail } = useSelector((state) => state.auth);

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      dispatch(emailRequest({ navigate }));
    }
  }, [token, dispatch, navigate]);

  let verified = false;
  return (
    <>
      <img src="/daiv-logo.svg" alt="logo" className="daiv_logo" />
      <div container className="login_container">
        <div className="login_background_image"></div>
        <div className="login_content">
          <div className="greeting_text_container">
            <div className="login_form">
              {!verified ? (
                <>
                  <div className="welcome_title1">
                    Verify Your Email Address
                  </div>
                  <div className="welcome_desc verify_email_desc">
                    We’ve sent a verification link to your email
                    <span className="verify_email">
                      {" "}
                      {email ? email : loginEmail}
                    </span>
                    . Please check the email & click on the link to verify your
                    account.
                  </div>
                </>
              ) : (
                <>
                  <div className="welcome_title">
                    Thank you for verifying your email address.
                  </div>
                  <div className="welcome_desc verify_email_desc">
                    Your account has been successfully verified. We’re grateful
                    to have you with us on this journey.
                  </div>
                </>
              )}
              <img
                src="/verify-email.svg"
                alt="verify email"
                className="verify_email_image"
              />
              {!verified ? (
                <AddButton
                  name="Resend Email"
                  type="submit"
                  width="100%"
                  padding="18px"
                  background="var(--secondary)"
                  sx={{
                    fontFamily: "var(--font-secondary) !important",
                    fontWeight: 600,
                    fontSize: ".9rem",
                  }}
                  onClick={() => dispatch(emailRequest({ navigate }))}
                />
              ) : (
                <AddButton
                  name="Continue"
                  type="submit"
                  width="100%"
                  padding="18px"
                  background="var(--secondary)"
                  sx={{
                    fontFamily: "var(--font-secondary) !important",
                    fontWeight: 600,
                    fontSize: ".9rem",
                  }}
                  onClick={() => navigate("/signup/contact")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default VerifyEmail;
