import {
  Autocomplete,
  Box,
  FormControl,
  Grid2,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import "../../styles/onboardingForm.css";

import { useDispatch, useSelector } from "react-redux";
import { uploadTempleRequest } from "../../features/slices/onBordingSlice";
import "../../styles/ModalDatePicker.css";
import { validateEmail } from "../../helpers/emailValidation";

const initialFormState = {
  templeName: "",
  templeEmail: "",
  templeType: "",
  templePhoneNumber: "",
  templeWebsite: "",
  mainDeityName: "",
  city: "",
  country: "",
  address: "",
  state: "",
  establishedYear: "",
  registeredTempleName: "",
  pinCode: "",
};

const initialErrorState = {
  templeName: "",
  templeEmail: "",
  templeType: "",
  templePhoneNumber: "",
  mainDeityName: "",
  city: "",
  country: "",
  address: "",
  state: "",
  establishedYear: "",
  registeredTempleName: "",
  pinCode: "",
};

const BasicTemplateDetails = forwardRef((props, ref) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);

  const dispatch = useDispatch();

  const handleChange = (field) => (event) => {
    const value = event.target.value;

    // Update the form data
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    let error = "";
    if (field === "templePhoneNumber") {
      if (!/^\d{0,10}$/.test(value)) {
        error = "Phone number must contain only digits.";
      } else if (value.length !== 10 && value.length > 0) {
        error = "Phone number must be exactly 10 digits.";
      }
    } else if (field === "templeEmail") {
      if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        error = "Please enter a valid email address.";
      }
    } else if (field === "templeWebsite") {
      if (value && !/^https?:\/\/[^\s$.?#].[^\s]*$/i.test(value)) {
        error = "Please enter a valid website URL.";
      }
    }

    setErrors((prev) => ({
      ...prev,
      [field]: error,
    }));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;

      if (!formData.templeName) {
        newErrors.templeName = "Temple Name is required";
        isValid = false;
      }
      if (!formData.establishedYear) {
        newErrors.establishedYear = "Established Year is required";
        isValid = false;
      }
      if (!formData.templeEmail) {
        newErrors.templeEmail = "Temple Email is required";
        isValid = false;
      } else if (!validateEmail(formData.templeEmail)) {
        newErrors.templeEmail = "Temple Email is not valid";
        isValid = false;
      }

      if (!formData.mainDeityName) {
        newErrors.mainDeityName = "Main Deity Name is required";
        isValid = false;
      }
      if (!formData.registeredTempleName) {
        newErrors.registeredTempleName = "Registered Name is required";
        isValid = false;
      }
      if (!formData.templeType) {
        newErrors.templeType = "Temple Type is required";
        isValid = false;
      }

      if (!formData.templeEmail) {
        newErrors.templeEmail = "Temple Email is required";
        isValid = false;
      }
      if (!formData.templePhoneNumber) {
        newErrors.templePhoneNumber = "Temple Contact Number is required";
        isValid = false;
      }

      setErrors(newErrors);

      if (isValid) {
        dispatch(uploadTempleRequest(formData));
      }
    },
  }));

  useEffect(() => {
    let newData = props?.OnBoardData;
    setFormData({
      ...formData,
      templeName: newData?.templeName ? newData?.templeName : "",
      mainDeityName: newData?.mainDeityName ? newData?.mainDeityName : "",
      templeEmail: newData?.templeEmail ? newData?.templeEmail : "",
      templeType: newData?.templeType ? newData?.templeType : "",
      templePhoneNumber: newData?.templePhoneNumber
        ? newData?.templePhoneNumber
        : "",
      templeWebsite: newData?.templeWebsite ? newData?.templeWebsite : "",
      city: newData?.city ? newData?.city : "",
      country: newData?.country ? newData?.country : "",
      address: newData?.address ? newData?.address : "",
      state: newData?.state ? newData?.state : "",
      establishedYear: newData?.establishedYear ? newData?.establishedYear : "",
      registeredTempleName: newData?.registeredTempleName
        ? newData?.registeredTempleName
        : "",
      pinCode: newData?.pinCode ? newData?.pinCode : "",
    });
  }, [props?.OnBoardData]);
  const handleKeyDown = (event) => {
    const { value } = event.target;

    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];

    if (
      !allowedKeys.includes(event.key) &&
      (!/^[0-9]$/.test(event.key) || value.length >= 10)
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="heading">Basic Temple Details</div>
      <Box style={{ marginTop: "0.5rem" }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Temple Name
            </Typography>
            <TextField
              fullWidth
              disabled
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  backgroundColor: "gray !important",
                  color: "#a0a0a0",
                },
              }}
              variant="outlined"
              value={formData.templeName}
              onChange={handleChange("templeName")}
              placeholder="Temple Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.templeName && (
              <Typography color="error">{errors.templeName}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Registered Temple Name*
            </Typography>
            <TextField
              fullWidth
              disabled={props?.previewStatus ? true : false}
              variant="outlined"
              value={formData.registeredTempleName}
              onChange={handleChange("registeredTempleName")}
              placeholder="Enter Registered Temple Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.registeredTempleName && (
              <Typography color="error">
                {errors.registeredTempleName}
              </Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Temple Type*
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-select"
            >
              <Autocomplete
                options={["HINDU", "JAIN", "BUDDHIST", "SIKH"]}
                value={formData.templeType}
                disabled={props?.previewStatus ? true : false}
                disableClearable
                onChange={(e, newVal) =>
                  setFormData({ ...formData, templeType: newVal })
                }
                renderOption={(props, option, { selected }) => {
                  return (
                    <span
                      {...props}
                      style={{
                        backgroundColor: selected
                          ? "var(--black)"
                          : "transparent",
                        color: selected ? "#FFD1A2" : "black",

                        pointerEvents: selected ? "none" : "auto",
                      }}
                    >
                      {option}
                    </span>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Temple Type"
                    variant="outlined"
                    style={{ marginBottom: "1rem" }}
                  />
                )}
              />
              {errors.templeType && (
                <Typography color="error">{errors.templeType}</Typography>
              )}
            </FormControl>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Established Year*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              disabled={props?.previewStatus ? true : false}
              value={formData.establishedYear}
              onChange={handleChange("establishedYear")}
              placeholder="Enter Established Year"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.establishedYear && (
              <Typography color="error">{errors.establishedYear}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Main Deity Name*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              disabled={props?.previewStatus ? true : false}
              value={formData.mainDeityName}
              onChange={handleChange("mainDeityName")}
              placeholder="Enter Main Deity Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.mainDeityName && (
              <Typography color="error">{errors.mainDeityName}</Typography>
            )}
          </Grid2>
        </Grid2>
        <div className="heading">Address Details</div>
        <Grid2 style={{ marginTop: "0.5rem" }} size={{ xs: 12, md: 12 }}>
          <Typography className="onboarding-form_Heading">Address*</Typography>
          <TextField
            fullWidth
            disabled
            variant="outlined"
            value={formData.address}
            onChange={handleChange("address")}
            placeholder="Enter address"
            style={{ marginBottom: "1rem" }}
            className="onboarding-custom-textfield"
            InputProps={{
              style: { color: "#8d8e9f" },
            }}
            sx={{
              "& .MuiInputBase-root.Mui-disabled": {
                backgroundColor: "gray !important",
                color: "#a0a0a0",
              },
            }}
          />
          {errors.address && (
            <Typography color="error">{errors.address}</Typography>
          )}
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Enter Your country*
            </Typography>
            <TextField
              fullWidth
              disabled
              variant="outlined"
              value={formData.country}
              onChange={handleChange("country")}
              placeholder="Enter Main Deity Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.country && (
              <Typography color="error">{errors.country}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Enter Your State*
            </Typography>
            <TextField
              fullWidth
              disabled
              variant="outlined"
              value={formData.state}
              onChange={handleChange("state")}
              placeholder="Enter Main Deity Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.state && (
              <Typography color="error">{errors.state}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Enter Your City*
            </Typography>
            <TextField
              fullWidth
              disabled
              variant="outlined"
              value={formData.city}
              onChange={handleChange("city")}
              placeholder="Enter Main Deity Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.city && (
              <Typography color="error">{errors.city}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Pincode*
            </Typography>
            <TextField
              fullWidth
              disabled
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  backgroundColor: "gray !important",
                  color: "#a0a0a0",
                },
              }}
              variant="outlined"
              value={formData.pinCode}
              onChange={handleChange("pinCode")}
              placeholder="Enter Pincode"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.pinCode && (
              <Typography color="error">{errors.pinCode}</Typography>
            )}
          </Grid2>
        </Grid2>
        <div className="heading">Contact Details of Temple*</div>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Phone number of the Temple*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              disabled={props?.previewStatus ? true : false}
              type="number"
              value={formData.templePhoneNumber}
              onKeyDown={handleKeyDown}
              onChange={handleChange("templePhoneNumber")}
              placeholder="Enter Phone number of the Temple*"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.templePhoneNumber && (
              <Typography color="error">{errors.templePhoneNumber}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Email ID of the Temple*
            </Typography>
            <TextField
              fullWidth
              disabled={props?.previewStatus ? true : false}
              variant="outlined"
              value={formData.templeEmail}
              onChange={handleChange("templeEmail")}
              placeholder="Enter Email ID of the Temple*"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.templeEmail && (
              <Typography color="error">{errors.templeEmail}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Official Temple Website
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              disabled={props?.previewStatus ? true : false}
              value={formData.templeWebsite}
              onChange={handleChange("templeWebsite")}
              placeholder="Enter Temple Website link"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
});

export default BasicTemplateDetails;
