import React, { useEffect, useState } from "react";
import StatsCard from "../../components/cards/StatsCard";
import "../../styles/dashboard.css";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Tabs from "../../components/common/Tabs";
import { CustomLoader } from "../../components/CustomLoader";
import NewBookings from "./NewBookings";
import ProgressBookings from "./ProgressBookings";
import CompletedBookings from "./CompletedBookings";
import RejectedBookings from "./RejectedBookings";

const tabItems = [
  { label: "New Bookings", value: "New-Bookings" },
  { label: "Bookings in Progress", value: "Progress-Bookings" },
  { label: "Completed Bookings", value: "Completed-Bookings" },
  { label: "Rejected Bookings", value: "Rejected-Bookings" },
];

const BookingManagement = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { actionType } = useParams();
  const [selectedMenu, setSelectedMenu] = useState(() => {
    let pathName = location.pathname.split("/");
    return pathName[2] ? pathName[2] : "New-Bookings";
  });

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
    navigate(`/booking-management/${menu}`);
  };

  useEffect(() => {
    navigate(selectedMenu);
  }, [pathName]);

  useEffect(() => {
    let pathName = location.pathname.split("/");

    const filteredData = tabItems.filter((item) =>
      pathName.includes(item.value),
    );

    if (filteredData.length > 0 && !selectedMenu) {
      setSelectedMenu(filteredData[0].value);
      navigate(
        `/booking-management${filteredData[0].value ? `/${filteredData[0].value}` : ""}${actionType ? `/${actionType}` : ""}`,
      );
    }
    if (filteredData.length === 0 && !selectedMenu) {
      setSelectedMenu("New-Bookings");
      navigate(`/booking-management/New-Bookings`);
    }
  }, [pathName]);

  return (
    <div className="dashboard_container">
      <CustomLoader loading={false} message="Fetching data, please wait..." />
      <div className="dashboard-stats-container">
        <StatsCard
          label={"Total Bookings"}
          stat={"25"}
          image="/dashboard-icons/seva-bookings.svg"
          info={
            "Total number of Online Bookings for Purohit Services for Puja Ceremonies for this Month."
          }
        />
        <StatsCard
          label={"Bookings in Progress"}
          stat={"270"}
          image="/dashboard-icons/progressBookings.svg"
        />
        <StatsCard
          label={"Completed Bookings"}
          stat={"725"}
          image="/dashboard-icons/completedBooking.svg"
        />
        <StatsCard
          label={"Rejected Bookings"}
          stat={"25"}
          image="/dashboard-icons/rejectedBookings.svg"
        />
      </div>

      <div style={{ marginTop: "1rem" }}>
        <Tabs
          tabs={tabItems}
          tabStart={selectedMenu}
          onClick={handleMenuButtonClick}
        />
      </div>
      <Outlet />
    </div>
  );
});

export default BookingManagement;
