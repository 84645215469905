import { createSlice } from "@reduxjs/toolkit";

const helpAndSupportSlice = createSlice({
  name: "helpAndSupport",
  initialState: {
    helpAndSupportData: [],
    error: "",
    helpAndSupportStatus: false,
  },
  reducers: {
    helpAndSupportRequestByStatus: (state) => {
      state.helpAndSupportStatus = true;
      state.helpAndSupportData = [];
      state.error = "";
    },
    helpAndSupportByStatusSuccess: (state, action) => {
      state.helpAndSupportStatus = false;
      state.helpAndSupportData = action.payload;
      state.error = "";
    },
    helpAndSupportByStatusFailure: (state, action) => {
      state.helpAndSupportStatus = false;
      state.helpAndSupportData = [];
      state.error = action.payload;
    },
  },
});

export const {
  helpAndSupportRequestByStatus,
  helpAndSupportByStatusSuccess,
  helpAndSupportByStatusFailure,
} = helpAndSupportSlice.actions;

export default helpAndSupportSlice.reducer;
