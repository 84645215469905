import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid2,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AddButton } from "../../components/Addbutton";
import "../../styles/purohit/scheduleAndAvailbity.css";
import CustomStaticDatePicker from "../../components/CustomStaticDatePicker";
import PledgeOthersCards from "../../components/cards/PledgeOthersCards";
import { useNavigate } from "react-router-dom";
import UnavailableDatePage from "./UnavailableDatePage";

// Sample event data
const events = {
  "2024-11-27": [
    {
      id: "101",
      title: "Wedding Ceremony",
      start: "1:00 PM",
      end: "3:00 PM",
      customer: "Rohan",
      phone: "9361233358",
      address: "Mumbai",
    },
    {
      id: "102",
      title: "Birthday Party",
      start: "6:00 PM",
      end: "8:00 PM",
      customer: "Anita",
      phone: "9123456789",
      address: "Delhi",
    },
  ],
  "2024-11-28": [
    {
      id: "201",
      title: "Conference",
      start: "10:00 AM",
      end: "12:00 PM",
      customer: "John",
      phone: "9876543210",
      address: "Chennai",
    },
  ],
};

const ScheduleAvailabilityManagement = () => {
  const today = new Date().toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(today || []);
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);
  const [eventsForDate, setEventsForDate] = useState(events[today] || []);
  const eventContainerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to 6 AM by default on initial render
    if (eventContainerRef.current) {
      const sixAMOffset = 6 * 60; // 6 hours * 60px per hour
      eventContainerRef.current.scrollTo({
        top: sixAMOffset,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    // Update events and reset index when the date changes
    setEventsForDate(events[selectedDate] || []);
    setSelectedEventIndex(0);
  }, [selectedDate]);

  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setSelectedDate(formattedDate);
  };

  const OnDateChange = (date) => {
    setSelectedDate(date);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    return formattedDate;
  }

  function formatDay(dateString) {
    const date = new Date(dateString);

    const dayOfWeek = date.toLocaleDateString("en-GB", {
      weekday: "short",
    });

    return dayOfWeek;
  }

  const handleNextEvent = () => {
    if (selectedEventIndex < eventsForDate.length - 1) {
      setSelectedEventIndex(selectedEventIndex + 1);
    }
  };

  const handlePreviousEvent = () => {
    if (selectedEventIndex > 0) {
      setSelectedEventIndex(selectedEventIndex - 1);
    }
  };

  const renderEventDetails = () => {
    const event = eventsForDate[selectedEventIndex];

    return (
      <Box
        sx={{
          background: "white",
          borderRadius: "0.8rem",
          height: "36vh",
          padding: "1.25rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ArrowBackIosIcon
            onClick={handlePreviousEvent}
            disabled={selectedEventIndex === 0}
            sx={{
              visibility: selectedEventIndex > 0 ? "visible" : "hidden",
              color: selectedEventIndex > 0 ? "black" : "transparent",
              cursor: selectedEventIndex > 0 ? "pointer" : "none",
              background: selectedEventIndex > 0 ? "aliceblue" : "none",
              marginLeft: selectedEventIndex > 0 ? "0.5rem" : "none",
            }}
          />

          <Box style={{ width: "85%" }}>
            <div className="event-details_content1">
              Details for today's booking (at 3:00pm)
            </div>
            <PledgeOthersCards
              content={
                <div className="schedule_container">
                  <div className="details_content">Customer Details:</div>
                  {[
                    { label: "User ID", value: "PR0001" },
                    { label: "User Name", value: "Rohan" },
                    { label: "Amount Paid", value: "₹ 684" },
                    { label: "Phone Number", value: "+91 9787868765" },
                    { label: "Mail ID", value: "rohan@gmail.com" },
                  ].map((detail, index) => (
                    <div key={index} className="details_text">
                      {detail.label} :{" "}
                      <span className="details_text">{detail.value}</span>
                    </div>
                  ))}

                  <div className="details_content">Delivery Address:</div>
                  {[
                    { label: "Neha", value: "9392463986" },
                    {
                      label: "",
                      value:
                        "A/9, Ashok Nagar, RC Marg, Vasi Naka, Chembur, Mumbai, Maharashtra, 400074",
                    },
                  ].map((address, index) => (
                    <div key={index} className="details_text">
                      {address.label && `${address.label} | `}
                      <span className="details_text">{address.value}</span>
                    </div>
                  ))}
                </div>
              }
              editButton={false}
              showView={false}
              borderColor="var(--primary-2)"
            />
          </Box>

          {eventsForDate.length !== 0 && eventsForDate !== undefined ? (
            <ArrowForwardIosIcon
              onClick={handleNextEvent}
              disabled={selectedEventIndex === eventsForDate.length - 1}
              sx={{
                visibility:
                  selectedEventIndex < eventsForDate.length - 1
                    ? "visible"
                    : "hidden",
                cursor:
                  selectedEventIndex < eventsForDate.length - 1
                    ? "pointer"
                    : "none",
                background:
                  selectedEventIndex < eventsForDate.length - 1
                    ? "aliceblue"
                    : "none",
                marginRight:
                  selectedEventIndex < eventsForDate.length - 1
                    ? "0.5rem"
                    : "none",
              }}
            />
          ) : null}
        </Box>
        {eventsForDate.length !== 0 ? (
          <div
            className="event-view-details-tag"
            onClick={() => navigate("/view-booking-details", { replace: true })}
          >
            View Details
          </div>
        ) : null}
      </Box>
    );
  };

  const nonSelectableDates = [
    "2024-11-13",
    "2024-11-01",
    "2024-11-08",
    "2024-11-23",
  ];

  const apiMessages = ["Holiday", "Maintenance"];
  const [openUnavailableDate, setOpenUnavailableDate] = useState(false);
  return (
    <div>
      <UnavailableDatePage
        openUnavailableDate={openUnavailableDate}
        onClose={() => setOpenUnavailableDate(false)}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="heading">Calendar</div>
        <AddButton
          name="Set Unavailable Date"
          onClick={() => setOpenUnavailableDate(true)}
        />
      </div>
      <Box className="schedule-dashboard_container">
        <Grid2 container spacing={2} padding={2}>
          <Grid2 size={{ xs: 7.5, md: 7.5 }}>
            <Paper
              elevation={3}
              sx={{ padding: 2, height: "78vh", borderRadius: "1.5rem" }}
            >
              <div className="calender_heading">
                <div className="day_calender">{formatDay(selectedDate)}</div>
                <div className="onboarding-sub_heading">
                  {formatDate(selectedDate)}
                </div>
              </div>

              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "70vh",
                  overflowY: "auto",
                  border: "1px solid #e0e0e0",
                  borderRadius: 2,
                  scrollBehavior: "smooth",
                  scrollbarWidth: "thin",
                }}
              >
                <Table stickyHeader aria-label="time table">
                  <TableBody>
                    {Array.from({ length: 24 }, (_, hour) => {
                      const timeLabel =
                        hour === 0
                          ? "12:00 AM"
                          : hour < 12
                            ? `${hour}:00 AM`
                            : hour === 12
                              ? "12:00 PM"
                              : `${hour - 12}:00 PM`;

                      const hasEvent = events[selectedDate]?.some(
                        (e) => parseInt(e.start.split(":")[0], 10) === hour,
                      );

                      return (
                        <TableRow
                          key={hour}
                          sx={{
                            height: 60,
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              width: "15%",
                              borderRight: "1px solid #e0e0e0",
                            }}
                          >
                            <Typography variant="body2">{timeLabel}</Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "85%",
                              padding: "0.5rem",
                              fontWeight: "500",
                              fontFamily: "var(--font-secondary)",
                              backgroundColor: hasEvent ? "#FFF1E6" : "none",
                              color: hasEvent ? "#FF6F01" : "#0D0C11",
                              borderLeft: hasEvent
                                ? "4px solid #FF6F01"
                                : "none",
                            }}
                          >
                            {hasEvent ? "Event" : ""}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid2>

          <Grid2 size={{ xs: 4.5, md: 4.5 }} container spacing={2}>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <CustomStaticDatePicker
                OnDateChange={OnDateChange}
                nonSelectableDates={nonSelectableDates}
                selectedDate={selectedDate}
                apiMessages={apiMessages}
                datePickerTitle="Select Date to Upload"
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>{renderEventDetails()}</Grid2>
          </Grid2>
        </Grid2>
      </Box>
    </div>
  );
};

export default ScheduleAvailabilityManagement;
