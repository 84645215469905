import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PropTypes from "prop-types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";

const CustomDatePicker = ({
  value,
  onChange,
  placeholder,
  label,
  isDob,
  disabledDate,
}) => {
  const handleDateChange = (newValue) => {
    // Ensure newValue is converted to the expected format or dayjs object before passing up
    onChange(newValue ? dayjs(newValue).format("YYYY-MM-DD") : "");
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {label && (
        <Typography color={"#9CA3AF"} sx={{ mb: "8px" }}>
          {label}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          defaultValue={value ? dayjs(value) : null}
          minDate={disabledDate ? disabledDate : null}
          // value={value}
          sx={{
            "& .MuiButtonBase-root": { color: "#05efb8" },
            "& .css-lxfshk-MuiInputBase-root-MuiOutlinedInput-root": {
              height: "3.2rem !important",
            },
          }}
          onChange={(newValue) => handleDateChange(newValue)}
        />
      </LocalizationProvider>
    </Box>
  );
};

CustomDatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isDob: PropTypes.bool,
};

export default CustomDatePicker;
