// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancel_save {
  display: flex;
  gap: 1rem;
  float: right;
}

.puja_sub_heading {
  font-family: var(--font-secondary);
  font-weight: 600;
  font-size: 0.9rem;
  color: #1d1d27;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.checkbox_card {
  background: #ffffff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.checkbox {
  padding: 0 1rem;
}

.checkbox_tags_card {
  display: flex;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/purohit/puja-offering-onboarding.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,YAAY;AACd;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".cancel_save {\n  display: flex;\n  gap: 1rem;\n  float: right;\n}\n\n.puja_sub_heading {\n  font-family: var(--font-secondary);\n  font-weight: 600;\n  font-size: 0.9rem;\n  color: #1d1d27;\n  margin-top: 1rem;\n  margin-bottom: 0.5rem;\n}\n\n.checkbox_card {\n  background: #ffffff;\n  border-radius: 0.5rem;\n  padding: 0.5rem;\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n}\n\n.checkbox {\n  padding: 0 1rem;\n}\n\n.checkbox_tags_card {\n  display: flex;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
