import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import {
  thoughtCreation,
  thoughtCreationSuccess,
  thoughtCreationFailure,
  fetchThought,
  fetchThoughtSuccess,
  fetchThoughtFailure,
  thoughtUpdation,
  thoughtUpdationSuccess,
  thoughtUpdationFailure,
  thoughtDeletion,
  thoughtDeletionSuccess,
  thoughtDeletionFailure,
} from "../slices/thoughtSlice";
import {
  deleteRequest,
  getRequest,
  postRequestUsingFormData,
  postRequestUsingPayload,
} from "../../api/apiActions";
import {
  createThoughtUrl,
  getThoughtsByStatusUrl,
  updateThoughtUrl,
  deleteThoughtUrl,
} from "../../api/apiUrls";

const createThought = (payload) => {
  return postRequestUsingFormData(createThoughtUrl, payload);
};

const getThought = (params) => {
  getRequest(`${getThoughtsByStatusUrl}${params}`);
};

const removeThought = (id) => {
  deleteRequest(`deleteThoughtUrl/${id}`);
};

function* addThought(action) {
  try {
    const result = yield call(createThought, action.payload);

    if (result.status === 200) {
      yield put(thoughtCreationSuccess(result?.data));
    } else {
      yield put(thoughtCreationFailure());
    }
  } catch (error) {
    yield put(thoughtCreationFailure(error));
  }
}

function* getThoughts(action) {
  try {
    const result = yield call(getThought, action.payload);

    if (result?.status === 200) {
      yield put(thoughtCreationSuccess(result?.data));
    } else {
      yield put(thoughtCreationFailure());
    }
  } catch (error) {
    yield put(thoughtCreationFailure(error));
  }
}

function* updateThought() {
  try {
  } catch (error) {
    yield put(thoughtUpdationFailure(error));
  }
}

function* deleteThought(action) {
  try {
    const result = yield put(removeThought, action.params);

    if (result.status === 200) {
      yield put(thoughtDeletionSuccess(result?.data));
    } else {
      yield put(thoughtDeletionFailure());
    }
  } catch (error) {
    yield put(thoughtDeletionFailure(error));
  }
}

export function* thoughtSaga() {
  yield takeEvery(thoughtCreation.type, addThought);
  yield takeEvery(fetchThought.type, getThoughts);
  yield takeEvery(thoughtDeletion.type, deleteThought);
}

export default thoughtSaga;
