import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid2,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";

import "../../styles/onboardingForm.css";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { AddButton } from "../../components/Addbutton";
import "../../styles/ModalDatePicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createSeva,
  getSevaById,
  updateSeva,
  toggleStatus,
  DeleteImageRequest,
} from "../../features/slices/sevaSlice";
import { CustomLoader } from "../../components/CustomLoader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

const initialFormState = {
  name: "",
  price: "",
  meaning: "",
  benefits: "",
  maxParticipant: "",
  online: "",
  days: [],
  startAt: dayjs().format("hh:mm A"),
  endAt: dayjs().format("hh:mm A"),
  prasadam: null,
  videoProvided: null,
  prasadamDetails: "",
  poojaSamagri: "",
  photosUrl: [],
  videoUrl: [],
  thumbnailUrl: [],
};

const initialErrorState = {
  name: "",
  price: "",
  meaning: "",
  benefits: "",
  maxParticipant: "",
  online: "",
  days: [],
  startAt: "",
  endAt: "",
  prasadam: null,
  videoProvided: null,
  prasadamDetails: "",
  poojaSamagri: "",
  photosUrl: [],
  videoUrl: [],
  thumbnailUrl: [],
};

const AddSeva = () => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SevaData = useSelector((state) => state.seva.seva);
  const { createStatus, loading, updateStatus, imageDeleteStatus } =
    useSelector((state) => state.seva);
  const fileInputRef = useRef(null);

  const params = useParams();

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const [selectedDays, setSelectedDays] = React.useState([]);

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDays(typeof value === "string" ? value.split(",") : value);
  };

  const handleRemoveSelectedItem1 = (index) => {
    const updatedDays = selectedDays.filter((_, i) => i !== index);
    setSelectedDays(updatedDays);
  };

  const handleSubmit = () => {
    const newErrors = { ...initialErrorState };
    let isValid = true;

    formData.days = selectedDays;

    if (!formData.name) {
      newErrors.name = "Seva Name is required";
      isValid = false;
    }
    if (!formData.price) {
      newErrors.price = "Seva Price is required";
      isValid = false;
    }
    if (!formData.meaning) {
      newErrors.meaning = "Purpose & Meaning of Seva is required";
      isValid = false;
    }
    if (!formData.benefits) {
      newErrors.benefits = "Seva Benifit is required";
      isValid = false;
    }
    if (!formData.maxParticipant) {
      newErrors.maxParticipant = "Max. Participants Count is required";
      isValid = false;
    }
    if (!formData.online) {
      newErrors.online = "Seva Availability is required";
      isValid = false;
    }

    if (selectedDays.length === 0) {
      newErrors.days = "Day is required";
      isValid = false;
    }
    if (!formData.prasadam) {
      newErrors.prasadam = "Will prasadam be provided is required";
      isValid = false;
    }
    if (!formData.videoProvided) {
      newErrors.videoProvided = "Recorded Video is required";
      isValid = false;
    }

    if (formData.photosUrl.length === 0) {
      newErrors.photosUrl = "Seva Image is required";
      isValid = false;
    }
    if (formData.thumbnailUrl.length === 0) {
      newErrors.thumbnailUrl = "Thumbnail Image is required";
      isValid = false;
    }
    if (formData.videoUrl.length === 0) {
      newErrors.videoUrl = "Seva Video is required";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      let formArr = new FormData();

      const appendFilesAsArray = (key, files) => {
        if (files.length > 0) {
          files.forEach((file) => {
            formArr.append(key, file);
          });
        }
      };

      if (formData?.photosUrl.length > 0) {
        const filteredPhotos = formData?.photosUrl.filter(
          (photo) => !photo.etag,
        );
        appendFilesAsArray("photos", mapLocalImage(filteredPhotos));
      }

      if (!formData?.thumbnailUrl[0]?.etag) {
        formArr.append("thumbnail", formData?.thumbnailUrl[0]?.file);
      }
      if (!formData?.videoUrl[0]?.etag) {
        formArr.append("video", formData?.videoUrl[0]?.file);
      }

      formArr.append("benefits", formData?.benefits);
      formArr.append("endAt", formData?.endAt);
      formArr.append("maxParticipant", formData?.maxParticipant);
      formArr.append("meaning", formData?.meaning);
      formArr.append("name", formData?.name);
      formArr.append("online", formData.online === "Online" ? true : false);

      formArr.append("poojaSamagri", formData?.poojaSamagri);
      formArr.append("prasadam", formData?.prasadam);
      formArr.append("prasadamDetails", formData?.prasadamDetails);
      formArr.append("price", formData?.price);

      formArr.append("startAt", formData?.startAt);
      if (formData?.videoProvided) {
        formArr.append("videoProvided", true);
      } else {
        formArr.append("videoProvided", false);
      }

      formArr.append("days", formData?.days.join(","));

      if (params.actionType === "Add") {
        dispatch(createSeva(formArr));
      } else if (params.actionType === "Edit") {
        dispatch(updateSeva({ formArr, id: params.id, navigate }));
      }
    }
  };

  const MAX_IMAGE_SIZE = 20 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

  const handleUpload = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;
    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (selectedFiles?.length + (formData[field]?.length || 0) > MAX_FILES) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type === "video/mp4";
      const isValidImageFormat = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ].includes(file.type);
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (
        (uploadType === "image/*" && (!isImage || !isValidImageFormat)) ||
        (uploadType === "video/mp4" && !isVideo)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Invalid file type. Please upload ${
            uploadType === "image/*" ? "JPEG, JPG, or PNG images" : "MP4 videos"
          }.`,
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "20MB" : "50MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeUpload = (field, index, item) => {
    if (item.etag && field === "photosUrl") {
      dispatch(DeleteImageRequest({ etag: item.etag, id: params.id }));
    }
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  useEffect(() => {
    if (createStatus || updateStatus) {
      navigate("/seva-management");
      dispatch(toggleStatus());
    }
  }, [createStatus, updateStatus]);
  useEffect(() => {
    if (params.actionType === "Edit") {
      dispatch(getSevaById(params.id));
    }
  }, [params, dispatch]);

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };

  useEffect(() => {
    if (params.actionType === "Edit" && SevaData) {
      setSelectedDays(SevaData?.days ? SevaData?.days : []);
      setFormData({
        ...formData,
        benefits: SevaData?.benefits ? SevaData?.benefits : "",
        endAt: SevaData?.endAt ? SevaData?.endAt : null,
        maxParticipant: SevaData?.maxParticipant
          ? SevaData?.maxParticipant
          : "",

        meaning: SevaData?.meaning ? SevaData?.meaning : "",

        days: SevaData?.days ? SevaData?.days : [],
        name: SevaData?.name ? SevaData?.name : "",
        online: SevaData?.online ? "Online" : "Offline",
        poojaSamagri: SevaData?.poojaSamagri ? SevaData?.poojaSamagri : "",
        prasadam: SevaData?.prasadam ? "Yes" : "No",
        prasadamDetails: SevaData?.prasadamDetails
          ? SevaData?.prasadamDetails
          : "",

        price: SevaData?.price ? SevaData?.price : "",

        startAt: SevaData?.startAt ? SevaData?.startAt : null,
        videoProvided: SevaData?.videoProvided ? "Available" : "Not Available",

        photosUrl:
          SevaData?.photosUrl?.length > 0 ? mapImages(SevaData?.photosUrl) : [],
        videoUrl: SevaData?.videoUrl ? mapImages([SevaData?.videoUrl]) : [],
        thumbnailUrl: SevaData?.thumbnailUrl
          ? mapImages([SevaData?.thumbnailUrl])
          : [],
      });
    }
  }, [SevaData]);

  const mapLocalImage = (images) => {
    if (images.length > 0) {
      let NewArr = images.map((item) => {
        return item?.file;
      });

      return NewArr;
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (field, e, maxFiles, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    handleUpload(field, e, maxFiles, uploadType);
  };

  return (
    <>
      <CustomLoader
        loading={loading}
        message="Please wait, We are updating Seva..."
      />
      <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <ArrowBackIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(`/seva-management`)}
        />
        <Box
          className={"heading"}
          sx={{
            textWrap: "nowrap",
            paddingY: "10px",
          }}
        >
          {params.actionType === "Add" ? "Add Seva" : "Edit Seva"}
        </Box>
      </span>
      <Box className="sevaForm">
        <div className="onboarding-sub_heading">Basic Details</div>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Seva Name*
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={handleChange("name")}
              placeholder="Enter Seva Name"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.name && (
              <Typography color="error">{errors.name}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Seva Price*
            </Typography>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              value={formData.price}
              onChange={handleChange("price")}
              placeholder="Enter Seva Price"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.price && (
              <Typography color="error">{errors.price}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Purpose & Meaning of Seva*
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={formData.meaning}
              onChange={handleChange("meaning")}
              placeholder="Elaborate the Purpose & Meaning of Seva"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.meaning && (
              <Typography color="error">{errors.meaning}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Benefits of Seva*
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={formData.benefits}
              onChange={handleChange("benefits")}
              placeholder="Elaborate the benefits of seva"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.benefits && (
              <Typography color="error">{errors.benefits}</Typography>
            )}
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">Participation Information</div>

        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Max. Participants Count*
            </Typography>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              value={formData.maxParticipant}
              onChange={handleChange("maxParticipant")}
              placeholder="Participant count"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.maxParticipant && (
              <Typography color="error">{errors.maxParticipant}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Seva Availability*
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-select"
            >
              <Select
                name="online"
                value={formData.online}
                onChange={handleChange("online")}
                displayEmpty
                renderValue={(value) => {
                  return value === "" ? (
                    <span style={{ color: "gray" }}>Select</span>
                  ) : (
                    value
                  );
                }}
              >
                <MenuItem value={"Online"}>Online</MenuItem>
                <MenuItem value={"Offline"}>Offline</MenuItem>
              </Select>
              {errors.online && (
                <Typography color="error">{errors.online}</Typography>
              )}
            </FormControl>
          </Grid2>
        </Grid2>
        <div className="onboarding-sub_heading">Schedule Information</div>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <div>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Typography className="onboarding-form_Heading">
                  Days*
                </Typography>
                <Select
                  name="Days"
                  multiple
                  value={selectedDays}
                  onChange={handleChange1}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    return selected.length > 0
                      ? selected.join(", ")
                      : "Select Days";
                  }}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={selectedDays.includes(name)} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div>
                {selectedDays.map((day, index) => (
                  <Chip
                    key={day}
                    label={day}
                    onDelete={() => handleRemoveSelectedItem1(index)}
                    sx={{
                      margin: 0.5,
                      "& .MuiSvgIcon-root": {
                        fill: "black !important",
                      },
                    }}
                  />
                ))}
              </div>
              {errors.days && (
                <Typography color="error">{errors.days}</Typography>
              )}
            </div>
          </Grid2>

          <Grid2 size={{ xs: 6, md: 6 }} spacing={2} container>
            <Grid2 size={{ xs: 4, md: 4 }}>
              <Typography className="onboarding-form_Heading">
                Start Time*
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={
                    formData.startAt ? dayjs(formData.startAt, "hh:mm A") : null
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      setFormData({
                        ...formData,
                        startAt: newValue.format("hh:mm A"),
                      });
                    }
                  }}
                />
              </LocalizationProvider>
              {errors.startAt && (
                <Typography color="error">{errors.startAt}</Typography>
              )}
            </Grid2>
            <Grid2 size={{ xs: 4, md: 4 }}>
              <Typography className="onboarding-form_Heading">
                End Time*
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={
                    formData.endAt ? dayjs(formData.endAt, "hh:mm A") : null
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      setFormData({
                        ...formData,
                        endAt: newValue.format("hh:mm A"),
                      });
                    }
                  }}
                />
              </LocalizationProvider>
              {errors.endAt && (
                <Typography color="error">{errors.endAt}</Typography>
              )}
            </Grid2>
          </Grid2>
        </Grid2>
        <div className="onboarding-sub_heading">Resources Provided</div>
        <Grid2 container spacing={2} mt={1}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Will prasadam be provided*
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-select"
            >
              <Select
                name="prasadam"
                value={formData.prasadam}
                onChange={handleChange("prasadam")}
                displayEmpty
                renderValue={(value) =>
                  value === "" ? (
                    <span style={{ color: "gray" }}>Select</span>
                  ) : (
                    value
                  )
                }
              >
                <MenuItem value={"Yes"}>Yes</MenuItem>
                <MenuItem value={"No"}>No</MenuItem>
              </Select>
              {errors.prasadam && (
                <Typography color="error">{errors.prasadam}</Typography>
              )}
            </FormControl>
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Recorded Video*
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-select"
            >
              <Select
                name="videoProvided"
                value={formData.videoProvided}
                onChange={handleChange("videoProvided")}
                displayEmpty
                renderValue={(value) =>
                  value === "" ? (
                    <span style={{ color: "gray" }}>Select</span>
                  ) : (
                    value
                  )
                }
              >
                <MenuItem value={"Available"}>Available</MenuItem>
                <MenuItem value={"Not Available"}>Not Available</MenuItem>
              </Select>
              {errors.videoProvided && (
                <Typography color="error">{errors.videoProvided}</Typography>
              )}
            </FormControl>
          </Grid2>
        </Grid2>

        <div className="onboarding-sub_heading">Additional Information</div>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              What would the devotees receive?
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={formData.prasadamDetails}
              onChange={handleChange("prasadamDetails")}
              placeholder="Elaborate on what the devotee would receive upon the completion of the seva(such as prasadam, videos, and other offerings)"
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.prasadamDetails && (
              <Typography color="error">{errors.prasadamDetails}</Typography>
            )}
          </Grid2>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Puja Samagri
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={formData.poojaSamagri}
              onChange={handleChange("poojaSamagri")}
              placeholder="Elaborate on pooja samagri that would be required for performing seva."
              style={{ marginBottom: "1rem" }}
              className="onboarding-custom-textfield"
              InputProps={{
                style: { color: "#8d8e9f" },
              }}
            />
            {errors.poojaSamagri && (
              <Typography color="error">{errors.poojaSamagri}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, md: 6 }} spacing={2}>
            <Typography className="onboarding-form_Heading">
              Seva Images*
            </Typography>
            <Box
              className="upload-box_charity "
              onDrop={(e) => handleDrop("photosUrl", e, 5, "image/*")}
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
            >
              {formData?.photosUrl?.length > 0 ? (
                <Box className="preview-image-container preview_images">
                  {formData?.photosUrl?.map((item, index) => (
                    <Box className="song-image-container">
                      <Close
                        className="song-image-close-icon"
                        onClick={() => removeUpload("photosUrl", index, item)}
                      />
                      <img src={item?.url} alt="img" className="song-image" />
                    </Box>
                  ))}
                </Box>
              ) : null}
              <Typography className="upload-instructions">
                <img
                  style={{ marginBottom: "-0.25rem" }}
                  src="/upload-file-icon.svg"
                  alt="img"
                />
                Drag and Drop your file
              </Typography>
              <Typography className="onboarding-upload-instructions ">
                OR
              </Typography>
              <input
                ref={fileInputRef}
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                id="thumbnailUrl-upload-2"
                onChange={(e) => handleUpload("photosUrl", e, 5, "image/*")}
                multiple={true}
              />
              <label htmlFor="thumbnailUrl-upload-2">
                <Button
                  variant="outlined"
                  component="span"
                  className="browse-button"
                >
                  Browse file
                </Button>
              </label>
              <Typography className="upload-instructions ">
                Max.5 Images, 20MB Each, JPEG/PNG
              </Typography>
            </Box>
            {errors.photosUrl && (
              <Typography color="error">{errors.photosUrl}</Typography>
            )}
          </Grid2>

          <Grid2 size={{ xs: 6, md: 6 }} spacing={2}>
            <Typography className="onboarding-form_Heading">
              Seva Video Thumbnail*
            </Typography>
            <Box
              className="upload-box_charity "
              onDrop={(e) => handleDrop("thumbnailUrl", e, 1, "image/*")}
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
            >
              {formData.thumbnailUrl?.length > 0 ? (
                <Box className="preview-image-container">
                  {formData.thumbnailUrl?.map((item, index) => (
                    <Box className="song-image-container">
                      <Close
                        className="song-image-close-icon"
                        onClick={() =>
                          removeUpload("thumbnailUrl", index, item)
                        }
                      />

                      <img src={item?.url} className="song-image" alt="img" />
                    </Box>
                  ))}
                </Box>
              ) : null}
              <Typography className="upload-instructions">
                <img
                  style={{ marginBottom: "-0.25rem" }}
                  src="/upload-file-icon.svg"
                  alt="img"
                />
                Drag and Drop your file
              </Typography>
              <Typography className="onboarding-upload-instructions ">
                OR
              </Typography>
              <input
                ref={fileInputRef}
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                id="thumbnailUrl-upload-VidThumb"
                onChange={(e) => handleUpload("thumbnailUrl", e, 1, "image/*")}
                multiple={false}
              />
              <label htmlFor="thumbnailUrl-upload-VidThumb">
                <Button
                  variant="outlined"
                  component="span"
                  className="browse-button"
                >
                  Browse file
                </Button>
              </label>
              <Typography className="upload-instructions ">
                Max.1 Images, 20MB Each, JPEG/PNG
              </Typography>
            </Box>
            {errors.thumbnailUrl && (
              <Typography color="error">{errors.thumbnailUrl}</Typography>
            )}
          </Grid2>
        </Grid2>
        <Grid2 spacing={2} container>
          <Grid2 size={{ xs: 6, md: 6 }}>
            <Typography className="onboarding-form_Heading">
              Seva Video*
            </Typography>
            <Box
              className="upload-box_charity "
              onDrop={(e) => handleDrop("videoUrl", e, 1, "video/mp4")}
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
            >
              {formData.videoUrl.length > 0 ? (
                <Box className="preview-image-container">
                  {formData.videoUrl.map((item, index) => (
                    <Box className="song-image-container">
                      <Close
                        className="song-image-close-icon"
                        onClick={() => removeUpload("videoUrl", index, item)}
                      />

                      <img
                        src="/mp4Icon.svg"
                        className="song-image"
                        alt="img"
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              <Typography className="upload-instructions">
                <img
                  style={{ marginBottom: "-0.25rem" }}
                  src="/upload-file-icon.svg"
                  alt="img"
                />
                Drag and Drop your file
              </Typography>
              <Typography className="onboarding-upload-instructions ">
                OR
              </Typography>
              <input
                ref={fileInputRef}
                accept="video/mp4"
                type="file"
                style={{ display: "none" }}
                id="thumbnailUrl-upload-video"
                onChange={(e) => handleUpload("videoUrl", e, 1, "video/mp4")}
                multiple={false}
              />
              <label htmlFor="thumbnailUrl-upload-video">
                <Button
                  variant="outlined"
                  component="span"
                  className="browse-button"
                >
                  Browse file
                </Button>
              </label>
              <Typography className="upload-instructions ">
                Max.1 Video, 50MB Each, MP4
              </Typography>
            </Box>
            {errors?.videoUrl && (
              <Typography color="error">{errors?.videoUrl}</Typography>
            )}
          </Grid2>
        </Grid2>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <AddButton
          name={params.actionType === "Add" ? "Add" : "Update"}
          onClick={handleSubmit}
          background="#A9392D"
          color="#FFFFFF"
          width="auto"
          sx={{
            marginRight: "1rem",
            // border: "1px solid #ACAEC3",
          }}
        />
      </Box>
    </>
  );
};

export default AddSeva;
