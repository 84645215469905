import React, { useState, useEffect } from "react";
import Tabs from "../components/common/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../styles/ProductManagement.css";
import StatsCard from "../components/cards/StatsCard";
import OrderDashboard from "./Order/PrasadamOrders";
import OrderList from "./Order/SevaOrders";
import { Box } from "@mui/material";

const tabItems = [
  { label: "Prasadam Orders", value: "Prasadam_Orders" },
  { label: "Seva Orders", value: "Seva_Orders" },
];

const OrderManagement = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { actionType } = useParams();
  const [selectedMenu, setSelectedMenu] = useState(() => {
    let pathName = location.pathname.split("/");
    return pathName[2] ? pathName[2] : "Prasadam_Orders";
  });
  const [fileOption, setFileOption] = React.useState("");

  const handleChange = (event) => {
    setFileOption(event.target.value);
  };

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
    navigate(`/order-management/${menu}`);
  };

  // useEffect(() => {
  //   navigate(selectedMenu)
  // }, [])

  useEffect(() => {
    let pathName = location.pathname.split("/");

    const filteredData = tabItems.filter((item) =>
      pathName.includes(item.value),
    );

    if (filteredData.length > 0 && !selectedMenu) {
      setSelectedMenu(filteredData[0].value);
      navigate(
        `/order-management${filteredData[0].value ? `/${filteredData[0].value}` : ""}${actionType ? `/${actionType}` : ""}`,
      );
    }
    if (filteredData.length === 0 && !selectedMenu) {
      setSelectedMenu("Prasadam_Orders");
      navigate(`/order-management/Prasadam_Orders`);
    }
  }, [pathName]);

  return (
    <>
      <Box
        className={"heading"}
        sx={{
          textWrap: "nowrap",
          paddingY: "10px",
        }}
      >
        Order Management
      </Box>
      <>
        <div className="product2-stats-container ">
          <StatsCard
            label={"Total Orders"}
            stat={"0"}
            image="/dashboard-icons/totalOrders.svg"
            // notification="+30"
          />
          <StatsCard
            label={"Pending Orders"}
            stat={"0"}
            image="/dashboard-icons/totalOrders.svg"
          />
          <StatsCard
            label={"Completed Orders"}
            stat={"0"}
            image="/dashboard-icons/totalOrdersCompleted.svg"
            // notification="0"
          />
        </div>
        <div style={{ marginTop: "1rem" }}>
          <Tabs
            tabs={tabItems}
            tabStart={selectedMenu}
            onClick={handleMenuButtonClick}
          />
        </div>
        {selectedMenu === "Prasadam_Orders" ? (
          <OrderDashboard />
        ) : selectedMenu === "Seva_Orders" ? (
          <OrderList />
        ) : null}
      </>
    </>
  );
});

export default OrderManagement;
