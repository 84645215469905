// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiAccordion-root {
  border-radius: 8px;
  margin-bottom: 10px;
}

.MuiAccordionSummary-root {
  background-color: #fff;
  padding: 8px 16px;
}

.MuiAccordionSummary-root.Mui-expanded {
  background-color: #f0f0f0;
}

.MuiAccordionDetails-root {
  padding: 8px 16px;
  background-color: #f9f9f9;
}

/* .MuiListItem-root:hover {
  background-color: #e0e0e0;
} */
.MuiAccordion-root {
  border-radius: 8px;
  margin-bottom: 10px;
}

.MuiAccordionSummary-root {
  background-color: #fff;
  padding: 8px 16px;
}

.MuiAccordionSummary-root.Mui-expanded {
  background-color: #f0f0f0;
}

.MuiAccordionDetails-root {
  padding: 8px 16px;
  background-color: #f9f9f9;
}

/* .MuiListItem-root:hover {
  background-color: #e0e0e0;
} */
`, "",{"version":3,"sources":["webpack://./src/styles/Onboard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;;GAEG;AACH;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;;GAEG","sourcesContent":[".MuiAccordion-root {\n  border-radius: 8px;\n  margin-bottom: 10px;\n}\n\n.MuiAccordionSummary-root {\n  background-color: #fff;\n  padding: 8px 16px;\n}\n\n.MuiAccordionSummary-root.Mui-expanded {\n  background-color: #f0f0f0;\n}\n\n.MuiAccordionDetails-root {\n  padding: 8px 16px;\n  background-color: #f9f9f9;\n}\n\n/* .MuiListItem-root:hover {\n  background-color: #e0e0e0;\n} */\n.MuiAccordion-root {\n  border-radius: 8px;\n  margin-bottom: 10px;\n}\n\n.MuiAccordionSummary-root {\n  background-color: #fff;\n  padding: 8px 16px;\n}\n\n.MuiAccordionSummary-root.Mui-expanded {\n  background-color: #f0f0f0;\n}\n\n.MuiAccordionDetails-root {\n  padding: 8px 16px;\n  background-color: #f9f9f9;\n}\n\n/* .MuiListItem-root:hover {\n  background-color: #e0e0e0;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
