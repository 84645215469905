import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchPrasadamRequest,
  fetchPrasadamSuccess,
  fetchPrasadamFailure,
  fetchPrasadamUploadRequest,
  fetchPrasadamUploadSuccess,
  fetchPrasadamUploadFailure,
  fetchPrasadamUpdateRequest,
  fetchPrasadamUpdateSuccess,
  fetchPrasadamUpdateFailure,
  fetchPrasadamUpdateByStatusRequest,
  fetchPrasadamUpdateByStatusSuccess,
  fetchPrasadamUpdateByStatusFailure,
  fetchPrasadamGetByIdRequest,
  fetchPrasadamGetByIdSuccess,
  fetchPrasadamGetByIdFailure,
} from "../slices/prasadamSlice";
import {
  deleteRequest,
  getRequest,
  postRequest4,
  putRequest,
  postRequestUsingFormData,
} from "../../api/apiActions";

import {
  prasadamPostUrls,
  prasadamGetAllUrls,
  prasadamGetByIdUrls,
  prasadamUpdateUrls,
  prasadamUpdateByStatusUrls,
} from "../../api/apiUrls";
import { openSnackbar } from "../slices/snackbarSlice";

const getCall = (payload) => getRequest(prasadamGetAllUrls + payload);

const updateCall = (payload) =>
  putRequest(`${prasadamUpdateUrls}/${payload.prasadamId}`, payload.formData);

const createCall = (payload) =>
  postRequestUsingFormData(prasadamPostUrls, payload);

const activeCall = (payload) =>
  putRequest(prasadamUpdateByStatusUrls + `/${payload}`);
const getByIDCall = (payload) =>
  getRequest(prasadamGetByIdUrls + `/${payload}`);

function* fetchPrasadamsAll(action) {
  try {
    const response = yield call(getCall, action.payload);

    if (response.status === 200) {
      let res = response?.data?.content;
      let page = response?.data?.totalPages;
      yield put(fetchPrasadamSuccess({ res, page }));
    } else {
      yield put(fetchPrasadamFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchPrasadamFailure(error?.response?.data));
  }
}

function* fetchPrasadamsById(action) {
  try {
    const response = yield call(getByIDCall, action.payload);
    if (response.status === 200) {
      yield put(fetchPrasadamGetByIdSuccess(response?.data));
    } else {
      yield put(fetchPrasadamGetByIdFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchPrasadamGetByIdFailure(error?.response?.data));
  }
}
function* postPrasadam(action) {
  try {
    const response = yield call(createCall, action.payload);
    if (response.status === 200 || response.status === 201) {
      yield put(fetchPrasadamUploadSuccess(response?.data));
      yield put(
        openSnackbar({
          message: "Prasadam Added Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(fetchPrasadamUploadFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchPrasadamUploadFailure(error?.response?.data));
  }
}
function* updatePrasadam(action) {
  try {
    const response = yield call(updateCall, action.payload);
    if (response.status === 200 || response.status === 201) {
      yield put(fetchPrasadamUpdateSuccess(response?.data));
      yield put(
        openSnackbar({
          message: "Prasadam Update Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(fetchPrasadamUpdateFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchPrasadamUpdateFailure(error?.response?.data));
  }
}

function* updateByStatusPrasadam(action) {
  try {
    const response = yield call(activeCall, action.payload);
    if (response.status === 200) {
      yield put(fetchPrasadamUpdateByStatusSuccess(response?.data));
      yield put(
        openSnackbar({
          message: "Status Update Successfully",
          severity: "success",
        }),
      );
    } else {
      yield put(fetchPrasadamUpdateByStatusFailure(response?.response?.data));
    }
  } catch (error) {
    yield put(fetchPrasadamUpdateByStatusFailure(error?.response?.data));
  }
}

export function* prasadamSaga() {
  yield takeLatest(fetchPrasadamRequest.type, fetchPrasadamsAll);
  yield takeLatest(fetchPrasadamGetByIdRequest.type, fetchPrasadamsById);
  yield takeLatest(fetchPrasadamUploadRequest.type, postPrasadam);
  yield takeLatest(fetchPrasadamUpdateRequest.type, updatePrasadam);
  yield takeLatest(
    fetchPrasadamUpdateByStatusRequest.type,
    updateByStatusPrasadam,
  );
}
