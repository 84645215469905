import React from "react";

import docVerifyAnimation from "../../components/DocVerify.json";
import LottieWithoutLibrary from "../../components/LottieWithoutLibrary";

const DocVerificationPage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <h2 style={styles.heading}>
          Your document verification is currently being processed.
        </h2>
        <p style={styles.subheading}>
          Estimated Time for Approval: 1-2 Business Days
          <br />
          Please note that this is an estimate and may vary depending on the
          volume of requests.
        </p>
      </div>

      <LottieWithoutLibrary animationData={docVerifyAnimation} />

      <p style={styles.infoText}>
        Once your documents have been verified, you will receive a welcome SMS
        with further instructions on how to proceed.
        <br />
        Check your email and SMS for updates on your verification status.
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    height: "70vh",
    justifyContent: "center",
    padding: "20px",
    boxSizing: "border-box",
  },
  textContainer: {
    marginBottom: "20px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#000",
    margin: "0",
  },
  subheading: {
    fontSize: "16px",
    color: "#555",
    margin: "10px 0 20px 0",
  },
  animation: {
    width: "300px",
    height: "300px",
    margin: "20px 0",
  },
  infoText: {
    fontSize: "14px",
    color: "#555",
    maxWidth: "600px",
  },
};

export default DocVerificationPage;
