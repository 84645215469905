import React from "react";
import ErrorIcon from "@mui/icons-material/ErrorOutline"; // Optional: replace with custom SVG if needed

const IncompleteSetup = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Profile Setup Incomplete</h2>
      <p style={styles.subheading}>
        Your profile setup form could not be verified due to an issue with the
        details submitted in the Profile Setup section. Please review your
        information in the following categories and correct any discrepancies:
      </p>

      <div style={styles.listContainer}>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <strong>1. Basic Temple Details</strong>
            <ul style={styles.subList}>
              <li style={styles.errorText}>Contact Information</li>
            </ul>
          </li>
          <li style={styles.listItem}>
            <strong>2. Temple Verification & Documentation</strong>
            <ul style={styles.subList}>
              <li style={styles.errorText}>Financial Details</li>
            </ul>
          </li>
        </ul>
      </div>

      <div style={styles.iconContainer}>
        <ErrorIcon style={styles.icon} fontSize="large" />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    height: "60vh",
    justifyContent: "center",
    padding: "20px",
    boxSizing: "border-box",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#000",
    margin: "0 0 20px 0",
  },
  subheading: {
    fontSize: "16px",
    color: "#555",
    maxWidth: "600px",
    marginBottom: "20px",
  },
  listContainer: {
    textAlign: "left",
    marginBottom: "20px",
  },
  list: {
    listStyleType: "none",
    padding: 0,
  },
  listItem: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#000",
  },
  subList: {
    listStyleType: "none",
    paddingLeft: "20px",
  },
  errorText: {
    fontSize: "16px",
    color: "red",
  },
  iconContainer: {
    marginTop: "20px",
  },
  icon: {
    color: "#f44336", // Customize the color if needed
    fontSize: "64px", // Adjust the size as per design
  },
};

export default IncompleteSetup;
