import { createSlice } from "@reduxjs/toolkit";

const donationSlice = createSlice({
  name: "donation",
  initialState: {
    loading: false,
    donationData: [],
    error: "",
    donationDataById: [],
    Totalpage: "",
    donationUploadStatus: false,
    donationUpdateStatus: false,
    donationActiveStatus: false,
    donationGetAllStatus: false,
    donationGetByIdStatus: false,
  },
  reducers: {
    fetchDonationRequest: (state) => {
      state.loading = true;
      state.donationGetAllStatus = false;
      state.donationData = [];
      state.error = "";
      state.Totalpage = "";
    },
    fetchDonationSuccess: (state, action) => {
      const { res, page } = action.payload;
      state.loading = false;
      state.donationGetAllStatus = true;
      state.donationData = res;
      state.error = "";
      state.Totalpage = page;
    },
    fetchDonationFailure: (state, action) => {
      state.loading = false;
      state.donationGetAllStatus = false;
      state.donationData = [];
      state.error = action.payload;
      state.Totalpage = "";
    },
    fetchDonationUploadRequest: (state) => {
      state.donationUploadStatus = false;
      state.loading = true;
      state.error = "";
    },
    fetchDonationUploadSuccess: (state, action) => {
      state.donationUploadStatus = true;
      state.loading = false;

      state.error = "";
    },
    fetchDonationUploadFailure: (state, action) => {
      state.donationUploadStatus = false;
      state.loading = false;
      state.error = action.payload;
    },

    fetchDonationUpdateRequest: (state) => {
      state.donationUpdateStatus = false;
      state.loading = true;

      state.error = "";
    },
    fetchDonationUpdateSuccess: (state, action) => {
      state.donationUpdateStatus = true;
      state.loading = false;
      state.error = "";
    },
    fetchDonationUpdateFailure: (state, action) => {
      state.donationUpdateStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    fetchDonationUpdateByStatusRequest: (state) => {
      state.donationActiveStatus = false;
      state.loading = true;
      state.error = "";
    },
    fetchDonationUpdateByStatusSuccess: (state, action) => {
      state.donationActiveStatus = true;
      state.loading = false;
      state.error = "";
    },
    fetchDonationUpdateByStatusFailure: (state, action) => {
      state.donationActiveStatus = false;
      state.loading = false;
      state.error = action.payload;
    },
    fetchDonationGetByIdRequest: (state) => {
      state.donationGetByIdStatus = false;
      state.donationDataById = [];
      state.error = "";
      state.loading = true;
    },
    fetchDonationGetByIdSuccess: (state, action) => {
      state.donationGetByIdStatus = true;
      state.donationDataById = action.payload;
      state.error = "";
      state.loading = false;
    },
    fetchDonationGetByIdFailure: (state, action) => {
      state.donationGetByIdStatus = false;
      state.donationDataById = [];
      state.error = action.payload;
      state.loading = false;
    },

    toggleStatus: (state) => {
      state.donationUploadStatus = false;
      state.donationUpdateStatus = false;
      state.donationActiveStatus = false;
      state.donationGetAllStatus = false;
      state.donationGetByIdStatus = false;
    },
  },
});

export const {
  fetchDonationRequest,
  fetchDonationSuccess,
  fetchDonationFailure,

  fetchDonationUploadRequest,
  fetchDonationUploadSuccess,
  fetchDonationUploadFailure,
  fetchDonationUpdateRequest,
  fetchDonationUpdateSuccess,
  fetchDonationUpdateFailure,
  fetchDonationUpdateByStatusRequest,
  fetchDonationUpdateByStatusSuccess,
  fetchDonationUpdateByStatusFailure,
  fetchDonationGetByIdRequest,
  fetchDonationGetByIdSuccess,
  fetchDonationGetByIdFailure,
  toggleStatus,
} = donationSlice.actions;

export default donationSlice.reducer;
