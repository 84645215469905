import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import "../styles/confrimationModal.css";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationModal = ({ open, onClose, onConfirm, actionType }) => {
  // Determine button text and colors based on the action type
  const isActivate = actionType === "activate";
  const primaryButtonColor = isActivate ? "#1D1D27" : "#ACAEC3";
  const primaryButtonText = isActivate ? "Activate" : "Cancel";
  const secondaryButtonText = "Cancel"; // Keep the cancel text the same for both scenarios
  const modalTitle = isActivate
    ? "Are you sure you want to Activate this User?"
    : "Are you sure you want to Inactivate this user?";

  const modalContent = isActivate
    ? ""
    : "By inactivating, this user will no longer have access to this panel.";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: "#141317",
          border: "1px solid #00D48B", // Border color
          borderRadius: "20px", // Border radius
          padding: "16px",
          color: "#fff", // Text color for contrast
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        <p className="Active_title"> {modalTitle} </p>

        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: "16px",
            top: "16px",
            color: "#fff",
            display: "flex",
            justifyContent: "flex-end",
            width: "auto",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#141317" }}>
        <p className="sub_Active_title">{modalContent}</p>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#141317" }}>
        <Button
          onClick={onClose}
          style={{
            color: "#ACAEC3",
            backgroundColor: "#141317",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "20px",
            border: "1px solid #67687C",
          }}
        >
          {isActivate ? "Cancel" : "InActivate"}
        </Button>
        <Button
          onClick={onConfirm}
          style={{
            backgroundColor: "#00D48B",
            color: "#1D1D27",
            fontWeight: 600,
            fontSize: "20px",
            textTransform: "none",
          }}
        >
          {primaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
