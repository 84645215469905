// CustomLoader.js
import React from "react";
import {
  Box,
  CircularProgress,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

const Overlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
});

const Dot = styled(Box)(({ theme }) => ({
  width: 15,
  height: 15,
  borderRadius: "50%",
  backgroundColor: "#FF6F01",
  margin: "0 5px",
  animation: "bounce 1.5s infinite",
  "@keyframes bounce": {
    "0%, 80%, 100%": { transform: "scale(0)" },
    "40%": { transform: "scale(1)" },
  },
}));

export const CustomLoader = ({ loading, message = "Loading..." }) => {
  if (!loading) return null;
  return (
    <Overlay>
      <div style={{ display: "flex" }}>
        <Dot sx={{ animationDelay: "0s" }} />
        <Dot sx={{ animationDelay: "0.2s" }} />
        <Dot sx={{ animationDelay: "0.4s" }} />
      </div>

      <Typography
        variant="body1"
        sx={{ mt: 2, fontSize: "1.2rem", fontWeight: 500 }}
      >
        {message}
      </Typography>
    </Overlay>
  );
};

export const FormLoader = ({ loading, message = "Loading..." }) => {
  if (!loading) return null;
  return (
    <>
      <Skeleton variant="text" height={56} />
      <Skeleton variant="rectangular" height={56} />
      <Skeleton variant="rectangular" height={56} />
      <Skeleton variant="rectangular" height={36} width="30%" />
    </>
  );
};

export const TableLoader = ({ loading, message = "Loading..." }) => {
  if (!loading) return null;
  const skeletonRows = 5;
  const skeletonColumns = 4;
  return (
    <>
      {Array.from({ length: skeletonRows }).map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {Array.from({ length: skeletonColumns }).map((_, colIndex) => (
            <TableCell key={colIndex}>
              <Skeleton variant="text" height={30} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export const ButtonLoader = ({ loading, message = "Loading..." }) => {
  if (!loading) return null;
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  );
};
