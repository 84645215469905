import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddButton } from "../../components/Addbutton";
import AntSwitch from "../../components/AntSwitch";
import SearchInput from "../../components/CommonSearchBar";
import CommonTable from "../../components/CommonTable";
import "../../styles/prasadam.css";
import AddEditPrasadam from "./Add/AddEditPrasadam";

import { useDispatch, useSelector } from "react-redux";
import { CustomLoader } from "../../components/CustomLoader";
import TableTooltip from "../../components/TableTooltip";
import {
  fetchPrasadamRequest,
  fetchPrasadamUpdateByStatusRequest,
} from "../../features/slices/prasadamSlice";

const Prasadam = React.memo(() => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);

  const navigate = useNavigate();
  const { actionType } = useParams();

  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const { prasadamData, prasadamActiveStatus, loading, Totalpage } =
    useSelector((state) => state.prasadam);

  useEffect(() => {}, [prasadamData, dispatch]);

  const columns = [
    { field: "name", headerName: "Prasadam Name" },
    {
      field: "description",
      headerName: "Description",
      renderCell: (params, row) => (
        <span className="action-row">
          <TableTooltip title={params} />
        </span>
      ),
    },
    { field: "price", headerName: "Price" },
    { field: "availableStock", headerName: "Available Stocks" },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params, row) => (
        <span className="action-row">
          <img
            src="/edit-icon.svg"
            alt="edit"
            className="edit-icon"
            onClick={() => onEdit(row)}
          />
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
            onChange={() => handleStatus(row.id, !row.active)}
            checked={row?.active}
          />
          Active
        </span>
      ),
    },
  ];

  const handleChangePage = (val) => {
    setPage(val);
  };

  const onAdd = () => {
    navigate("/prasadam-management/Add");
  };

  const onEdit = (row) => {
    navigate(`/prasadam-management/Edit?prasadamId=${row.id}`);
  };

  const handleStatus = (id, status) => {
    dispatch(fetchPrasadamUpdateByStatusRequest(id));
  };

  useEffect(() => {
    let queryParams;
    if (searchText) {
      queryParams = `?page=0&size=10&searchTerm=${searchText}`;
    } else {
      queryParams = `?page=${page - 1}&size=10&searchTerm=${searchText}`;
    }

    dispatch(fetchPrasadamRequest(queryParams));
  }, [dispatch, page, searchText, prasadamActiveStatus]);

  return (
    <>
      {actionType === "Edit" || actionType === "Add" ? (
        <AddEditPrasadam actionType={actionType} />
      ) : (
        <>
            <CustomLoader
              loading={loading}
              message="Please wait, We are Fetching Prasadams..."
            />
          <div className="prasadam-header">
            <Box className="heading">Prasadam Management</Box>
            <AddButton name="Add" onClick={() => onAdd()} icon={<AddIcon />} />
          </div>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "1rem 0",
            }}
          >
            <SearchInput setSearchTerm={setSearchText} />
          </Box>

          <CommonTable
            columns={columns}
            data={prasadamData}
            page={page}
            count={Totalpage}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </>
      )}
    </>
  );
});

export default Prasadam;
