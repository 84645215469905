// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/FaqCard.css */

.faq-card {
  border-bottom: 1px solid #ACAEC3;
  padding: 0.7rem 0;
  color: #333;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.question {
  font-style: var(--font-primary);
  font-weight: 800;
  color:#0D0C11;
}

.toggle-icon {
  font-size: 2rem;
  color: #46464F;
}

.answer {
  margin-left:1.5rem;
  margin-top: 0.7rem;
  color: #6E6E6E;
  font-size: 0.9rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/FAQCard.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;EACE,gCAAgC;EAChC,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":["/* src/components/FaqCard.css */\n\n.faq-card {\n  border-bottom: 1px solid #ACAEC3;\n  padding: 0.7rem 0;\n  color: #333;\n}\n\n.faq-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n}\n\n.question {\n  font-style: var(--font-primary);\n  font-weight: 800;\n  color:#0D0C11;\n}\n\n.toggle-icon {\n  font-size: 2rem;\n  color: #46464F;\n}\n\n.answer {\n  margin-left:1.5rem;\n  margin-top: 0.7rem;\n  color: #6E6E6E;\n  font-size: 0.9rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
