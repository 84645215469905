import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Close } from "@mui/icons-material";
import { AddButton } from "../../components/Addbutton";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import dayjs from "dayjs";
import { Tooltip, styled } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadDonationRequest,
  updateDonationRequest,
  fetchDonationRequest,
  DeleteImageRequest,
  toggleStatus,
} from "../../features/slices/onBordingSlice";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const initialFormState = {
  name: "",
  category: "",
  minimumAmount: "",
  goalAmount: "",
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD"),
  description: "",
  thumbnailPhoto: [],
  donationCertificate: [],
};

const initialErrorState = {
  name: "",
  category: "",
  minimumAmount: "",
  goalAmount: "",
  startDate: "",
  endDate: "",
  description: "",
  thumbnailPhoto: [],
  donationCertificate: [],
};
const DonationDetails = forwardRef((props, ref) => {
  const categoryList = [
    { label: "Seva Donation", value: "SEVA_DONATION" },
    { label: "Education", value: "EDUCATION" },
    { label: "Annadhanam", value: "ANNADHANAM" },
    { label: "Others", value: "OTHERS" },
  ];

  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialErrorState);

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { onBoardingUploadStatus, DonationData, imageDeleteStatus } =
    useSelector((state) => state.onbording);

  const handleChange = (field) => (event, newValue) => {
    const value = event.target.value;

    setFormData((prev) => ({
      ...prev,
      [field]: newValue ? newValue?.value : value,
    }));

    if (field === "availableStock" || field === "bookingAvailability") {
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const MAX_IMAGE_SIZE = 20 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

  const handleImageUpload = (field, event, MAX_FILES, uploadType) => {
    let selectedFiles;
    if (event.dataTransfer) {
      selectedFiles = Array.from(event.dataTransfer.files);
    } else {
      selectedFiles = Array.from(event.target.files);
    }

    if (selectedFiles?.length + (formData[field]?.length || 0) > MAX_FILES) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `You can only upload up to ${MAX_FILES} files at a time.`,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }

    const validFiles = [];

    selectedFiles.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type === "video/mp4";
      const isValidImageFormat = [
        "image/jpeg",
        "image/jpg",
        "image/png",
      ].includes(file.type);
      const maxSize = isImage ? MAX_IMAGE_SIZE : isVideo ? MAX_VIDEO_SIZE : 0;

      if (
        (uploadType === "image/*" && (!isImage || !isValidImageFormat)) ||
        (uploadType === "video/mp4" && !isVideo)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Invalid file type. Please upload ${
            uploadType === "image/*" ? "JPEG, JPG, or PNG images" : "MP4 videos"
          }.`,
        }));
      } else if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `File size should be less than ${isImage ? "20MB" : "50MB"}.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
        validFiles.push(file);
      }
    });

    if (validFiles?.length) {
      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFormData((prev) => ({
            ...prev,
            [field]: [
              ...(prev[field] || []),
              { name: file.name, url: e.target.result, file: file },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeUpload = (field, index, item) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      const newErrors = { ...initialErrorState };
      let isValid = true;

      if (!formData.name) {
        newErrors.name = "Donation Name is required";
        isValid = false;
      }

      if (!formData.category) {
        newErrors.category = "Donation Category is required";
        isValid = false;
      }

      if (!formData.description) {
        newErrors.description = "Description is required";
        isValid = false;
      }

      if (formData.thumbnailPhoto.length === 0) {
        newErrors.thumbnailPhoto = "At least one image is required";
        isValid = false;
      }

      setErrors(newErrors);

      if (isValid) {
        let formArray = new FormData();

        if (!formData?.thumbnailPhoto[0]?.etag) {
          formArray.append("file", formData?.thumbnailPhoto[0]?.file);
        }

        formArray.append("name", formData?.name);
        formArray.append("category", formData?.category);
        formArray.append("minimumAmount", formData?.minimumAmount);
        formArray.append("goalAmount", formData?.goalAmount);
        formArray.append("startDate", formData?.startDate);
        formArray.append("endDate", formData?.endDate);
        formArray.append("description", formData?.description);
        if (formData?.donationCertificate.length > 0) {
          const filteredPhotos = formData?.donationCertificate.filter(
            (photo) => !photo.etag,
          );
          if (filteredPhotos && filteredPhotos.length > 0)
            formArray.append(
              "cerficiatePhoto",
              formData?.donationCertificate[0]?.file,
            );
        }

        if (DonationData && DonationData?.id) {
          dispatch(updateDonationRequest({ id: DonationData?.id, formArray }));
        } else {
          dispatch(uploadDonationRequest(formArray));
        }
      }
    },
  }));

  useEffect(() => {
    dispatch(fetchDonationRequest());
  }, [dispatch, imageDeleteStatus]);

  const mapImages = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      const newArr = images.map((item) => {
        const newBlob = new Blob([new ArrayBuffer(item?.fileSizeInBytes)]);
        const newFile = new File([newBlob], item?.fileName, {
          type: item?.fileType,
          size: item.fileSizeInBytes,
          lastModified: Date.now(),
        });
        return {
          name: item?.fileName,
          url: item?.filePath,
          etag: item?.etag,
          file: newFile,
        };
      });
      return newArr;
    }
    return [];
  };

  useEffect(() => {
    if (DonationData && Object.values(DonationData).length > 0) {
      setFormData({
        ...formData,
        name: DonationData?.name,
        category: DonationData?.category,
        minimumAmount: DonationData?.minimumAmount,
        goalAmount: DonationData?.goalAmount,
        startDate: DonationData?.startDate,

        endDate: DonationData?.endDate,

        description: DonationData?.description,
        thumbnailPhoto: DonationData?.thumbnailPhoto
          ? mapImages([DonationData.thumbnailPhoto])
          : [],
        donationCertificate: DonationData?.donationCertificate
          ? mapImages([DonationData.donationCertificate])
          : [],
      });
    }
  }, [DonationData]);

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (field, e, maxFiles, uploadType) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    handleImageUpload(field, e, maxFiles, uploadType);
  };

  return (
    <>
      <div className="heading">Donation Details</div>
      {/* <div variant="h6">
        Note: List all the donations that the User can donate to your temple.
      </div> */}

      <div className="onboarding-sub_heading">Basic Details</div>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 6, md: 6 }}>
          <Typography variant="subtitle1" className="donation-form-title">
            Donation Name*
          </Typography>

          <TextField
            fullWidth
            disabled={props?.previewStatus ? true : false}
            variant="outlined"
            value={formData?.name}
            onChange={handleChange("name")}
            className="custom-textfield"
            placeholder="Enter donation name"
          />

          {errors?.name && (
            <Typography color="error">{errors?.name}</Typography>
          )}
        </Grid2>

        <Grid2 size={{ xs: 6, sm: 6 }}>
          <Typography variant="subtitle1" className="donation-form-title">
            Donation Category*
          </Typography>

          <FormControl fullWidth variant="outlined" className="custom-select">
            <Autocomplete
              disabled={props?.previewStatus ? true : false}
              options={categoryList}
              disableClearable
              getOptionLabel={(option) => option.label}
              value={
                categoryList.find(
                  (option) => option.value === formData.category,
                ) || null
              }
              // value={formData.category}
              onChange={(e, newVal) => {
                setFormData({ ...formData, category: newVal.value });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Donation Category"
                  variant="outlined"
                />
              )}
            />

            {errors.category && (
              <Typography color="error">{errors.category}</Typography>
            )}
          </FormControl>
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 6, md: 6 }}>
          <Typography variant="subtitle1" className="donation-form-title">
            Minimum Amount
          </Typography>

          <TextField
            fullWidth
            disabled={props?.previewStatus ? true : false}
            type="number"
            variant="outlined"
            value={formData?.minimumAmount}
            onChange={handleChange("minimumAmount")}
            className="custom-textfield"
            placeholder="Enter Minimum Amount"
          />
        </Grid2>

        <Grid2 size={{ xs: 6, md: 6 }}>
          <Typography variant="subtitle1" className="donation-form-title">
            Goal
          </Typography>

          <TextField
            fullWidth
            disabled={props?.previewStatus ? true : false}
            type="number"
            variant="outlined"
            value={formData?.goalAmount}
            onChange={handleChange("goalAmount")}
            className="custom-textfield"
            placeholder="Enter Goal"
          />
        </Grid2>
      </Grid2>
      <div className="onboarding-sub_heading">Schedule Information</div>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 6, sm: 6 }}>
          <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Typography variant="subtitle1" className="donation-form-title">
              Start Date
            </Typography>
            <LightTooltip
              sx={{
                color: "black !important",
                background: "white !important",
              }}
              title="Start & end date are tentative and will change with accordance of Onboarding Process, You can also change dates after successful Onboarding"
            >
              <InfoIcon
                sx={{ color: "#ACAEC3", width: "1.8rem", height: "1.8rem" }}
              />
            </LightTooltip>
          </span>

          <div className="muiNormal-date-picker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  "& .MuiButtonBase-root": { color: "#67687c" },
                  width: "100%",
                }}
                value={dayjs(formData.startDate, "YYYY-MM-DD")}
                disabled={props?.previewStatus ? true : false}
                onChange={(newValue) => {
                  setFormData({
                    ...formData,
                    startDate: dayjs(newValue).format("YYYY-MM-DD"),
                  });
                }}
              />
            </LocalizationProvider>
          </div>
        </Grid2>

        <Grid2 size={{ xs: 6, sm: 6 }}>
          <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Typography variant="subtitle1" className="donation-form-title">
              End Date
            </Typography>
            <LightTooltip title="Start & end date are tentative and will change with accordance of Onboarding Process, You can also change dates after successful Onboarding">
              <InfoIcon
                sx={{ color: "#ACAEC3", width: "1.8rem", height: "1.8rem" }}
              />
            </LightTooltip>
          </span>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                "& .MuiButtonBase-root": { color: "#67687c" },
                width: "100%",
              }}
              value={dayjs(formData.endDate, "YYYY-MM-DD")}
              disabled={props?.previewStatus ? true : false}
              onChange={(newValue) => {
                setFormData({
                  ...formData,
                  endDate: dayjs(newValue).format("YYYY-MM-DD"),
                });
              }}
              defaultValue={dayjs("2022-04-17")}
            />
          </LocalizationProvider>
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 6, sm: 6 }}>
          <Typography variant="subtitle1" className="donation-form-title">
            Donation Description*
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            disabled={props?.previewStatus ? true : false}
            value={formData.description}
            onChange={handleChange("description")}
            placeholder="Enter Description"
            className={["custom-textfield", "input-fields"]}
          />

          {errors?.description && (
            <Typography color="error">{errors?.description}</Typography>
          )}
        </Grid2>

        <Grid2 size={{ xs: 6, md: 6 }}>
          <Box>
            <Typography variant="subtitle1" className="donation-form-title">
              Donation Thumbnail Photo*
            </Typography>

            <Box
              className="file-upload-box"
              onDrop={(e) => handleDrop("thumbnailPhoto", e, 1, "image/*")}
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
            >
              {formData?.thumbnailPhoto?.length > 0 ? (
                <Box className="file-preview-image-container">
                  {formData?.thumbnailPhoto?.map((item, index) => (
                    <Box className="prasadam-image-container">
                      {!props?.previewStatus && (
                        <Close
                          className="song-image-close-icon"
                          onClick={() =>
                            removeUpload("thumbnailPhoto", index, item)
                          }
                        />
                      )}

                      <img
                        src={item.url}
                        className="prasadam-image"
                        alt="song_img"
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              {!props?.previewStatus && (
                <>
                  <Typography className="file-upload-instructions">
                    <UploadFileIcon className="upload-file-icon" />
                    Drop Your File Here
                  </Typography>

                  <Typography className="file-upload-instructions">
                    OR
                  </Typography>
                  <input
                    ref={fileInputRef}
                    accept="image/*"
                    name="thumbnailPhoto"
                    type="file"
                    className="prasadam-file-input"
                    id="prasadam-package-upload-package"
                    onChange={(e) =>
                      handleImageUpload("thumbnailPhoto", e, 1, "image/*")
                    }
                  />
                  <label htmlFor="prasadam-package-upload-package">
                    <Button
                      variant="outlined"
                      component="span"
                      className="browse-button"
                    >
                      Browse file
                    </Button>
                  </label>

                  <Typography className="file-upload-instructions">
                    Max: 20MB, JPG, PNG
                  </Typography>
                </>
              )}
            </Box>
          </Box>

          {errors?.thumbnailPhoto && (
            <Typography color="error">{errors?.thumbnailPhoto}</Typography>
          )}
        </Grid2>
      </Grid2>
      <div className="onboarding-sub_heading">User Benefits</div>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 6, md: 6 }}>
          <Box>
            <Typography variant="subtitle1" className="donation-form-title">
              Donation Certificate (if available)
            </Typography>

            <Box
              className="file-upload-box"
              onDrop={(e) => handleDrop("donationCertificate", e, 1, "image/*")}
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
            >
              {formData?.donationCertificate?.length > 0 ? (
                <Box className="file-preview-image-container">
                  {formData?.donationCertificate?.map((item, index) => (
                    <Box className="prasadam-image-container">
                      {!props?.previewStatus && (
                        <Close
                          className="song-image-close-icon"
                          onClick={() =>
                            removeUpload("donationCertificate", index, item)
                          }
                        />
                      )}

                      <img
                        src={item.url}
                        className="prasadam-image"
                        alt="song_img"
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              {!props?.previewStatus && (
                <>
                  <Typography className="file-upload-instructions">
                    <UploadFileIcon className="upload-file-icon" />
                    Drop Your File Here
                  </Typography>

                  <Typography className="file-upload-instructions">
                    OR
                  </Typography>
                  <input
                    ref={fileInputRef}
                    accept="image/*"
                    name="thumbnailPhoto"
                    type="file"
                    className="prasadam-file-input"
                    id="prasadam-package-upload-package-donationCertificate"
                    onChange={(e) =>
                      handleImageUpload("donationCertificate", e, 1, "image/*")
                    }
                  />
                  <label htmlFor="prasadam-package-upload-package-donationCertificate">
                    <Button
                      variant="outlined"
                      component="span"
                      className="browse-button"
                    >
                      Browse file
                    </Button>
                  </label>

                  <Typography className="file-upload-instructions">
                    Max: 20MB, JPG, PNG
                  </Typography>
                </>
              )}
            </Box>
          </Box>
          {errors?.donationCertificate && (
            <Typography color="error">{errors?.donationCertificate}</Typography>
          )}
        </Grid2>
      </Grid2>
    </>
  );
});

export default DonationDetails;
