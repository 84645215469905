import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
} from "@mui/material";

const CommonTable = ({
  columns,
  data,
  page,
  rowsPerPage,
  onPageChange,
  count,
}) => {
  const renderCellContent = (column, row) => {
    return column?.renderCell
      ? column?.renderCell(row[column?.field], row)
      : row[column?.field];
  };

  return (
    <Paper className="table_height" sx={{ background: "white" }}>
      <TableContainer className="custom-table-container">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell key={column?.field}>{column?.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index} hover>
                {columns?.map((column) => (
                  <TableCell key={column?.field}>
                    {renderCellContent(column, row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Centering the pagination */}
      {count ? (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            margin: 0,
            padding: 0,
            marginTop: 2,
            backgroundColor: "var(--black)",
          }}
        >
          <Pagination
            component="div"
            sx={{
              ".MuiTablePagination-displayedRows": {
                fontSize: "1.15rem",
                fontWeight: 600,
                textAlign: "right",
              },
            }}
            colSpan={6}
            count={count ? count : 0}
            rowsPerPage={rowsPerPage ? rowsPerPage : 0}
            page={page ? page : 0}
            onChange={(event, newPage) => onPageChange(newPage)}
          />
        </Box>
      ) : null}
    </Paper>
  );
};

export default CommonTable;
