import React, { useEffect, useState, useRef } from "react";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import { AddButton } from "../components/Addbutton";
import DashboardStartpage from "./Onboarding/DashboardStartpage";
import PurohitDetails from "./Onboarding/PurohitDetails";
import PurohitDocumentation from "./Onboarding/PurohitDocumentation";
import ExperienceAndLineage from "./Onboarding/ExperienceAndLineage";
import PujaOfferingsAndServices from "./Onboarding/PujaOfferingsAndServices";
import SelectedPujaOfferings from "./Onboarding/SelectedPujaOfferings";
import TimingAndAvailability from "./Onboarding/TimingAndAvailability";
import OnboardingPreview from "./Onboarding/OnboardingPreview";

import {
  fetchOnBoardingStatusRequest,
  toggleStatus,
} from "../features/slices/purohitOnboardingSlice";
import { useDispatch, useSelector } from "react-redux";

const steps = [
  { label: "Purohit Details" },
  { label: "Purohit Documentation" },
  { label: "Experience & Lineage" },
  { label: "Puja Offerings & Services" },
  { label: "Selected Puja Offerings" },
  { label: "Timing & Availability" },
];

const stepMapping = {
  SELECTED_PUJA: steps.length - 1,
};

const PurohitOnboarding = () => {
  const [activeStep, setActiveStep] = useState(0);

  const [openPreview, setOpenPreview] = useState(false);
  const purohitDetailsRef = useRef(null);
  const purohitDocumentationRef = useRef(null);
  const experienceAndLineageRef = useRef(null);
  const pujaOfferingsAndServicesRef = useRef(null);
  const selectedPujaOfferingsRef = useRef(null);
  const timingAndAvailabilityRef = useRef(null);

  const {
    loading,
    onBoardingActiveStatus,
    onBoardingUploadStatus,
    onBoardingStatusDtata,
  } = useSelector((state) => state.purohitOnboarding);
  const dispatch = useDispatch();

  const handleNext = async () => {
    let success = false;

    switch (activeStep) {
      case 0:
        success = await purohitDetailsRef.current.handleSubmit();
        break;
      case 1:
        success = await purohitDocumentationRef.current.handleSubmit();
        break;
      case 2:
        success = await experienceAndLineageRef.current.handleSubmit();
        break;
      case 3:
        success = await pujaOfferingsAndServicesRef.current.handleSubmit();
        break;
      case 4:
        success = await selectedPujaOfferingsRef.current.handleSubmit();
        break;
      case 5:
        success = await timingAndAvailabilityRef.current.handleSubmit();
        break;
      default:
        break;
    }

    if (onBoardingUploadStatus) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    dispatch(fetchOnBoardingStatusRequest());
  }, [dispatch]);

  useEffect(() => {
    if (onBoardingStatusDtata) {
      const stepIndex = stepMapping[onBoardingStatusDtata] ?? 0;
      setActiveStep(stepIndex);
    }
  }, [onBoardingStatusDtata]);

  const renderFormStep = () => {
    switch (activeStep) {
      case 0:
        return <PurohitDetails ref={purohitDetailsRef} />;
      case 1:
        return <PurohitDocumentation ref={purohitDocumentationRef} />;
      case 2:
        return <ExperienceAndLineage ref={experienceAndLineageRef} />;
      case 3:
        return <PujaOfferingsAndServices ref={pujaOfferingsAndServicesRef} />;
      case 4:
        return <SelectedPujaOfferings ref={selectedPujaOfferingsRef} />;
      case 5:
        return <TimingAndAvailability ref={timingAndAvailabilityRef} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", background: "white" }}>
      <Box sx={{ width: 240, pt: 2 }}>
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "16px",
          }}
        >
          <img src="/daiv-icon.svg" alt="img" />
          <p className="daivFontstyle">Daiv</p>
        </Typography>
        <div className="stepper-head">{activeStep + 1}/6 Progress</div>
        <Stepper orientation="vertical" activeStep={activeStep} sx={{ pl: 2 }}>
          {steps.map((step, index) => (
            <Step key={step.label} completed={activeStep > index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <div style={{ height: "5rem" }}>
          <p className="daivFontstyle" style={{ padding: 0 }}>
            Welcome To Daiv
          </p>
          <div className="heading" style={{ margin: "-0.75rem 0.5rem" }}>
            Please Setup The Following Details
          </div>
        </div>
        <Box sx={{ flexGrow: 1, p: 1 }}>
          {openPreview ? (
            <Box className="onboarding-message-container">
              <DashboardStartpage />
            </Box>
          ) : (
            <Box className="onboarding-main-container">{renderFormStep()}</Box>
          )}
          {!openPreview && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
              <AddButton
                name="Back"
                onClick={handleBack}
                background="#FFFFFF"
                color="#A9392D"
                disabled={activeStep === 0}
                borderColor="var(--secondary)"
                sx={{
                  marginRight: "1rem",
                }}
              />
              <AddButton
                name={
                  activeStep === steps.length - 1 ? "Finish" : "Save & Continue"
                }
                onClick={handleNext}
                background="#A9392D"
                color="#FFFFFF"
                borderColor="var(--secondary)"
                sx={{
                  marginRight: "1rem",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PurohitOnboarding;
