import React, { useEffect, useState } from "react";
import Tabs from "../components/common/Tabs";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../styles/SettingsAndPreferences.css";

const SettingAndPrefrences = React.memo(() => {
  const userRole = localStorage.getItem("role");
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(() => {
    let path = location.pathname.split("/");

    if(userRole === "ROLE_TEMPLE_OWNER") {
      return path[2] ? path[2] : "temple-details";
    } else if(userRole === "ROLE_PUROHIT")
      return path[2] ? path[2] : "profile";
  });

  const tabItems = userRole === "ROLE_TEMPLE_OWNER" ? [
    { label: "Temple Details", value: "temple-details" },
    { label: "Admin Details", value: "admin-details" },
    { label: "Notifications", value: "notifications" },
  ] : userRole === "ROLE_PUROHIT" ? [
    { label: "Profile", value: "profile" },
    { label: "Notifications", value: "notifications" },
  ] : [];

  useEffect(() => {
    navigate(selectedMenu);
  }, []);

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
    navigate(menu);
  };

  return (
    <>
      <div>
        <div className="heading">Settings & Preferences</div>
        <div style={{ margin: "1rem 0" }}>
          <Tabs
            userRole={userRole}
            tabs={tabItems}
            tabStart={selectedMenu}
            onClick={handleMenuButtonClick}
          />
        </div>
        <Outlet />
      </div>
    </>
  );
});
export default SettingAndPrefrences;
